import createRouterRoutes from '../createRouterRoutes'
import prosports from '../../config/sports/pro/prosports'

const routes = createRouterRoutes(prosports)

routes.push(
  {
    path: '/sports',
    name: 'sports',
    component: () => import('@/views/sports/Sport.vue'),
    meta: {
      pageTitle: 'Sports',
      breadcrumb: [
        {
          text: 'Sports',
          active: true,
        },
      ],
      resource: 'Public',
      action: 'read',
    },
  },
)

routes.push(
  {
    path: '/headline',
    name: 'headline',
    component: () => import('@/views/sports/Sport.vue'),
    meta: {
      pageTitle: 'Headline',
      breadcrumb: [
        {
          text: 'Headline',
          active: true,
        },
      ],
      resource: 'Public',
      action: 'read',
    },
  },
)

export default routes

export default [
  {
    path: '/authors',
    name: 'authors',
    component: () => import(/* webpackChunkName: "Authors" */ '@/views/authors/Authors.vue'),
    meta: {
      pageTitle: 'Authors',
      breadcrumb: [
        {
          text: 'Authors',
          active: true,
        },
      ],
      resource: 'Authors',
      action: 'read',
    },
  },

  {
    path: '/authors/posts',
    name: 'authors-post-list',
    component: () => import('@/views/authors/PostList.vue'),
    meta: {
      pageTitle: 'Post List',
      breadcrumb: [
        {
          text: 'Post List',
          active: true,
        },
      ],
      resource: 'User',
      action: 'read',
    },
  },

  {
    path: '/authors/table',
    name: 'authors-post-table',
    component: () => import('@/views/authors/TableKitchenSink.vue'),
    meta: {
      pageTitle: 'Posts',
      breadcrumb: [
        {
          text: 'Posts',
          active: true,
        },
      ],
      resource: 'Authors',
      action: 'read',
    },
  },

  {
    path: '/moderators/feeds',
    name: 'feed-table',
    component: () => import('@/views/authors/TableFeeds.vue'),
    meta: {
      pageTitle: 'Feeds',
      breadcrumb: [
        {
          text: 'Feeds',
          active: true,
        },
      ],
      resource: 'Moderators',
      action: 'read',
    },
  },

  {
    path: '/moderators/ads',
    name: 'ad-table',
    component: () => import('@/views/authors/TableAds.vue'),
    meta: {
      pageTitle: 'Ads',
      breadcrumb: [
        {
          text: 'Ads',
          active: true,
        },
      ],
      resource: 'Moderators',
      action: 'read',
    },
  },

  {
    path: '/authors/map',
    name: 'map',
    component: () => import('@/views/Map/Map.vue'),
    meta: {
      pageTitle: 'Map',
      breadcrumb: [
        {
          text: 'Map',
          active: true,
        },
      ],
      resource: 'Authors',
      action: 'read',
    },
  },

  {
    path: '/authors/post/edit/:id',
    name: 'authors-post-edit',
    component: () => import('@/views/authors/PostEdit.vue'),
    meta: {
      pageTitle: 'Post Edit',
      breadcrumb: [
        {
          text: 'Post Edit',
          active: true,
        },
      ],
      resource: 'Authors',
      action: 'read',
    },
  },

  {
    path: '/authors/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/authors/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account',
          active: true,
        },
      ],
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/authors/profile',
    name: 'pages-profile',
    component: () => import('@/views/authors/profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
      resource: 'User',
      action: 'read',
    },
  },
]

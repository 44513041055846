export default [
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('@/views/Home.vue'),
  //   meta: {
  //     pageTitle: 'Home',
  //     resource: 'Public',
  //     action: 'read',
  //     // layout: 'main',
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/go/:slug',
    name: 'go-slug',
    component: () => import('@/views/pages/Go.vue'),
    meta: {
      pageTitle: 'Go',
      resource: 'Public',
      action: 'read',
      layout: 'full',
    },
  },
  {
    path: '/go/:sport/:slug',
    name: 'go-sport-slug',
    component: () => import('@/views/pages/Go.vue'),
    meta: {
      pageTitle: 'Go',
      resource: 'Public',
      action: 'read',
      layout: 'full',
    },
  },
  {
    path: '/go/:sport/:team/:slug',
    name: 'go-sport-team-slug',
    component: () => import('@/views/pages/Go.vue'),
    meta: {
      pageTitle: 'Go',
      resource: 'Public',
      action: 'read',
      layout: 'full',
    },
  },
  {
    path: '/pages/second-page',
    name: 'second-page',
    component: () => import('@/views/pages/SecondPage.vue'),
    meta: {
      pageTitle: 'Second Page',
      breadcrumb: [
        {
          text: 'Second Page',
          active: true,
        },
      ],
      resource: 'Public',
    },
  },

  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "Search" */ '@/views/pages/Search.vue'),
    props: true,
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Search',
          active: true,
        },
      ],
      resource: 'Public',
      action: 'read',
    },
  },
]

export default {
  // Endpoints
  //   - Previously the end points were hardcoded as this:
  //       loginEndpoint: `${window.location.protocol}//${window.location.hostname}:3000/api/v1/users/login`,
  //     But, the endpoints are called from the jwtService.js which defines the axiosIns from the axios.js
  //     file where the 'baseURL' is defined already, so there is no need to explicity define them here.
  loginEndpoint: '/users/login',
  registerEndpoint: '/users/register',
  refreshEndpoint: '/users/refresh-token',
  logoutEndpoint: '/users/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}

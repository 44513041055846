/* eslint-disable array-bracket-spacing */
/* eslint-disable comma-dangle */
export default {
  title: 'NFL',
  category: 'nfl',
  path: '/nfl',
  route: 'sports-nfl',
  size: '30px',
  style: 'padding: 5px 10px;',
  iconSource: 'image',
  images: [
    'https://www.pixelstalk.net/wp-content/uploads/images6/NFL-Pictures-Free-Download.jpg',
    'https://www.pixelstalk.net/wp-content/uploads/images6/NFL-Desktop-Wallpaper.jpg',
    'https://mcdn.wallpapersafari.com/medium/97/21/ifxDet.jpg',
    'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/p1ptxud8c3cys7xtgtbg',
    'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/zlfx8z87zigiautrifm2'
  ],
  logo: 'https://content.sportslogos.net/logos/7/1007/thumbs/dwuw5lojnwsj12vfe0hfa6z47.gif',
  createTeamRoutes: true,
  createTeamNavRoutes: true,
  divisions: {
    nfc: {
      title: 'NFC',
      icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/National_Football_Conference_logo.svg/225px-National_Football_Conference_logo.svg.png',
      iconSource: 'image',
      teams: [
        {
          name: 'Cowboys',
          code: 'DAL',
          path: 'cowboys',
          location: 'dallas',
          logo: 'https://content.sportslogos.net/logos/7/165/thumbs/406.gif',
          youtube: [ 'https://www.youtube.com/c/DallasCowboys' ],
          nicknames: [ 'cowboys', 'boys', 'americas team', 'triplets' ],
          keywords: [],
          colors: [ '#041E42', '#869397' ],
          rivals: [ 'Giants', 'Eagles', 'Commanders' ],
          images: [
            'https://content.sportslogos.net/logos/7/165/thumbs/406.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/fmqkeomhq45nu96qwatz.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/knckumoogjqy1hgieyup',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/jnd1nxikvcbx5oqismgm',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/hpmzimnissf23es5jj9x.jpg',
            'https://dallascowboyscheerleaders.com/wp-content/uploads/2021/11/20211003_DalvCar_cheer_Gina-2-1-scaled.jpg',
            'https://dallascowboyscheerleaders.com/wp-content/uploads/2021/11/Gina-059-scaled.jpg',
            'https://dallascowboyscheerleaders.com/wp-content/uploads/2021/11/BJB46904.jpg',
            'https://i.giphy.com/media/BL3qxXWvA7GI6drD6J/giphy.webp',
            'https://dallascowboyscheerleaders.com/wp-content/uploads/2020/08/20191215_DalvLAR_cheer_Kelcey-1-scaled.jpg',
            'https://dallascowboyscheerleaders.com/wp-content/uploads/2020/08/7Z7A2456-scaled.jpg',
            'https://dallascowboyscheerleaders.com/wp-content/uploads/2020/08/B900AF9D-C7DD-4CDF-852E-CDB9FDBDE8F3-scaled.jpg',
            'https://insidethestar.com/wp-content/uploads/2015/08/are-you-ready-to-get-loud-cowboys-fans.jpeg',
            'https://i0.wp.com/insidethestar.com/wp-content/uploads/2022/08/tonystahl_game-notes_cowboys-at-chargers-preseason-preview.jpg',
            'https://i0.wp.com/insidethestar.com/wp-content/uploads/2022/07/nickcoppola_game-notes_2022-cowboys-season-preview-week-11-vs-vikings.jpg',
            'https://i0.wp.com/insidethestar.com/wp-content/uploads/2021/08/jess-haynie_dallas-cowboys_cooper-rush-makes-a-case-for-backup-qb-e1629645544661.jpg',
            'https://i0.wp.com/insidethestar.com/wp-content/uploads/2022/07/nickcoppola_game-notes_2022-cowboys-season-preview-week-10-vs-packers.jpg',
            'https://i0.wp.com/insidethestar.com/wp-content/uploads/2022/07/nickcoppola_dallas-cowboys_2022-cowboys-season-preview-week-8-vs-bears.jpg',
            'https://i0.wp.com/insidethestar.com/wp-content/uploads/2017/11/bmartin_dallas-cowboys_cowboys-offense-more-hot-sauce-needed-from-cole-beasley-3.jpg',
            'https://images.complex.com/complex/images/c_fill,dpr_auto,f_auto,q_auto,w_1400/fl_lossy,pg_1/cxpnkuzi6rzdd0cjfn9s/cowboys-fan-shoots-tv?fimg-ssr',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-article/001/595/892/9bc69fe7086fd55b587c8cb38094ec6f_crop_exact.jpg',
            'https://sportshub.cbsistatic.com/i/r/2017/09/01/0d211fd7-f554-463b-a461-a7006283820b/thumbnail/770x433/bf5fc6dae02120a9ac1b7c1f7b8821d4/cowboys-fans.jpg',
            'https://dmn-dallas-news-prod.cdn.arcpublishing.com/resizer/pB8B5syXCJ3_fQFKZ2303ukJphE=/1660x934/smart/filters:no_upscale()/cloudfront-us-east-1.images.arcpublishing.com/dmn/Z65F6N5GQ5H2TAA4C2KHKBPRJI.jpg',
            'https://cdn.vox-cdn.com/thumbor/UBJCsAfiSES15uPXZ_4En97S-3o=/0x0:3508x2487/1200x800/filters:focal(1569x796:2129x1356)/cdn.vox-cdn.com/uploads/chorus_image/image/70206921/1351892539.0.jpg',
            'https://insidethestar.com/wp-content/uploads/2015/08/are-you-ready-to-get-loud-cowboys-fans.jpeg',
            'https://a.espncdn.com/photo/2018/0411/r355081_630x354cc.jpg',
            'https://pbs.twimg.com/media/FYcBAc3WQAIbXBX?format=jpg&name=small',
            'https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTkwMzY3Mzk1ODc1OTIzMjA0/dak-jump-.jpg',
            'https://akns-images.eonline.com/eol_images/Entire_Site/2012714/634.pepsi.kelly.mh.081412.jpg?fit=around%7C634:1024&output-quality=90&crop=634:1024;center,top'
          ]
        },
        {
          name: 'Giants',
          code: 'NYG',
          path: 'giants',
          location: 'new york',
          logo: 'https://content.sportslogos.net/logos/7/166/thumbs/919.gif',
          youtube: [ 'https://www.youtube.com/c/NewYorkGiants' ],
          nicknames: [ 'giants', 'g men', 'big blue', 'big blue wrecking crew' ],
          keywords: [],
          colors: [ '#0B2265', '#A71930' ],
          rivals: [ 'Cowboys', 'Eagles', 'Commanders' ],
          images: [
            'https://content.sportslogos.net/logos/7/166/thumbs/919.gif',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/ojztyqeftncdztby9psz',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/kbmee8553qegbgvtkvvv',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/sp2omwtz3ltkorvaxqhi.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/giants/m786ogknuvedsh19lqvf.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/giants/aqnjxwvivpbal2w5n0zh.jpg',
            'http://assets.sbnation.com/assets/76532/giants_cheerwinner.jpg',
            'https://i.pinimg.com/originals/d2/2c/e2/d22ce2ffe6323edaf7419c64d0586e81.jpg',
            'https://a.espncdn.com/photo/2012/0203/espnw_a_giantfans_sy_576.jpg',
            'https://i.pinimg.com/originals/95/70/16/957016252fabb83d47bd8a478e9b4924.jpg',
            'https://www.premiumseatsusa.com/special-events/wp-content/uploads/2017/07/15585423_10209620543045099_3976311836959334660_o.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fgmenhq.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F09%2F623238808-cincinnati-bengals-v-new-york-giants.jpg-850x560.jpg',
            'https://cdn.vox-cdn.com/thumbor/4XGokz-BSWaf-WxH82uvPFLhpBI=/0x0:4072x3033/1200x800/filters:focal(1711x1192:2361x1842)/cdn.vox-cdn.com/uploads/chorus_image/image/68855874/usa_today_10335575.0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fgmenhq.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2022%2F01%2F17479013-850x560.jpeg',
            'https://www.gannett-cdn.com/presto/2021/10/24/PNJM/a22d6958-f184-452e-8a4b-3023a4757849-jones_catch.JPG',
            'https://static.foxnews.com/foxnews.com/content/uploads/2021/10/Daniel-Jones5.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/giants/x1b9g0xwl7djxtb2dfrw.jpg',
            'https://www.si.com/.image/t_share/MTgzNTQxMjQwMzM4MzI2NTY2/usatsi_16651369_168386747_lowres.jpg',
            'https://images.spot.im/v1/production/ngeerk8pgo2unakulefh',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fgmenhq.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2022%2F05%2F1344520754.jpeg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/giants/p7fwojq18fgg1psbgch9.jpg',
            'https://empiresportsmedia.com/wp-content/uploads/2021/11/USATSI_17115725-1-scaled.jpg',
            'https://www.gannett-cdn.com/presto/2022/05/19/PDTN/b1bdf4c9-88ef-4dc7-8d06-aca61d24a20c-Screen_Shot_2022-05-18_at_9.20.31_PM.png',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fgmenhq.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2016%2F04%2F1191305104-850x560.jpeg',
            'https://sportshub.cbsistatic.com/i/r/2021/09/17/3dac9bb0-f20d-4d7b-8c59-c975963b0dae/thumbnail/1200x675/9d0f4f4f0032749d8128e91b7a49077d/saquon-barkley-giants-3.jpg',
            'https://empiresportsmedia.com/wp-content/uploads/2019/10/USATSI_13401459-1-1024x720.jpg',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/aocm3raiir1486l5zpmo',
            'https://theawesomedaily.com/wp-content/uploads/2013/12/nfl-fans-3-500x600.jpg'
          ]
        },
        {
          name: 'Eagles',
          code: 'PHI',
          path: 'eagles',
          location: 'philadelphia',
          logo: 'https://content.sportslogos.net/logos/7/167/thumbs/960.gif',
          youtube: [ 'https://www.youtube.com/c/eagles' ],
          nicknames: [ 'eagles', 'birds', 'iggles' ],
          keywords: [],
          colors: [ '#004C54', '#A5ACAF' ],
          rivals: [ 'Giants', 'Cowboys', 'Commanders' ],
          images: [
            'https://content.sportslogos.net/logos/7/167/thumbs/960.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/zmsfe3veqk3hhictadzc.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/umrlyaz9iel2zyo3usvd',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/qzbb4mfz4kmnre3msgbm',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/eagles/lnrx8xmzzrbbepaqry3f.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/eagles/hgfebsebf9pp9hnhsuru.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/eagles/bcvsfqgpkmrk0boxm9z0.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/eagles/tvtl7lev3cyvtmobfih1.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/eagles/ndw8nrj2lwzsnwuqfjog.jpg',
            'https://static.clubs.nfl.com/image/private/t_portrait_tablet_desktop/f_png/eagles/af0bzhr89kukpcld6lmx.png',
            'https://static.clubs.nfl.com/image/private/t_portrait_tablet_desktop/f_auto/eagles/h8vujys1dpbkmialsyh3.jpg',
            'https://static.clubs.nfl.com/image/private/t_portrait_tablet_desktop/f_png/eagles/zzysqwuzoxs5vagmjm6q.png',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Finsidetheiggles.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F457906222-850x560.jpeg',
            'https://i.pinimg.com/originals/b1/06/06/b106060a7eb24e50ec0cee5f00b7c28c.jpg',
            'https://townsquare.media/site/399/files/2021/09/attachment-GettyImages-1339873362.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fsection215.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F15341940-850x560.jpeg',
            'https://cdn.vox-cdn.com/thumbor/cAWTe-a_sZ33NPRhY4fmidceYWc=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22794809/1333880468.jpg',
            'https://res.cloudinary.com/rotowire/image/upload/ar_16:7,c_fill,dpr_3.0,f_auto,g_auto:custom_no_override,q_auto,w_400/ffasl4jonlhhhaetimcv.jpg',
            'https://insidetheiggles.com/wp-content/uploads/getty-images/2022/07/1351982054.jpeg',
            'https://i.giphy.com/media/cDbUjYrlv81zXzlT93/giphy.webp',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Finsidetheiggles.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2022%2F02%2F1365229349.jpeg',
            'https://i.pinimg.com/736x/9b/79/8d/9b798d1950324c8cbe9c2c9dba3b9317--philadelphia-eagles-pictures-of.jpg',
            'https://www.northchannelstar.com/wp-content/uploads/2020/12/channelview-jalen-hurts-first-start-philadelphia-eagles-win.png',
            'https://www.mockdrafthq.com/wp-content/uploads/2021/04/Screen-Shot-2021-04-04-at-9.09.56-PM-610x400.png',
            'https://cdn.theathletic.com/cdn-cgi/image//cdn-cgi/image/quality=85,height=600/app/uploads/2022/08/31215409/GettyImages-1242424429-scaled-e1661997282727-1024x683.jpg?width=1920',
            'https://cdn.theathletic.com/cdn-cgi/image//cdn-cgi/image/quality=85,height=800/app/uploads/2022/08/26131243/USATSI_17208172-1024x683.jpg?width=1920',
            'https://cdn.theathletic.com/cdn-cgi/image//app/uploads/2022/08/30182534/USATSI_18940469-1024x683.jpg?width=750'
          ]
        },
        {
          name: 'Commanders',
          code: 'WAS',
          path: 'commanders',
          location: 'washington',
          logo: 'https://content.sportslogos.net/logos/7/168/thumbs/im5xz2q9bjbg44xep08bf5czq.gif',
          youtube: [ 'https://www.youtube.com/c/commandersNFL' ],
          nicknames: [ 'commanders', 'redskins', 'skins', 'dead skins' ],
          keywords: [],
          colors: [ '#773141', '#FFB612' ],
          rivals: [ 'Giants', 'Eagles', 'Cowboys' ],
          images: [
            'https://content.sportslogos.net/logos/7/168/thumbs/im5xz2q9bjbg44xep08bf5czq.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/z2nbz5s8knmviecuzr6z.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/cdvqwmijbkdbuxcksinm',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/redskins/li8bnejsfil8tqhvi2yq.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/redskins/xaddist0kzpmrztuugxf.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/redskins/k1yulzwzvaiuo8ktux2h.jpg',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/l68q0xw3172vydywzfqj',
            'https://cdn.vox-cdn.com/thumbor/U7q-bVs4c52w5zFFS3XaO0I9Ud8=/0x0:5472x3648/1200x800/filters:focal(1554x802:2428x1676)/cdn.vox-cdn.com/uploads/chorus_image/image/70662240/1386314156.5.jpg',
            'https://mocoshow.com/wp-content/uploads/2022/04/katie.jpg',
            'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2022-02/220201-washington-football-team-ew-150p-55eb71.jpg',
            'https://riggosrag.com/wp-content/uploads/imagn-images/2022/08/18779555.jpeg',
            'https://capitolcommunicator.com/wp-content/uploads/2022/02/Commanders2.png',
            'https://imageio.forbes.com/specials-images/imageserve/62b71d6506f112663fb0235a/Washington-Football-Team-v-Denver-Broncos/960x0.jpg?format=jpg&width=960',
            'https://media11.s-nbcnews.com/i/mpx/2704722219/2022_02/1643809003021_tdy_news_8a_washington_commanders_220202_1920x1080-n5b3cw.jpg',
            'https://www.si.com/.image/t_share/MTg3MDkzNjE5MzMxNTcyNzQ3/joe_theismann.jpg',
            'https://cdn.vox-cdn.com/thumbor/eIdibztBlBJgkFoGqJJax0vx4LY=/0x0:4008x2886/1200x800/filters:focal(1684x1123:2324x1763)/cdn.vox-cdn.com/uploads/chorus_image/image/71196452/1241357404.0.jpg',
            'https://media.pff.com/2022/05/USATSI_17478035_168395514_lowres.jpg?w=956&h=538',
            'https://www.inquirer.com/resizer/uKEDjI6dBwDH_kqjaIr4Ug111vk=/0x0:4893x3264/760x507/filters:format(webp)/cloudfront-us-east-1.images.arcpublishing.com/pmn/ZK2P7DDYZRFWHDO3VZF7UXP3PQ.jpg',
            'https://cdn.vox-cdn.com/thumbor/9lCj6S9mxJbAXAXUVL50sEHZUyA=/0x0:860x484/1200x800/filters:focal(362x174:498x310)/cdn.vox-cdn.com/uploads/chorus_image/image/70465580/Commander_Terry.0.jpg',
            'https://www.si.com/.image/t_share/MTg5MjQyOTk5NzQ0NTA1NzQ3/new-york-giants-vs-washington-commanders.jpg',
            'https://i.giphy.com/media/qGQvlmuydvzKFFsbzP/giphy.webp',
            'https://i.giphy.com/media/4za3ZiEUmze8GEmDhc/giphy.webp'
          ]
        },
        {
          name: 'Falcons',
          code: 'ATL',
          path: 'falcons',
          location: 'atlanta',
          logo: 'https://content.sportslogos.net/logos/7/173/thumbs/299.gif',
          youtube: [ 'https://www.youtube.com/c/AtlantaFalcons' ],
          nicknames: [ 'falcons', 'birds', 'dirty birds', 'gritz blitz' ],
          keywords: [],
          colors: [ '#A71930', '#000000' ],
          rivals: [ 'Panthers', 'Saints', 'Buccaneers' ],
          images: [
            'https://content.sportslogos.net/logos/7/173/thumbs/299.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/gwp67gog0te3yrneutcl.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/cojcwshzolhwhmkdnwem.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/falcons/gij7fpynyqlmgz9cpd1i.jpg',
            'https://s.yimg.com/os/creatr-uploaded-images/2020-10/487ed690-1967-11eb-9317-a4381ed6e9ea',
            'https://www.si.com/.image/t_share/MTY4MTA2ODU5MDE4MTM0ODAx/atlanta-falcons-georgia-dome-inlinejpg.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/falcons/vfwdzyiftpqos8jxjcvp.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/falcons/n3jjcxzi7en7ldfojesn.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/falcons/ucxj44wcxfp0bbpihhsn.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/falcons/bevswtu5dig6dk6rlhdz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/falcons/cx0w9m92rk0qfif3lxmy.jpg',
            'https://www.gannett-cdn.com/presto/2021/10/07/USAT/0a572ad5-e588-4200-90ca-c81434c84c3d-GTY_1344614165.jpg?width=660&height=440&fit=crop&format=pjpg&auto=webp',
            'https://sportshandle.com/wp-content/uploads/2022/03/calvin-ridley-atl.jpg',
            'https://www.ajc.com/resizer/fLhV11LlrcKQ-Uwo6GnMX_XEsKk=/814x458/cloudfront-us-east-1.images.arcpublishing.com/ajc/KKICAAQS3XXZMHPVGQWXOGOR4A.jpg',
            'https://i.pinimg.com/736x/de/37/22/de37222cd8c50aa22e82fb99dfdd272b.jpg',
            'https://cdn.vox-cdn.com/thumbor/6QBd3pIZMP17IAe77xMtaaEzPbs=/0x0:902x546/1200x800/filters:focal(382x135:526x279)/cdn.vox-cdn.com/uploads/chorus_image/image/66627584/uni7.0.png',
            'https://cloudfront-us-east-1.images.arcpublishing.com/ajc/X6RNGRAKGEBR7RJSPFA6PCONAI.jpg',
            'https://cdn.vox-cdn.com/thumbor/6XPZzm1w1GyLa4yyL06R6kj5JJI=/0x0:3000x2000/1200x800/filters:focal(1260x760:1740x1240)/cdn.vox-cdn.com/uploads/chorus_image/image/69340971/1292278834.0.jpg',
            'https://cloudfront-us-east-1.images.arcpublishing.com/ajc/RY4SYMHBQMRZA3UDDAG4DPGL4U.jpg',
            'https://sportshub.cbsistatic.com/i/r/2021/02/04/632bd5ec-55af-46fe-a465-fccedaafd7a9/thumbnail/1200x675/eba187e67404a331a142a56eb8113a57/matt-ryan.jpg',
            'https://athlonsports.com/.image/t_share/MTgyNDUyNDE5NDIxMDIxNTEy/atlanta_nfl_magazine_cover_2021.jpg',
            'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MTA2ODU5Mjg1MjU5NTM3/atlanta-falcons-fansjpg.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/falcons/wpqczp6wvi2wwllkvvy9',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fbloggingdirty.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F10%2F631702308-divisional-round-seattle-seahawks-v-atlanta-falcons.jpg-850x560.jpg',
            'https://townsquare.media/site/517/files/2016/10/IMG_5153-e1475613612232.png',
            'https://philadelphia.cbslocal.com/wp-content/uploads/sites/15116066/2012/10/154120877.jpg'
          ]
        },
        {
          name: 'Panthers',
          code: 'CAR',
          path: 'panthers',
          location: 'carolina',
          logo: 'https://content.sportslogos.net/logos/7/174/thumbs/f1wggq2k8ql88fe33jzhw641u.gif',
          youtube: [ 'https://www.youtube.com/c/CarolinaPanthers' ],
          nicknames: [ 'panthers' ],
          keywords: [],
          colors: [ '#0085CA', '#101820' ],
          rivals: [ 'Falcons', 'Saints', 'Buccaneers' ],
          images: [
            'https://content.sportslogos.net/logos/7/174/thumbs/f1wggq2k8ql88fe33jzhw641u.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/tyjau7imjgset0weejjw.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/shs7ysamycukohcs6uo4.jpg',
            'https://s3media.247sports.com/Uploads/Assets/863/536/8536863.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/panthers/cuv8ju3fc1lks7m4tneg.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/panthers/yobu2a1wuscxqpvxvbqu.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/panthers/ccaibjwh4gmkvpi47swb.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/panthers/igrmne9iziphgpvh3pio.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/panthers/mpidkxnkphheno66tnvz.jpg',
            'https://sportsfly.cbsistatic.com/fly-0295/bundles/sportsmediacss/images/team-logos/nfl/light/CAR.svg',
            'https://3.bp.blogspot.com/-k9e966DhoMg/WlS5L17zw7I/AAAAAAAAcWI/JB4xFM_stLQzy4UzTXiahVIzzmkWtrppgCLcBGAs/s1600/CelebRookie356Decker.JPG',
            'https://cdn.vox-cdn.com/thumbor/4OwRTt0K-hs7a_L6G4heeoAmS4E=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/9900513/usa_today_9101722.jpg',
            'https://pantherswire.usatoday.com/wp-content/uploads/sites/62/2022/01/USATSI_17212546-e1643092398288.jpg?w=1000&h=600&crop=1',
            'https://streetz1033clt.com/wp-content/uploads/2021/09/Brittany-Renner-1.jpg',
            'https://static.foxnews.com/foxnews.com/content/uploads/2021/08/AP21233843876943.jpg',
            'https://theriotreport.com/wp-content/uploads/2021/08/DJ-Moore-Steelers.jpg',
            'https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MTkwMTIwNDM3MDg4/atlanta-falcons-v-carolina-panthers.jpg',
            'https://ftw.usatoday.com/wp-content/uploads/sites/90/2016/02/sb50catch1.jpg?w=1000&h=600&crop=1',
            'https://carolinablitz.com/wp-content/uploads/2014/11/Kelvin-Benjamin.jpeg',
            'https://media4.giphy.com/media/OXnzPXGeL7ogshcBDt/giphy.webp',
            'https://cassidysportsmytake.files.wordpress.com/2015/08/bills-panthers-football-kelvin-benjamin-corey-graham_pg_600.jpg'
          ]
        },
        {
          name: 'Saints',
          code: 'NO',
          path: 'saints',
          location: 'new orleans',
          logo: 'https://content.sportslogos.net/logos/7/175/thumbs/907.gif',
          youtube: [ 'https://www.youtube.com/c/NewOrleansSaints' ],
          nicknames: [ 'saints', 'aints' ],
          keywords: [],
          colors: [ '#D3BC8D', '#101820' ],
          rivals: [ 'Falcons', 'Panthers', 'Buccaneers' ],
          images: [
            'https://content.sportslogos.net/logos/7/175/thumbs/907.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/c2dwprxx96abfed1vmtq.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/hqe0j3tried0b4kendto',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/tubpszpxlhv3conbk12i',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/saints/vvbqlb9ssm6wbofjdpds.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/saints/qprki4zgsfwyua1h4tfc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/saints/zlicy6kewxso2llzuppg.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/saints/htm336sbjabthmxk4ual.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/saints/uqwpjelzluvbrnlyf2mm.jpg',
            'https://sportsnaut.com/wp-content/uploads/2021/08/Saints-Marshon-Lattimore-contract.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY4MTAzMTcyNTk5MTI5NDcy/marshon-lattimore-new-orleans-saints-rookiejpg.jpg',
            'https://s.yimg.com/ny/api/res/1.2/0PBu0RJdG7hUWT5gbfXVlQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTQyNg--/https://s.yimg.com/os/creatr-uploaded-images/2021-10/98db6200-39de-11ec-bfff-a9cbacf50c47',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_1067,w_1600/https%3A%2F%2Fwhodatdish.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2021%2F12%2F1356882385.jpeg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/saints/jzvxiyuldtfvguyxraz7',
            'http://res.cloudinary.com/ybmedia/image/upload/c_crop,h_1427,w_2000,x_0,y_0/c_scale,f_auto,q_auto,w_700/v1/m/1/9/19ab31ce7e856c024cb9911e5e0f5f9b995323d1/USATSI_11990762.jpg',
            'https://whodatdish.com/wp-content/uploads/getty-images/2018/08/1290601826.jpeg',
            'https://bloximages.newyork1.vip.townnews.com/nola.com/content/tncms/assets/v3/editorial/6/d9/6d9e161b-9059-5f26-8fcc-b2221be97d57/6004e5c64f8fc.image.jpg?resize=656%2C500',
            'https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg2NjY2OTA1MjAzOTEwNDE2/usatsi_17116327_168388561_lowres-2.jpg',
            'https://bloximages.newyork1.vip.townnews.com/nola.com/content/tncms/assets/v3/editorial/5/1b/51b69978-fb85-5c62-87d0-3328649170d8/61d250789364f.image.jpg?resize=1200%2C1066',
            'https://www.gannett-cdn.com/presto/2021/03/14/USAT/8985470e-886c-4571-812b-a2492209932b-USP_NFL__Indianapolis_Colts_at_New_Orleans_Saints.JPG',
            'https://bloximages.newyork1.vip.townnews.com/nola.com/content/tncms/assets/v3/editorial/a/87/a87e3816-21de-563d-8c4a-db107ff6fd01/5cddce0154ba8.image.jpg?crop=1567%2C1175%2C98%2C0&resize=1567%2C1175&order=crop%2Cresize',
            'https://cdn.vox-cdn.com/thumbor/dSLCeAYRMV5up0DVXv9fORaIhDY=/0x0:3654x2580/1200x800/filters:focal(1216x336:1800x920)/cdn.vox-cdn.com/uploads/chorus_image/image/62986633/usa_today_12030401.0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fwhodatdish.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2016%2F10%2F96195721-new-orleans-saints-fans-2010-nfc-championship-850x560.jpg',
            'https://www.si.com/.image/t_share/MTY4MjYxNDY3OTEyNDc5OTA5/new-orleans-saints.jpg',
            'https://i0.wp.com/i.pinimg.com/originals/cf/27/4f/cf274f3bdde0ca72260c7f05f7f5ea2a.jpg',
            'https://whodatdish.com/wp-content/uploads/getty-images/2021/09/1197154747.jpeg',
            'https://i.iheart.com/v3/re/new_assets/5d4990e3d4db8e3d3da2d592?ops=contain(1480,0)'
          ]
        },
        {
          name: 'Buccaneers',
          code: 'TB',
          path: 'buccaneers',
          location: 'tampa bay',
          logo: 'https://content.sportslogos.net/logos/7/176/thumbs/17683632020.gif',
          youtube: [ 'https://www.youtube.com/c/tbbuccaneers' ],
          nicknames: [
            'buccaneers',
            'bucs',
            'sucaneers',
            'gravediggers',
            'succs',
            'yuccs'
          ],
          keywords: [],
          colors: [ '#D50A0A', '#0A0A08' ],
          rivals: [ 'Falcons', 'Panthers', 'Saints' ],
          images: [
            'https://content.sportslogos.net/logos/7/176/thumbs/17683632020.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/cof1ipjaezyekojmujdq.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/vdhuwpshqdsm21eeqlr8.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/c2wrcpxetgvbmpzw6nwu.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/cfiwyhmnycqap1ec4p5k.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/buccaneers/x1rrkqicd8qtcfrttcvt.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/buccaneers/pftl8heguexgdiyvlafk.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/buccaneers/ytquechlwqyrvrxmvhk6.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/buccaneers/gb1repqhimszvaga3wab.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/buccaneers/ghgmdhlyesu6e17pdoln.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/buccaneers/kcwe90x50gd9r8lxlu9z.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/buccaneers/aswzj7fgw2f0djpq6ojh.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fthepewterplank.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F11%2F602996178-tampa-bay-buccaneers-v-atlanta-falcons.jpg.jpg',
            'https://arc-anglerfish-arc2-prod-tbt.s3.amazonaws.com/public/FN7I3NWG6YI6TBKNIBWI6S7HAY.jpg',
            'https://bucswire.usatoday.com/wp-content/uploads/sites/50/2021/08/b7c86bea564c415ebec4e8644514b64e.jpg?w=1000&h=600&crop=1',
            'https://cdn.vox-cdn.com/thumbor/o5LHI81jWEtZMagmuqpV75n9vpI=/0x0:3725x2534/1200x800/filters:focal(940x833:1536x1429)/cdn.vox-cdn.com/uploads/chorus_image/image/70367978/usa_today_17479930.0.jpg',
            'https://content.sportslogos.net/news/2022/02/Tampa-Bay-Buccaneers-750x422.png',
            'https://content.sportslogos.net/news/2020/09/111-750x488.png',
            'https://a4.espncdn.com/combiner/i?img=%2Fphoto%2F2021%2F0208%2Fr812083_1296x729_16%2D9.jpg',
            'https://ewscripps.brightspotcdn.com/dims4/default/0a446ee/2147483647/strip/true/crop/1000x563+0+0/resize/1280x720!/quality/90/?url=http%3A%2F%2Fewscripps-brightspot.s3.amazonaws.com%2F7e%2Ff5%2F34b3284d4e67a139cf144952c9de%2Fvita-vea-touchdown-catch-celebration.png',
            'https://media.pff.com/2022/01/USATSI_17298519_168395514_lowres.jpg?w=956&h=538',
            'https://cdn.vox-cdn.com/thumbor/kl_ZNcroYaulCW3N-xQu4J7F6Ec=/0x0:4242x3042/1200x800/filters:focal(1652x331:2330x1009)/cdn.vox-cdn.com/uploads/chorus_image/image/67651547/usa_today_15086109.0.jpg',
            'https://i.guim.co.uk/img/media/1ef5dba911aeb2bbf9a70826d5afccce72a6b5b4/0_165_4890_2935/master/4890.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=a6e8f0c3333cb1719eb2dce31c920beb',
            'https://media.pff.com/2019/11/USATSI_13579425.jpg',
            'https://i.guim.co.uk/img/media/d6163b3d0829290b292536432c3db2f3b7a6e5be/0_122_3685_2212/master/3685.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=f92d4f8fb4c23a8604cb8d778b1597e0',
            'https://www.sportphotogallery.com/content/images/cmsfiles/product/36678/37452-list.jpg',
            'https://img.asmedia.epimg.net/resizer/9lwOIQPXCFIktRaOIl8UHid6XK4=/1952x1098/cloudfront-eu-central-1.images.arcpublishing.com/diarioas/CBWCWV4GIVIWXDDKZX6TJHFXCQ.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fthepewterplank.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F15544436-850x560.jpeg',
            'https://ca-times.brightspotcdn.com/dims4/default/11ed883/2147483647/strip/true/crop/6000x3999+0+1/resize/2000x1333!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fb6%2F6c%2Fe51499a54ba392aa6bc65e1d1b23%2Fchiefs-buccaneers-super-bowl-football-78636.jpg'
          ]
        },
        {
          name: 'Bears',
          code: 'CHI',
          path: 'bears',
          location: 'chicago',
          logo: 'https://content.sportslogos.net/logos/7/169/thumbs/364.gif',
          youtube: [ 'https://www.youtube.com/c/ChicagoBears' ],
          nicknames: [ 'bears', 'da bears', 'monsters of the midway' ],
          keywords: [],
          colors: [ '#0B162A', '#C83803' ],
          rivals: [ 'Lions', 'Packers', 'Vikings' ],
          images: [
            'https://content.sportslogos.net/logos/7/169/thumbs/364.gif',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/chig3hwfultt2pqiv1t8',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/lk7e9fflh5uqm5q2clgc',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/nltcarj93vl7ybzi7mmu',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/p1ptxud8c3cys7xtgtbg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/zlfx8z87zigiautrifm2',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/hrwfywf9gqoypu81qfen.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/rg5gotevjdm2ahxluh0p.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/bears/nrrqlghmxxxizlc0yznf',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/bears/azsucwf4mdgvhystcvqo',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bears/wodkrf13aencprtfwha4.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bears/vb2ktzarnz3vyfxypvab.jpg',
            'https://cdn.vox-cdn.com/thumbor/_sOvlKdF-p4eRJlFEtldKzOjbnA=/0x0:3396x2547/1200x800/filters:focal(1427x1003:1969x1545)/cdn.vox-cdn.com/uploads/chorus_image/image/66899733/usa_today_10304565.0.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/bears/o6ybt9gdjivmf08ldq4m',
            'https://athlonsports.com/.image/t_share/MTgyMDIzMTkyMjA0NDIwMjI3/chicago-bears-depth-chart.jpg',
            'https://news.wttw.com/sites/default/files/field/image/Lions_Bears_Football_21276629156637%20%281%29.jpg',
            'https://cdn.vox-cdn.com/thumbor/RzEh6y111bfnqws5NYq3GNuJ3oM=/0x158:5880x4080/1200x800/filters:focal(1783x1268:2765x2250)/cdn.vox-cdn.com/uploads/chorus_image/image/62187175/usa_today_11594489.0.jpg',
            'https://beargoggleson.com/wp-content/uploads/imagn-images/2017/07/11920798.jpeg',
            'https://i.pinimg.com/236x/23/db/02/23db028b1ff102c4ef3a9caaea736c90--buccaneers-cheerleaders-hottest-nfl-cheerleaders.jpg',
            'https://ontapsportsnet.com/wp-content/uploads/2022/05/1341533154.0-1024x683.jpg',
            'https://cdn.vox-cdn.com/thumbor/yyRoNg0tRhEp-PjTnPAGWWKcJ4U=/0x0:3771x2509/1200x800/filters:focal(1050x898:1652x1500)/cdn.vox-cdn.com/uploads/chorus_image/image/69891168/usa_today_16787766.0.jpg',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/vlzu5zleo0ygstymttpx',
            'https://a.espncdn.com/photo/2018/1010/r445205_1296x729_16-9.jpg',
            'https://www.reviewjournal.com/wp-content/uploads/2021/10/15697417_web1_FBN-RAIDERS-OCT10-21-BT25.jpg?crop=1',
            'https://www.nbcsports.com/sites/rsnunited/files/styles/article_hero_image/public/archive/assets_article/bayarea/2018/09/10/mackap.jpg',
            'https://cdn.vox-cdn.com/thumbor/4Dg_9QcSLdh54bWT-M1x443uj_g=/0x0:3250x2246/1200x800/filters:focal(1051x850:1571x1370)/cdn.vox-cdn.com/uploads/chorus_image/image/61363039/usa_today_11217976.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/fiNJJLS04pHjc09_8Sgx-a2MPYs=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22208536/usa_today_15249507.jpg',
            'https://wktysports.com/wp-content/uploads/2018/09/Khalil-Mack-Chase-Russell-Wilson-Ap.jpg',
            'https://a.espncdn.com/photo/2012/1119/chi_g_bmarshy7_cr_576.jpg',
            'https://i.giphy.com/media/9NB2gymuMnDBWDscLJ/giphy.webp'
          ]
        },
        {
          name: 'Lions',
          code: 'DET',
          path: 'lions',
          location: 'detroit',
          logo: 'https://content.sportslogos.net/logos/7/170/thumbs/17013982017.gif',
          youtube: [ 'https://www.youtube.com/user/detroitlionsnfl' ],
          nicknames: [ 'lions', 'silver rush' ],
          keywords: [],
          colors: [ '#0076B6', '#B0B7BC' ],
          rivals: [ 'Bears', 'Packers', 'Vikings' ],
          images: [
            'https://content.sportslogos.net/logos/7/170/thumbs/17013982017.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/tktszvumkjwtj9na7kd7.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/vgpzgoch8mifect2nbhr',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/gy4e48h09zvhfdwgv9ub',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/lions/yc0ia2q4dhh4qrmndtzr.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/lions/fzvm32ig1zjyhnzcykzz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/lions/lzzcxsa0ud9fohjf8tl6.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/lions/atqrlhuytw13barhmkiu.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/lions/jvynpumoa8ftygmqanig.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/lions/rhcwzcvtoidcudalwdxm.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/lions/zrffjpdcegsdr7rjauj9.jpg',
            'https://www.gannett-cdn.com/presto/2021/11/25/PDTF/ce8099c2-8177-4249-98bf-ae0f84344cec-GTY_1355520339.jpg?width=660&height=440&fit=crop&format=pjpg&auto=webp',
            'https://www.reviewjournal.com/wp-content/uploads/2019/10/12861367_web1_Chiefs-Lions-Football-4-1-2.jpg',
            'https://www.al.com/resizer/8ZlV974fFgTaIe20mmGsp630Uls=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/MZNMOI7VSND55GS3IMTHYBBV7Q.jpg',
            'https://www.gannett-cdn.com/presto/2021/12/05/USAT/ac37a262-4f0d-47f0-9420-00eaf78761aa-lionscatch.JPG',
            'https://cdn.vox-cdn.com/thumbor/GfOoZrW7flkK2tlhOgaasH7qn9U=/0x0:4014x2676/1200x800/filters:focal(1686x1017:2328x1659)/cdn.vox-cdn.com/uploads/chorus_image/image/71096399/1363861417.0.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgyNDQ4Mzg0ODM2MTE3NjM1/swift5.jpg',
            'https://thespun.com/.image/t_share/MTg3NDI1NjQzNjU3ODk3NTMy/nfl-nov-25-bears-at-lions.jpg',
            'https://cdn.vox-cdn.com/thumbor/3ILyxTHL2gnELIttxH_XxuCx_80=/0x0:4760x3173/1200x800/filters:focal(1720x1079:2480x1839)/cdn.vox-cdn.com/uploads/chorus_image/image/70022488/1347532306.0.jpg',
            'https://i.pinimg.com/736x/39/a7/72/39a77207306cddd4aa060b02dbc4117e--detroit-lions-football-american-football.jpg',
            'https://pbs.twimg.com/media/DReHTzTW0AA44EC.jpg',
            'https://images.squarespace-cdn.com/content/v1/5ab4527b3c3a536a7a352c05/1542908578540-GQ3YOKXQQSM5U0XM992X/ke17ZwdGBToddI8pDm48kPqQfq0L3n3wpHIsRapTfg8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKczo5Zn4xktlpMsMj-QlHXeMfNK6GwvtVkYEWiR8XAPyD3GfLCe_DXOSC_YcAacWL_/877904334.jpg.jpg',
            'https://www.gannett-cdn.com/presto/2018/11/22/PDTF/1a1a87ec-f7de-47d3-a698-e2787f316235-112218_lions-19.jpg?crop=1464,2211,x136,y189',
            'https://sidelionreport.com/wp-content/uploads/getty-images/2019/03/1067725446.jpeg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fsidelionreport.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2018%2F08%2F16560174.jpeg',
            'https://riggosrag.com/wp-content/uploads/getty-images/2022/05/1359865173.jpeg',
            'https://img.bleacherreport.net/img/images/photos/002/366/160/hi-res-158546763_crop_exact.jpg?w=3072&h=2048&q=75',
            'https://i.pinimg.com/550x/0e/a1/87/0ea1872a9d0f6d0b6cedf442f5008adc.jpg',
            'https://cdn.theathletic.com/cdn-cgi/image//cdn-cgi/image/quality=85,height=600/app/uploads/2022/08/30210331/USATSI_18913391-scaled-e1661907840211-1024x682.jpg?width=1920'
          ]
        },
        {
          name: 'Packers',
          code: 'GB',
          path: 'packers',
          location: 'green bay',
          logo: 'https://content.sportslogos.net/logos/7/171/thumbs/dcy03myfhffbki5d7il3.gif',
          youtube: [ 'https://www.youtube.com/c/packers' ],
          nicknames: [ 'packers', 'pack', 'peckers' ],
          keywords: [],
          colors: [ '#203731', '#FFB612' ],
          rivals: [ 'Bears', 'Lions', 'Vikings' ],
          images: [
            'https://content.sportslogos.net/logos/7/171/thumbs/dcy03myfhffbki5d7il3.gif',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/sx0vmllfr8rbxcxc7pbo',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/psmgtrj3hbszy351bznu',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/b5nb08wdcf1rvjjtvjf9',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/vrljecwymwolv4vnkfbp',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/r1qt6bh5y169k7yh6ivg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/kqynvydhpml4bcbxeqyz',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/bitrjnab8s6hvuendjwa.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/packers/ersglcg3slq1tdppik5g.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/packers/zk4ivdor0y32u2gcafkd.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/packers/zmh3lt0v57hulusaelvo.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/packers/nqzbdigrsuisdzlvcczp.jpg',
            'https://nypost.com/wp-content/uploads/sites/2/2021/10/davante-adams-packers-covid-status.jpg?quality=75&strip=all',
            'https://pbs.twimg.com/media/CL8DHFZUwAATjCQ.jpg',
            'https://www.golfdigest.com/content/dam/images/golfdigest/fullset/2020/11/davanteadams_square.jpg',
            'https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2021/10/640/320/Davante-Adams3.jpg?ve=1&tl=1',
            'https://packerswire.usatoday.com/wp-content/uploads/sites/57/2021/08/USATSI_16566157.jpg',
            'https://i0.wp.com/zonecoverage.com/wp-content/uploads/2021/03/USATSI_15479148_168390100_lowres.jpg?fit=1500%2C981&ssl=1',
            'https://cdn.vox-cdn.com/thumbor/PsQDk-a1AHbMWgY39KTawVS6J88=/0x0:5472x3648/1200x800/filters:focal(2385x1100:3259x1974)/cdn.vox-cdn.com/uploads/chorus_image/image/70338759/1358666481.0.jpg',
            'https://static4.businessinsider.com/image/52924b9d6bb3f70055219faf-1200-924/green-bay-bikinis.jp2?maxX=800',
            'https://img.bleacherreport.net/img/article/media_slots/photos/001/272/158/458917171-fan-of-the-green-bay-packers-dances-in-the-snow-in-her_crop_exact.jpg?w=2975&h=2048&q=85',
            'https://www.sportscasting.com/wp-content/uploads/2016/09/Packers-fan-Mike-McGinnis-Getty-Images.jpg',
            'https://www.thelostgirlsguide.com/wp-content/uploads/DSC00200.jpg',
            'https://pbs.twimg.com/profile_images/2564021332/m3c0wpfzidn3tl1r7mok_400x400.jpeg',
            'https://i.pinimg.com/originals/12/bc/df/12bcdf7842e12a1a7888667b3f643cd0.jpg',
            'https://www.gannett-cdn.com/presto/2019/12/08/PPAN/680ac505-d7a6-4ad4-961b-97a246ee0d49-APC_Packers_vs_Redskins_0248_120819_wag.jpg',
            'https://i.pinimg.com/originals/2f/72/9e/2f729e5ac3bfb35888156cb3755543f9.jpg',
            'https://moolta.files.wordpress.com/2012/09/green-bay-packers-bikini-girls-fans-nfl.jpeg',
            'https://i.iheart.com/v3/re/new_assets/5d49908fd4db8e3d3da2d586?ops=contain(1480,0)'
          ]
        },
        {
          name: 'Vikings',
          code: 'MIN',
          path: 'vikings',
          location: 'minnesota',
          logo: 'https://content.sportslogos.net/logos/7/172/thumbs/17227042013.gif',
          youtube: [ 'https://www.youtube.com/c/vikings' ],
          nicknames: [ 'vikings', 'vikes', 'purple people eaters' ],
          keywords: [],
          colors: [ '#4F2683', '#FFC62F' ],
          rivals: [ 'Bears', 'Lions', 'Packers' ],
          images: [
            'https://content.sportslogos.net/logos/7/172/thumbs/17227042013.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/acqhgug9rfbgjqlfsppq.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/xk6tezzod2hmxsnuwzhs.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/kkpbhx3i7plbsmkmqaep.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/vikings/wiw4lrjq31fmqs4cv16x.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/vikings/a4gpjdejj17fdw3qug2w.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/vikings/td7owleepvfyixa1mg8y.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/vikings/uy5r7iojhy6tkrla3dba.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/vikings/tlcjcpyggsd1jl30srna.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/vikings/y3a1uuunmbtz3zxbad0u.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/vikings/kdoki45unpscfjzwyypk.jpg',
            'https://i.pinimg.com/736x/57/e9/c6/57e9c6cae4480c6a1212e44a8bb6d555--vikings-game-vikings-football.jpg',
            'https://cdn.vox-cdn.com/thumbor/JXzspX-UEw2J53_70r0dgTEZbDA=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22886553/1235559914.jpg',
            'https://sportsnaut.com/wp-content/uploads/2021/12/NFL-Green-Bay-Packers-at-Minnesota-Vikings-17226579-scaled.jpg',
            'https://pbs.twimg.com/media/EGXbxoqWwAAG7Ld.jpg',
            'https://i.pinimg.com/originals/ef/73/de/ef73dec137f748468a87ee20ecf10022.jpg',
            'https://townsquare.media/site/67/files/2021/10/attachment-vikings-fans.jpg?w=1200&h=0&zc=1&s=0&a=t&q=89',
            'https://external-preview.redd.it/6VaA8riHqN0kpfh5uIOK6K9HRGI6ZJSgmUQERk3T-Yk.jpg?auto=webp&s=3726f9b13259296acd4afcb4085ff85aca632a08',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fthevikingage.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2022%2F07%2F10303687.jpeg',
            'https://cdn.vox-cdn.com/thumbor/flG2W5JhMpTKHn8NJJo3ocg8S0I=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/19572786/usa_today_13680001.jpg',
            'https://thevikingage.com/wp-content/uploads/getty-images/2018/08/1173494131.jpeg',
            'https://cdn.vox-cdn.com/thumbor/6tqu6UURv2j_H5XHMnQe88Xyus4=/0x0:3000x2226/1200x800/filters:focal(1651x439:2131x919)/cdn.vox-cdn.com/uploads/chorus_image/image/71039948/usa_today_17251285.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/Qf3AyyKdCg2-teQJpVsGM5fjt2A=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23048878/usa_today_17251507.jpg',
            'https://imageio.forbes.com/specials-images/imageserve/60f82bf4004e8f345a091f01/Minnesota-Vikings-v-Dallas-Cowboys/960x0.jpg?format=jpg&width=960',
            'https://static.clubs.nfl.com/image/private/t_person_squared_mobile/f_auto/vikings/s04c2exdllhdomcdkvcx.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fthevikingage.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2016%2F04%2F1188269341.jpeg',
            'https://kstp.com/wp-content/uploads/2021/10/kstp_kjosbornvikingspanthers10.17.21.jpg',
            'https://vikingswire.usatoday.com/wp-content/uploads/sites/44/2021/12/USATSI_17329317.jpg?w=1000&h=600&crop=1',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/vikings/tyvwxslvczna4iefduse',
            'https://www.twincities.com/wp-content/uploads/2021/10/jea-2473-Vikings-vs-Cleveland.jpg',
            'https://i.giphy.com/media/O92aG78R1YDBykkl1n/giphy.webp',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/vikings/xcet8zb8mjkabuvlpccr',
            'https://cdn.theathletic.com/cdn-cgi/image//cdn-cgi/image/quality=85,height=600/app/uploads/2022/09/01190721/USATSI_18904296-1024x683.jpg?width=1920',
            'https://media0.giphy.com/media/iKBUDfoXoISw8uGEqX/giphy.gif?cid=ecf05e47144lljjfhce5isu2f97x12bt4kyhfa8g1e8jdpxs&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Cardinals',
          code: 'ARZ',
          path: 'cardinals',
          location: 'arizona',
          logo: 'https://content.sportslogos.net/logos/7/177/thumbs/kwth8f1cfa2sch5xhjjfaof90.gif',
          youtube: [ 'https://www.youtube.com/c/azcardinals' ],
          nicknames: [
            'cardinals',
            'red birds',
            'birds',
            'cards',
            'cardiac cardinals',
            'patsies'
          ],
          keywords: [],
          colors: [ '#97233F', '#000000' ],
          rivals: [ 'Rams', '49ers', 'Seahawks' ],
          images: [
            'https://content.sportslogos.net/logos/7/177/thumbs/kwth8f1cfa2sch5xhjjfaof90.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/zseuvfun5avdk1ro3iax.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/gzceuggo7omej8t0puxv',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/vjcocynp3zfhqt9q9ar8.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/bkyiw7jf1fp6rytvjmry.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/tmtym3wfmxwzirwm0et6.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/c2vlcyyb4ncfyc0vdmep.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/yuc4jgaicftn3tbuxf7o.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/tubiaoaunldakscptpcc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/grfndy6vizlikssa8cdt.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/ynkwczcs3c1lnqvrixrb.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/cardinals/bo0prdhc5mo4nmwepz7h',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/igukdquyyqm8rhlbt7cj.jpg',
            'https://www.gannett-cdn.com/presto/2020/11/16/USAT/f96ed19a-a6e2-4969-a736-19f3e4ce3442-USP_NFL__Buffalo_Bills_at_Arizona_Cardinals_1.jpg?crop=2432,1572,x0,y175',
            'https://arizonasports.com/wp-content/uploads/2020/10/deandre-hopkins-cardinals-td-jets.jpg',
            'https://arizonasports.com/wp-content/uploads/2017/03/FitzDivingCatch_MA3.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fraisingzona.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F10%2F853876708-dallas-cowboys-v-arizona-cardinals.jpg-850x560.jpg',
            'https://www.ardmoreite.com/wp-content/uploads/2022/07/07-24-02-Ar01102026.jpg',
            'https://nflspinzone.com/wp-content/uploads/getty-images/2018/08/1365265943.jpeg',
            'https://images.foxtv.com/static.fox10phoenix.com/www.fox10phoenix.com/content/uploads/2022/07/1280/720/GettyImages-1342849924.jpg?ve=1&tl=1',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/cardinals/ieafookhzpamjrvgsboj',
            'https://media.pff.com/2021/10/USATSI_17023048_168392742_lowres.jpg?w=956&h=538',
            'https://dmn-dallas-news-prod.cdn.arcpublishing.com/resizer/P0-QWkDoPsah9G2k3y95xkmVuUw=/1660x934/filters:focal(987x445:997x435):no_upscale()/cloudfront-us-east-1.images.arcpublishing.com/dmn/RCU5VZEZ7RCKXMITAF3OHBZORU.jpg',
            'https://s.hdnux.com/photos/01/21/66/72/21458579/3/rawImage.jpg',
            'https://cardswire.usatoday.com/wp-content/uploads/sites/60/2016/08/usatsi_9080989.jpg?w=1000&h=600&crop=1',
            'https://media2.phoenixnewtimes.com/phx/imager/u/magnum/11337426/phx-news-20161113-arizonacardinalsvssanfrancisco49ers-jimlouvau-24.jpg?cb=1642610055',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/cardinals/meptbuiiedsel827arg5.jpg',
            'https://i.giphy.com/media/vPJztf5sHWhSkat4CI/giphy.webp',
            'https://i.giphy.com/media/FNKttVFthEHkRbIJLY/giphy.webp',
            'https://media3.giphy.com/media/xT0GqLzZH55MoZGjjq/giphy.gif?cid=ecf05e47nfich8b0i2djxmmi15x51hlok0jy28rrw0zs646m&rid=giphy.gif&ct=g',
            'https://assets.simpleviewinc.com/simpleview/image/fetch/c_fill,h_690,q_70,w_1040/https://assets.simpleviewinc.com/simpleview/image/upload/crm/chandler/cardinalStadium-interior-flag_credit-Phoenix-Regional-Sports-Commission_3360d398-5056-a36a-0b7e00c5d00ecba8.jpg',
            'https://cdn.theathletic.com/cdn-cgi/image//cdn-cgi/image/quality=85,height=600/app/uploads/2022/08/31214136/USATSI_16561567-scaled-e1661996854697-1024x682.jpg?width=1920'
          ]
        },
        {
          name: 'Rams',
          code: 'LA',
          path: 'rams',
          location: 'los angeles',
          logo: 'https://content.sportslogos.net/logos/7/5941/thumbs/594183342020.gif',
          youtube: [ 'https://www.youtube.com/c/LARams' ],
          nicknames: [ 'rams', 'lambs' ],
          keywords: [],
          colors: [ '#003594', '#FFD100' ],
          rivals: [ 'Cardinals', '49ers', 'Seahawks' ],
          images: [
            'https://content.sportslogos.net/logos/7/5941/thumbs/594183342020.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/hpmqssabpkszpic8tlmg.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/dzoqxbfll51q9elxq0rx',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/jy1vxln1sfzrq47puptu',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/beshbkamhrkedix1a7ky',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/pfzzrudqnacvoxaoh1mv.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/zsjbfuvgk75tqfjgivf4.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/dunjrqc8x9ajpjkdiyys.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/rams/uj0evzndvortl7qoi5t6.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/rams/wbwd69hb4r5mpjmhg48n.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/rams/px7wyhu7yq3demr8opag.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/rams/dvuibopv3v5ubnrz3gji.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/rams/ms3k0i2bkvwyabbdwogm.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/rams/ez0yptbf2vlqmpglvngh.jpg',
            'https://theramswire.usatoday.com/wp-content/uploads/sites/64/2021/12/52dd7ae358ec43c78533cda2e19a2cba.jpg?w=1000&h=600&crop=1',
            'https://theramswire.usatoday.com/wp-content/uploads/sites/64/2021/12/0a2fc00a834944d9a7f1a1f645852198.jpg?w=1000&h=600&crop=1',
            'https://cdn.vox-cdn.com/thumbor/CUEgfziCxmh6g-O4t4BD_BtvsVg=/0x0:4256x2832/1200x800/filters:focal(1716x909:2396x1589)/cdn.vox-cdn.com/uploads/chorus_image/image/70354681/usa_today_15415021.0.jpg',
            'https://nypost.com/wp-content/uploads/sites/2/2021/11/FOOTBALL-NFL-GB-LAR_-1.jpg?quality=75&strip=all',
            'https://theramswire.usatoday.com/wp-content/uploads/sites/64/2021/11/3c8331d8a57041498ab066dd9feb3648.jpg?w=1000&h=600&crop=1',
            'https://sportshub.cbsistatic.com/i/r/2022/01/24/2396cdc1-5b95-4a9e-a900-18ccdfaecd2e/thumbnail/1200x675/590dc2362f5dc24f3cd4fda806fc1d87/matthew-stafford-rams-usatsi.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/rams/pueb0v9sg6vdd1zqyl6l',
            'https://cdn.vox-cdn.com/thumbor/7NhPzV_XZhr9QnoyYbL5zfyvS4I=/0x0:4467x2973/1200x800/filters:focal(1845x949:2559x1663)/cdn.vox-cdn.com/uploads/chorus_image/image/61380969/usa_today_10483826.0.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/rams/ordkyris9sacwpjtwat3',
            'https://guysgirl.com/wp-content/uploads/2016/01/rams-fan-in-la.jpg',
            'https://bet-the-nfl-super-bowl.com/wp-content/uploads/2018/06/Sexy-Rams-Fan-Super-Bowl.jpg',
            'https://pbs.twimg.com/media/DxFOVfLVsAIloxI.jpg',
            'https://www.lafbnetwork.com/wp-content/uploads/2021/03/Danny-Trejo-And-YG-600x365.jpg',
            'https://i.insider.com/5c47999cdde8674deb6c1c21',
            'https://i.iheart.com/v3/re/new_assets/5d499defd4db8e3d3da2d5d3?ops=contain(1480,0)'
          ]
        },
        {
          name: '49ers',
          code: 'SF',
          path: '49ers',
          location: 'san francisco',
          logo: 'https://content.sportslogos.net/logos/7/179/thumbs/17994552009.gif',
          youtube: [ 'https://www.youtube.com/c/49ers' ],
          nicknames: [ '49ers', 'niners' ],
          keywords: [],
          colors: [ '#AA0000', '#B3995D' ],
          rivals: [ 'Cardinals', 'Rams', 'Seahawks' ],
          images: [
            'https://content.sportslogos.net/logos/7/179/thumbs/17994552009.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/lat9vhzkfus8f5yow52f.jpg',
            'https://static.clubs.nfl.com/image/private/w_300,c_scale/f_auto/nfl100/uzfqeufryly986bm6w6l',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/zh43txuqop6c9lqqgnzn',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/omffy5j1bmffeixknwk5',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/mebi4mnbtazbgw9b9sxz',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/fmrcpuxuofb00uzym6ve',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/wq8t2vgj5yzcs1dsknqd',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/v1567199108/nfl100/isdxhfggisqect4qom1o',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/49ers/qucmbabbk9l7bxlwwetl.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/49ers/o5upypjof69vepij4vgq.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/49ers/odybrw77vbgsnrmyssib.jpg',
            'https://s.hdnux.com/photos/01/10/07/34/18904862/6/1200x0.jpg',
            'https://i.pinimg.com/originals/63/a7/71/63a771969b406f1ec21b2f37767b01c0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fninernoise.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2021%2F01%2F15392687.jpeg',
            'https://static01.nyt.com/images/2019/12/09/sports/09nfl-wwl-subtop/merlin_165637641_31fd653f-e5e7-4e0b-a96f-2a4d137e6702-articleLarge.jpg?quality=75&auto=webp&disable=upscale',
            'https://www.nbcsports.com/sites/rsnunited/files/styles/article_video_thumbnail/public/short_form_video/hero/GAROPPOLO_AIYUK_PRESSER_092221.jpg',
            'https://cdn.vox-cdn.com/thumbor/Im9b-RGrC-eByn8BhUiXK6LrKzY=/0x0:2897x1926/1200x800/filters:focal(1554x309:2016x771)/cdn.vox-cdn.com/uploads/chorus_image/image/70012209/1346474277.0.jpg',
            'https://www.mercurynews.com/wp-content/uploads/2021/11/BNG-L-49ERS-1122-2.jpg',
            'https://ninernoise.com/wp-content/uploads/getty-images/2021/11/1354731822.jpeg',
            'https://www.dailydemocrat.com/wp-content/uploads/2021/10/BNG-L-49ERs-1011-27-1.jpg?w=552',
            'https://ninerswire.usatoday.com/wp-content/uploads/sites/77/2021/03/1291099632.jpg?w=1000&h=600&crop=1',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/bgexb2ldryxqrcbqawri',
            'https://ninerswire.usatoday.com/wp-content/uploads/sites/77/2022/01/1362443116.jpg?w=1000&h=600&crop=1'
          ]
        },
        {
          name: 'Seahawks',
          code: 'SEA',
          path: 'seahawks',
          location: 'seattle',
          logo: 'https://content.sportslogos.net/logos/7/180/thumbs/pfiobtreaq7j0pzvadktsc6jv.gif',
          youtube: [ 'https://www.youtube.com/c/Seahawks' ],
          nicknames: [ 'seahawks', 'hawks', 'blue wave', 'legion of boom' ],
          keywords: [],
          colors: [ '#002244', '#A5ACAF' ],
          rivals: [ 'Cardinals', 'Rams', '49ers' ],
          images: [
            'https://content.sportslogos.net/logos/7/180/thumbs/pfiobtreaq7j0pzvadktsc6jv.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/bkqykvh6vdoyhsni8hdr.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/mjkb7dwu4o36l2xidw7w.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/seahawks/qvgnekoatb2m35kisg3z.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/seahawks/vozeipsb3denyprzl4ta.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/seahawks/tkfpp7urjsk1spun0uzc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/seahawks/mpvcbrdxs0krpjwmeu5r.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/seahawks/pzlrz02vqactquvlipfg.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/seahawks/ozhvqkenvcdep8k46dck.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/seahawks/n10wuymchdvoxrc7rvgm.jpg',
            'https://media.king5.com/assets/KING/images/612823273/612823273_1140x641.jpg',
            'https://images.complex.com/complex/images/c_fill,dpr_auto,f_auto,q_auto,w_1400/fl_lossy,pg_1/qwcljouf9obirgyyelpt/seattle-seahawks?fimg-ssr-default',
            'https://cloudfront-us-east-1.images.arcpublishing.com/gmg/H4VRQ4N54JHANL3GLUDEETRQWA.jpg',
            'https://static.foxnews.com/foxnews.com/content/uploads/2021/10/Geno-Smith.jpg',
            'https://images.seattletimes.com/wp-content/uploads/2022/04/04232022_brooks_151657.jpg?d=780x540',
            'https://images.seattletimes.com/wp-content/uploads/2018/08/08172018_brandenjackson_0817_tzr_tzr_151514.jpg?d=780x501',
            'http://sports.mynorthwest.com/wp-content/uploads/2021/11/Penny-deadline-Getty.jpg',
            'https://cdn.vox-cdn.com/thumbor/TcweINKP35RhskJD0GZOGajlIA8=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23051953/1354770898.jpg',
            'https://www.thelines.com/wp-content/uploads/2021/09/preview-1232.jpg',
            'https://www.guinnessworldrecords.com/Images/Seahawks-Article-580-327_tcm25-18829.jpg',
            'https://sports.cbsimg.net/images/blogs/Seahawks-fans-steelers-11-24-15.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fimages2.minutemediacdn.com%2Fimage%2Ffetch%2Fc_fill%2Cf_auto%2Cw_1000%2Ch_1000%2Fhttps%3A%2F%2Ffansided.com%2Fwp-content%2Fassets%2Fsite_images%2Fseahawks%2Flogo_12thmanrising-com.png',
            'https://townsquare.media/site/432/files/2013/10/Seahawks-stadium-630x410.jpg?w=980&q=75',
            'https://s.hdnux.com/photos/15/27/16/3502986/3/rawImage.jpg',
            'https://i.giphy.com/media/nP3zYi6mzSTIy0obgo/giphy.webp',
            'https://sportsnaut.com/wp-content/uploads/2022/06/NFL-Jacksonville-Jaguars-at-Seattle-Seahawks-17069102-748x420.jpg',
            'https://cutewallpaper.org/21/seattle-seahawks-live-wallpaper/Seahawks-Pictures-Wallpapers-Lock-Screen.jpg',
            'https://www.gannett-cdn.com/presto/2020/10/12/USAT/62ffd2aa-d550-4f81-b907-e4d3ff81690d-USP_NFL__Minnesota_Vikings_at_Seattle_Seahawks_2.jpg?width=660&height=441&fit=crop&format=pjpg&auto=webp',
            'https://i.pinimg.com/736x/bc/85/74/bc8574b0316753e3af18178d253880d7--sexy-cheerleaders-tennessee-titans.jpg'
          ]
        }
      ]
    },
    afc: {
      title: 'AFC',
      icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/American_Football_Conference_logo.svg/225px-American_Football_Conference_logo.svg.png',
      iconSource: 'image',
      teams: [
        {
          name: 'Bills',
          code: 'BUF',
          path: 'bills',
          location: 'buffalo',
          logo: 'https://content.sportslogos.net/logos/7/149/thumbs/n0fd1z6xmhigb0eej3323ebwq.gif',
          youtube: [ 'https://www.youtube.com/c/buffalobills' ],
          nicknames: [ 'bills' ],
          keywords: [],
          colors: [ '#00338D', '#C60C30' ],
          rivals: [ 'Dolphins', 'Patriots', 'Jets' ],
          images: [
            'https://content.sportslogos.net/logos/7/149/thumbs/n0fd1z6xmhigb0eej3323ebwq.gif',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/vmrmxprfgozkzbr7avdy',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/nvnzwolu8xtd1fal5ioa',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/ee04wfsr23wzy6kxcheu',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/gp5hcz6diiqohlek6pim.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/uudmlnzrr3mpmjrpzvoo.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/cgbdc80mzgfxng8unnwh.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/xtklttrv2tcgi9mffdzw.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/ezebcylyrhthegpptfsy.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/mjgyz7foludmlgvmaybw.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/gwjqhl2hsgxz5yvdynb8.jpg',
            'https://townsquare.media/site/10/files/2022/01/attachment-gettyimages-1363688101-594x594.jpg',
            'https://www.thecoldwire.com/wp-content/uploads/2021/12/Josh-Allen-of-Buffalo-Bills-scaled.jpg',
            'https://titanswire.usatoday.com/wp-content/uploads/sites/43/2019/10/usatsi_13473043.jpg?w=1000&h=600&crop=1',
            'https://i.pinimg.com/550x/63/58/d7/6358d76020e65b12bb5bb77e0467bf3c.jpg',
            'https://a.espncdn.com/photo/2019/1104/r623318_1296x729_16-9.jpg',
            'https://www.newyorkupstate.com/resizer/nN0k5Yr0gvMitz7WZChH-rA-O9c=/1280x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/4USRQJXP2JFCRERP4XXZ62Z6E4.jpg',
            'https://profootballtalk.nbcsports.com/wp-content/uploads/sites/25/2022/03/GettyImages-1343328124-e1646405025213.jpg',
            'https://buffalowdown.com/wp-content/uploads/getty-images/2017/07/1191945194.jpeg',
            'https://i.pinimg.com/736x/c8/16/a5/c816a59e9469fa53c91df39b317de802.jpg',
            'https://www.visitbuffaloniagara.com/content/uploads/2022/01/visitbuffaloniagara-Instagram-2674-ig-17889755753563276.jpg',
            'https://www.syracuse.com/resizer/tT_aXmDWOV9W7sFr9hz96i3iw7o=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/7JND7OQYH5H6VLCGNBN53YJZCY.JPG',
            'https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/NVFDDOCK54I6XF5WJ247OL7UNM.jpg',
            'https://s7d2.scene7.com/is/image/TWCNews/ap_stefon_diggs_bucs_12162021',
            'https://billswire.usatoday.com/wp-content/uploads/sites/65/2021/08/1336923023.jpg?w=1000&h=600&crop=1',
            'https://i.insider.com/5a401155b0bcd5b9008b6e84?width=1136&format=jpeg',
            'https://buffalowdown.com/wp-content/uploads/imagn-images/2017/07/15248696.jpeg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/bills/yc4qtvkxglbw4ra4frl7',
            'https://billswire.usatoday.com/wp-content/uploads/sites/65/2020/12/USATSI_15369655.jpg?w=1000&h=600&crop=1',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/ph7whgz2adlju336nxmu',
            'https://cdn-wp.thesportsrush.com/2020/12/e88e6068-stefon-diggs.jpg',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/wtsxefoqecerfecnbnd4',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/m5ytmahuflwhumblzyx6',
            'https://cdn.theathletic.com/cdn-cgi/image//app/uploads/2022/09/01002655/GettyImages-1361106552-scaled.jpg?width=1920',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/qm7d2nnsqktmztuymwoy.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/nqp5lczj9wsm7rcmcuff.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/epeej3qw2i7rpzpzedrw.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/lq1plaqu8xdmeimymy9l.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/xrk378o0czxnbrq6vow2.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/pjkghheesufbsbmi15l5.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/mjxeop1erbbjkeuaq5iz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/xbku7mibktjjri3faqea.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/bkbkqpieqp3issljdelz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bills/m0czktrcpfwwhbbdvklc.jpg',
            'https://i.iheart.com/v3/re/new_assets/5d499022d4db8e3d3da2d57a?ops=contain(1480,0)'
          ]
        },
        {
          name: 'Dolphins',
          code: 'MIA',
          path: 'dolphins',
          location: 'miami',
          logo: 'https://content.sportslogos.net/logos/7/150/thumbs/15073062018.gif',
          youtube: [ 'https://www.youtube.com/c/MiamiDolphins' ],
          nicknames: [ 'dolphins', 'fins', 'killer bees', 'miami pound machine' ],
          keywords: [],
          colors: [ '#008E97', '#FC4C02' ],
          rivals: [ 'Bills', 'Patriots', 'Jets' ],
          images: [
            'https://content.sportslogos.net/logos/7/150/thumbs/15073062018.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/hndqbjxn8ekdqzv0hqlm.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/wipahezlc295hcvmpeva.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/dolphins/u0gjjbj1yvsrq0vpjo1i.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/dolphins/vp3fid2dzygym3bnfgs3.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/dolphins/o5s1jqf6gfp64pv4qaym.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/dolphins/onz4kstiohmoniwstzgu.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/dolphins/cirzspka4fjwhugiytsc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/dolphins/nlbrbrgxdx6cveagippj.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/dolphins/vto0nwgeezzpc3igdsuq.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/dolphins/yqgxyaxa5og0hs4kclwi.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTc1NTYwODc1NDIyMjYyNTA3/gesickiusatsi_14957650_168390362_lowres.jpg',
            'https://www.masslive.com/resizer/GLlU2XlWBuL0W5tkld_dWXNUHyA=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/ZHNQ4UF4TNEITD5QTCVXVG2UQ4.jpg',
            'https://i.pinimg.com/originals/9f/27/9d/9f279d7ea1e7e91e0c3e8c39bcabb302.jpg',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/km7qrt5jipar11gqndlf',
            'https://www.gannett-cdn.com/presto/2021/01/03/NPPP/10e52844-42e1-4b1d-a723-903068bf23e9-AP21003778937555.jpg?width=660&height=440&fit=crop&format=pjpg&auto=webp',
            'https://res.cloudinary.com/rotowire/image/upload/ar_16:7,c_fill,dpr_3.0,f_auto,g_auto:custom_no_override,q_auto,w_400/ftj8y7jg98yyeu3f8a06.jpg',
            'https://s.yimg.com/ny/api/res/1.2/bxdOZ0iDVcS180_1uIMXIg--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTQyNw--/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2020-08/133d9cf0-e618-11ea-bbf7-fa2ad896e261',
            'https://media1.miaminewtimes.com/mia/imager/u/slideshow/12865206/dolphins_vs_bills_12_31_2017_by_ian_witlen-7896.jpg',
            'https://ca-times.brightspotcdn.com/dims4/default/b5b3802/2147483647/strip/true/crop/2048x1365+0+0/resize/840x560!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F97%2F10%2F3e6b02d2405e9d6056ebec199c48%2Fgoff1.png',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fnflspinzone.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F17480428-1-850x560.jpeg',
            'https://staticc.sportskeeda.com/editor/2022/03/bed41-16483920061512-1920.jpg',
            'https://cutewallpaper.org/25/best-nfl-catch-wallpaper/2067223565.jpg',
            'https://i.iheart.com/v3/re/new_assets/5d499e3fd4db8e3d3da2d5d7?ops=contain(1480,0)',
            'https://i.giphy.com/media/Z62iCq6819AHr2TIW7/giphy.webp'
          ]
        },
        {
          name: 'Patriots',
          code: 'NE',
          path: 'patriots',
          location: 'new england',
          logo: 'https://content.sportslogos.net/logos/7/151/thumbs/y71myf8mlwlk8lbgagh3fd5e0.gif',
          youtube: [ 'https://www.youtube.com/c/patriots' ],
          nicknames: [
            'patriots',
            'pats',
            'brady bunch',
            'boogeyman',
            'cheatriots'
          ],
          keywords: [],
          colors: [ '#002244', '#C60C30' ],
          rivals: [ 'Bills', 'Dolphins', 'Jets' ],
          images: [
            'https://content.sportslogos.net/logos/7/151/thumbs/y71myf8mlwlk8lbgagh3fd5e0.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/nobsct8vrppy7fmrkcm2.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/kd3jbbpvbhg2ffc3uk9j',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/hizgqtjdganpyk8pekyc',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/ddqnn1oxnxh1m8blbvpm',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/ok3qxdapeq7dbml4axy6',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/patriots/w7tclgqckptqxwq1pika.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_png/patriots/g8xxypf5jvtyp3jkrfow.png',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/patriots/cfwschubuuscezouvtf4.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/patriots/c5gzpdgkpgc4y7zaycgj.jpg',
            'https://cdn.vox-cdn.com/thumbor/XwBwNi9gX6ZcMuLJgGpaqKui3nA=/0x0:3564x2620/1200x800/filters:focal(1267x695:1837x1265)/cdn.vox-cdn.com/uploads/chorus_image/image/69439733/136122797.0.jpg',
            'https://e0.365dm.com/21/12/768x432/skysports-americanfootball_5620052.jpg',
            'https://jetsxfactor.com/wp-content/uploads/2021/10/Mac-Jones-NY-Jets-New-England-Patriots-Highlights.jpg',
            'https://static01.nyt.com/images/2021/11/28/multimedia/28nfl-tenvsne-bourne/28nfl-tenvsne-bourne-mediumSquareAt3X.jpg',
            'https://www.nbcsports.com/sites/rsnunited/files/styles/article_hero_image/public/article/hero/Jones_Mac_USATI_1.jpg',
            'https://www.al.com/resizer/KqrDoHSQzN6s_m-H42b2hCUWR9k=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/AYHEPGCCIBCWZJOSVBEPJ6KITE.jpg',
            'https://www.masslive.com/resizer/qEpN8mG4D3PjiWdQ17Rs5VXo1r0=/1280x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/N2GEY5HSERECDPCJE7UPFGWOHA.jpg',
            'https://cdn.vox-cdn.com/thumbor/Nvee7r8eYpWTp2lawTX2cMYdK5A=/0x0:3972x2763/1200x800/filters:focal(1669x1065:2303x1699)/cdn.vox-cdn.com/uploads/chorus_image/image/70860900/usa_today_17249687.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/6BaH3mj6vIULFJyh1f4gY-ECHnI=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/18339344/usa_today_13009617.jpg',
            'https://s.yimg.com/ny/api/res/1.2/0UIXXq2WgCqbaBkjCAExCQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTQyNw--/https://s.yimg.com/uu/api/res/1.2/rietZkKKSL1hQ0Gyfa00Ow--~B/aD0xNzA3O3c9MjU2MDthcHBpZD15dGFjaHlvbg--/https://media.zenfs.com/en/touchdown_wire_usa_today_sports_articles_829/990f2f791446af9544dcae1d9c8dbf30',
            'https://media.pff.com/2022/05/USATSI_17250819_168395514_lowres.jpg?w=956&h=538',
            'https://compote.slate.com/images/98c89f65-0858-4c01-8863-6396a450e5e7.jpeg?width=780&height=520&rect=4628x3085&offset=0x0',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_640/MTg4MzE3NjY2MjIwNDUwODMw/20-belichick.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_436/MTg4MzE3NTA4MTExOTY3MzU0/16-belichick.webp'
          ]
        },
        {
          name: 'Jets',
          code: 'NYJ',
          path: 'jets',
          location: 'new york',
          logo: 'https://content.sportslogos.net/logos/7/152/thumbs/15291162019.gif',
          youtube: [ 'https://www.youtube.com/c/nyjets' ],
          nicknames: [
            'jets',
            'gang green',
            'new jack city',
            'sack exchange',
            'sons of anarchy'
          ],
          keywords: [],
          colors: [ '#125740', '#000000' ],
          rivals: [ 'Bills', 'Dolphins', 'Patriots' ],
          images: [
            'https://content.sportslogos.net/logos/7/152/thumbs/15291162019.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/p44tdvyponco8tsdbxob.jpg',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/bmp3iaxjeufuebilyarh.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jets/xexh5qn6njozbg3o6zjq.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jets/j0tv7oo2pgfpf0dvnl0l.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jets/qpvjc7pxcmjfsdd3rkex.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jets/iauqdep1yauwnkrxnje9.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jets/qw8mlgjxdbtsir5md1jr.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jets/m6xvkyq5pofwjagl2pvc.jpg',
            'https://library.sportingnews.com/styles/twitter_card_120x120/s3/2021-10/keelan-cole-103121-getty-ftr_8zkgshfuxccu1a8dvqsdsjn1y.jpg?itok=Gmctk8_C',
            'https://images2.minutemediacdn.com/image/upload/c_fill,w_720,ar_16:9,f_auto,q_auto,g_auto/shape/cover/sport/https-3A-2F-2Fthejetpress-com-2Fwp-content-2Fuploads-2Fgetty-images-2F2017-2F07-2F1183826353-850x560-98de31be64f6ba589692db480650fb4b.jpg',
            'https://storage.googleapis.com/afs-prod/media/ab04cf2d6ef648c8bb1a0f301b89ab36/3000.jpeg',
            'https://media.pff.com/2020/11/Denzel-Mims1.jpg',
            'https://heavy.com/wp-content/uploads/2022/08/Zach-Wilson-70-e1659377170130.jpg?quality=65&#038;strip=all&#038;w=780',
            'https://www.si.com/.image/t_share/MTg0MzEyMDI4NTkxMDM5NTEz/tannehillryan-jetssack.jpg',
            'https://jetswire.usatoday.com/wp-content/uploads/sites/66/2016/02/gastineau-e1650039738928.jpg?w=900&h=540&crop=1',
            'https://jetsxfactor.com/wp-content/uploads/2021/09/Zach-Wilson-NY-Jets-Sacks-Stats-Film.jpg',
            'https://jetsxfactor.com/wp-content/uploads/2022/05/Zach-Wilson-NY-Jets-2022-Stats-Odds-Rumors.jpg',
            'https://empiresportsmedia.com/wp-content/uploads/2022/06/USATSI_17250914-1-scaled.jpg',
            'https://cdn.lastwordonsports.com/nfl/wp-content/uploads/sites/5/2021/07/gettyimages-1166819578-612x612-1.jpg',
            'https://cdn.lwosports.com/wp-content/uploads/2021/12/Jets-Defense.jpg',
            'https://jetsxfactor.com/wp-content/uploads/2021/05/New-York-Jets-Fans-MetLife-Stadium.jpg',
            'https://s3.amazonaws.com/cdn.chatsports.com/ny9.jpg',
            'https://www.bettingpros.com/wp/wp-content/uploads/Zach_Wilson_Jets_818x288_3.jpg',
            'https://images2.minutemediacdn.com/image/upload/c_fill,w_720,ar_16:9,f_auto,q_auto,g_auto/shape/cover/sport/https-3A-2F-2Fthejetpress-com-2Fwp-content-2Fuploads-2Fgetty-images-2F2017-2F11-2F853083602-miami-dolphins-v-new-york-jets-jpg-0c1aeb0d8466f0f0fc076481bb44875d.jpg'
          ]
        },
        {
          name: 'Texans',
          code: 'HST',
          path: 'texans',
          location: 'houston',
          logo: 'https://content.sportslogos.net/logos/7/157/thumbs/570.gif',
          youtube: [ 'https://www.youtube.com/c/HoustonTexans' ],
          nicknames: [ 'texans', 'bulls on parade' ],
          keywords: [],
          colors: [ '#03202F', '#A71930' ],
          rivals: [ 'Colts', 'Jaguars', 'Titans' ],
          images: [
            'https://content.sportslogos.net/logos/7/157/thumbs/570.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/sru015a9pgpufwsfg1r4.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/zilz0k6n5owigbbrpr95',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/urpm55flxryrmcvgxiww.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/ze1w7u4fzqbc6dy3fapa.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/lplmuxemqdaaneg41i6y.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/wxj1rpkgv129acc78aey.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/rfkmf4xrrf6hc92nltr4.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/yoi1cxfkhbuzcn4asfus.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/xosqnt7k1r1ivimnu73h.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/pluyhfig0bkwaacak9od.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/rxizuv6pakznvk8jzyoz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/y44bxfo8rlyvsqymtnvn.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/texans/ryco4e2pbz6gvfldg0ke.jpg',
            'https://www.yardbarker.com/media/d/1/d180d79875a9cd130e78681b4cde9fab6b1ec63b/600_wide/USATSI_11946874.jpg',
            'https://primetimesportstalk.com/wp-content/uploads/2022/01/gettyimages-1362439061-594x594-1-0x0_c.jpg',
            'https://cdn.vox-cdn.com/thumbor/GJ8YAOa12AvEjMoKGjxetZoKw9w=/0x0:3466x2411/1200x800/filters:focal(1749x715:2303x1269)/cdn.vox-cdn.com/uploads/chorus_image/image/70360519/1362444931.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/qIMIgqm0Y6dUQ1ueBZp6Z4qBHak=/0x0:3268x2205/1200x800/filters:focal(1106x544:1628x1066)/cdn.vox-cdn.com/uploads/chorus_image/image/70100075/1350488241.0.jpg',
            'https://texanswire.usatoday.com/wp-content/uploads/sites/54/2021/08/USATSI_16606930.jpg?w=1000&h=600&crop=1',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/texans/htmxi4ouxygzuzlhn7is',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fwiththefirstpick.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2018%2F08%2F15365363-850x560.jpeg',
            'https://cdn.vox-cdn.com/thumbor/wCbDWC6WixUqU9AmTOvkW3FAcTY=/0x0:2762x2127/1200x800/filters:focal(912x508:1352x948)/cdn.vox-cdn.com/uploads/chorus_image/image/68750096/usa_today_15393428.0.jpg',
            'https://ca-times.brightspotcdn.com/dims4/default/052a991/2147483647/strip/true/crop/3000x2000+0+0/resize/840x560!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F71%2F99%2F4cd6cb244063ab29e505b5bc07b5%2Fhttps-delivery.gettyimages.com%2Fdownloads%2F1285823748',
            'https://pbs.twimg.com/media/Db5Y-WOV0AEH0HO.jpg',
            'https://hottestfemaleathletes.com/wp-content/uploads/2022/02/18990-liz-rivera-houston-texans-cheerleader.png',
            'https://i.pinimg.com/originals/ce/b2/cd/ceb2cdcbe7b20658b5655c78294135bd.jpg',
            'https://s.hdnux.com/photos/01/14/41/65/20061557/3/rawImage.jpg',
            'https://i.pinimg.com/736x/ec/2e/ef/ec2eefd3c0d6672961554cac95fccdb2--football-girls-texans-football.jpg',
            'https://i.pinimg.com/originals/66/9e/21/669e2187423c5f7ed843ddd5572e1071.jpg',
            'https://i.insider.com/5fd785fd9cf1420018d2f238?width=1000&format=jpeg&auto=webp',
            'https://i.iheart.com/v3/re/new_assets/5d499228d4db8e3d3da2d5b6?ops=contain(1480,0)'
          ]
        },
        {
          name: 'Colts',
          code: 'IND',
          path: 'colts',
          location: 'indianapolis',
          logo: 'https://content.sportslogos.net/logos/7/158/thumbs/593.gif',
          youtube: [ 'https://www.youtube.com/c/colts' ],
          nicknames: [ 'colts', 'c.o.l.t.s' ],
          keywords: [],
          colors: [ '#002C5F', '#A2AAAD' ],
          rivals: [ 'Texans', 'Jaguars', 'Titans' ],
          images: [
            'https://content.sportslogos.net/logos/7/158/thumbs/593.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/arw2lonehyorjjamyxfh.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/vdahewfztjakn2lo1d0s',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/wv6qde7nwoglpkyxodqu',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/jefdr20kyidvsgty0cck',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/qzjls43v6n056zfndd7r',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/qhon0jptm9eb4vgpiiai',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/qebxegncm3bpolmy03zc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/colts/vk800iemugfapysz9sgi.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/colts/lqwfjr3nwf3pca314ome.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/colts/ps7amypfyvxehc5r0bfl.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/colts/ifsxenarow3gxgxcqvxu.jpg',
            'https://editorial01.shutterstock.com/preview-440/9939768bv/55258cd5/Shutterstock_9939768bv.jpg',
            'https://cdn.vox-cdn.com/thumbor/kKjxpfmLl2uk5s_9S2JKJEAc4Z0=/0x0:2738x1825/1200x800/filters:focal(1052x464:1490x902)/cdn.vox-cdn.com/uploads/chorus_image/image/68584089/usa_today_15340202.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/DSyx1OQXxsks2vMwQAkgsBe-7Is=/0x0:2737x1826/1200x800/filters:focal(1151x695:1587x1131)/cdn.vox-cdn.com/uploads/chorus_image/image/70880825/usa_today_17094133.0.jpg',
            'https://img.bleacherreport.net/img/images/photos/002/588/756/hi-res-96144327-fans-of-the-indianapolis-colts-hold-up-signs-before-the_crop_exact.jpg?w=1200&h=1200&q=75',
            'https://indyhub.org/wp-content/uploads/Fans-10.jpg',
            'https://www.gannett-cdn.com/presto/2019/10/20/PIND/d85e33a1-d929-4e69-b5f7-6274edd7d470-Colts_RS22.JPG?crop=2399,1349,x0,y116&width=2399&height=1349&format=pjpg&auto=webp',
            'https://www.gannett-cdn.com/presto/2019/10/20/PIND/c2a2f9ad-7039-4fc6-b2ed-60f7ecf9c87b-Colts_RS21.JPG',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/colts/cjgqq1uoksdes277wovz',
            'https://www.si.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_faces:center%2Cq_auto:good%2Cw_768/MTg5NzM5NTYzNzMwNjA5ODUy/usatsi_17298636.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fhorseshoeheroes.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2022%2F08%2F18806841.jpeg',
            'https://www.thecoldwire.com/wp-content/uploads/2022/06/GettyImages-1350876554.jpg',
            'https://coltswire.usatoday.com/wp-content/uploads/sites/49/2021/09/USATSI_16740035.jpg?w=1000&h=600&crop=1',
            'https://www.reviewjournal.com/wp-content/uploads/2018/10/11311956_web1_11286470-9cbbb5419f6e40fd86437220f00d3d2f.jpg',
            'https://profootballtalk.nbcsports.com/wp-content/uploads/sites/25/2020/09/GettyImages-1272540496-e1600624477402.jpg',
            'https://i.cbc.ca/1.6298418.1640493850!/fileImage/httpImage/image.jpg_gen/derivatives/16x9_780/carson-wentz-25122021.jpg',
            'https://moolta.files.wordpress.com/2012/09/crazy-nfl-fans-costumes-20.jpeg'
          ]
        },
        {
          name: 'Jaguars',
          code: 'JAX',
          path: 'jaguars',
          location: 'jacksonville',
          logo: 'https://content.sportslogos.net/logos/7/159/thumbs/15988562013.gif',
          youtube: [ 'https://www.youtube.com/c/jaguars' ],
          nicknames: [
            'jaguars',
            'jags',
            'wildcats',
            'cats',
            'jackson 5',
            'cardiac jags',
            'sacksonville'
          ],
          keywords: [],
          colors: [ '#006778', '#D7A22A' ],
          rivals: [ 'Texans', 'Colts', 'Titans' ],
          images: [
            'https://content.sportslogos.net/logos/7/159/thumbs/15988562013.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/bzcdol7frkhq1dwdw2zc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/qjd2fjwxri3kzlrlmvm5.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/o9zkjmg5o5qizp9c6xxe.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/irhxkrddkpcuvocevvoc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/tqxrachoqwtdg6ger0i6.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/m5tx4itoltgfszuwf2jk.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/gqh9zjbofdwnww0awcd8.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/yw55xeplrlnrwejkl3i8.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/vnzp9ogzxt9vywdoidt7.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/vbao5wt9tnh7kqtncgs4.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/k8byvvop5e2kpfwossm1.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/lddmoch0yhiyhkyuhqsc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/jaguars/djlfxb25n4j3cr5ys26z.jpg',
            'https://media.nbcchicago.com/2021/11/web-211107-josh-allen-josh-allen.jpg?quality=85&strip=all&resize=1200%2C675',
            'https://www.gannett-cdn.com/presto/2021/11/24/NFTU/375d1cd2-281e-4b41-9bd9-2b5b7d6c849a-JKI_112121_bsJagsVs49ers_2_1.jpg',
            'https://jaguarswire.usatoday.com/wp-content/uploads/sites/46/2019/12/usatsi_13828944.jpg',
            'https://thefalconswire.usatoday.com/wp-content/uploads/sites/56/2019/07/gettyimages-1023457474.jpg',
            'https://s.yimg.com/ny/api/res/1.2/Ail6oeMSzqq3PjwrW1hrhA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTQyNw--/https://s.yimg.com/uu/api/res/1.2/07HzilLmuxMekwtESd3j1g--~B/aD0xNzA3O3c9MjU2MDthcHBpZD15dGFjaHlvbg--/https://media.zenfs.com/en/jacksonville_jaguars_wire_usa_today_sports_articles_630/cff58351f3b631f6b3f1543a1b2abc34',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cq_auto:good%2Cw_1200/MTg5MTEzMjgwOTk0NzQ4MzA3/travon-walker-jags-site.png',
            'https://www.si.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_faces:center%2Cq_auto:good%2Cw_768/MTcxMjA0NzAxMDkyMzI1MDQz/image-placeholder-title.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fblackandteal.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F82530753-850x560.jpeg',
            'https://s.yimg.com/ny/api/res/1.2/PyhiyXY2P0CIVJbz.tRHvQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTQ1MQ--/https://s.yimg.com/uu/api/res/1.2/pLwjJ_IfMprqqiWmeZvrJg--~B/aD0xOTQ0O3c9Mjc2MDthcHBpZD15dGFjaHlvbg--/https://media.zenfs.com/en/the-florida-times-union/8ad31aabdaa095b016b0f2058ed1bc4a',
            'https://www.nbcsports.com/sites/rsnunited/files/styles/article_hero_image/public/article/hero/web-210926-jamal-agnew.jpg',
            'https://cdn.vox-cdn.com/thumbor/Vdgu7mmzlaCdL9LWREMLI-nEPv4=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23346446/1277198929.jpg'
          ]
        },
        {
          name: 'Titans',
          code: 'TEN',
          path: 'titans',
          location: 'tennessee',
          logo: 'https://content.sportslogos.net/logos/7/160/thumbs/1053.gif',
          youtube: [ 'https://www.youtube.com/c/Titans' ],
          nicknames: [ 'titans' ],
          keywords: [],
          colors: [ '#0C2340', '#4B92DB' ],
          rivals: [ 'Texans', 'Colts', 'Jaguars' ],
          images: [
            'https://content.sportslogos.net/logos/7/160/thumbs/1053.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/jt5nivbfjm5ivhzv3jd3.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/xc5wvkqneqdn8vklkhrv',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/wsnrtofy3m7ic6eir3vu.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/xp597uqysgg9jzxibacw.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/zioeftfxgqqntpdjdmxn.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/csvabc8eb9fpachagtat.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/jjvth5u3a6xfjhg0ll9w.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/tjnvqpsbgjdbxthgmdyo.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/hqmtyqbtvcz5ddvaj66t.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/jhrkwsz6z8wakyqsivec.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/y3a5klf7wnwqmoconrlj.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/titans/ylfl9polw2bebm72nn1r.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_1067,w_1600/https%3A%2F%2Ftitansized.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2021%2F05%2F1294718797.jpeg',
            'https://cdn.vox-cdn.com/thumbor/He31n_1NubYQnuR0A9KGsThZ4Ek=/0x0:2478x1530/1200x800/filters:focal(1016x398:1412x794)/cdn.vox-cdn.com/uploads/chorus_image/image/70396949/1350681542.0.jpg',
            'https://storage.googleapis.com/afs-prod/media/40c4d972847e4c05a52a498df3849974/3000.jpeg',
            'https://cdn.vox-cdn.com/thumbor/z5mR6Yw55LLnUQDmCAhU12AvFOA=/0x0:5100x3400/1200x800/filters:focal(1842x191:2658x1007)/cdn.vox-cdn.com/uploads/chorus_image/image/65673644/1181459906.jpg.5.jpg',
            'https://d.newsweek.com/en/full/1560536/derrick-henry-tennessee-titans.jpg?w=1600&h=1600&q=88&f=474385fea208c00e293fb2c7e1c14d26',
            'https://atozsports.com/wp-content/uploads/2021/11/USATSI_17117992-scaled.jpg',
            'https://titanswire.usatoday.com/wp-content/uploads/sites/43/2021/10/USATSI_16982966.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Ftenntruth.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1192323262-850x560.jpeg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Ftitansized.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F14923646.jpeg',
            'https://titanswire.usatoday.com/wp-content/uploads/sites/43/2021/01/4b53caa8a4464bb696b6d3838ca20d5c.jpg',
            'https://storage.googleapis.com/afs-prod/media/2b66a3041ee642a6892733ba9f0d54cc/3000.jpeg',
            'https://cdn.profootballrumors.com/files/2020/01/USATSI_13898625.jpg',
            'https://bloximages.newyork1.vip.townnews.com/nashvillepost.com/content/tncms/assets/v3/editorial/6/b9/6b9ec514-79aa-11ec-84f6-8714850b55bf/61e8e69bd2f0b.image.jpg?resize=1200%2C772',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/titans/ys6gvehmbguktl3iunq9',
            'https://www.yardbarker.com/media/c/4/c432ecfdeaf55a2129d310ae08cae49dffb5dea4/600_wide/USATSI_11921425.jpg'
          ]
        },
        {
          name: 'Ravens',
          code: 'BLT',
          path: 'ravens',
          location: 'baltimore',
          logo: 'https://content.sportslogos.net/logos/7/153/thumbs/318.gif',
          youtube: [ 'https://www.youtube.com/c/BaltimoreRavens' ],
          nicknames: [ 'ravens', 'birds', 'purple pain', 'purple murder' ],
          keywords: [],
          colors: [ '#241773', '#4B9E7C0C92DB' ],
          rivals: [ 'Bengals', 'Browns', 'Steelers' ],
          images: [
            'https://content.sportslogos.net/logos/7/153/thumbs/318.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/hrszjrksurduktmmj9pt.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/zwmshppmhqmgwi4cf6wv',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/ravens/chcjmhagz22hpwpcvf63.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/ravens/ttraqx5hbzrbjxskqqyk.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/ravens/ajg5ld7nojfeq0i9diki.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/ravens/zma8gxxof8omy7zfxtnx.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/ravens/zybxjsjfdfuxda5yzeey.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/ravens/cxzal3myy1r9vlbksk3e.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/ravens/joxizxnd9dh6mpl63qaf.jpg',
            'https://sportshub.cbsistatic.com/i/r/2021/05/20/e77fde70-d1f9-4c11-bee9-c10d7b2291f4/resize/670x377/0f1b96b1161bc889455e01b1617c70bf/j-k-dobbins-4-1400.jpg',
            'https://img.bleacherreport.net/cms/media/image/09/59/52/98/11b3/44e7/8237/d65d1a802b64/crop_exact_preview.jpg?h=341&q=90&w=512',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/ejiouvqf2z50q2wgmpn5',
            'https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg3MTMzMDE0OTE5MDk1NDM3/thtstdhs61d0biiklaaz.jpg',
            'https://cdn.vox-cdn.com/thumbor/PTwwpNW--D1GE8hnA07QC03Fxwg=/0x0:3000x1999/1200x800/filters:focal(946x448:1426x928)/cdn.vox-cdn.com/uploads/chorus_image/image/70020396/1347118815.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/LS9Yz5XzOXqUcOi3K00ghqhbGJM=/0x0:3471x5206/1200x800/filters:focal(1424x1097:1978x1651)/cdn.vox-cdn.com/uploads/chorus_image/image/71207383/usa_today_18771079.0.jpg',
            'http://res.cloudinary.com/ybmedia/image/upload/c_crop,h_431,w_769,x_582,y_68/c_fill,f_auto,h_495,q_auto,w_880/v1/m/0/d/0d879fb123b829b2ab9d48f883c56753f932a362/ravens-de-calais-campbell-retire-2022-season.jpg',
            'https://cdn.vox-cdn.com/thumbor/2I8_v5qblqYY8w5N8U55LnLw1Ks=/0x0:2300x1150/fit-in/1200x600/cdn.vox-cdn.com/uploads/chorus_asset/file/22934594/1347119064.jpg',
            'https://cdn.vox-cdn.com/thumbor/kl_aGr_D8508Jyfuh7MCFwKyEwA=/0x0:5472x2736/fit-in/1200x600/cdn.vox-cdn.com/uploads/chorus_asset/file/23325593/1177910309.jpg',
            'https://i5.walmartimages.com/dfw/4ff9c6c9-bf8b/k2-_4d217994-bb5f-4a73-8853-0dd2c1604984.v1.jpg',
            'https://ravenswire.usatoday.com/wp-content/uploads/sites/55/2018/05/usatsi_10394570.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Febonybird.com%2Ffiles%2F2016%2F06%2Fnfl-st.-louis-rams-baltimore-ravens-1.jpg',
            'https://i.pinimg.com/originals/e9/37/74/e93774c4b479d0431b7e708a35d5c519.jpg',
            'https://4.bp.blogspot.com/-G30oaPSJur8/UhKR-qTpcwI/AAAAAAAANEQ/60Jyra38pXY/s1600/ravens+-02i-u-0Capture.JPG',
            'https://static.www.nfl.com/image/private/t_editorial_landscape_12_desktop/league/fkgzzwa0qgnmym9okaso',
            'https://cdn.vox-cdn.com/thumbor/w9eyj8959Wo3c9rkDgfQuJ8Clag=/0x0:6048x4024/1200x800/filters:focal(2116x1510:3082x2476)/cdn.vox-cdn.com/uploads/chorus_image/image/69727652/usa_today_16566343.0.jpg',
            'https://pressboxonline.com/wp-content/uploads/2019/11/ravens18-wk11-generic-flag-800x445.jpg',
            'https://imageresizer.static9.net.au/VmYpFn522N2QFp1bW4Kzlst3AFE=/696x522/https%3A%2F%2Fprod.static9.net.au%2Ffs%2F99792d21-3562-4062-836f-e82ba929381f',
            'https://sportshub.cbsistatic.com/i/r/2020/12/15/cf4161f3-5817-47fb-9007-37f82c222b82/thumbnail/1200x675/ed646886a6f4e6f4090b05b0fd9f506d/template.jpg',
            'https://cdn.vox-cdn.com/thumbor/tb8RzeyVARgm5cPUT9WeJRPImIE=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/13632114/1083454512.jpg.jpg',
            'https://static01.nyt.com/images/2020/11/08/sports/08nfl-live-1/08nfl-live-1-videoSixteenByNineJumbo1600.jpg',
            'https://cdn.vox-cdn.com/thumbor/PL9aWlgULNrQXwHAfuzKaw--h4c=/0x0:3282x2188/1200x800/filters:focal(1440x161:1964x685)/cdn.vox-cdn.com/uploads/chorus_image/image/70866319/1284614452.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/LfDHMkptZtgU37lv5GSinbMLF_8=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/19815751/1196924378.jpg.jpg',
            'https://cdn.theathletic.com/cdn-cgi/image//app/uploads/2022/06/05190258/GettyImages-1344659817-1024x682.jpg?width=750'
          ]
        },
        {
          name: 'Bengals',
          code: 'CIN',
          path: 'bengals',
          location: 'cincinnati',
          logo: 'https://content.sportslogos.net/logos/7/154/thumbs/15420492021.gif',
          youtube: [ 'https://www.youtube.com/c/Bengals' ],
          nicknames: [ 'bengals', 'wildcats', 'cats', 'bungles', 'swat team' ],
          keywords: [],
          colors: [ '#FB4F14', '#000000' ],
          rivals: [ 'Ravens', 'Browns', 'Steelers' ],
          images: [
            'https://content.sportslogos.net/logos/7/154/thumbs/15420492021.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/g3sc7ecge9qa8aqd3zcn.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bengals/xzcw8anjb9bmzwpnjepq.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bengals/roq4luyp27tu2x8sjx4e.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bengals/yuy1uvx4fpcca3vevdft.jpg',
            'https://www.betus.com.pa/wp-content/uploads/2022/06/ezgif.com-gif-maker-51-scaled.webp',
            'https://ca-times.brightspotcdn.com/dims4/default/a98f7b3/2147483647/strip/true/crop/2048x1365+0+0/resize/2400x1600!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F0e%2F15%2F4934473b4e85863f24ea9ef475f4%2Fburrow1.png',
            'https://media-cldnry.s-nbcnews.com/image/upload/t_nbcnews-fp-1200-630,f_auto,q_auto:best/newscms/2015_43/1270456/151021-cincinnati-bengal-cheerleaders-mn-1125.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bengals/rjrroqrr3tpuvoohphrg.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/bengals/k4fg7nib7eipx3s31mga.jpg',
            'https://kdvr.com/wp-content/uploads/sites/11/2022/01/AP22030710491816.jpg?w=900',
            'https://i.insider.com/61f85495415c3f0018d3ce08',
            'https://sportshub.cbsistatic.com/i/r/2022/02/08/ea4a4d5e-fa9b-42a9-9b91-9d7a383edd8e/thumbnail/1200x675/821bed81fdfd92e332df2e519263f76c/burrow-chase.jpg',
            'https://www.si.com/.image/t_share/MTg4NzMyMTIxNDM3NzEwMDY4/usatsi_17680429_168390307_lowres.jpg',
            'https://cdn.vox-cdn.com/thumbor/pFm9Q3zSqrqFgIkZKKEDrlPte1g=/0x0:3674x2310/1200x800/filters:focal(1423x612:2009x1198)/cdn.vox-cdn.com/uploads/chorus_image/image/67309819/usa_today_13578927.0.jpg',
            'https://i.pinimg.com/474x/ce/48/32/ce48322e6a753e9af27c8c939a6e0e76--fans-sports-sports-fanatics.jpg',
            'https://i.pinimg.com/236x/18/c3/5c/18c35c0acf841474adc396933767acf3.jpg',
            'https://steelersdepot.com/wp-content/uploads/2020/12/JuJu-Smith-Schuster-Vonn-Bell-fumble.jpg',
            'https://ca-times.brightspotcdn.com/dims4/default/1d8eb4a/2147483647/strip/true/crop/4750x3173+0+0/resize/1486x993!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F6e%2Fed%2Fffa467bfa90a8ce0887d47f38773%2F60df4609fde84f40827fca6be541337d',
            'https://abc6onyourside.com/resources/media/46397b0c-6533-4444-931c-b72b68d7c2dc-large1x1_AP20257755967377.jpg?1600302856161',
            'https://www.gannett-cdn.com/presto/2022/07/26/PCIN/3a2cd21d-44dd-4666-8f0e-efb405d881c5-DSC09569-lowres.jpg',
            'https://stripehype.com/wp-content/uploads/getty-images/2018/08/1174191779.jpeg',
            'https://cdn.vox-cdn.com/thumbor/9RJUS7PfXqGG29Q0ol-i5v90G6g=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23213745/1236922431.jpg',
            'https://cdn.theathletic.com/cdn-cgi/image//app/uploads/2022/08/31224144/USATSI_17698519-1024x683.jpg?width=750',
            'https://i.iheart.com/v3/re/new_assets/5d499123d4db8e3d3da2d59a?ops=contain(1480,0)',
            'https://i.giphy.com/media/7R2xx7K5wbZHt3EYFm/giphy.webp'
          ]
        },
        {
          name: 'Browns',
          code: 'CLV',
          path: 'browns',
          location: 'cleveland',
          logo: 'https://content.sportslogos.net/logos/7/155/thumbs/15578552015.gif',
          youtube: [ 'https://www.youtube.com/c/browns' ],
          nicknames: [ 'browns', 'brownies', 'dawg pound', 'legion of vroom' ],
          keywords: [],
          colors: [ '#311D00', '#FF3C00' ],
          rivals: [ 'Ravens', 'Bengals', 'Steelers' ],
          images: [
            'https://content.sportslogos.net/logos/7/155/thumbs/15578552015.gif',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/km45jhsfcp0daj1oo4qt',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/da9oo1regfzadj1wyjbu',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/vhqpupux21ktydws1wqw',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/browns/yh8ydlmc0skowyzrawwp.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/browns/kesr9nfhtjhys3qsqsvd.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/browns/jtbfwqyvfo3ueqxrbxwg.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/browns/ameqykgmmykmtik7b5k7.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/browns/t7bffpnrrh88laf8shgc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/browns/eeuokfrrz5cplipxxffg.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2534,w_3200/https%3A%2F%2Fdawgpounddaily.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2018%2F08%2F16999319.jpeg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fdawgpounddaily.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2018%2F08%2F17240852.jpeg',
            'https://www.mlive.com/resizer/ngVkCKJlZVzhuzQeLHWdJDEYJc0=/800x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/VJDYM44JPBFLVJLTTYK5HPXLRQ.JPG',
            'https://www.gannett-cdn.com/media/2020/08/26/Akron/ghows-OH-200719077-d34665a2.jpg',
            'https://www.gannett-cdn.com/presto/2021/09/26/NABJ/f43f3e42-a32e-42ad-8d43-1bebeff98931-AP21269670812465.jpg?width=660&height=990&fit=crop&format=pjpg&auto=webp',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fdawgpounddaily.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F14944057.jpeg',
            'https://i.pinimg.com/236x/62/99/96/6299962f67b5420384ecb8ca77c826cb--cleveland-browns-football-nfl-football.jpg',
            'https://i.pinimg.com/736x/6d/61/8c/6d618c803f64a6a4c0f6f0fa78db2da8.jpg',
            'https://i.insider.com/5e17413db2e66a2e2654f114?width=1136&format=jpeg',
            'https://ca-times.brightspotcdn.com/dims4/default/576affe/2147483647/strip/true/crop/1200x700+0+0/resize/840x490!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fb0%2Ffe%2Faec83aaa9ac41d8c1dca9ac0b225%2Fla-sp-browns-fans-20141115',
            'https://www.cleveland.com/resizer/4UORU1nrkIOe2sjHWMxyon54f4I=/1280x0/smart/advancelocal-adapter-image-uploads.s3.amazonaws.com/expo.advance.net/img/45a1e92403/width2048/0e5_gettyimages226201.jpeg',
            'https://wehco.media.clients.ellingtoncms.com/img/photos/2017/12/18/resized_250499-2cbrownsfan40pbw_78-24046_t800.jpg?90232451fbcadccc64a17de7521d859a8f88077d',
            'https://cdn.theathletic.com/cdn-cgi/image//app/uploads/2022/08/29111935/GettyImages-1242799854-scaled-e1661786437850-1024x681.jpg?width=750',
            'https://cdn.theathletic.com/cdn-cgi/image//app/uploads/2022/08/21195223/USATSI_18907429-1024x683.jpg?width=750',
            'https://i.iheart.com/v3/re/new_assets/5d499cbad4db8e3d3da2d5c5?ops=contain(1480,0)'
          ]
        },
        {
          name: 'Steelers',
          code: 'PIT',
          path: 'steelers',
          location: 'pittsburgh',
          logo: 'https://content.sportslogos.net/logos/7/156/thumbs/970.gif',
          youtube: [ 'https://www.youtube.com/c/steelers' ],
          nicknames: [ 'steelers', 'steel curtain', 'blitzburgh', 'killer bs' ],
          keywords: [],
          colors: [ '#FFB612', '#101820' ],
          rivals: [ 'Ravens', 'Bengals', 'Browns' ],
          images: [
            'https://content.sportslogos.net/logos/7/156/thumbs/970.gif',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/rdekphph5hiusspenook',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/lgsbfjj3hf4br6woo3vb',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/f9a8saseruisejenuivs',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/yugjxarzz2dwddf7ovsd',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/v1567112625/nfl100/wfnvoomrhmtdzgmulols',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/ooy3etwtjldkccdduyxh.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/steelers/bcwneqrps0aqzybmkzff.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/steelers/vzoayqf9cdv1zspdtycc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/steelers/ayjw3zi2ufrqt2mall9d.jpg',
            'https://i.ytimg.com/vi/PlUGgYnyLVU/maxresdefault.jpg',
            'https://www.gannett-cdn.com/presto/2019/12/19/PPYD/314db574-e9e3-47fa-838c-bb428b4c6884-AP19351196087300.jpg?width=660&height=441&fit=crop&format=pjpg&auto=webp',
            'https://pbs.twimg.com/media/B6eSlu1IUAMPQb_.jpg',
            'https://64.media.tumblr.com/1c865dc7e4b0e4cd6b4211a17d60f159/08696f88386725de-5e/s500x750/b065b39060263738719f7513404d0230777803e9.jpg',
            'https://i.insider.com/5a26b695f914c35e018b7e95?width=1136&format=jpeg',
            'http://media.cleveland.com/browns_impact/photo/harrison-mass-hit-horiz-jgjpg-797e85001d0dc878.jpg',
            'https://stillcurtain.com/files/2016/01/antwon-blake-ryan-shazier-tyler-eifert-nfl-afc-wild-card-pittsburgh-steelers-cincinnati-bengals.jpg',
            'https://steelerswire.usatoday.com/wp-content/uploads/sites/76/2020/09/2a3e376d394947da9345b9cf843eeb5d-1.jpg?w=1000&h=600&crop=1',
            'https://www.si.com/.image/t_share/MTc2MTI2NjI0MzUyNTc2OTM3/top-pittsburgh-steelers-rivals.jpg',
            'https://steelerswire.usatoday.com/wp-content/uploads/sites/76/2020/11/USATSI_15204511-1-1.jpg',
            'https://steelerswire.usatoday.com/wp-content/uploads/sites/76/2020/04/usatsi_10440945.jpg',
            'https://cdn.vox-cdn.com/thumbor/_u6Um6lVpAQZVpKbWAjVlZmdEvg=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/18958241/1160492223.jpg.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/steelers/xo5sdufc9kwz2xhidumb',
            'https://cdn.vox-cdn.com/thumbor/Aq9LS6DHKjpmpsJSnBI1h17DQno=/0x0:3288x2336/1400x933/filters:focal(1151x764:1677x1290):no_upscale()/cdn.vox-cdn.com/uploads/chorus_image/image/57336157/usa_today_10367709.0.jpg',
            'https://www.dkpittsburghsports.com/img/Steelers/TJ%20Watt%20Week%201%20hype%20Cincinnati%20Roser%20STEELERS-1600x900.webp',
            'https://www.dkpittsburghsports.com/img/Steelers/Najee%20Harris%20Week%201%20Cincinnati%20Roser%20Steelers-544x306.webp'
          ]
        },
        {
          name: 'Broncos',
          code: 'DEN',
          path: 'broncos',
          location: 'denver',
          logo: 'https://content.sportslogos.net/logos/7/161/thumbs/9ebzja2zfeigaziee8y605aqp.gif',
          youtube: [ 'https://www.youtube.com/c/broncos' ],
          nicknames: [ 'broncos', 'no fly zone', 'orange crush', 'three amigos' ],
          keywords: [],
          colors: [ '#FB4F14', '#002244' ],
          images: [
            'https://content.sportslogos.net/logos/7/161/thumbs/9ebzja2zfeigaziee8y605aqp.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/qwcnsosp73oq7u6vhejm.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/nve7lx7g1sh6cjhywkpt',
            'https://www.sportsnet.ca/wp-content/uploads/2022/01/Denver-Broncos-cornerback-Pat-Surtain-II-1040x572.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2160,w_3200/https%3A%2F%2Fpredominantlyorange.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F18336828.jpeg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/rfumh393jr7qikjzayjq.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/jbmwa4lzlvev9qlv3mfo.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/yehple6pvvd9jwlmjrjc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/dsdsucgihzb2yrbi1rbz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/k3djl3ksiiall2scf6zc.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/ww93jregklfbws4gehog.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/h2n0tlza0mjeftt6ubfe.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/w3jgpk5weryqws7qslue.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/cmh7hkoqcvoczshqlibe.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/broncos/drith41wb2gwirag8wab.jpg',
            'https://i0.wp.com/insidethestar.com/wp-content/uploads/2022/07/tonystahl_dallas-cowboys_cowboys-at-broncos-preseason-preview-2-scaled.webp',
            'https://ca-times.brightspotcdn.com/dims4/default/365fcec/2147483647/strip/true/crop/5334x8001+0+0/resize/840x1260!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F6b%2F53%2F6923e8ad6fe3ed15e5305fd75d82%2F2a56f94d9a2c48a88965f0e0869e7a2f',
            'https://images.complex.com/complex/images/c_fill,dpr_auto,f_auto,q_auto,w_1400/fl_lossy,pg_1/USATSI_9528932_161889396_lowres_ginecx/cam-newton?fimg-ssr-default',
            'https://static.independent.co.uk/s3fs-public/thumbnails/image/2015/12/12/01/Rob-Gronkowski-getty.jpg',
            'https://www.sportico.com/wp-content/uploads/2022/01/AP21346769612475.jpg',
            'https://cdn.vox-cdn.com/thumbor/XswJxjuSRSUNBWigPOeCHXLjM-Q=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22974311/1350499908.jpg',
            'https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,h_1200,q_75,w_1200/v1/clients/denver/broncos-super-bowl-parade-woman-2016_571e7b76-f0ae-5102-065b60d78be6b0a8.jpg',
            'https://milehighsports.com/wp-content/uploads/2016/09/USATSI_9528924_164063748_lowres-696x464.jpg',
            'https://cdn.vox-cdn.com/thumbor/BfMLp3OKCzVzsZe-km6Njm8dHwI=/0x0:3750x2499/1400x1400/filters:focal(1575x950:2175x1550):format(jpeg)/cdn.vox-cdn.com/uploads/chorus_image/image/51378203/607570368.0.jpg',
            'https://cdn.thednvr.com/uploads/2015/07/USATSI_8140161-Cropped.jpg',
            'https://www.denverpost.com/wp-content/uploads/2016/05/010515_bronco_fans_41.jpg',
            'https://www.denverpost.com/wp-content/uploads/2016/05/010515_bronco_fans_24.jpg',
            'https://cdn.vox-cdn.com/thumbor/ksk5GapAXc_oaqcchO4oqNZ5THA=/0x0:5019x3346/1200x800/filters:focal(1917x1139:2719x1941)/cdn.vox-cdn.com/uploads/chorus_image/image/70017029/1344834410.0.jpg',
            'https://www.sbo.net/wp-content/uploads/2013/09/denver-broncos-cheerleaders-12.jpg',
            'https://media3.giphy.com/media/dQdfvRH5ismhLsRFJz/giphy.gif?cid=ecf05e47iseju1p8ks48z92ml99ngw87g803nlyk2thcxdf6&rid=giphy.gif&ct=g',
            'https://moolta.files.wordpress.com/2012/09/crazy-nfl-fans-911.jpeg',
            'https://media0.giphy.com/media/LBCnioHLnPwpqBjGhI/giphy.webp'
          ]
        },
        {
          name: 'Chiefs',
          code: 'KC',
          path: 'chiefs',
          location: 'kansas city',
          logo: 'https://content.sportslogos.net/logos/7/162/thumbs/857.gif',
          youtube: [ 'https://www.youtube.com/c/KansasCityChiefs' ],
          nicknames: [
            'chiefs',
            'legion of zoom',
            'sack nation',
            'redwood forest'
          ],
          keywords: [],
          colors: [ '#E31837', '#FFB81C' ],
          images: [
            'https://content.sportslogos.net/logos/7/162/thumbs/857.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/ssidiwc2zr4jfuq1sujj.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/ur9hm1tjillep28dvugt',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/tm9dxfhzcr6kobckf6no',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/koaa9w2kwrydqwnwoi5k.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/v6ddx3acfbtcl0hsybrz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/iv5hwcsir1atz9cckihl.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/aycnfrrtbfsyiw4pcy5i.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/skxkyfrgu6g18js0yqhj.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/ogakentsssjgjjfxt4k1.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/h3stfdtljgjtiuprsajf.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/dlddbfdddmlk97p6dh74.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chiefs/xjy1mceleqf1r2ivd8j6.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_12_desktop/chiefs/bmenyrzkqumcoe2emjw7',
            'https://www.kansascity.com/latest-news/7fby5i/picture235402657/alternates/FREE_1140/KCM_ChiefsRavens_154_092219_JWcopy.JPG',
            'https://www.bethanyclipper.com/wp-content/uploads/2022/01/Chiefs-playoffs-459x840.jpg',
            'https://www.gannett-cdn.com/presto/2020/11/23/NCDT/639266dd-2a59-4a8c-8013-88b4b12bf33d-AP20328159225398.jpg',
            'https://ca-times.brightspotcdn.com/dims4/default/8335cb8/2147483647/strip/true/crop/4412x3172+0+0/resize/840x604!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F13%2F70%2F7ed57113405e9a013462e1690bca%2Fsd-photos-1staff-887576-sd-sp-chargers-kansascity-015.JPG',
            'https://i.pinimg.com/originals/dc/39/27/dc3927d70d7a97bbe5e67bac3913184f.jpg',
            'https://i.pinimg.com/originals/f0/33/b3/f033b3e6a7a7c5391c7431c282a934a0.jpg',
            'https://www.wallpaperup.com/uploads/wallpapers/2017/12/27/1178511/ad0f3998786a72ffa314528ece583d7d-700.jpg',
            'https://www.kansascity.com/latest-news/crhq2b/picture246567968/alternates/FREE_1140/ChiefsBillsFootball%20(6).JPG',
            'https://cdn.vox-cdn.com/thumbor/WNlNRh1ymGnhT7TyULiIGV6t9to=/0x0:3944x2888/1200x800/filters:focal(427x439:1057x1069)/cdn.vox-cdn.com/uploads/chorus_image/image/70422806/1298927471.0.jpg',
            'https://arrowheadaddict.com/wp-content/uploads/getty-images/2018/08/1063593454.jpeg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_480/MTg4MzE3NTc4NDQyMDU2ODI2/3-reid.webp',
            'https://i.giphy.com/media/YfFbDWIBXppNoMAKlW/giphy.webp',
            'https://i.giphy.com/media/dwfnEGlT7LKjw4Ch7G/giphy.webp'
          ]
        },
        {
          name: 'Raiders',
          code: 'LV',
          path: 'raiders',
          location: 'las vegas',
          logo: 'https://content.sportslogos.net/logos/7/6708/thumbs/670885212020.gif',
          youtube: [ 'https://www.youtube.com/c/raiders' ],
          nicknames: [ 'raiders', 'silver and black', 'men in black' ],
          keywords: [],
          colors: [ '#000000', '#A5ACAF' ],
          images: [
            'https://content.sportslogos.net/logos/7/6708/thumbs/670885212020.gif',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/lwejnzvpogjehcnuvizg.jpg',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/p2pfdpb93b5222nrued1',
            'https://static.www.nfl.com/image/private/t_new_photo_album/f_auto/league/ajcai60rlnbkazupieek.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/pu3ndr2qchsosolzqiww.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/pkrslohn1d98nmjdu5y3.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/opccevijljzs07t9mcr1.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/wjgmshbankxhpy13sqgk.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/pp9a3ujumkx5xitwaanx.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/gnon1aqunxaydxy0xcph.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/sf8j07gifwrqbmzv8xff.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/es0cq5hgxjqlpkczljps.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/siekubkd8tmlqdjfp4wg.jpg',
            'https://www.reviewjournal.com/wp-content/uploads/2016/01/2016-01-11t180948z_1_lynxnpec0a11l_rtroptp_3_nfl_1.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/mqfveshu5tztmgjcgsjf.jpg',
            'https://sportshub.cbsistatic.com/i/r/2022/08/05/ad64890f-907b-4c5d-9946-b79913f48f27/thumbnail/1200x675/79aa4a672604bb944ccad77d4206c5c5/template-pic.jpg',
            'https://www.mercurynews.com/wp-content/uploads/2020/11/BNG-L-RAIDERS-1116-19-1.jpg',
            'https://cdn.vox-cdn.com/thumbor/JO8-gxDnuDFLOgsri8KYlYzWl2E=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22821030/1334220638.jpg',
            'https://justblogbaby.com/wp-content/uploads/getty-images/2016/04/1194183957.jpeg',
            'https://cdn.vox-cdn.com/thumbor/3mEoDPqQaQAQ62LZHhV3Cqpu7Rw=/0x0:5860x3907/1200x800/filters:focal(2462x1486:3398x2422)/cdn.vox-cdn.com/uploads/chorus_image/image/70241929/1196617802.jpg.0.jpg',
            'https://www.fresnobee.com/latest-news/to4edm/picture264209356/alternates/FREE_1140/RaidersJaguarsFootball.JPG',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_mobile/f_auto/raiders/fmendfwqajjpsvo83avo.jpg',
            'https://static.clubs.nfl.com/image/private/t_editorial_landscape_mobile/f_auto/raiders/xwwwpebvxvpdkokscisz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/raiders/hunyilybtwnway0d15my.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/raiders/g3btq0heatslgpvcbcjz.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/raiders/qtljl73hoknlvjeho4wk.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/raiders/hc32oo2ldd9oo2lwi7og.jpg',
            'https://i1.wp.com/www.raidersbeat.com/wp-content/uploads/2021/12/img_8461.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fjustblogbaby.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2016%2F04%2F1047811166.jpeg',
            'https://pvtimes.com/wp-content/uploads/2021/12/15917254_web1_WashingtonRaiders.jpg',
            'https://i.pinimg.com/originals/5a/bc/3c/5abc3c100348d6f5ac869dc1450fb63c.jpg'
          ]
        },
        {
          name: 'Chargers',
          code: 'LAC',
          path: 'chargers',
          location: 'los angeles',
          logo: 'https://content.sportslogos.net/logos/7/6446/thumbs/644616602020.gif',
          youtube: [ 'https://www.youtube.com/c/Chargers' ],
          nicknames: [ 'chargers', 'bolts', 'border patrol', 'super chargers' ],
          keywords: [],
          colors: [ '#0080C6', '#FFC20E' ],
          images: [
            'https://content.sportslogos.net/logos/7/6446/thumbs/644616602020.gif',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/jnkpn5ou5wp2l0sgg5n8',
            'https://static.clubs.nfl.com/image/private/t_photo_album/w_800,h_450/nfl100/uluer5ladpaj2d7uepsd',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/gbpxjxy09qcy2fwfs8fu.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/pp66axxuovbjdmazus8y.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/cegpxcclay7fpztcjupl.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/x28pr4jrj1ldrkkvlvnp.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/ham96mlym2jlw66wldzs.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/bt1shg98hnczrj4ihupt.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/t61kykyuqya41ldmhhol.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/xqjmljnwfjux3gf0yket.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/orncdfhypiywoehqef0b.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/hrj9t3rnpw7patgh8oea.jpg',
            'https://static.clubs.nfl.com/image/private/t_new_photo_album/f_auto/chargers/n1obcamxzqszewxhmazr.jpg',
            'https://cdn.vox-cdn.com/thumbor/q-52kEqhy0kct5_WIX1qqVBGxC4=/0x0:5117x3411/1200x800/filters:focal(2150x1297:2968x2115)/cdn.vox-cdn.com/uploads/chorus_image/image/70645681/1363753533.0.jpg',
            'https://ca-times.brightspotcdn.com/dims4/default/49de3be/2147483647/strip/true/crop/2832x1888+0+0/resize/840x560!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F26%2Fd7%2F9d74ca394fdfad197dd66f159551%2Fla-photos-1staff-885883-sp-1212-chargers-6-rcg.jpg',
            'https://cdn.vox-cdn.com/thumbor/oZ7SgU86eZBUvzWXRpb4WIJOxL0=/0x0:4180x2787/1200x800/filters:focal(1798x888:2466x1556)/cdn.vox-cdn.com/uploads/chorus_image/image/71098770/1364148573.0.jpg',
            'https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTc1MjczMTAzNzU1MDYwNjA2/derwin-james.jpg',
            'https://cdn.vox-cdn.com/thumbor/Khv4mGk5piwctaHWzznGfEy6iuU=/0x0:3900x2600/1200x800/filters:focal(2437x1510:3061x2134)/cdn.vox-cdn.com/uploads/chorus_image/image/70050949/1235711436.0.jpg',
            'https://www.sharpfootballanalysis.com/wp-content/uploads/2021/07/Chargers-Justin-Herbert-Sharp-Preview-2021-scaled.jpg',
            'https://www.whittierdailynews.com/wp-content/uploads/2021/10/LDN-L-CHARGERS-1005-84TP.jpg',
            'https://www.lafbnetwork.com/wp-content/uploads/2022/01/TIght-ends-scaled.jpeg',
            'https://i.guim.co.uk/img/media/794c038d84e2de9c13b1147b1addbefbc16ce570/0_533_8001_4801/master/8001.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=e697b2f16e0a479f155a811f42ec12c4',
            'https://sportshub.cbsistatic.com/i/r/2020/10/29/d1f58d9c-a6ba-40df-a705-6c951519cf8f/thumbnail/1200x675/90c33b10c2381811dadecc8ef8e9ddda/new-team.jpg',
            'https://cdn.theathletic.com/cdn-cgi/image//cdn-cgi/image/quality=85,height=800/app/uploads/2022/07/04114832/Fantasy_Kit_Hero_22-scaled.jpg?width=1920'
          ]
        }
      ]
    }
  }
}

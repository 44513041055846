export default {
  show({ commit }) {
    commit('SHOW')
  },

  hide({ commit }) {
    commit('HIDE')
  },

  pending({ commit }) {
    commit('PENDING')
  },

  done({ commit }) {
    commit('DONE')
  },
}

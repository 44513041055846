export default [

  {
    path: '/teams/:sport/:away/:home',
    name: 'posts-team-compare',
    component: () => import(/* webpackChunkName: "TeamCompare" */ '@/views/posts/TeamCompare.vue'),
    props: route => route.params,
    meta: {
      pageTitle: 'Teams',
      breadcrumb: [
        {
          text: 'Teams',
          active: true,
        },
      ],
      resource: 'Public',
      action: 'read',
    },
  },

  {
    path: '/post/:slug',
    name: 'post-detail-slug',
    component: () => import(/* webpackChunkName: "PostDetailContainer" */ '@/views/posts/PostDetailContainer.vue'),
    props: route => route.params,
    meta: {
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'Post',
          active: true,
        },
      ],
      resource: 'Public',
      action: 'read',
    },
  },

  {
    path: '/post/:sport/:slug',
    name: 'post-detail-sport-slug',
    component: () => import(/* webpackChunkName: "PostDetailContainer" */ '@/views/posts/PostDetailContainer.vue'),
    props: route => route.params,
    meta: {
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'Post',
          active: true,
        },
      ],
      resource: 'Public',
      action: 'read',
    },
  },

  {
    path: '/post/:sport/:team/:slug',
    name: 'post-detail-sport-team-slug',
    component: () => import(/* webpackChunkName: "PostDetailContainer" */ '@/views/posts/PostDetailContainer.vue'),
    props: route => route.params,
    meta: {
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'Post',
          active: true,
        },
      ],
      resource: 'Public',
      action: 'read',
    },
  },

  {
    path: '/person/:sport/:path',
    name: 'person-posts',
    component: () => import(/* webpackChunkName: "PersonDetails" */ '@/views/posts/PersonDetails.vue'),
    props: route => route.params,
    meta: {
      pageTitle: 'Person',
      breadcrumb: [
        {
          text: 'Person',
          active: true,
        },
      ],
      resource: 'Public',
      action: 'read',
    },
  },
]

// Vue imports
import Vue from 'vue'
import VueRouter from 'vue-router'
// import { createWebHistory, createRouter } from 'vue-router'

// Utility Imports
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import ability from '@/libs/acl/ability'

// Imported Routes
import admin from './routes/admin'
import authentication from './routes/authentication'
import error from './routes/error'
import pages from './routes/pages'
import posts from './routes/posts'
import authors from './routes/authors'
import prosports from './routes/prosports'
// import college from './routes/college'
// import other from './routes/other'
// import soccer from './routes/soccer'

// import othersports from './routes/othersports'
import fantasy from './routes/fantasy'

// import xxx from '../../public/config/xxx'

// eslint-disable-next-line no-console
// console.log(xxx)

// Use VueRouter
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // BASE_URL is defined in the vue.config.js file via optiion: (publicPath: '/',)
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/sports/Main.vue'),
      meta: {
        pageTitle: 'Sports',
        breadcrumb: [
          {
            text: 'Sports',
            active: true,
          },
        ],
        resource: 'Public',
        action: 'read',
      },
    },

    // { path: '/', redirect: { name: 'sports' } },

    // Imported Routes
    ...admin,
    ...authentication,
    ...error,
    ...pages,
    ...authors,
    ...posts,
    ...prosports,
    // ...college,
    // ...other,
    // ...soccer,
    // ...othersports,
    ...fantasy,

    // {
    //   path: '/:team*',
    //   component: () => import('@/views/sports/Team.vue'),
    //   meta: {
    //     pageTitle: 'Sports',
    //     resource: 'Public',
    //     action: 'read',
    //   },
    // },

    // All other Routes
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: () => import('@/views/sports/FrontPage.vue'),
//     meta: {
//       pageTitle: 'Sports',
//       breadcrumb: [
//         {
//           text: 'Sports',
//           active: true,
//         },
//       ],
//       resource: 'Public',
//       action: 'read',
//     },
//   },
//
//   // Imported Routes
//   ...admin,
//   ...authentication,
//   ...error,
//   ...pages,
//   ...authors,
//   ...sports,
//   ...fantasy,
//
//   // All other Routes
//   {
//     path: '*',
//     redirect: 'error-404',
//   },
// ]
//
// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// })
// Vue.use(router)

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // console.log('# Check if canNavigate(to)')
  // console.log(`        to.meta.action: ${to.meta.action}`)
  // console.log(`      to.meta.resource: ${to.meta.resource}`)
  // console.log(`           ability.can: ${ability.can(to.meta.action || 'read', to.meta.resource)}`)
  if (!canNavigate(to)) {
    // console.log(`#  Logged in? ${isLoggedIn}`)
    // if (!isLoggedIn) {
    //   console.log(' ----- NOT Logged In -----> auth-login')
    // } else {
    //   console.log(' ----- YES Logged In, but does not have navigation permission -----> misc-not-authorized')
    // }

    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  // console.log(`# .... CAN Navigate - so redirect to logged in route: ${to.meta.redirectIfLoggedIn}`)

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    // console.log('# .... Got to next route for Logged in User based on ROLE')
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  // console.log('# .... Else, not logged in, nor has meta redirect, so go next()')
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'DEK', // Will update name in navigation menu (Branding)
    appNameSecond: 'SPORTS',
    webAddress: 'deksports.com',

    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/dek_sports_01.png'), // Will update logo in navigation menu (Branding)
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'boxed', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'sticky', // static, sticky, hidden
    },
    customizer: true,
    deals: true,
    enableHorizontalSportMenu: false,
    enableScrollToTop: true,
    postMode: 'default', // 'default', 'drudge'
  },
  money: {
    bitcoin: {
      name: 'Bitcoin',
      link: 'http://www.bitcoin.com/deksports',
      icon: 'BitcoinIcon',
    },
  },
  social: {
    twitter: {
      name: 'Twitter',
      link: 'http://www.twitter.com/deksports',
      icon: 'TwitterIcon',
    },
    facebook: {
      name: 'Facebook',
      link: 'http://www.facebook.com/deksports',
      icon: 'FacebookIcon',
    },
    instagram: {
      name: 'Instagram',
      link: 'http://www.instagram.com/deksports',
      icon: 'InstagramIcon',
    },
    mail: {
      name: 'Email',
      link: 'mailto:xxx',
      icon: 'MailIcon',
    },
  },
}

/* eslint-disable array-bracket-spacing */
/* eslint-disable comma-dangle */
export default {
  title: 'NBA',
  category: 'nba',
  path: '/nba',
  route: 'sports-nba',
  size: '30px',
  style: 'padding: 5px 5px;',
  iconSource: 'image',
  images: [
    'https://sport.one/content/images/2019/01/42-6.jpg',
    'https://imageio.forbes.com/specials-images/imageserve/62311e20d5de500ed52177f5/0x0.jpg',
    'https://ontapsportsnet.com/wp-content/uploads/2022/02/DeMar-DeRozan-Dunk-Bulls-Magic.png',
    'https://ontapsportsnet.com/wp-content/uploads/2022/02/FLCuqGdWYAIPAFX-e1644293307507.jpeg'
  ],
  logo: 'https://content.sportslogos.net/leagues/thumbs/6.gif',
  createTeamRoutes: true,
  createTeamNavRoutes: true,
  divisions: {
    eastern: {
      title: 'Eastern',
      icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Eastern_Conference_%28NBA%29_logo_2018.png/225px-Eastern_Conference_%28NBA%29_logo_2018.png',
      iconSource: 'image',
      teams: [
        {
          name: 'Celtics',
          code: 'BOS',
          path: 'celtics',
          location: 'boston',
          logo: 'https://content.sportslogos.net/logos/6/213/thumbs/slhg02hbef3j1ov4lsnwyol5o.gif',
          youtube: [ 'https://www.youtube.com/c/Celtics' ],
          nicknames: [ 'celtics', 'celts', 'green', 'boston three party' ],
          keywords: [],
          colors: [ '#007A33', '#BA9653' ],
          rivals: [ 'Nets', 'Knicks', '76ers', 'Raptors' ],
          images: [
            'https://content.sportslogos.net/logos/6/213/thumbs/slhg02hbef3j1ov4lsnwyol5o.gif',
            'https://cdn.theathletic.com/app/uploads/2022/05/28225946/USATSI_18224785-scaled-e1653793241181-1536x1174.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/05/30102333/USATSI_18325799-1-1536x1039.jpg',
            'https://www.nba.com/celtics/sites/celtics/files/getty-images-1231915015.jpg',
            'https://cdn.bleacherreport.net/images_root/slides/photos/000/242/609/Celtics-Girls-10_original.jpg?1275342871',
            'https://cdn.bleacherreport.net/images_root/slides/photos/000/242/611/Celtics-Girls-2_original.jpg?1275343466',
            'https://cdn.bleacherreport.net/images_root/slides/photos/000/242/614/Celtics-Girls-5_original.jpg?1275343524',
            'https://cdn.bleacherreport.net/images_root/slides/photos/000/242/615/Celtics-Girls-6_original.jpg?1275343528',
            'https://cdn.bleacherreport.net/images_root/slides/photos/000/242/618/Celtics-Girls-9_original.jpg?1275343631',
            'https://sport.one/content/images/2019/01/44-1.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347f687aa0095aadde928_60de2b796657b14b08ebb2b3_SLM-226-CELTICS.jpeg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTg0NDAxMTM2ODE0Nzk0MjE4/001160608.webp',
            'https://fadeawayworld.net/.image/t_share/MTg5Njk4MDY0NDE0NjE1MzA1/9264378-nba-playoffs-atlanta-hawks-at-boston-celtics.jpg',
            'https://i.pinimg.com/originals/cf/2c/d9/cf2cd9b74155e37b9f4e4f92223dfdd3.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg0ODQ2ODc2NTczNDQzMTky/usatsi_17028349_168388303_lowres.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fhardwoodhoudini.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1399664988-850x560.jpeg',
            'https://cdn.vox-cdn.com/thumbor/ucyYZOkIb0F8LqcF7Jekb_ONyOU=/0x0:2132x3197/1200x800/filters:focal(905x926:1245x1266)/cdn.vox-cdn.com/uploads/chorus_image/image/70835241/1240431106.0.jpg',
            'https://bottlegatedotcom.files.wordpress.com/2014/11/bc11.jpg',
            'https://www.bostonherald.com/wp-content/uploads/2019/04/celticsce032.jpg'
          ]
        },
        {
          name: 'Nets',
          code: 'BKN',
          path: 'nets',
          location: 'brooklyn',
          logo: 'https://content.sportslogos.net/logos/6/3786/thumbs/hsuff5m3dgiv20kovde422r1f.gif',
          youtube: [ 'https://www.youtube.com/c/BrooklynNetsBasketball' ],
          nicknames: [ 'nets' ],
          keywords: [],
          colors: [ '#000000', '#FFFFFF' ],
          rivals: [ 'Celtics', 'Knicks', '76ers', 'Raptors' ],
          images: [
            'https://content.sportslogos.net/logos/6/3786/thumbs/hsuff5m3dgiv20kovde422r1f.gif',
            'https://www.nba.com/nets/sites/nets/files/nets-dancers-new-site.jpg?w=756&h=440',
            'https://static01.nyt.com/images/2017/12/31/arts/31basketball6/31basketball6-articleLarge.jpg?quality=75&auto=webp&disable=upscale',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThNIfCWB5a7pUjHODQMd1QUVIHMHeapIFADCrWUh0qUiIrMC62uMUwo1Y1ysO85oZ_jhI&usqp=CAU',
            'http://nbasweethearts.weebly.com/uploads/4/4/8/1/44810311/6567145_orig.jpg',
            'https://i.cdn.turner.com/drp/nba/nets/sites/default/files/asha-thumb.jpg',
            'https://www.nba.com/resources/static/team/v2/nets/homepage/slider/assets/BKN_2223_TS_MiniPlansAvail.jpeg',
            'https://www.nba.com/nets/sites/nets/files/kd-01.jpg',
            'https://www.nba.com/nets/sites/nets/files/getty-images-1240236961.jpg',
            'https://entertainmentchronicle.com/wp-content/uploads/2020/12/Kevin-Durant-Dunk.jpg',
            'https://s1.ibtimes.com/sites/www.ibtimes.com/files/styles/embed/public/2022/07/04/kevin-durant-brooklyn-nets.jpg',
            'https://nypost.com/wp-content/uploads/sites/2/2021/05/kevin-durant-defense-nets-celtics.jpg',
            'https://ftw.usatoday.com/wp-content/uploads/sites/90/2022/03/GTY_1383229967.jpeg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTc5Nzc5MzUyODQwMzgxNzIw/blake-griffin-nets-dunk.jpg',
            'https://pbs.twimg.com/media/Dv1yfdxXQAE9Bc-?format=jpg',
            'https://cdn.vox-cdn.com/thumbor/oZ8o9jh-O-93ev_m5xGEJSjevSk=/1400x788/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22294586/thanksfans_16.jpg',
            'https://fansided.com/wp-content/uploads/imagn-images/2021/12/17358486.jpeg'
          ]
        },
        {
          name: 'Knicks',
          code: 'NYK',
          path: 'knicks',
          location: 'new york',
          logo: 'https://content.sportslogos.net/logos/6/216/thumbs/2nn48xofg0hms8k326cqdmuis.gif',
          youtube: [ 'https://www.youtube.com/knicks' ],
          nicknames: [ 'knicks', 'knickerbockers', 'knuckleheads' ],
          keywords: [],
          colors: [ '#006BB6', '#F58426' ],
          rivals: [ 'Celtics', 'Nets', '76ers', 'Raptors' ],
          images: [
            'https://content.sportslogos.net/logos/6/216/thumbs/2nn48xofg0hms8k326cqdmuis.gif',
            'https://s1.ticketm.net/dam/a/403/fb1379e0-0c54-4550-a940-81a175dbd403_1339871_TABLET_LANDSCAPE_LARGE_16_9.jpg',
            'https://www.nycgo.com/images/events/7705/new-york-knicks-msg-nyc_030421_3489.jpg',
            'https://nbaanalysis.net/wp-content/uploads/2022/01/This-Knicks-Mavs-Trade-Features-Jalen-Brunson-To-NYC-678x381.jpeg',
            'https://nypost.com/wp-content/uploads/sites/2/2013/10/cover31.jpg?quality=75&strip=all&w=744',
            'https://cdn2.hubspot.net/hub/1594707/file-4161912448-jpg/blog-files/kerrieorange.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347ffe56cebab6f561cbe_60de2ba45deebf11cf085917_SLM-232-KNICKS.jpeg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTg0NDAxMTM3MzUxNDY4MDU3/001297970.webp',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2133,w_3200/https%3A%2F%2Fdailyknicks.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F15472398.jpeg',
            'https://multifiles.pressherald.com/uploads/sites/10/2022/02/AP22051150938557.jpg',
            'https://dailyknicks.com/wp-content/uploads/getty-images/2020/04/1209903015.jpeg',
            'https://a.espncdn.com/photo/2017/0212/r180254_1296x729_16-9.jpg',
            'https://library.sportingnews.com/styles/crop_style_16_9_mobile_2x/s3/2021-10/knicks-fans-go-crazy-at-madison-square-garden_fyhx9m51b051zlhmt4omg0qq.jpg',
            'https://cdn.vox-cdn.com/thumbor/zjXo_1u9tTeO4scjqFe4WneUCUA=/0x0:4290x2860/1200x800/filters:focal(1341x210:2027x896)/cdn.vox-cdn.com/uploads/chorus_image/image/70471238/usa_today_17608448.0.jpg',
            'https://empiresportsmedia.com/wp-content/uploads/2021/10/USATSI_17004983-1-1024x723.jpg',
            'https://media.newyorker.com/photos/5909578ec14b3c606c104a2e/master/pass/117161598_opt.jpg',
            'https://www.opencourt-basketball.com/wp-content/uploads/2018/08/knicks-fans-bags.png'
          ]
        },
        {
          name: '76ers',
          code: 'PHI',
          path: '76ers',
          location: 'philadelphia',
          logo: 'https://content.sportslogos.net/logos/6/218/thumbs/21870342016.gif',
          youtube: [ 'https://www.youtube.com/c/sixers' ],
          nicknames: [ '76ers', 'sixers' ],
          keywords: [],
          colors: [ '#006BB6', '#ED174C' ],
          rivals: [ 'Celtics', 'Nets', 'Knicks', 'Raptors' ],
          images: [
            'https://content.sportslogos.net/logos/6/218/thumbs/21870342016.gif',
            'https://cdn.nba.com/teams/uploads/sites/1610612765/2022/01/Bleu.jpg?imwidth=400&imheight=600',
            'https://cdn.nba.com/teams/uploads/sites/1610612765/2022/01/Destane.jpg?imwidth=386&imheight=600',
            'https://cdn.nba.com/teams/uploads/sites/1610612765/2022/01/Brooklyn.jpg?imwidth=400&imheight=600',
            'https://cdn.nba.com/teams/uploads/sites/1610612765/2022/01/Zariah.jpg?imwidth=400&imheight=600',
            'https://cdn.nba.com/teams/uploads/sites/1610612765/2022/01/Lorna.jpg?imwidth=400&imheight=600',
            'https://cdn.nba.com/teams/uploads/sites/1610612765/2022/01/Jamie.jpg?imwidth=400&imheight=600',
            'https://cdn.nba.com/teams/uploads/sites/1610612765/2022/04/GettyImages-1239797373.jpg?imwidth=1200&imheight=800',
            'https://cdn.theathletic.com/app/uploads/2022/05/28231916/USATSI_18232851-1764x2048.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/sixers/sites/sixers/files/getty-images-1240588181.jpg',
            'https://sixerswire.usatoday.com/wp-content/uploads/sites/30/2018/11/embiidmisseddunk-e1542686256314.jpg',
            'https://media.phillyvoice.com/media/images/USATSI_17925342.5a6db41c.fill-735x490.jpg',
            'https://sixerswire.usatoday.com/wp-content/uploads/sites/30/2022/08/Tyrese-Maxey-1.jpg',
            'https://static01.nyt.com/images/2022/05/10/sports/09nba-sixheat-G4-print/merlin_206607456_8f308093-09e9-41a9-b264-ece6118eb526-articleLarge.jpg',
            'https://cdn.vox-cdn.com/thumbor/q_QdlW3BE66F8FGeeNvYfEJCIyE=/0x0:3457x2305/1200x800/filters:focal(1765x754:2317x1306)/cdn.vox-cdn.com/uploads/chorus_image/image/71207625/1396298721.0.jpg',
            'https://images.eurohoops.net/2022/03/38c57003-harden-625x375.jpg',
            'https://www.nbcsports.com/sites/rsnunited/files/article/hero/Sixers-fans-USATSI.jpg',
            'https://www.beta.nba.com/sixers/sites/sixers/files/8625566_dsc2223_jpg_alex_subers_20220319_021249.jpg',
            'https://thesixersense.com/wp-content/uploads/getty-images/2017/07/1386486554.jpeg',
            'https://media3.giphy.com/media/cYoLlfOQ8RGbkaPIwB/giphy.gif?cid=ecf05e47wtqqpbd9zo2cq7n0unul4bxmslbew56lwpjxzdc8&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Raptors',
          code: 'TOR',
          path: 'raptors',
          location: 'toronto',
          logo: 'https://content.sportslogos.net/logos/6/227/thumbs/22770242021.gif',
          youtube: [ 'https://www.youtube.com/c/TorontoRaptors' ],
          nicknames: [ 'raptors', 'raps', 'dinos', 'dinosaurs' ],
          keywords: [],
          colors: [ '#CE1141', '#000000' ],
          rivals: [ 'Celtics', 'Nets', 'Knicks', '76ers' ],
          images: [
            'https://content.sportslogos.net/logos/6/227/thumbs/22770242021.gif',
            'https://assets.website-files.com/5d7fff4197d2f20ee7c02c12/5d831b2628a6014250708119_kawhi-celebrating-p-500.png',
            'https://cdn.nba.com/teams/legacy/www.nba.com/raptors/sites/raptors/files/getty-images-955773844.jpg?imwidth=512&imheight=512',
            'http://windsorite.ca/wp-content/uploads/2013/08/969267_10100130904100158_915267807_n1.jpg',
            'https://images.thestar.com/LVK1xQRE6EN31JE_pssxgYmBZXc=/1280x1024/smart/filters:cb(1642952055945)/https://www.thestar.com/content/dam/thestar/sports/raptors/analysis/2021/11/27/the-raptors-arent-sure-what-theyve-got-at-the-nba-quarter-mark-but-they-know-what-they-need/vanvleet_raptors_pacers.jpg',
            'https://www.tsn.ca/polopoly_fs/1.163975!/fileimage/httpImage/image.jpg_gen/derivatives/landscape_620/toronto-raptors-logo.jpg',
            'https://images-platform.99static.com//i9DnlLImVKZcjWzpBZcfudkHqnw=/153x109:886x842/fit-in/590x590/99designs-contests-attachments/105/105739/attachment_105739652',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg5NzEzNTUxNzk4MDUyMDY2/usatsi_18154124_168390270_lowres-3.jpg',
            'https://www.sportsnet.ca/wp-content/uploads/2022/01/siakam-bucks-raptors-1040x572.jpg',
            'https://library.sportingnews.com/styles/crop_style_16_9_mobile_2x/s3/2022-08/scottie-barnes-raptors-ftr.jpeg',
            'https://www.sportsnet.ca/wp-content/uploads/2022/02/Toronto-Raptors-guard-Fred-VanVleet-All-Stars-1040x572.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2317,w_3200/https%3A%2F%2Fraptorsrapture.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2018%2F08%2F1358112587.jpeg',
            'https://www.raptorsrepublic.com/wp-content/uploads/2019/11/Siakam-dunk-against-Sixers.jpg',
            'https://imageio.forbes.com/specials-images/imageserve/1081231886/Toronto-Raptors-fans-passing-a--We-The-North--banner-through-Scotiabank-Arena-during/960x0.jpg',
            'https://ftw.usatoday.com/wp-content/uploads/sites/90/2019/05/gettyimages-1146337941.jpg',
            'https://www.raptorsrepublic.com/wp-content/uploads/2019/05/mc05202019.png',
            'https://www.sportsnet.ca/wp-content/uploads/2019/06/jurassic-park-game-4.jpg'
          ]
        },
        {
          name: 'Bulls',
          code: 'CHI',
          path: 'bulls',
          location: 'chicago',
          logo: 'https://content.sportslogos.net/logos/6/221/thumbs/hj3gmh82w9hffmeh3fjm5h874.gif',
          youtube: [ 'https://www.youtube.com/c/ChicagoBulls' ],
          nicknames: [ 'bulls', 'da bulls' ],
          keywords: [],
          colors: [ '#CE1141', '#000000' ],
          rivals: [ 'Cavaliers', 'Pistons', 'Pacers', 'Bucks' ],
          images: [
            'https://content.sportslogos.net/logos/6/221/thumbs/hj3gmh82w9hffmeh3fjm5h874.gif',
            'https://assets.website-files.com/5d7fff4197d2f20ee7c02c12/5d86b622f0c5f382608641f5_mj-trohpy.png',
            'https://cdn.theathletic.com/app/uploads/2022/01/28174533/AP22022140978307-1-scaled-e1643488818671-1536x1098.jpg',
            'https://media.npr.org/assets/img/2020/04/17/credit_-andy-hayt-_-nba-photos-caption_-vancouver-bc---january-27_-scottie-pippen-33-and-michael-jordan-23-of-the-chicago-bulls-sit-on-the-bench-during-the-game-against-the-vancouver-grizzlies-at-general-motors-_wide-9b96538edd2ee6655bc7a372b00bc19165f10d1a.jpg',
            'https://sport.one/content/images/2019/01/42-6.jpg',
            'https://imageio.forbes.com/specials-images/imageserve/62311e20d5de500ed52177f5/0x0.jpg',
            'https://ontapsportsnet.com/wp-content/uploads/2022/02/DeMar-DeRozan-Dunk-Bulls-Magic.png',
            'https://ontapsportsnet.com/wp-content/uploads/2022/02/FLCuqGdWYAIPAFX-e1644293307507.jpeg',
            'https://sportshub.cbsistatic.com/i/r/2021/10/29/a82ddfd0-dcc8-42e5-a796-80febb47fd89/thumbnail/1200x675/929632747fe1e5bca2724e2322461422/derozan-vs-knicks.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2104,w_3200/http%3A%2F%2Fhoopshabit.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2018%2F08%2F1219026938.jpeg',
            'https://wgntv.com/wp-content/uploads/sites/5/2022/01/gettyimages-1364320718-594x594-1.jpg',
            'https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg4NTk2Nzk0NTM0NTM2ODIw/usatsi_17028161_168388303_lowres.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg0NzExNzQxMzMzMDU0NTg0/usatsi_16992723_168388303_lowres.jpg',
            'https://cdn.theathletic.com/app/uploads/2017/02/11164324/USATSI_8508789_168381809_lowres-1024x688.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fpippenainteasy.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F11%2F667004430-orlando-magic-v-chicago-bulls.jpg-850x560.jpg',
            'https://fansided.com/files/2014/01/7338790.jpg'
          ]
        },
        {
          name: 'Cavaliers',
          code: 'CLE',
          path: 'cavaliers',
          location: 'cleveland',
          logo: 'https://content.sportslogos.net/logos/6/222/thumbs/22269212018.gif',
          youtube: [ 'https://www.youtube.com/c/cavs' ],
          nicknames: [ 'cavaliers', 'cavs' ],
          keywords: [],
          colors: [ '#860038', '#FDBB30' ],
          rivals: [ 'Bulls', 'Pistons', 'Pacers', 'Bucks' ],
          images: [
            'https://content.sportslogos.net/logos/6/222/thumbs/22269212018.gif',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVMAaqFC1AhAu9LX73pKax3o_V1ghCW_N9ZcnW6zKgmC6nJu8n7cihnvHIM66eJ2DbfXo&usqp=CAU',
            'https://a.espncdn.com/photo/2021/1229/r956219_1296x518_5-2.jpg',
            'https://i.pinimg.com/736x/63/25/3e/63253e64df3c6ee1c9b1622994634c91--nba-cleveland-cavalier.jpg',
            'https://i.pinimg.com/736x/72/ba/35/72ba354c3dcbcb0ced62d9b35b7ba33c.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2017/10/nba-cheer18-cavaliers-100617.vadapt.767.high_.51-726x1024.jpg',
            'https://www.hispanosnba.com/imagenes/equipos/cleveland-cavaliers-t1.jpg',
            'https://cdn.vox-cdn.com/thumbor/jo23YA_hTS-mwIfHebkzZaxHqsM=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22533219/1232938081.jpg',
            'https://kingjamesgospel.com/wp-content/uploads/getty-images/2017/07/1312730208.jpeg',
            'https://ca-times.brightspotcdn.com/dims4/default/a9b2155/2147483647/strip/true/crop/4650x3100+0+0/resize/840x560!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F35%2F76%2F59b49caa4438b809dd8784fe082d%2Fclippers-cavaliers-basketball-06464.jpg',
            'https://www.cleveland.com/resizer/xTLDLFbht7XljmoBy3V8aFZcgmM=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/PVYTCXQ7S5CTPB37ARSF6Q577Y.jpg',
            'https://a3.espncdn.com/combiner/i?img=%2Fphoto%2F2021%2F1229%2Fr956219_1296x729_16%2D9.jpg',
            'https://9b16f79ca967fd0708d1-2713572fef44aa49ec323e813b06d2d9.ssl.cf2.rackcdn.com/1140x_a10-7_cTC/Cavaliers-Parade-Basketball-4-1568256830.jpg',
            'http://cavsnation.com/wp-content/uploads/2016/06/cavs-fans-1.jpg',
            'https://www.al.com/resizer/HTAzTSeE8wzfxn3VLZwVjMHmXQQ=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/6G4R45ZODVD4LJXSGKLRBVGQUU.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612739/2022/06/2-desk.png',
            'https://cdn.nba.com/teams/uploads/sites/1610612739/2022/06/1-desk.png',
            'https://cdn.nba.com/teams/uploads/sites/1610612739/2022/06/4-desk.png',
            'https://media0.giphy.com/media/l44QdXiPWsNlvk38k/giphy.gif?cid=ecf05e47ty7ddh1vaahkdoljq138yzwn79u7ajhs2w35fwvw&rid=giphy.gif&ct=g',
            'https://media4.giphy.com/media/cA4ApS5dOduG4/giphy.gif?cid=ecf05e47us3dkg5jqwzgp0loymrud6ts3c8iq4rqh7nop9pm&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Pistons',
          code: 'DET',
          path: 'pistons',
          location: 'detroit',
          logo: 'https://content.sportslogos.net/logos/6/223/thumbs/22321642018.gif',
          youtube: [ 'https://www.youtube.com/c/DetPistonsOfficial' ],
          nicknames: [ 'pistons', 'stones', 'bad boys' ],
          keywords: [],
          colors: [ '#C8102E', '#1D42BA' ],
          rivals: [ 'Bulls', 'Cavaliers', 'Pacers', 'Bucks' ],
          images: [
            'https://content.sportslogos.net/logos/6/223/thumbs/22321642018.gif',
            'https://cdn.theathletic.com/app/uploads/2022/01/28181811/USATSI_17440826-1536x1024.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/01/29124819/USATSI_17462936-1536x1024.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/62ba0cab7de57663e0a6a322_slam238-3.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTg0NDAxMTM2ODE0NDY2NTM4/006273881.webp',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fpistonpowered.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F17384405-850x560.jpeg',
            'https://www.sportscasting.com/wp-content/uploads/2022/03/detroit-pistons-white.jpg',
            'https://www.gannett-cdn.com/presto/2022/01/31/PDTF/be4bc585-a06b-4ba3-80c9-4f15a8bae799-PistonsClev_01302022_kd808.jpg',
            'https://fadeawayworld.net/.image/t_share/MTg3NzcyMzM3NDQ0MTA0MDg2/cade-cunningham-says-he-wants-to-dunk-on-stephen-a-smith.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612765/2021/11/GettyImages-1353519450-1.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fpistonpowered.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2016%2F04%2F1074338858.jpeg',
            'https://static-prod.adweek.com/wp-content/uploads/2022/04/detroit-pistons-embracing-culture-2022.jpg',
            'https://www.gannett-cdn.com/presto/2019/04/12/PDTN/949aeecc-959e-4cec-99bd-8bcc63c52ef9-2019-0409-ctj-sp-PistM204.jpg',
            'https://cloudfront-us-east-1.images.arcpublishing.com/advancelocal/DEU6FTBVQJBTTPDJ6X4U2XMJ64.jpg'
          ]
        },
        {
          name: 'Pacers',
          code: 'IND',
          path: 'pacers',
          location: 'indiana',
          logo: 'https://content.sportslogos.net/logos/6/224/thumbs/22448122018.gif',
          youtube: [ 'https://www.youtube.com/c/Pacers' ],
          nicknames: [ 'pacers' ],
          keywords: [],
          colors: [ '#002D62', '#FDBB30' ],
          rivals: [ 'Bulls', 'Cavaliers', 'Pistons', 'Bucks' ],
          images: [
            'https://content.sportslogos.net/logos/6/224/thumbs/22448122018.gif',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e34710a7789144e54a831b_60de282aa24629ac0b3f2546_SLAM_33_MILLER.jpeg',
            'https://pbs.twimg.com/media/Dif04DlUwAARQvc?format=jpg&name=large',
            'https://pbs.twimg.com/profile_images/1286067183387279360/ViSnlxbL_400x400.jpg',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvXMcYJp7OKNZApL7AoLr40uLx_T173DtCxA&usqp=CAU',
            'https://ultimatecheerleaders.com/wp-content/uploads/2020/04/76807418_158689245360337_4801566944544259972_n-768x768.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2017/10/nba-cheer18-pacers-3-101017.vadapt.767.high_.67-682x1024.jpg',
            'https://pbs.twimg.com/media/FZLv1puWIAEKqdP.jpg',
            'https://www.gannett-cdn.com/media/USATODAY/USATODAY/2013/02/07/gty-144699434-16_9.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Ffansided.com%2Ffiles%2F2016%2F04%2Fnba-playoffs-toronto-raptors-indiana-pacers-1-806x560.jpg',
            'https://d1l5jyrrh5eluf.cloudfront.net/wp-content/uploads/2022/01/GettyImages-1237938943-scaled.jpg',
            'https://cdn.nba.com/manage/2020/10/brogdon-layup-784x444.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612754/2022/04/PacersMagic_1032-copy.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2F8points9seconds.com%2Ffiles%2F2014%2F05%2Fnene-hilario-lance-stephenson-nba-playoffs-indiana-pacers-washington-wizards-590x900.jpg',
            'http://i0.wp.com/kentsterling.com/wp-content/uploads/2015/11/Fans.jpg',
            'https://www.eurobasket.com/fans/indp.jpg',
            'https://i.giphy.com/media/d3mmkM7o2qC78iZ2/giphy.webp'
          ]
        },
        {
          name: 'Bucks',
          code: 'MIL',
          path: 'bucks',
          location: 'milwaukee',
          logo: 'https://content.sportslogos.net/logos/6/225/thumbs/22582752016.gif',
          youtube: [ 'https://www.youtube.com/c/bucks' ],
          nicknames: [ 'bucks' ],
          keywords: [],
          colors: [ '#00471B', '#EEE1C6' ],
          rivals: [ 'Bulls', 'Cavaliers', 'Pistons', 'Pacers' ],
          images: [
            'https://content.sportslogos.net/logos/6/225/thumbs/22582752016.gif',
            'https://cdn.theathletic.com/app/uploads/2022/05/28224749/AP22132065331111-1536x1023.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/05/28225552/AP22132068854688-1814x2048.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/617829403ba0440fbc522dd8_234-giannis-anteokounmpo.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTg0NDAzNzc5ODI5OTAwMzc3/x162526_tk1_01624_rawcovfinal.webp',
            'https://www.sportsnet.ca/wp-content/uploads/2022/05/giannis-celtics-bucks-1040x572.jpg',
            'https://s.hdnux.com/photos/01/26/65/00/22761519/3/1200x0.jpg',
            'https://e0.365dm.com/21/11/2048x1152/skysports-nba-milwaukee-bucks_5600349.jpg',
            'https://behindthebuckpass.com/wp-content/uploads/getty-images/2017/07/501169512.jpeg',
            'https://wtmj.com/wp-content/uploads/2020/11/GettyImages-624481438.jpg',
            'https://i.giphy.com/media/YrBwifZ6rwtnnX8uHL/giphy.webp',
            'https://media4.giphy.com/media/ZdfqmL8O2Vz1THESX5/giphy.gif?cid=ecf05e475wc2xcupz9fbqubkwrypyiqdyglpr4qnqlmdnr6o&rid=giphy.gif&ct=g',
            'https://media1.giphy.com/media/kfdl1CjGlyZjKD0Fbx/giphy.gif?cid=ecf05e47i0j76n4m72zrzjseeded8fnylpcslnero0yy8cfu&rid=giphy.gif&ct=g',
            'https://media1.giphy.com/media/ZeKONZ80ezov1UHLD0/giphy.gif?cid=ecf05e47yllm2ofxep33m2nlnylrtoqyj8uy3x7tlr7aivur&rid=giphy.gif&ct=g',
            'https://media3.giphy.com/media/jRC0vdHjnN5bp1Dzmv/giphy.gif?cid=ecf05e47l1fictjz5jqfettzx3s3ch61fghbqcrobxewgndo&rid=giphy.gif&ct=g',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_389,w_590/http%3A%2F%2Fhoopshabit.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2018%2F08%2F1174749167-850x560.jpeg',
            'https://www.fiservforum.com/assets/img/milwaukee-bucks-header-cc85da3c73.jpg',
            'https://cdn.vox-cdn.com/thumbor/FoanIJBSJ_Q8YFRghg54ZUDOkAk=/0x0:2537x2178/1200x800/filters:focal(1186x481:1590x885)/cdn.vox-cdn.com/uploads/chorus_image/image/65793428/usa_today_13704520.0.jpg',
            'https://sportshub.cbsistatic.com/i/2022/05/01/b56f858e-da7d-4160-a508-58bfce2df943/bucks-win-g.jpg',
            'https://cdn.vox-cdn.com/thumbor/KRe_4mYWpmJvjUg6Qz2V2BLREqI=/0x0:3000x2000/1200x675/filters:focal(1187x412:1667x892)/cdn.vox-cdn.com/uploads/chorus_image/image/71194817/1396106467.0.jpg'
          ]
        },
        {
          name: 'Hawks',
          code: 'ATL',
          path: 'hawks',
          location: 'atlanta',
          logo: 'https://content.sportslogos.net/logos/6/220/thumbs/22081902021.gif',
          youtube: [ 'https://www.youtube.com/c/ATLHawks' ],
          nicknames: [ 'hawks' ],
          keywords: [],
          colors: [ '#E03A3E', '#26282A' ],
          rivals: [ 'Hornets', 'Heat', 'Magic', 'Wizards' ],
          images: [
            'https://content.sportslogos.net/logos/6/220/thumbs/22081902021.gif',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347f2a4f7c08114f98f65_60de2b79b5f879140965e2a0_SLM-225-TRAE.jpeg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2019/10/50940474_1246234055526690_5996044962354487610_n-768x960.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2020/05/79217692_576121692951691_2460900318120388343_n-768x960.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2020/03/80418844_557027191522967_8963870772973520878_n-768x960.jpg',
            'https://therookiewire.usatoday.com/wp-content/uploads/sites/12/2022/04/USATSI_18096609.jpg',
            'https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2021/11/640/320/Trae-Young5.jpg',
            'https://www.nbcsports.com/sites/rsnunited/files/article/hero/web-220422-trae-young-float.jpg',
            'https://images2.minutemediacdn.com/image/upload/c_fill,w_720,ar_16:9,f_auto,q_auto,g_auto/shape/cover/sport/Orlando-Magic-v-Atlanta-Hawks-7eef4bdf174a2cdd60a0402b8c7363c4.jpg',
            'https://hoopshype.com/wp-content/uploads/sites/92/2022/03/USATSI_17664550-1.jpg',
            'https://soaringdownsouth.com/wp-content/uploads/imagn-images/2017/07/17204630.jpeg',
            'https://nypost.com/wp-content/uploads/sites/2/2021/05/Hawks-fans.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fsoaringdownsouth.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F472289044-850x560.jpeg',
            'https://grantland.com/wp-content/uploads/2015/03/atlfans.jpg'
          ]
        },
        {
          name: 'Hornets',
          code: 'CHA',
          path: 'hornets',
          location: 'charlotte',
          logo: 'https://content.sportslogos.net/logos/6/5120/thumbs/512019262015.gif',
          youtube: [ 'https://www.youtube.com/c/hornets' ],
          nicknames: [ 'hornets', 'bees', 'wasps' ],
          keywords: [],
          colors: [ '#1D1160', '#00788C' ],
          rivals: [ 'Hawks', 'Heat', 'Magic', 'Wizards' ],
          images: [
            'https://content.sportslogos.net/logos/6/5120/thumbs/512019262015.gif',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjU4NzUyMTQ1Nzk0OTQ1/-new-orleans-hornets.webp',
            'https://fadeawayworld.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTc5ODYwMDc5NDM2OTY1NDg3/charlotte-hornets-honey-bees-dance-team-by4_0704.webp',
            'https://live.staticflickr.com/4287/35020859103_bc1fbfeda7_o.jpg',
            'https://live.staticflickr.com/466/19320401915_8bf667b717_o.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2018/12/DpqN3PuU8AA02ZF-768x953.jpg',
            'https://cdn.nba.com/manage/2021/09/bridges-lamelo-talk-784x441.jpg',
            'https://www.si.com/.image/t_share/MTg0ODgyNjk3OTQyODY5MTEy/lamelo-ball-hornets-layup-nets.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/hornets/sites/hornets/files/211026_length_main.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612766/2022/07/cha-vs-min-highligts-img.jpg',
            'https://www.charlotteobserver.com/latest-news/56ni33/picture243797407/alternates/FREE_1140/HORNETS_HEAT_36.JPG',
            'https://cdn.vox-cdn.com/thumbor/MUczggJpb6rIS1K7B9BBNZlPQd0=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23066018/1236485444.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612766/2022/07/GettyImages-1241944657_1920_Summer_League_071622.jpg',
            'https://www.charlotteobserver.com/latest-news/k6muzr/picture240929066/alternates/FREE_1140/IMG_hornetsfans-codyzell_2_1_LVCGM2EC_L345597413.JPG',
            'https://swarmandsting.com/wp-content/uploads/getty-images/2017/07/1177833340.jpeg',
            'https://cdn.nba.com/manage/2022/03/GettyImages-1239629891-scaled-e1648686046951.jpg',
            'https://i.giphy.com/media/VEsiZoNKnY4VIVzcOr/giphy.webp',
            'https://media2.giphy.com/media/ihGgbVTbXDqf4idWah/giphy.gif?cid=ecf05e47dj14uc8rkhy1wu26oxlmhpiurab9iewf58gf1lzj&rid=giphy.gif&ct=g',
            'https://media4.giphy.com/media/2SXX5He1hnsLDnZfhc/giphy.gif?cid=ecf05e473gtvuw22loaugek7dj1ei73myj0n0oulxm2q5pc9&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Heat',
          code: 'MIA',
          path: 'heat',
          location: 'miami',
          logo: 'https://content.sportslogos.net/logos/6/214/thumbs/burm5gh2wvjti3xhei5h16k8e.gif',
          youtube: [ 'https://www.youtube.com/user/miamiheat' ],
          nicknames: [ 'heat', 'heaters', 'heatles', '7 eleven' ],
          keywords: [],
          colors: [ '#98002E', '#000000' ],
          rivals: [ 'Hawks', 'Hornets', 'Magic', 'Wizards' ],
          images: [
            'https://content.sportslogos.net/logos/6/214/thumbs/burm5gh2wvjti3xhei5h16k8e.gif',
            'https://cdn.theathletic.com/app/uploads/2022/05/29101533/AP22133068050250-1536x1024.jpg',
            'https://fadeawayworld.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTc5ODYwMDc5MTcwNDk2MTEx/1aa22368938dcc2dc91b426fd791e891.webp',
            'https://cdn.vox-cdn.com/thumbor/k8CZeqzcJm0htyLkgezn_7pSJgQ=/0x0:5616x3744/1200x800/filters:focal(2319x694:3217x1592)/cdn.vox-cdn.com/uploads/chorus_image/image/71194781/1240736605.0.jpg',
            'https://www.miamiherald.com/latest-news/5co3nw/picture261936245/alternates/FREE_1140/HEAT_CELTICS_DAV7.jpg',
            'https://pbs.twimg.com/media/FTAqcdgWUAAQb58?format=jpg',
            'https://phantom-marca.unidadeditorial.es/a646eeb3cbcbff44805fc75feb7b8a6e/resize/1320/f/jpg/assets/multimedia/imagenes/2022/05/13/16524098388010.jpg',
            'https://sircharlesincharge.com/wp-content/uploads/imagn-images/2017/07/18374859-1.jpeg',
            'https://wp.usatodaysports.com/wp-content/uploads/sites/90/2013/06/usatsi_7292594-copy.jpg',
            'https://cdn.britannica.com/88/163888-050-D9D34015/Dwyane-Wade-NBA-Miami-Heat.jpg',
            'https://cdn.vox-cdn.com/thumbor/B9eaUn4pjKvGs4D0ltzP4jCSj4U=/0x15:3000x2015/1400x1400/filters:focal(0x15:3000x2015):format(jpeg)/cdn.vox-cdn.com/uploads/chorus_image/image/45510270/147119543.0.jpg',
            'https://image-cdn.essentiallysports.com/wp-content/uploads/2022-05-30T010831Z_1380107849_MT1USATODAY18390867_RTRMADP_3_NBA-PLAYOFFS-BOSTON-CELTICS-AT-MIAMI-HEAT.jpg',
            'https://www.denverpost.com/wp-content/uploads/2016/04/20130621__story_adc2a5d7-84b8-4395-bf8d-34fcd2bfc51fp1.jpg'
          ]
        },
        {
          name: 'Magic',
          code: 'ORL',
          path: 'magic',
          location: 'orlando',
          logo: 'https://content.sportslogos.net/logos/6/217/thumbs/wd9ic7qafgfb0yxs7tem7n5g4.gif',
          youtube: [ 'https://www.youtube.com/user/NBAOrlandoMagic' ],
          nicknames: [ 'magic' ],
          keywords: [],
          colors: [ '#0077C0', '#C4CED4' ],
          rivals: [ 'Hawks', 'Hornets', 'Heat', 'Wizards' ],
          images: [
            'https://content.sportslogos.net/logos/6/217/thumbs/wd9ic7qafgfb0yxs7tem7n5g4.gif',
            'https://ultimatecheerleaders.com/wp-content/uploads/2019/04/dancersline_2000_20180930.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2014/07/IMG_9081.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2013/07/MG_3548.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612753/2022/06/Welcome-Paolo-Banchero.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fhoopshabit.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2018%2F08%2F1407850560-850x560.jpeg',
            'https://orlandomagicdaily.com/wp-content/uploads/imagn-images/2017/07/18660611.jpeg',
            'https://cdn.vox-cdn.com/thumbor/x9Q_GeG1KG9PyajbrgCeawp1_dM=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23233223/1235866968.jpg',
            'https://cdn.vox-cdn.com/thumbor/x1gN8EXJS-a7kyYPDgrvPodob94=/0x0:5132x3421/1200x800/filters:focal(2156x1301:2976x2121)/cdn.vox-cdn.com/uploads/chorus_image/image/70987376/1239677034.0.jpg',
            'https://images.foxtv.com/static.fox35orlando.com/www.fox35orlando.com/content/uploads/2020/12/1280/720/GettyImages-88382337.jpg',
            'http://sportschump.net/wp-content/uploads/2015/07/Orlando-Magic-fans.jpg',
            'https://orlandomagicdaily.com/wp-content/uploads/getty-images/2020/06/1209936562.jpeg',
            'https://i.giphy.com/media/3o6gDUfcfzroCOfLVe/giphy.webp',
            'https://i.giphy.com/media/l3q2BhukQ7kNkDj9u/giphy.webp',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Forlandomagicdaily.com%2Ffiles%2F2015%2F04%2Fnba-phoenix-suns-orlando-magic.jpg'
          ]
        },
        {
          name: 'Wizards',
          code: 'WAS',
          path: 'wizards',
          location: 'washington',
          logo: 'https://content.sportslogos.net/logos/6/219/thumbs/21956712016.gif',
          youtube: [ 'https://www.youtube.com/c/WashingtonWizards' ],
          nicknames: [ 'wizrds', 'wiz' ],
          keywords: [],
          colors: [ '#002B5C', '#E31837' ],
          rivals: [ 'Hawks', 'Hornets', 'Heat', 'Magic' ],
          images: [
            'https://content.sportslogos.net/logos/6/219/thumbs/21956712016.gif',
            'https://live.staticflickr.com/1766/41273358080_b99af471a8_o.jpg',
            'https://live.staticflickr.com/835/43190847261_720eacd0c8_o.jpg',
            'https://farm5.staticflickr.com/4040/35433159772_b7d373f755_o.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2018/01/DSdgr9-XcAAh6Tn-768x769.jpg',
            'https://cdn.nba.com/manage/2021/09/wizards092521.jpg',
            'https://cdn.vox-cdn.com/thumbor/UL-wu6AgTMeUzVQ_CVhdFthRo_I=/0x0:6000x4000/1200x800/filters:focal(1961x225:2921x1185)/cdn.vox-cdn.com/uploads/chorus_image/image/70395053/1364125789.0.jpg',
            'https://static01.nyt.com/images/2021/02/12/sports/12nba-wizards4/12nba-wizards4-mobileMasterAt3x.jpg',
            'https://fansided.com/files/2014/01/80753721.jpg',
            'https://sports.cbsimg.net/images/visual/whatshot/wizards_fans.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY4MDA3MTk0NTcwNzk0MzY4/wizards-fans-ticket-reps-ernie-grunfeldjpg.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fwizofawes.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F09%2F612086190-washington-wizards-training-camp.jpg-850x560.jpg',
            'https://cdn.vox-cdn.com/thumbor/pIK4Y2r3NTaLpbc39eRk_-vW5rM=/0x0:5184x3456/1200x800/filters:focal(2243x659:3071x1487)/cdn.vox-cdn.com/uploads/chorus_image/image/67692062/1198061557.jpg.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/Xtx_nmM180fgm6S648qho8mUMog=/0x0:2107x3182/1200x800/filters:focal(901x738:1237x1074)/cdn.vox-cdn.com/uploads/chorus_image/image/70492557/1369601633.0.jpg',
            'https://cdn1.sportngin.com/attachments/photo/49b4-152194459/WIZ-DCFam-Gallery3_large.jpg'
          ]
        }
      ]
    },
    western: {
      title: 'Western',
      icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Western_Conference_%28NBA%29_logo_2018.png/225px-Western_Conference_%28NBA%29_logo_2018.png',
      iconSource: 'image',
      teams: [
        {
          name: 'Nuggets',
          code: 'DEN',
          path: 'nuggets',
          location: 'denver',
          logo: 'https://content.sportslogos.net/logos/6/229/thumbs/22989262019.gif',
          youtube: [ 'https://www.youtube.com/c/DenverNuggets' ],
          nicknames: [ 'nuggets' ],
          keywords: [],
          colors: [ '#0E2240', '#E318FEC52437' ],
          rivals: [ 'Timberwolves', 'Thunder', 'Trail Blazers', 'Jazz' ],
          images: [
            'https://content.sportslogos.net/logos/6/229/thumbs/22989262019.gif',
            'https://fadeawayworld.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTc5ODYwMDc5NDM1MTMwNDc5/nba-preseason-phoenix-suns-at-denver-nuggets.webp',
            'https://sport.one/content/images/2019/01/84.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347fdb641d85f8bc875ab_60de2b7ab5f8791df165e2a2_SLM-230-JAMAL.jpeg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2016/02/sab.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2016/01/alisha.jpg',
            'https://cdn.vox-cdn.com/thumbor/M3Xk0fRat0yFuCRTH0g-GCHFF0Q=/0x0:4928x3280/1200x800/filters:focal(2070x1246:2858x2034)/cdn.vox-cdn.com/uploads/chorus_image/image/71175168/1231129464.0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2133,w_3200/http%3A%2F%2Fhoopshabit.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1352142722.jpeg',
            'https://cloudfront-us-east-2.images.arcpublishing.com/reuters/5HBJKJDTWNJWJPDNG7LKIS2QIA.jpg',
            'https://cdn.vox-cdn.com/thumbor/sPZU_3ravgSSzvmsAfyHyCN-Li4=/0x0:1977x1392/1200x800/filters:focal(773x592:1089x908)/cdn.vox-cdn.com/uploads/chorus_image/image/68960826/usa_today_15583481.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/mdr1SrauWxBr4wErVbpEuANKUnE=/0x0:4368x2912/1200x800/filters:focal(1835x1107:2533x1805)/cdn.vox-cdn.com/uploads/chorus_image/image/66730930/1197635711.jpg.0.jpg',
            'https://hoopshabit.com/wp-content/uploads/getty-images/2018/08/1194799421.jpeg',
            'https://cloudfront-us-east-2.images.arcpublishing.com/reuters/VLQLZHL4QZK5ZNU77NUADNZ6TM.jpg',
            'https://cdn.vox-cdn.com/thumbor/kcP39qmjIykWg1Y8KawkAaHuuYU=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/13177001/Nuggets_fans.jpg',
            'https://milehighsports.com/wp-content/uploads/2018/11/USATSI_11673007-696x470.jpg',
            'https://cdnph.upi.com/pv/upi/a0203ba1c5d7c14b0ee656c05d90f349/NBA-PLAYOFFS-Jazz-vs-Nuggets.jpg',
            'https://i.giphy.com/media/YRKXY0uQKpWyKhWtJX/giphy.webp',
            'https://media2.giphy.com/media/1zi2PKSofH11wuKoJu/giphy.gif?cid=ecf05e47ojrynup4jjlp7dwexcj79e4puo4ze394x5rvmcla&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Timberwolves',
          code: 'MIN',
          path: 'timberwolves',
          location: 'minnesota',
          logo: 'https://content.sportslogos.net/logos/6/232/thumbs/23296692018.gif',
          youtube: [ 'https://www.youtube.com/c/timberwolves' ],
          nicknames: [ 'timberwolves', 'wolves', 't wolves', 't bolts', 't pups' ],
          keywords: [],
          colors: [ '#0C2340', '#9EA2A2' ],
          rivals: [ 'Nuggets', 'Thunder', 'Trail Blazers', 'Jazz' ],
          images: [
            'https://content.sportslogos.net/logos/6/232/thumbs/23296692018.gif',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTg0NDAxMTM3MDgzMzYwMjgx/006274321.webp',
            'https://ultimatecheerleaders.com/wp-content/uploads/2017/05/wolves-dancers-demi-03.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2013/06/2013.01.23_NETS_033b.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2011/04/Kait-Cassie-Alisa.jpg',
            'https://dunkingwithwolves.com/wp-content/uploads/imagn-images/2017/07/17489336.jpeg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg1ODA5OTIzNjY4NDUyNzU2/karl-anthony-towns-timberwolves-screaming.jpg',
            'https://ftw.usatoday.com/wp-content/uploads/sites/90/2021/11/USP-NBA_-Miami-Heat-at-Minnesota-Timberwolves.jpg',
            'https://ca-times.brightspotcdn.com/dims4/default/4eaa6a7/2147483647/strip/true/crop/2940x1961+0+0/resize/1486x991!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F39%2F8a%2F29ad8a8a41579f08b936ab58865f%2Flakers-timberwolves-basketball-69052.jpg',
            'https://grantland.com/wp-content/uploads/2013/05/grant_g_wolves_cr_64011.jpg',
            'https://chorus.stimg.co/23408358/08_14WOLF042222.jpg',
            'https://cdn.vox-cdn.com/thumbor/Ap5_1aqBvfC-fzsp7fYc_i5c_bw=/0x0:4040x2695/1200x800/filters:focal(1815x297:2461x943)/cdn.vox-cdn.com/uploads/chorus_image/image/58065531/usa_today_10491749.0.jpg',
            'https://images.foxtv.com/static.fox9.com/www.fox9.com/content/uploads/2022/03/1280/720/timberwolves-shoes.jpg',
            'https://arizonasports.com/wp-content/uploads/cms/apimage/216/ap_00460b471ff84619be71bf257c3b331c.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/timberwolves/sites/timberwolves/files/fan-4-8-2016.jpg',
            'https://i.giphy.com/media/GjrpDwIFKMUso/giphy.webp'
          ]
        },
        {
          name: 'Thunder',
          code: 'OKC',
          path: 'thunder',
          location: 'oklahoma city',
          logo: 'https://content.sportslogos.net/logos/6/2687/thumbs/khmovcnezy06c3nm05ccn0oj2.gif',
          youtube: [ 'https://www.youtube.com/c/OklahomaCityThunder' ],
          nicknames: [ 'thunder', 'stache brothers' ],
          keywords: [],
          colors: [ '#002D62', '#EF3B24' ],
          rivals: [ 'Nuggets', 'Timberwolves', 'Trail Blazers', 'Jazz' ],
          images: [
            'https://content.sportslogos.net/logos/6/2687/thumbs/khmovcnezy06c3nm05ccn0oj2.gif',
            'https://cdn.theathletic.com/app/uploads/2022/01/28173321/AP22020101588043-1536x1024.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612760/2021/12/hdr-211215-JIMMYDO-OKCNOP-029.jpg',
            'https://cdn.nba.com/manage/2021/03/GettyImages-1230399550-scaled-e1614813179202-1568x1009.jpg',
            'https://thunderousintentions.com/wp-content/uploads/imagn-images/2018/08/15440858.jpeg',
            'https://imageio.forbes.com/specials-images/imageserve/62c3b77ffa821c34fae0d85c/Oklahoma-City-Thunder-v-Golden-State-Warriors/960x0.jpg',
            'https://cloudfront-us-east-1.images.arcpublishing.com/gray/NNRCUVSAF5PUFCKFIBOF4CCE7U.jpg',
            'https://a1.espncdn.com/combiner/i?img=%2Fphoto%2F2022%2F0706%2Fr1033081_500x333_3%2D2.jpg',
            'https://www.nwahomepage.com/wp-content/uploads/sites/90/2020/11/Thunder-fans.jpg',
            'https://grantland.com/wp-content/uploads/2012/05/grant_g_okc_fans_b1_64011.jpg',
            'https://news.microsoft.com/wp-content/uploads/2013/03/03-20Game_Page.jpg',
            'https://cdn.vox-cdn.com/thumbor/yUil1SluCmOgbNNcuNaheCXe3dQ=/0x0:4727x3545/1200x800/filters:focal(2832x407:3588x1163)/cdn.vox-cdn.com/uploads/chorus_image/image/71177993/usa_today_18678293.0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fthunderousintentions.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1238147387.jpeg'
          ]
        },
        {
          name: 'Trail Blazers',
          code: 'POR',
          path: 'trailblazers',
          location: 'portland',
          logo: 'https://content.sportslogos.net/logos/6/239/thumbs/23997252018.gif',
          youtube: [ 'https://www.youtube.com/c/trailblazers' ],
          nicknames: [ 'trail blazers', 'blazers', 'jail blazers', 'rip city' ],
          keywords: [],
          colors: [ '#E03A3E', '#000000' ],
          rivals: [ 'Nuggets', 'Timberwolves', 'Thunder', 'Jazz' ],
          images: [
            'https://content.sportslogos.net/logos/6/239/thumbs/23997252018.gif',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347e0d59e88557e0d8a5a_60de2b37b5f879161065e0c0_SLAM-213-CJ.jpeg',
            'https://i.pinimg.com/originals/a0/0d/05/a00d059cb1882dcc0fb389725aff9403.jpg',
            'https://blog.thelineup.com/hs-fs/hub/1594707/file-4141067027-jpg/blog-files/pistons-61.jpg?width=373&name=pistons-61.jpg',
            'https://i.pinimg.com/originals/72/d7/dd/72d7dd592874bc136420f575e287dc5c.jpg',
            'https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MjU5MzY5NDQ0Nzcx/portland-trail-blazers-v-phoenix-suns.jpg',
            'https://cdn.vox-cdn.com/thumbor/mxzTtlMy0zCHGNtYZsPLJuBRyyM=/0x0:3848x2568/1200x800/filters:focal(1578x509:2192x1123)/cdn.vox-cdn.com/uploads/chorus_image/image/71118451/1362993431.0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2134,w_3200/http%3A%2F%2Fhoopshabit.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2018%2F08%2F16161156.jpeg',
            'https://sport.one/content/images/2020/08/22-20.jpg',
            'https://cdn.vox-cdn.com/thumbor/w9XLLOKapSCPkz1NX0TfC7kNhXU=/0x172:2261x2738/1400x1400/filters:focal(1201x840:1561x1200):format(jpeg)/cdn.vox-cdn.com/uploads/chorus_image/image/56352637/97002468.0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fsircharlesincharge.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2018%2F08%2F1264392714-1-850x560.jpeg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fripcityproject.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1146837859-850x560.jpeg',
            'https://cdn.vox-cdn.com/thumbor/CqGW1y18wPshOzCwn8VqUBMs_nE=/0x0:5760x3840/1200x800/filters:focal(2511x1056:3431x1976)/cdn.vox-cdn.com/uploads/chorus_image/image/66864400/1139050423.jpg.0.jpg',
            'https://mspoweruser.com/wp-content/uploads/2016/05/blazers-fans-lillard-103112jpg-625f9a711c2da100-e1462823980585.jpg',
            'https://www.onmsft.com/wp-content/uploads/2016/05/portland-trailblazers.jpg',
            'https://thespun.com/.image/t_share/MTgzMTI4NjA1Mzg0MDU4MjA4/portland-trail-blazers-v-los-angeles-lakers.jpg',
            'https://media4.giphy.com/media/26ufaboy0gK2r19ks/giphy.gif?cid=ecf05e47mu05nate3d9f3hfkkb6r8ijd064x55abse1exvg6&rid=giphy.gif&ct=g',
            'https://media0.giphy.com/media/l2SqboKFm7YFpSzG8/giphy.gif?cid=ecf05e47vsyj49mpni9ovd6rd5ylfmsl2q5hdky87v595klh&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Jazz',
          code: 'UTA',
          path: 'jazz',
          location: 'utah',
          logo: 'https://content.sportslogos.net/logos/6/234/thumbs/23467492017.gif',
          youtube: [ 'https://www.youtube.com/c/utahjazz' ],
          nicknames: [ 'jazz', 'spazz' ],
          keywords: [],
          colors: [ '#002B5C', '#00471B' ],
          rivals: [ 'Nuggets', 'Timberwolves', 'Thunder', 'Trail Blazers' ],
          images: [
            'https://content.sportslogos.net/logos/6/234/thumbs/23467492017.gif',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/61d5eca07149ad3185019637_Donovan.jpg',
            'https://www.nba.com/resources/static/team/v2/jazz/2122_chelsey_headshot2.jpg',
            'https://utahjazzweb.s3.us-west-2.amazonaws.com/2122_willow_headshot2.jpg',
            'https://utahjazzweb.s3.us-west-2.amazonaws.com/2122_savannah_f_headshot2.jpg',
            'https://cdn.vox-cdn.com/thumbor/-ILHBT05IgHbyTQGhLfBn-ORkLo=/0x0:3543x2362/1200x800/filters:focal(1423x600:1989x1166)/cdn.vox-cdn.com/uploads/chorus_image/image/71213844/1237981362.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/EBA7IophJHzl5s48VgwBj8YwKMg=/0x0:5460x3640/1200x800/filters:focal(1567x784:2439x1656)/cdn.vox-cdn.com/uploads/chorus_image/image/71208895/1228112693.0.jpg',
            'https://www.gannett-cdn.com/-mm-/e2fabb98ce5a9c7328f3f920ef9e93cf658663b4/c=0-83-2720-3710/local/-/media/2018/02/17/Louisville/Louisville/636545044558274933-AP-18049133271512.jpg',
            'https://idsb.tmgrup.com.tr/ly/uploads/images/2021/02/25/thumbs/800x531/95879.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTcxMDU5OTkzNDM1NDQ4NTgx/mike-conley-utah-jazz-contenders.jpg',
            'https://thejnotes.com/wp-content/uploads/getty-images/2017/07/1199285239.jpeg',
            'https://kslsports.com/wp-content/uploads/2022/04/0423jazz.spt_SW_8282-2nd-half.jpg',
            'https://ak-d.tripcdn.com/images/350j0z000000ntw616FD5.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fthejnotes.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F952026346-850x560.jpeg',
            'https://kslsports.com/wp-content/uploads/2021/06/Vivint-Arena-Utah-Jazz-vs.-Los-Angeles-Clippers-Game-1-620x370.jpg',
            'https://i.giphy.com/media/3o7bujNF9KpbEMBydW/giphy.webp'
          ]
        },
        {
          name: 'Warriors',
          code: 'GSW',
          path: 'warriors',
          location: 'golden state',
          logo: 'https://content.sportslogos.net/logos/6/235/thumbs/23531522020.gif',
          youtube: [ 'https://www.youtube.com/c/warriors' ],
          nicknames: [ 'warriors', 'bdubs', 'believers', 'dubs' ],
          keywords: [],
          colors: [ '#1D428A', '#FFC72C' ],
          rivals: [ 'Clippers', 'Lakers', 'Suns', 'Kings' ],
          images: [
            'https://content.sportslogos.net/logos/6/235/thumbs/23531522020.gif',
            'https://cdn.theathletic.com/app/uploads/2022/05/28234218/AP22121767775581-1536x1022.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/05/29220534/USATSI_18298789-1536x1024.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/05/29221235/USATSI_18314479-1547x2048.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/05/29220325/GettyImages-1240847690-1-scaled.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/05/29221333/USATSI_18265579-1536x1024.jpg',
            'https://fadeawayworld.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTc5ODYwMDc5MTY4ODU3NzEx/nba-finals-scenes-7.webp',
            'https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg5NDY3MDkwNDY4NDgwNjA5/usatsi_18264846_168390270_lowres.jpg',
            'https://www.mercurynews.com/wp-content/uploads/2022/05/BNG-L-WARRIORS-0514-124.jpg',
            'https://cdn.vox-cdn.com/thumbor/wK0l8XowpLujJmrM-K6YRYzSjkI=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23021060/usa_today_17135321.jpg',
            'https://imageio.forbes.com/specials-images/imageserve/62ac670a084ee26b5bf964f9/APTOPIX-NBA-Finals-Warriors-Celtics-Basketball/960x0.jpg?format=jpg',
            'https://www.nbcsports.com/sites/rsnunited/files/article/hero/steph-curry-joe-lacob-klay-thompson-GettyImages-1241355934.jpg',
            'https://cdn.vox-cdn.com/thumbor/aey_5Ss2OpOrUWeeJB0Mc2rP1uI=/0x0:4338x2892/1200x800/filters:focal(1822x1099:2516x1793)/cdn.vox-cdn.com/uploads/chorus_image/image/65745153/usa_today_12893537.0.jpg',
            'https://www.mercurynews.com/wp-content/uploads/2019/06/BNG-L-WARRIORS-0614-334.jpg',
            'https://cdn.abcotvs.com/dip/images/11981859_062022kgoapwarriorsparade3img.jpg',
            'https://www.nbcsports.com/sites/rsnunited/files/styles/article_video_thumbnail/public/short_form_video/hero/OLD_LADY_GETS_FAMOUS_042122.jpg',
            'https://i.pinimg.com/474x/38/c8/78/38c878c39c986ef84c48694375f23d7e.jpg',
            'https://i.giphy.com/media/l0Exit31fdZfMWd7a/giphy.webp',
            'https://i.giphy.com/media/l2JhL1AzTxORUTDlC/giphy.webp'
          ]
        },
        {
          name: 'Clippers',
          code: 'LAC',
          path: 'clippers',
          location: 'los angeles',
          logo: 'https://content.sportslogos.net/logos/6/236/thumbs/23654622016.gif',
          youtube: [ 'https://www.youtube.com/clippers' ],
          nicknames: [ 'clippers', 'clips', 'lob city' ],
          keywords: [],
          colors: [ '#C8102E', '#1D428A' ],
          rivals: [ 'Warriors', 'Lakers', 'Suns', 'Kings' ],
          images: [
            'https://content.sportslogos.net/logos/6/236/thumbs/23654622016.gif',
            'https://cdn.theathletic.com/app/uploads/2022/01/28174950/USATSI_17440475-scaled-e1643488778727-1536x1198.jpg',
            'https://www.nba.com/resources/static/team/v2/clippers/dancer_candace.JPG',
            'https://ultimatecheerleaders.com/wp-content/uploads/2011/09/Clips-Sept-Shoot_28.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2011/09/2011-Ana-feature-3.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2011/01/anasheh-brittany.jpg',
            'https://www.nba.com/clippers/sites/clippers/files/220406_vs_phx_clean_ik-34.jpg',
            'https://www.nba.com/clippers/sites/clippers/files/220219_all_star_luke_3_point_ik-8.jpg',
            'https://www.si.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTkxMzk4NzEyMDEyOTczNjY2/kawhi-leonard-paul-george-clippers.jpg',
            'https://cdn.nba.com/manage/2020/10/noah-leonard-george-morris-iso-784x523.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY3ODA1MzE1MDAwMDUxMDcy/kawhi-leonard-clippers-lakers.jpg',
            'https://i.ytimg.com/vi/YS4SWkqYJsI/maxresdefault.jpg',
            'https://www.gannett-cdn.com/presto/2020/03/01/USAT/21577477-aba0-4c10-ab50-3aa0e8706e9a-paul-george.jpg',
            'https://nypost.com/wp-content/uploads/sites/2/2021/06/Paul-George-2.jpg',
            'https://213hoops.com/wp-content/uploads/2020/03/TheFans.jpg',
            'https://media-cldnry.s-nbcnews.com/image/upload/t_fit-760w,f_auto,q_auto:best/msnbc/Components/Photos/040627/060427_clippers_vmed_1p.jpg',
            'https://www.pe.com/wp-content/uploads/2017/08/0808_nws_idb-l-ontclippers-0808-01-jcm.jpg',
            'https://clippersweb.blob.core.windows.net/hooptroop/photos/2022/leQXhh5s.jpg',
            'https://clippersweb.blob.core.windows.net/hooptroop/photos/2022/AAfTzKZc.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612746/2022/06/06-220606-Intuit-Dome-View-1-Final.jpg',
            'https://wallpapers.com/images/hd/los-angeles-clippers-lightspeed-art-fhfe8h9ernxnnk16.webp'
          ]
        },
        {
          name: 'Lakers',
          code: 'LAL',
          path: 'lakers',
          location: 'los angeles',
          logo: 'https://content.sportslogos.net/logos/6/237/thumbs/uig7aiht8jnpl1szbi57zzlsh.gif',
          youtube: [ 'https://www.youtube.com/c/lakers' ],
          nicknames: [ 'lakers', 'fakers', 'lake show', 'show time' ],
          keywords: [],
          colors: [ '#552583', '#FDB927' ],
          rivals: [ 'Warriors', 'Clippers', 'Suns', 'Kings' ],
          images: [
            'https://content.sportslogos.net/logos/6/237/thumbs/uig7aiht8jnpl1szbi57zzlsh.gif',
            'https://assets.website-files.com/5d7fff4197d2f20ee7c02c12/5d86b349f0c5f3f3fd863407_GettyImages-454701972.png',
            'https://cdn.theathletic.com/app/uploads/2022/01/28174146/AP22020225064016-1583x2048.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/01/29151151/USATSI_17523287-1536x1294.jpg',
            'https://d1l5jyrrh5eluf.cloudfront.net/wp-content/uploads/2022/05/lakers-top75.jpg',
            'https://cdn.shopify.com/s/files/1/2612/1482/products/pre-sale-slam-presents-lebron-2nd-edition-240603.jpg?v=1602501532',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347fa3df4c41c545a8685_60de2b7a4048bd834c7271a0_SLM-229-LAKERS.jpeg',
            'https://thespun.com/.image/t_share/MTkwODI1NzI4MjEwNjQyNTEz/new-york-knicks-v-los-angeles-lakers.jpg',
            'https://cdn.nba.com/manage/2021/10/GettyImages-1235850994-1-scaled.jpg',
            'https://ca-times.brightspotcdn.com/dims4/default/105e5d6/2147483647/strip/true/crop/4307x3107+0+0/resize/840x606!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fdf%2F65%2F5e784c2f48a5814b2fc879590c00%2Fhttps-delivery.gettyimages.com%2Fdownloads%2F1194180180.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Flakeshowlife.com%2Ffiles%2F2016%2F04%2Flarry-nance-jr-nba-los-angeles-lakers-phoenix-suns-1.jpg',
            'https://cdn.vox-cdn.com/thumbor/mZgtWdgie67j4YDxWqc3-tv25GQ=/0x0:4901x3466/1200x800/filters:focal(1939x1097:2723x1881)/cdn.vox-cdn.com/uploads/chorus_image/image/65939358/usa_today_13673437.0.jpg',
            'https://sportshub.cbsistatic.com/i/r/2018/11/24/048f3b61-1241-461f-bab5-25aed116cacb/thumbnail/1200x675/eeedcc2a72b424d7366e8ccf9ee852d1/webp-net-resizeimage-1.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Ffansided.com%2Ffiles%2F2016%2F11%2F9153965-nba-los-angeles-lakers-denver-nuggets.jpg',
            'https://homeofplaymakers.com/wp-content/uploads/2020/06/lakers-crowd.jpg',
            'https://preview.thenewsmarket.com/Previews/LVVA/StillAssets/1920x1440/481255.jpg',
            'https://cdn.vox-cdn.com/thumbor/J_6sWhvc5_InWuLuvokt7tWIQDM=/0x0:6054x4036/1200x800/filters:focal(2500x1546:3468x2514)/cdn.vox-cdn.com/uploads/chorus_image/image/67104586/usa_today_13986642.0.jpg',
            'https://lalweb.blob.core.windows.net/public/lakers/laker-girls/LG_WEB/2021-22-lg-team-photo-top.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/lakers/sites/lakers/files/gettyimages-1239908950.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/lakers/sites/lakers/files/gettyimages-1239910249.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/lakers/sites/lakers/files/gettyimages-1239724186.jpg',
            'https://lalweb.blob.core.windows.net/public/lakers/product-marketing/web/wallpapers/jack-perkins/lakers-jack-perkins-mikan-vibes-2022023-desktop.JPG',
            'https://i.cdn.turner.com/drp/nba/lakers/sites/default/files/i6_8.jpg',
            'https://i.cdn.turner.com/drp/nba/lakers/sites/default/files/i6_template_0.jpg',
            'https://i.cdn.turner.com/drp/nba/lakers/sites/default/files/i6_10.jpg',
            'https://www.nba.com/.element/media/2.0/teamsites/lakers/images/history/shaq/images/shaq34_wallpaper.jpg',
            'https://cdn.nba.com/teams/uploads/sites/1610612747/2022/05/Lakers-Huddle-SC-Hallway-1328x78-1.jpg?imwidth=664&imheight=392',
            'https://d1l5jyrrh5eluf.cloudfront.net/wp-content/uploads/2020/11/SLM-229-LAKERS-site-featured-image.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_400/MTg4MzE3NjMyMTI5MTQ4MDI2/10-young.webp',
            'https://i.giphy.com/media/3upXBfSi6erok/giphy.webp',
            'https://media4.giphy.com/media/uK0TvS8iITRbKky4YW/giphy.gif?cid=ecf05e47u3kb1at2gecxszl4wwkxek3z3a3uhc8z091wctfz&rid=giphy.gif&ct=g',
            'https://media4.giphy.com/media/12ZV3oW1llVG0M/giphy.gif?cid=ecf05e47eu9wlk90kszidmtv7c2wli0tnjrb78m5hfgmkbjg&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Suns',
          code: 'PHX',
          path: 'suns',
          location: 'phoenix',
          logo: 'https://content.sportslogos.net/logos/6/238/thumbs/23843702014.gif',
          youtube: [ 'https://www.youtube.com/c/suns' ],
          nicknames: [ 'suns' ],
          keywords: [],
          colors: [ '#1D1160', '#E56020' ],
          rivals: [ 'Warriors', 'Clippers', 'Lakers', 'Kings' ],
          images: [
            'https://content.sportslogos.net/logos/6/238/thumbs/23843702014.gif',
            'https://assets.website-files.com/5d7fff4197d2f20ee7c02c12/5d88eac0a711b1a96ab5c7c2_steve-nash.jpg',
            'https://cdn.theathletic.com/app/uploads/2022/05/29095010/USATSI_18193286-1536x1024.jpg',
            'https://fadeawayworld.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTc5ODYwMDc5MTY5NTEzMDcx/suns_dancer_17-900x600.webp',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347fb7b0cd8b76df3be15_60de2b7a2facfe225e26f211_SLM-229-CHRIS-PAUL.jpeg',
            'https://www.si.com/.image/t_share/MTkxMDU0NTc1MDc0MDI2ODYy/phoenix-suns-trio.png',
            'https://library.sportingnews.com/styles/facebook_1200x630/s3/2021-11/phoenix-suns_qrfwbwqmx0l41ayjd4fd2zy40.jpeg',
            'https://www.gannett-cdn.com/presto/2022/05/11/PPHX/b1e7e3b9-5771-4e66-86dd-4c75da9e7f3d-uscp-7ky65586b4gjz4ow1rk2_original.jpg',
            'https://cdn.vox-cdn.com/thumbor/pZRVAFqIXZd810PUrvmG8v5HXzc=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22659032/1233440917.jpg',
            'https://www.globaltimes.cn/Portals/0/attachment/2021/2021-06-14/37b4becf-c179-4957-9d25-d2f0a5f6e480.jpeg',
            'https://arizonasports.com/wp-content/uploads/2021/12/ap_72013a41c20343d2ad678cd36a83141b-e1639718075379.jpg',
            'https://cdn.vox-cdn.com/thumbor/2EmIaTlZu4hSjPAMru7BPWUuv_4=/0x0:3423x2273/1200x800/filters:focal(1492x512:2038x1058)/cdn.vox-cdn.com/uploads/chorus_image/image/68483457/98753092.jpg.0.jpg',
            'https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2021/07/1200/675/Suns-fans.jpg',
            'http://cdn.chatsports.com/thumbnails/1555-68428-original.jpeg',
            'https://media0.giphy.com/media/JYpVNpmcoFGGWDmxG2/giphy.gif?cid=ecf05e47l5rbjjn99g8854vmmq5x67jtry16usf7jhwk5oau&rid=giphy.gif&ct=g',
            'https://i.giphy.com/media/xUA7aPluIOKknnPDig/giphy.webp',
            'https://media4.giphy.com/media/7CpbZf1z3nVCeYn9De/giphy.gif?cid=ecf05e47yqh4swz6g4ecdqev0clgw5l7yclpnsqhayvn05j1&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Kings',
          code: 'SAC',
          path: 'kings',
          location: 'sacramento',
          logo: 'https://content.sportslogos.net/logos/6/240/thumbs/24040432017.gif',
          youtube: [ 'https://www.youtube.com/c/SacramentoKings' ],
          nicknames: [ 'kings' ],
          keywords: [],
          colors: [ '#5A2D81', '#63727A' ],
          rivals: [ 'Warriors', 'Clippers', 'Lakers', 'Suns' ],
          images: [
            'https://content.sportslogos.net/logos/6/240/thumbs/24040432017.gif',
            'https://ultimatecheerleaders.com/wp-content/uploads/2018/12/DJUtBk-UEAEEveH.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2018/08/C0t5ReUXgAAC6EF.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2017/11/DMiexuhV4AAUcXp.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2017/04/C796pu7VwAA6L_U.jpg',
            'https://www.nbcsports.com/sites/rsnunited/files/article/hero/sacramento-kings-damontas-sabonis.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg4MDIxMTM3NjUxODAzOTQ2/usatsi_17652443_168388303_lowres.jpg',
            'https://www.sacbee.com/latest-news/5pqc7e/picture226547875/alternates/FREE_1140/SAC_KingsVs76ers_013_190202.JPG',
            'https://chumley.barstoolsports.com/union/getty/2021/09/20/GettyImages-1232150882.ed130adb.jpeg',
            'https://i.insider.com/606c43e2a7d803001963ee80?width=1136&format=jpeg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Faroyalpain.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F947716330-850x560.jpeg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/kings/sites/kings/files/1signlady.jpg',
            'https://www.sacbee.com/latest-news/tqb1lw/picture225171875/alternates/FREE_1140/IMG_0240.JPG',
            'https://s.yimg.com/uu/api/res/1.2/_Cuf8t9EBiHBTGDUT8VFsA--~B/aD00MjA7dz02MzA7YXBwaWQ9eXRhY2h5b24-/http://media.zenfs.com/en/blogs/sptusnbaexperts/167262669.jpg.jpg',
            'https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/detroit-pistons-v-sacramento-kings-rocky-widner.jpg'
          ]
        },
        {
          name: 'Mavericks',
          code: 'DAL',
          path: 'mavericks',
          location: 'dallas',
          logo: 'https://content.sportslogos.net/logos/6/228/thumbs/22834632018.gif',
          youtube: [ 'https://www.youtube.com/c/mavericks' ],
          nicknames: [ 'mavericks', 'mavs', 'meva' ],
          keywords: [],
          colors: [ '#00538C', '#B8C4CA' ],
          rivals: [ 'Rockets', 'Grizzlies', 'Pelicans', 'Spurs' ],
          images: [
            'https://content.sportslogos.net/logos/6/228/thumbs/22834632018.gif',
            'https://cdn.theathletic.com/app/uploads/2022/05/29095505/USATSI_18230276-1628x2048.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347fc5850640d58fdb2af_60de2b7ad16cbe7b457d7438_SLM-230-LUKA.jpeg',
            'https://www.mavs.com/wp-content/uploads/2022/05/MB-05-24-WCF-DALatGSW-Game-4-7197.jpg',
            'https://fivethirtyeight.com/wp-content/uploads/2022/05/AP22131301741536-4x3-1.jpg',
            'https://dmn-dallas-news-prod.cdn.arcpublishing.com/resizer/r8D0LVEDfvgtnXfIAwv6RLIaxss=/1660x934/smart/filters:no_upscale()/arc-anglerfish-arc2-prod-dmn.s3.amazonaws.com/public/O5UDDSJ4FVEHNIB7ZXIRIK7RIM.JPG',
            'https://cdn.nba.com/manage/2020/07/porzingis-doncic-ahs.jpg',
            'https://sportsnaut.com/wp-content/uploads/2021/05/16167367.jpg',
            'https://cdn.vox-cdn.com/thumbor/xA8as2cb9xyUALxgxVBte7Tfj2U=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23284617/1238864261.jpg',
            'https://a.espncdn.com/photo/2022/0306/r982631_1024x683_3-2.jpg',
            'https://media.nbcdfw.com/2021/05/Mavs-Game-3-Fans.jpg',
            'https://images.radio.com/aiu-media/gettyimages-115750024-88a869fa-8b1a-401f-8bea-dec61155472d.jpg',
            'https://townsquare.media/site/175/files/2017/05/GettyImages-115945970.jpg'
          ]
        },
        {
          name: 'Rockets',
          code: 'HOU',
          path: 'rockets',
          location: 'houston',
          logo: 'https://content.sportslogos.net/logos/6/230/thumbs/23068302020.gif',
          youtube: [ 'https://www.youtube.com/user/OfficialRockets' ],
          nicknames: [ 'rockets' ],
          keywords: [],
          colors: [ '#CE1141', '#000000' ],
          rivals: [ 'Mavericks', 'Grizzlies', 'Pelicans', 'Spurs' ],
          images: [
            'https://content.sportslogos.net/logos/6/230/thumbs/23068302020.gif',
            'https://cdn.theathletic.com/app/uploads/2022/01/28173702/AP22020165323079-scaled.jpg',
            'https://fadeawayworld.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTc5ODYwMDc5MTY3Mjg0ODQ3/houston-rockets-power-dancers-457321100_master.webp',
            'https://ultimatecheerleaders.com/wp-content/uploads/2019/04/D14cBQLWsAE6gmC-768x512.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2019/03/rpd_7_47-768x984.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2019/03/rpd_7_51-768x536.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2018/08/Da7JJL0V4AAP_n1-768x727.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2017/06/C_FUbD4XgAENHUx.jpg',
            'https://www.sportsnet.ca/wp-content/uploads/2022/03/Houston-Rockets-Jalen-Green-1040x572.jpg',
            'https://cdn.vox-cdn.com/thumbor/urI_SnTiLDxGYcTGsKUeo0pDLHk=/0x0:3880x2728/1200x800/filters:focal(1647x435:2267x1055)/cdn.vox-cdn.com/uploads/chorus_image/image/67004677/usa_today_14007277.0.jpg',
            'https://cdn.nba.com/manage/2021/09/rockets-2020-2021-cropped.jpg',
            'https://cdn.vox-cdn.com/thumbor/CJ60bgCIiDktr_JRajXv0anMpEA=/0x0:5029x3445/1200x800/filters:focal(2113x1321:2917x2125)/cdn.vox-cdn.com/uploads/chorus_image/image/71045001/usa_today_17846657.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/4MjAWMvf9vGmaEhDbzIWcLwjA8k=/0x0:3192x2128/1200x800/filters:focal(1218x138:1728x648)/cdn.vox-cdn.com/uploads/chorus_image/image/70530185/1369048168.0.jpg',
            'https://s.hdnux.com/photos/01/24/40/64/22154498/3/1200x0.jpg',
            'https://assets-cms.thescore.com/uploads/image/file/241591/cropped_2017-04-20T033435Z_731492354_NOCID_RTRMADP_3_NBA-PLAYOFFS-OKLAHOMA-CITY-THUNDER-AT-HOUSTON-ROCKETS.jpg',
            'https://rockets.clutchfans.net/wp-content/uploads/2015/04/houston-rockets-fans.jpg',
            'https://s.hdnux.com/photos/01/06/24/62/18435261/3/rawImage.jpg',
            'https://i.pinimg.com/originals/b3/7e/94/b37e94cb426519a8306d02708f2f18f4.jpg',
            'https://i.giphy.com/media/3oKIP9s5HdPGukRaJq/giphy.webp'
          ]
        },
        {
          name: 'Grizzlies',
          code: 'MEM',
          path: 'grizzlies',
          location: 'memphis',
          logo: 'https://content.sportslogos.net/logos/6/231/thumbs/23143732019.gif',
          youtube: [ 'https://www.youtube.com/memphisgrizzlies' ],
          nicknames: [ 'grizzlies', 'griz', 'grit and grind' ],
          keywords: [],
          colors: [ '#5D76A9', '#12173F' ],
          rivals: [ 'Mavericks', 'Rockets', 'Pelicans', 'Spurs' ],
          images: [
            'https://content.sportslogos.net/logos/6/231/thumbs/23143732019.gif',
            'https://cdn.theathletic.com/app/uploads/2022/05/29084930/USATSI_18187932-1536x1057.jpg',
            'https://sport.one/content/images/2019/01/82.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/62ba0be2215c4d5cad5c6032_slam238-1.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347f7a8785f76b24099ea_60de2b79879f8f30b7e017c4_SLM-227-JA.jpeg',
            'https://s.yimg.com/os/en/blogs/blog/113908313.jpg',
            'https://twsn.net/wp-content/uploads/sites/105/2022/04/memphis-grizzlies-extend-win-streak-to-10-games_5qlywfa69a8j1qd6sldse4v5x.png',
            'https://cdn.cnn.com/cnnnext/dam/assets/220512043538-01-warriors-grizzlies-0511-large-169.jpg',
            'https://cdn.vox-cdn.com/thumbor/kJtGH9z5hQQrqKPogkrjX9P4vf0=/0x0:3000x1997/1200x800/filters:focal(1260x759:1740x1239)/cdn.vox-cdn.com/uploads/chorus_image/image/70501996/1356709144.0.jpg',
            'https://static01.nyt.com/images/2020/08/05/sports/03nba-morant-lede/03nba-morant-lede-mediumSquareAt3X.jpg',
            'https://library.sportingnews.com/styles/crop_style_16_9_mobile_2x/s3/2022-04/nba-plain--16992863-5025-48cb-814c-bc95250d7d40.jpeg',
            'https://bealestreetbears.com/wp-content/uploads/imagn-images/2017/07/13980467.jpeg',
            'https://bealestreetbears.com/wp-content/uploads/imagn-images/2017/07/13980467.jpeg',
            'https://s.hdnux.com/photos/01/25/50/64/22449441/3/1200x0.jpg',
            'https://cdn.vox-cdn.com/thumbor/FhgzuHhBWJXS4orBiR9dVHGN9jA=/0x0:4274x2849/1200x800/filters:focal(1802x1242:2484x1924)/cdn.vox-cdn.com/uploads/chorus_image/image/65050076/usa_today_9729968.0.jpg',
            'https://s.yimg.com/uu/api/res/1.2/iiffo8BXPGoOSUZPbT97cg--~B/aD00NDA7dz02MzA7YXBwaWQ9eXRhY2h5b24-/http://media.zenfs.com/en/blogs/sptusnbaexperts/BJWUI6vCEAATXTC.jpg.jpg'
          ]
        },
        {
          name: 'Pelicans',
          code: 'NOP',
          path: 'pelicans',
          location: 'new orleans',
          logo: 'https://content.sportslogos.net/logos/6/4962/thumbs/496226812014.gif',
          youtube: [ 'https://www.youtube.com/c/NBAPelicans' ],
          nicknames: [ 'pelicans' ],
          keywords: [],
          colors: [ '#0C2340', '#85714D' ],
          rivals: [ 'Mavericks', 'Rockets', 'Grizzlies', 'Spurs' ],
          images: [
            'https://content.sportslogos.net/logos/6/4962/thumbs/496226812014.gif',
            'https://cdn.theathletic.com/app/uploads/2022/01/29152015/USATSI_17537583-1536x1024.jpg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347f8c95701709633e01e_60de2b7a7b5b45713fb12e16_SLM-228-ZION.jpeg',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e347f0ca986c3a95ee1111_60de2b672849fef8efacef7e_SLAM-224-PELICANS.jpeg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2017/10/pi-nba-cheer18-pelicans-100317.vadapt.767.high_.45.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/pelicans/sites/pelicans/files/pels_game_4_look_ahead.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/pelicans/sites/pelicans/files/profile_ingram_0.jpg',
            'https://cdn.nba.com/manage/2022/02/GettyImages-1238487091-scaled.jpg',
            'https://imageio.forbes.com/specials-images/imageserve/6042727f954559521e6cc15c/Minnesota-Timberwolves-v-New-Orleans-Pelicans/960x0.jpg?format=jpg',
            'https://pelicandebrief.com/wp-content/uploads/imagn-images/2017/07/13688721.jpeg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/pelicans/sites/pelicans/files/_eh_2896_0.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/pelicans/sites/pelicans/files/gallery-pelicans-suns-fans-v2-042822-0073_0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fpelicandebrief.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2018%2F02%2F905739504-new-orleans-pelicans-v-new-york-knicks.jpg.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/pelicans/sites/pelicans/files/gallery-pelicans-suns-fans-v2-042822-0025.jpg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/pelicans/sites/pelicans/files/_eh_7624.jpg'
          ]
        },
        {
          name: 'Spurs',
          code: 'SAS',
          path: 'spurs',
          location: 'san antonio',
          logo: 'https://content.sportslogos.net/logos/6/233/thumbs/23325472018.gif',
          youtube: [ 'https://www.youtube.com/c/spurs' ],
          nicknames: [ 'spurs' ],
          keywords: [],
          colors: [ '#C4CED4', '#000000' ],
          rivals: [ 'Mavericks', 'Rockets', 'Grizzlies', 'Pelicans' ],
          images: [
            'https://content.sportslogos.net/logos/6/233/thumbs/23325472018.gif',
            'https://assets.website-files.com/60c7deb2c60aa9181c632225/60e34721585064a7effdb138_60de2842edefe803a38ca5c2_SLAM_47_DUNCAN.jpeg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTg0NDAxMTM2NTQ2MTYxNjg5/001288135.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTg0NDAxMTM3MzUxMzM3NDUw/006274327-2.webp',
            'https://s.hdnux.com/photos/01/25/33/72/22396591/3/rawImage.jpg',
            'https://images2.minutemediacdn.com/image/upload/c_crop,w_2942,h_1654,x_0,y_549/c_fill,w_720,ar_16:9,f_auto,q_auto,g_auto/images/GettyImages/mmsport/29/01g8txgb1z307hmdbs7j.jpg',
            'https://res.cloudinary.com/remezcla/images/f_auto,q_auto/v1658848015/production/San_Antonio_Spurs_Sports/San_Antonio_Spurs_Sports.jpg',
            'https://cdn.vox-cdn.com/thumbor/soALlOsT0VyML9IrxW4e0qHomNo=/0x0:5254x3495/1200x800/filters:focal(1881x617:2721x1457)/cdn.vox-cdn.com/uploads/chorus_image/image/70319988/1237431127.0.jpg',
            'https://images2.minutemediacdn.com/image/upload/c_fill,w_720,ar_16:9,f_auto,q_auto,g_auto/shape/cover/sport/https-3A-2F-2Fairalamo-com-2Fwp-content-2Fuploads-2Fgetty-images-2F2017-2F07-2F1191339487-7b50b07133b3cd6c4a5cbe7e83aed6f7.jpg',
            'https://cdn.vox-cdn.com/thumbor/WPEUlxEKZNT1NFY4bvtB7XWifIw=/0x0:736x485/1200x800/filters:focal(310x185:426x301)/cdn.vox-cdn.com/uploads/chorus_image/image/68892622/1137998154_850x560.0.jpeg',
            'https://cdn.nba.com/teams/legacy/www.nba.com/spurs/sites/spurs/files/042218-spurs-warriors-adrianrgarcia-_aa95117.jpg',
            'https://images2.minutemediacdn.com/image/upload/c_fill,w_720,ar_16:9,f_auto,q_auto,g_auto/shape/cover/sport/https-3A-2F-2Fairalamo-com-2Fwp-content-2Fuploads-2Fgetty-images-2F2017-2F07-2F1187426410-850x560-5c57bba7578b39c4e752539ad3924857.jpg',
            'https://s.hdnux.com/photos/01/01/64/10/17252907/3/1200x0.jpg'
          ]
        }
      ]
    }
  }
}

import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,

  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    searchRoutePath: '/',
    searchQueryText: '',
  },

  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },

    isBiggerThanBreakPoint: state => bp => {
      const { windowWidth } = state
      if (bp === 'xl' && windowWidth >= $themeBreakpoints.xl) return true
      if (bp === 'lg' && windowWidth >= $themeBreakpoints.lg) return true
      if (bp === 'md' && windowWidth >= $themeBreakpoints.md) return true
      if (bp === 'sm' && windowWidth >= $themeBreakpoints.sm) return true
      return false
    },
  },

  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },

    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },

    SEARCH_ROUTE_PATH(state, val) {
      state.searchRoutePath = val !== undefined ? val : '/'
    },

    SEARCH_QUERY_TEXT(state, val) {
      state.searchQueryText = val !== undefined ? val : ''
    },
  },

  actions: {},
}

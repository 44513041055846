import Cookies from 'js-cookie'
import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,

  state: {
    layout: {
      isRTL: $themeConfig.layout.isRTL,
      skin: localStorage.getItem('deksports-skin') || $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
      type: $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
      postMode: Cookies.get('postMode') || $themeConfig.layout.postMode,
      fontSizeTitle: Cookies.get('fontSizeTitle') || '1.0rem',
      fontSizeDesc: Cookies.get('fontSizeDesc') || '0.9rem',
      // favoriteTeams: Cookies.get('favoriteTeams') || '',
    },
  },

  getters: {
    // getFavoriteTeams: state => state.layout.favoriteTeams,
  },

  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_POST_MODE(state, val) {
      state.layout.postMode = val
      Cookies.set('postMode', val, { expires: 365 })
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin

      // Update value in localStorage
      localStorage.setItem('deksports-skin', skin)

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    SET_FONT_SIZE_TITLE(state, size) {
      state.layout.fontSizeTitle = size
      Cookies.set('fontSizeTitle', size, { expires: 365 })
    },
    SET_FONT_SIZE_DESCRIPTION(state, size) {
      state.layout.fontSizeDesc = size
      Cookies.set('fontSizeDesc', size, { expires: 365 })
    },
    // SET_FAVORITE_TEAMS(state, val) {
    //   state.layout.favoriteTeams = val
    //   Cookies.set('favoriteTeams', val, { expires: 365 })
    // },
  },

  actions: {
    setFontSizeTitle({ commit }, size) {
      commit('SET_FONT_SIZE_TITLE', size)
    },
    setFontSizeDesc({ commit }, size) {
      commit('SET_FONT_SIZE_DESCRIPTION', size)
    },
    setPostMode({ commit }, val) {
      commit('UPDATE_POST_MODE', val)
    },
    // setFavoriteTeams({ commit }, val) {
    //   commit('SET_FAVORITE_TEAMS', val)
    // },
  },
}

/* eslint-disable array-bracket-spacing */
/* eslint-disable comma-dangle */
export default {
  title: 'American',
  icon: 'https://content.sportslogos.net/leagues/thumbs/54.gif',
  iconSource: 'image',
  teams: [
    {
      name: 'Cubs',
      path: 'cubs',
      location: 'chicago',
      logo: 'https://content.sportslogos.net/logos/54/54/thumbs/q9gvs07u72gc9xr3395u6jh68.gif',
      youtube: [ 'https://www.youtube.com/c/cubs' ],
      nicknames: [ 'cubs', 'cubbies', 'baby bears', 'lovable losers' ],
      keywords: [],
      colors: [ '#0E3386', '#CC3433' ],
      rivals: [ 'Reds', 'Brewers', 'Pirates', 'Cardinals' ],
      images: [
        'https://content.sportslogos.net/logos/54/54/thumbs/q9gvs07u72gc9xr3395u6jh68.gif',
        'https://www.bleachernation.com/wp-content/uploads/2022/07/Screen-Shot-2022-07-06-at-5.16.50-PM.jpg',
        'https://a.espncdn.com/photo/2022/0706/r1033217_1296x518_5-2.jpg',
        'https://cubbiescrib.com/wp-content/uploads/getty-images/2018/08/1176680066.jpeg',
        'https://storage.googleapis.com/afs-prod/media/media:655049c8e76b46e69e5b9e9de1f3e208/2716.jpeg',
        'http://d279m997dpfwgl.cloudfront.net/wp/2016/11/AP_16308115675097-1000x667.jpg',
        'https://cdn.shopify.com/s/files/1/0749/2955/products/afraidgoats_grande.jpg?v=1476125341',
        'https://i.pinimg.com/originals/cf/dd/25/cfdd2580283f0734fb1c9b5d4cac1836.jpg',
        'https://i.pinimg.com/originals/76/02/07/7602079cc28ccfa76826d908262c9b9e.jpg',
        'https://pbs.twimg.com/media/BFe3xeVCcAMCs4g.jpg',
        'https://media.gq.com/photos/55d62ed795040f5e14f4defc/master/w_1600%2Cc_limit/MM.jpg',
        'https://media1.popsugar-assets.com/files/thumbor/d0v6GK5UwFIfdaPf_9wC8WNHzHg/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/05/02/834/n/1922398/658d5694fa2755f2_116959282/i/Vince-Vaughn-took-mound-Chicago-Cubs-June-2011.jpg'
      ]
    },
    {
      name: 'Reds',
      path: 'cincinnati/reds',
      location: 'cincinnati',
      logo: 'https://content.sportslogos.net/logos/54/56/thumbs/z9e0rqit393ojiizsemd0t1hx.gif',
      youtube: [ 'https://www.youtube.com/c/reds' ],
      nicknames: [ 'reds', 'big red machine' ],
      keywords: [],
      colors: [ '#C6011F', '#000000' ],
      rivals: [ 'Cubs', 'Brewers', 'Pirates', 'Cardinals' ],
      images: [
        'https://content.sportslogos.net/logos/54/56/thumbs/z9e0rqit393ojiizsemd0t1hx.gif',
        'https://securea.mlb.com/assets/images/9/4/8/116584948/cuts/RedsFan_gh32iezk_dxa659wq.jpg',
        'https://s.yimg.com/ny/api/res/1.2/852K6YloaPT3NlfADYJLHQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTU5NjtjZj13ZWJw/https://s.yimg.com/os/en/blogs/sptusmlbexperts/GABPpicBLS45.jpg',
        'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Ffansided.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2022%2F05%2F1393073132.jpeg',
        'https://thumbor.bigedition.com/nick-lachey/eTv2UCzYKRLC-KQGv26FL8O7hY4=/800x600/filters:format(webp):quality(80)/granite-web-prod/82/ab/82abbd58c29d4c23903e6ae0d9d900ba.jpeg',
        'https://phantom-marca.unidadeditorial.es/11d526ebd5ffc3fb72276e302021b06b/resize/660/f/webp/assets/multimedia/imagenes/2022/04/12/16497982869109.jpg',
        'https://sportsnaut.com/wp-content/uploads/2022/04/18164077-748x420.jpg',
        'https://kubrick.htvapps.com/htv-prod-media.s3.amazonaws.com/images/ap-20267837429210.jpg',
        'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/v1576607766/mlb/vafcibtiplbww6fatrpg.jpg',
        'https://phantom-marca.unidadeditorial.es/9c37e9df67ec71bc5c6afb2e569469ff/resize/660/f/webp/assets/multimedia/imagenes/2022/06/20/16556936369616.jpg',
        'https://pbs.twimg.com/media/Bu3trizIEAAPNiA.jpg',
        'https://i.pinimg.com/originals/5c/4f/c3/5c4fc32896b17842ca240d9bae6d8df7.jpg',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTkwNjg3NTI2NjMyMDM5NTQ5/brandon-drury-reds.webp'
      ]
    },
    {
      name: 'Brewers',
      path: 'brewers',
      location: 'milwaukee',
      logo: 'https://content.sportslogos.net/logos/54/64/thumbs/6464742020.gif',
      youtube: [ 'https://www.youtube.com/c/brewers' ],
      nicknames: [ 'brewers', 'brew crew', 'crew' ],
      keywords: [],
      colors: [ '#FFC52F', '#12284B' ],
      rivals: [ 'Cubs', 'Reds', 'Pirates', 'Cardinals' ],
      images: [
        'https://content.sportslogos.net/logos/54/64/thumbs/6464742020.gif',
        'https://cdn.vox-cdn.com/thumbor/66sCkSgMFFUuocpa488-faXUmwA=/0x0:4931x3282/920x613/filters:focal(1895x750:2683x1538):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/67497361/1052010504.jpg.0.jpg',
        'https://bloximages.newyork1.vip.townnews.com/ivpressonline.com/content/tncms/assets/v3/editorial/6/25/62518004-7be7-5453-a335-dc14c05c7d40/62bfb5fe159e1.image.jpg?resize=750%2C500',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTkwNjg3MzgwMzM0NzE2Nzk2/brandon-woodruff-brewers.webp',
        'https://pbs.twimg.com/media/Cs1tHzZWcAEqdn5.jpg',
        'https://img.mlbstatic.com/mlb-images/image/private/t_2x1/t_w1536/mlb/bxqomlr8xw1er0krawrp.jpg'
      ]
    },
    {
      name: 'Pirates',
      path: 'pirates',
      location: 'pittsburgh',
      logo: 'https://content.sportslogos.net/logos/54/71/thumbs/7112502014.gif',
      youtube: [ 'https://www.youtube.com/c/Pirates' ],
      nicknames: [ 'pirates', 'bucs' ],
      keywords: [],
      colors: [ '#27251F', '#FDB827' ],
      rivals: [ 'Cubs', 'Reds', 'Brewers', 'Cardinals' ],
      images: [
        'https://content.sportslogos.net/logos/54/71/thumbs/7112502014.gif',
        'https://thumbor.bigedition.com/michael-keaton/M34JJTJZtiRRJWLxeFUAv6WyMDs=/800x600/filters:format(webp):quality(80)/granite-web-prod/25/8a/258acb66f85045368b4564ea79d8b9c0.jpeg',
        'https://ftw.usatoday.com/wp-content/uploads/sites/90/2017/10/usp_mlb__world_series-houston_astros_at_los_angele_94995675.jpg?resize=1024,811',
        'https://media3.giphy.com/media/xT0GqhT8SulsK6DHmo/giphy.gif?cid=ecf05e47qfndut94g3w0n8l3dz2me3o9p9f63pcjddm9kbdl&rid=giphy.gif&ct=g',
        'https://i.giphy.com/media/Y1MGj9GGPniHm/giphy.webp'
      ]
    },
    {
      name: 'Cardinals',
      path: 'cardinals',
      location: 'st louis',
      logo: 'https://content.sportslogos.net/logos/54/72/thumbs/3zhma0aeq17tktge1huh7yok5.gif',
      youtube: [ 'https://www.youtube.com/c/Cardinal' ],
      nicknames: [ 'cardinals', 'cards', 'red birds' ],
      keywords: [],
      colors: [ '#C41E3A', '#0C2340' ],
      rivals: [ 'Cubs', 'Reds', 'Brewers', 'Pirates' ],
      images: [
        'https://content.sportslogos.net/logos/54/72/thumbs/3zhma0aeq17tktge1huh7yok5.gif',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MjIxOTYzMjU3MTA1/st-louis-cardinals-team-fredbird-cgd15050226_pirates_at_cardinalsjpg.webp',
        'https://i.pinimg.com/474x/1e/1f/fe/1e1ffe1624ceccd03bb4a084069f4e94--sports-women-st-louis-cardinals.jpg',
        'https://images.squarespace-cdn.com/content/v1/54cfafa1e4b059148956c403/1456158185860-EXBIMKU9CT019E6MMQGL/image-asset.jpeg?format=1500w',
        'https://i.pinimg.com/originals/c1/ca/31/c1ca31330cd639c31fcde9c962012107.png',
        'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fredbirdrants.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1166629942.jpeg',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN4ZKjNdPiGlMkNhq7DKiCgz5JQyNPiMzVWoyCZxidyvN-zrR9DI88fs0al_kq94WzFRA&usqp=CAU',
        'https://img.mlbstatic.com/mlb-images/image/private/ar_16:9,g_auto,q_auto:good,w_372,c_fill,f_jpg/mlb/hkmf3frt2zvt0p3kydj8',
        'https://thumbor.bigedition.com/jon-hamm/rZDsh6N5gnDOKpU5eJpIDX-FehI=/800x600/filters:format(webp):quality(80)/granite-web-prod/ef/e2/efe2925dc74744bc9bb63b712cf3a737.jpeg'
      ]
    },
    {
      name: 'Braves',
      path: 'braves',
      location: 'atlanta',
      logo: 'https://content.sportslogos.net/logos/54/51/thumbs/5171502018.gif',
      youtube: [ 'https://www.youtube.com/c/braves' ],
      nicknames: [ 'braves' ],
      keywords: [],
      colors: [ '#CE1141', '#13274F' ],
      rivals: [ 'Marlins', 'Mets', 'Phillies', 'Nationals' ],
      images: [
        'https://content.sportslogos.net/logos/54/51/thumbs/5171502018.gif',
        'https://thumbor.bigedition.com/ludacris/U3QRU6PpH7M4-KICMzxPNhDyavI=/800x600/filters:format(webp):quality(80)/granite-web-prod/5b/d1/5bd175e499734fd2b52aa903f9c4722b.jpeg',
        'https://a.espncdn.com/photo/2021/1013/r922229_1296x729_16-9.jpg',
        'https://www.gannett-cdn.com/presto/2018/09/14/USAT/3988f827-c4c2-42ad-9bb4-6c4beff97a37-AP_BRAVES_ROTATION.JPG?width=660&height=588&fit=crop&format=pjpg&auto=webp',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_594/MTY4MTI2MTE4NzIzODU1NjMz/atlanta-braves-tomahawk-team-gettyimages-541729482_masterjpg.webp',
        'https://i.pinimg.com/originals/b2/30/d4/b230d4a1601bd041ba5612a0dc3e4e98.jpg',
        'https://i.pinimg.com/originals/9d/dc/a3/9ddca3c59ebf1ebe816d5d1f8c88cefb.jpg',
        'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w2208/mlb/kddkqnlwhimvz0ym2jec.jpg'
      ]
    },
    {
      name: 'Marlins',
      path: 'marlins',
      location: 'miami',
      logo: 'https://content.sportslogos.net/logos/54/3637/thumbs/363775442019.gif',
      youtube: [ 'https://www.youtube.com/c/marlins' ],
      nicknames: [ 'marlins', 'fish', 'fins' ],
      keywords: [],
      colors: [ '#00A3E0', '#EF3340' ],
      rivals: [ 'Braves', 'Mets', 'Phillies', 'Nationals' ],
      images: [
        'https://content.sportslogos.net/logos/54/3637/thumbs/363775442019.gif',
        'https://thumbor.bigedition.com/scott-stapp/L7L_9XgcajsmgZVX9JxifO7i75M=/800x600/filters:format(webp):quality(80)/granite-web-prod/99/36/9936d00430fa4f8ab4b9ea75dbacc4dd.jpeg',
        'https://ultimatecheerleaders.com/wp-content/uploads/2011/02/jenmarlinmermaid.jpg',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_580/MTY4MTI2MTE4NDU3Mzg2MjU3/miami-marlins-energy-team-1c05c945eddf4e05b4317b71681cc7b2-0jpg.webp',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NDU3MTI0MTEz/miami-marlins-energy-team-375e3c0b2c554237849f6d6775bcdd8c-0jpg.webp',
        'https://www.si.com/.image/t_share/MTY4MjYxMzk1NDMxMzAyNDIx/marilins-mermaids-20110421_mje_su8_003jpg.jpg',
        'https://www.si.com/.image/t_share/MTY4MjYxMzk1NDMzMjAyOTY1/marilins-mermaids-20110411_kkt_su8_173jpg.jpg',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_500/MTY4MjYxMzk1MTYzNzg0NDY5/marilins-mermaids-20110405_ter_su8_371jpg.webp',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_513/MTY4MjYxMzk1OTY4NDM1NDc3/marilins-mermaids-20110424_pjc_su8_074jpg.webp',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_581/MTY4MjYxMzk1MTYyOTk4MDM3/marilins-mermaids-20110519_kkt_su8_654jpg.webp',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYxMzk1MTYxNzUyODUz/marilins-mermaids-20110612_pjc_su8_073jpg.webp',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MjIxOTYxMDI4ODgx/miami-marlins-energy-team-a7b51e9abeff49dcac2f8937a1d6fa48-0jpg.webp'
      ]
    },
    {
      name: 'Mets',
      path: 'mets',
      location: 'new york',
      logo: 'https://content.sportslogos.net/logos/54/67/thumbs/m01gfgeorgvbfw15fy04alujm.gif',
      youtube: [ 'https://www.youtube.com/c/mets' ],
      nicknames: [ 'mets', 'metropolitans', 'metros' ],
      keywords: [],
      colors: [ '#002D72', '#FF5910' ],
      rivals: [ 'Braves', 'Marlins', 'Phillies', 'Nationals' ],
      images: [
        'https://content.sportslogos.net/logos/54/67/thumbs/m01gfgeorgvbfw15fy04alujm.gif',
        'https://thumbor.bigedition.com/jerry-seinfeld/R_xkFyYTjq55ilnkcvkT7Ag0xHU=/800x600/filters:format(webp):quality(80)/granite-web-prod/13/01/130101b425374169a9f8fdbf080e2193.jpeg',
        'https://media1.popsugar-assets.com/files/thumbor/mowlB8SbDMnkPwAzKFbkUMGZbMY/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/05/02/731/n/1922398/b99fffa9ffa078fa_150634237/i/Olympic-gold-medalist-Gabby-Douglas-threw-first-pitch.jpg',
        'https://apptrigger.com/wp-content/uploads/getty-images/2017/07/1233857892.jpeg',
        'https://i.pinimg.com/736x/16/8a/55/168a55d687bf37f35b41394621305d86--mets-game-tonight-my-mets.jpg',
        'https://cdn.abcotvs.com/dip/images/1044592_10202015-wabc-mets-fans-game3-1280-363-img.jpg',
        'https://cdn.cnn.com/cnnnext/dam/assets/151030235709-11-ws-game-three-1030-super-169.jpg',
        'https://pbs.twimg.com/media/Cg_ftEJU4AExb52?format=jpg&name=small',
        'https://pagesix.com/wp-content/uploads/sites/3/2015/10/people_billy_joel.jpg?quality=80&strip=all&w=1024',
        'https://i.pinimg.com/736x/04/94/b2/0494b2b0551950ff1574bd9ba5f95b50--mets-game-mets-baseball.jpg'
      ]
    },
    {
      name: 'Phillies',
      path: 'phillies',
      location: 'philadelphia',
      logo: 'https://content.sportslogos.net/logos/54/70/thumbs/7075792019.gif',
      youtube: [ 'https://www.youtube.com/c/phillies' ],
      nicknames: [ 'phillies', 'phils' ],
      keywords: [],
      colors: [ '#E81828', '#002D72' ],
      rivals: [ 'Braves', 'Marlins', 'Mets', 'Nationals' ],
      images: [
        'https://content.sportslogos.net/logos/54/70/thumbs/7075792019.gif',
        'https://thumbor.bigedition.com/tina-fey/c0JUhElbIgT7t3CauDCkmhuV3aM=/800x600/filters:format(webp):quality(80)/granite-web-prod/5a/bb/5abb7d8e18014e7597d214b470e24371.jpeg',
        'https://i.pinimg.com/474x/8b/97/2e/8b972e28617cd2addb728c019c5d1fc2--baseball-girls-baseball-jerseys.jpg',
        'https://pbs.twimg.com/media/D6T1BcIW4AEEt9W.jpg',
        'https://pbs.twimg.com/media/C9ekZdgXsAImWTL.jpg',
        'https://billypenn.com/wp-content/uploads/2021/05/philliesfan-springtraining-costume-crop-1024x576.jpg',
        'https://www.etonline.com/sites/default/files/styles/video_1920x1080/public/images/2019-05/eto_aol_bruce_willis_051619.jpg?h=59450369&itok=XoUX_1AI',
        'https://i.pinimg.com/originals/bb/7e/3c/bb7e3c90424159e8c17df7801dcb9f8f.jpg',
        'https://townsquare.media/site/399/files/2022/07/attachment-Untitled-design62.jpg',
        'https://i.giphy.com/media/4WWEOyzQSh0CXizWET/giphy.webp',
        'https://cdn.vox-cdn.com/thumbor/tPMNDZvQBIqNBhCt2DU143M9r6g=/0x0:3813x2541/1200x800/filters:focal(1602x966:2212x1576)/cdn.vox-cdn.com/uploads/chorus_image/image/69487083/usa_today_16286261.0.jpg',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NDU3MjU1MTg1/philadelphia-phillies-ballgirls-gettyimages-537995170jpg.webp'
      ]
    },
    {
      name: 'Nationals',
      path: 'nationals',
      location: 'washington',
      logo: 'https://content.sportslogos.net/logos/54/578/thumbs/rcehah9k0kekjkgzm077fflws.gif',
      youtube: [ 'https://www.youtube.com/c/washingtonnationals' ],
      nicknames: [ 'nationals', 'nats' ],
      keywords: [],
      colors: [ '#AB0003', '#14225A' ],
      rivals: [ 'Braves', 'Marlins', 'Mets', 'Phillies' ],
      images: [
        'https://content.sportslogos.net/logos/54/578/thumbs/rcehah9k0kekjkgzm077fflws.gif',
        'https://i.ytimg.com/vi/NDUjKDJZYvs/maxresdefault.jpg',
        'https://cdn.vox-cdn.com/thumbor/vK-PxHIujuIAsKrJLlzuq0_49f8=/0x0:3990x2660/1400x1400/filters:focal(0x0:3990x2660):format(jpeg)/cdn.vox-cdn.com/uploads/chorus_image/image/36919770/20140808_ajw_ad1_283.JPG.0.jpg',
        'https://jmuddd.files.wordpress.com/2013/07/haley_natpack.jpg',
        'https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/3K73RHWHYMI6VKBFQ4RAATSBKA.jpg',
        'https://wtop.com/wp-content/uploads/2017/03/032817_pitch14.jpg'
      ]
    },
    {
      name: 'Diamondbacks',
      path: 'diamondbacks',
      location: 'arizona',
      logo: 'https://content.sportslogos.net/logos/54/50/thumbs/gnnnrbxcmjhdgeu6mavqk3945.gif',
      youtube: [ 'https://www.youtube.com/c/Dbacks' ],
      nicknames: [ 'diamondbacks', 'd backs', 'snakes' ],
      keywords: [],
      colors: [ '#A71930', '#000000' ],
      rivals: [ 'Rockies', 'Dodgers', 'Padres', 'Giants' ],
      images: [
        'https://content.sportslogos.net/logos/54/50/thumbs/gnnnrbxcmjhdgeu6mavqk3945.gif',
        'https://thewiffhotstove.files.wordpress.com/2014/06/diamondbacks-sexy.jpg',
        'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTgwNTc4MDY1NjI4Mjc1ODAw/madison-bumgarner.webp',
        'https://cdn.vox-cdn.com/thumbor/RzvdrqCmJ-Q5_sDYPLgkfbpX3vY=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23395600/1240032769.jpg',
        'https://pbs.twimg.com/media/Dep0s5EVAAAY0Zr.jpg',
        'https://cdn.vox-cdn.com/thumbor/hxw1_-zgu_AsqNFBGH_y6Jc4lEI=/0x66:4000x2733/1400x1050/filters:focal(0x66:4000x2733):format(jpeg)/cdn.vox-cdn.com/uploads/chorus_image/image/11683453/166642095.0.jpg',
        'https://static.wikia.nocookie.net/sportsmascots/images/e/e5/Baxter.jpg/revision/latest/scale-to-width-down/340?cb=20160214013932',
        'https://3.bp.blogspot.com/-Yuemw5q7jPQ/TZ2pMnQ-rCI/AAAAAAAAAtE/5fXUFVWLrVU/s1600/diamondbacks+2.jpg'
      ]
    },
    {
      name: 'Rockies',
      path: 'rockies',
      location: 'colorado',
      logo: 'https://content.sportslogos.net/logos/54/58/thumbs/5868712017.gif',
      youtube: [ 'https://www.youtube.com/c/Rockies' ],
      nicknames: [ 'rockies', 'rocks' ],
      keywords: [],
      colors: [ '#33006F', '#C4CED4' ],
      rivals: [ 'Diamondbacks', 'Dodgers', 'Padres', 'Giants' ],
      images: [
        'https://content.sportslogos.net/logos/54/58/thumbs/5868712017.gif',
        'https://travel.home.sndimg.com/content/dam/images/travel/fullset/2011/09/30/9f/idea_baseball-stadiums_003_596x334.rend.hgtvcom.966.644.suffix/1491586507224.jpeg',
        'https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MTc0NTA2MTQxMjQ0NDY3MTkw/the-greatest-players-in-colorado-rockies-history.webp',
        'https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MTc1NDU3OTc1NzkwNjA5OTg4/the-greatest-players-in-colorado-rockies-history.webp',
        'https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MTc1NDU3OTc1NzkwNjc1NTI0/the-greatest-players-in-colorado-rockies-history.webp',
        'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w372/mlb/cfebsy9lsgoj3ndnasiv.jpg'
      ]
    },
    {
      name: 'Dodgers',
      path: 'dodgers',
      location: 'los angeles',
      logo: 'https://content.sportslogos.net/logos/54/63/thumbs/efvfv5b5g1zgpsf56gb04lthx.gif',
      youtube: [ 'https://www.youtube.com/c/Dodgers' ],
      nicknames: [ 'dodgers', 'blue crew', 'dodger blue' ],
      keywords: [],
      colors: [ '#005A9C', '#A5ACAF' ],
      rivals: [ 'Diamondbacks', 'Rockies', 'Padres', 'Giants' ],
      images: [
        'https://content.sportslogos.net/logos/54/63/thumbs/efvfv5b5g1zgpsf56gb04lthx.gif',
        'https://pagesix.com/wp-content/uploads/sites/3/2016/10/gettyimages-175119507.jpg?quality=90&strip=all&w=513',
        'https://pagesix.com/wp-content/uploads/sites/3/2016/10/gettyimages-453829586.jpg?quality=90&strip=all&w=814',
        'https://images.viacbs.tech/uri/mgid:arc:imageassetref:bet.com:5a12cbc0-4873-11e7-a442-0e40cf2fc285?quality=0.7',
        'https://pagesix.com/wp-content/uploads/sites/3/2016/10/gettyimages-471389344.jpg?quality=90&strip=all&w=722',
        'https://pagesix.com/wp-content/uploads/sites/3/2016/10/gettyimages-615944608.jpg?quality=90&strip=all&w=361',
        'https://ftw.usatoday.com/wp-content/uploads/sites/90/2017/10/gty_868767542_94996027.jpg?w=1000&h=600&crop=1',
        'https://ftw.usatoday.com/wp-content/uploads/sites/90/2017/10/usp_mlb__world_series-houston_astros_at_los_angele_94995679.jpg?resize=1030,1536',
        'https://data.nssmag.com/images/galleries/19642/856103948-0.jpg'
      ]
    },
    {
      name: 'Padres',
      path: 'padres',
      location: 'san diego',
      logo: 'https://content.sportslogos.net/logos/54/73/thumbs/7375172020.gif',
      youtube: [ 'https://www.youtube.com/c/Padres' ],
      nicknames: [ 'padres', 'pads', 'friars' ],
      keywords: [],
      colors: [ '#2F241D', '#FFC425' ],
      rivals: [ 'Diamondbacks', 'Rockies', 'Dodgers', 'Giants' ],
      images: [
        'https://content.sportslogos.net/logos/54/73/thumbs/7375172020.gif',
        'https://data.nssmag.com/images/galleries/19642/openingnight6.jpg',
        'https://data.nssmag.com/images/galleries/19642/dsasd-01.jpg',
        'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2133,w_3200/https%3A%2F%2Ffriarsonbase.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F14883508.jpeg',
        'https://static01.nyt.com/images/2021/08/24/sports/24mlb-padres-1/24mlb-padres-1-videoSixteenByNine3000.jpg',
        'https://mlbblogger.files.wordpress.com/2012/08/padres.jpg'
      ]
    },
    {
      name: 'Giants',
      path: 'giants',
      location: 'san francisco',
      logo: 'https://content.sportslogos.net/logos/54/74/thumbs/cpqj6up5bvgpoedg5fwsk20ve.gif',
      youtube: [ 'https://www.youtube.com/c/Giants' ],
      nicknames: [ 'giants', 'gints', 'g men' ],
      keywords: [],
      colors: [ '#27251F', '#FD5A1E' ],
      rivals: [ 'Diamondbacks', 'Rockies', 'Dodgers', 'Padres' ],
      images: [
        'https://content.sportslogos.net/logos/54/74/thumbs/cpqj6up5bvgpoedg5fwsk20ve.gif',
        'https://fivethirtyeight.com/wp-content/uploads/2022/04/GettyImages-1391303069-4x3-1.jpg?w=916',
        'http://ww2.kqed.org/bayareabites/wp-content/uploads/sites/24/2012/11/mvp-fans1000a.jpg',
        'https://i.pinimg.com/originals/67/10/fd/6710fd26f1d611eca1ab558cc665ef79.jpg',
        'https://www.hollywoodreporter.com/wp-content/uploads/2012/10/rob_schneider_giants.jpg?w=800',
        'https://cdn.vox-cdn.com/thumbor/tuKIKBAAY0jAYUzQY-vd3bGBaA0=/0x0:3931x2621/920x613/filters:focal(1652x997:2280x1625):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/70959206/1401853555.0.jpg',
        'https://travel.home.sndimg.com/content/dam/images/travel/fullset/2011/09/30/95/idea_baseball-stadiums_004_596x334.rend.hgtvcom.966.644.suffix/1491586605176.jpeg'
      ]
    }
  ]
}

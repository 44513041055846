export default {
  SHOW(state) {
    state.isLoading = true
  },

  HIDE(state) {
    state.isLoading = false
  },

  PENDING(state) {
    if (state.requestsPending === 0) {
      this.commit('loader/SHOW')
    }

    state.requestsPending += 1
  },

  DONE(state) {
    if (state.requestsPending >= 1) {
      state.requestsPending -= 1
    }

    if (state.requestsPending <= 0) {
      this.commit('loader/HIDE')
    }
  },
}

import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null

// console.log('=====ability.js===================================')
// console.log(userData)
// console.log('--------------------')
// console.log(initialAbility)
// console.log('--------------------')
// console.log(existingAbility)
// console.log('--------------------')
// const testability = new Ability(existingAbility || initialAbility)
// console.log(testability.can('read', 'Auth'))

export default new Ability(existingAbility || initialAbility)

export default [
  {
    path: '/authentication/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/authentication/register',
    name: 'auth-register',
    component: () => import('@/views/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/authentication/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/authentication/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
      // Should this have:    resource: 'Auth',   ???
    },
  },

  // Extra authorization pages.
  {
    path: '/authentication/login-v1',
    name: 'auth-login-v1',
    component: () => import('@/views/authentication/Login-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/authentication/login-v2',
    name: 'auth-login-v2',
    component: () => import('@/views/authentication/Login-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/authentication/register-v1',
    name: 'auth-register-v1',
    component: () => import('@/views/authentication/Register-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/authentication/register-v2',
    name: 'auth-register-v2',
    component: () => import('@/views/authentication/Register-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/authentication/forgot-password-v1',
    name: 'auth-forgot-password-v1',
    component: () => import('@/views/authentication/ForgotPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/authentication/forgot-password-v2',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/authentication/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/authentication/reset-password-v2',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  // {
  // path: '/pages/miscellaneous/coming-soon',
  //   name: 'misc-coming-soon',
  //   component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/miscellaneous/not-authorized',
  //   name: 'misc-not-authorized',
  //   component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  // },
  // {
  //   path: '/pages/miscellaneous/under-maintenance',
  //   name: 'misc-under-maintenance',
  //   component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/account-setting',
  //   name: 'pages-account-setting',
  //   component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
  //   meta: {
  //     pageTitle: 'Account Settings',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Account Settings',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/pages/profile',
  //   name: 'pages-profile',
  //   component: () => import('@/views/pages/profile/Profile.vue'),
  //   meta: {
  //     pageTitle: 'Profile',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Profile',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

]

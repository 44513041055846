import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import appData from './app-data'
import loader from './loader'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    appData,
    loader,
    verticalMenu,
  },
  strict: process.env.DEV,
})

import { subject as an } from '@casl/ability'
import Cookies from 'js-cookie'
import * as PostService from '../../services/PostService'
import * as DataService from '../../services/DataService'

import nfldata from '../../config/sports/pro/nfl'
import nhldata from '../../config/sports/pro/nhl'
import nbadata from '../../config/sports/pro/nba'
import mlbdata from '../../config/sports/pro/mlb'
import soccerdata from '../../config/sports/pro/soccer'

function getRandom(arr, n) {
  let count = n
  const result = new Array(count)
  let len = arr.length
  const taken = new Array(len)
  if (count > len) {
    throw new RangeError('getRandom: more elements taken than available')
  }
  while (count > 0) {
    count -= 1
    const x = Math.floor(Math.random() * len)
    result[count] = arr[x in taken ? taken[x] : x]
    /* eslint-disable-next-line no-plusplus */
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

const setPeopleToStore = () => {
  // axios
  //   .get('../config/sports/people/people.json')
  //   .then(response => {
  //     this.commit('SET_PEOPLE', response.data)
  //   })
  // console.log('------------------- set people to store----------------')
  // fs.readFile('../config/sports/people/people.json', 'utf8', (err, jsonString) => {
  //   if (err) {
  //     console.log('File read failed:', err)
  //     return
  //   }
  //   const data = JSON.parse(jsonString)
  //   this.commit('SET_PEOPLE', data)
  // })
}

// ============================================================================
// Default state
// ============================================================================
const getDefaultState = () => {
  const result = {
    // postsForCurrentPage: {},
    currentSportPage: {
      sport: '',
      page: 1,
    },

    cache: {
      headline: { 1: { posts: [], count: 0, time: 0 } },
      favorite: { 1: { posts: [], count: 0, time: 0 } },

      top: { 1: { posts: [], count: 0, time: 0 }, 2: { posts: [], count: 0, time: 0 } },
      nhl: { 1: { posts: [], count: 0, time: 0 } },
      nfl: { 1: { posts: [], count: 0, time: 0 } },
      nba: { 1: { posts: [], count: 0, time: 0 } },
      mlb: { 1: { posts: [], count: 0, time: 0 } },
      soccer: { 1: { posts: [], count: 0, time: 0 } },
      tweets: { 1: { posts: [], count: 0, time: 0 } },
    },

    favorites: [],
    favoriteTeams: Cookies.get('favoriteTeams') || '',

    people: [],
    peopleSource: '',
    search: [],
    wordcloud: [],
    games: {
      nhl: [],
      nfl: [],
      nba: [],
      mlb: [],
      soccer: [],
    },
    standings: {
      nhl: {},
      nfl: {},
      nba: {},
      mlb: {},
      soccer: {},
    },

    sports: {
      nfl: nfldata,
      nhl: nhldata,
      nba: nbadata,
      mlb: mlbdata,
      soccer: soccerdata,
    },

    error: '',
  }

  setPeopleToStore()

  return result
}

// ============================================================================
// initial state
// ============================================================================
const initstate = getDefaultState()

// ============================================================================
// Getters
// ============================================================================
const getters = {
  getFavoriteTeams: state => state.favoriteTeams,

  getFavoriteTeamsList: (state, getterref) => {
    const lst = []
    const str = state.favoriteTeams

    if (str.length > 0) {
      const favorites = str.split(';')

      if (favorites.length > 0) {
        favorites.forEach(s => {
          const i = s.indexOf(':')
          const splits = [s.slice(0, i), s.slice(i + 1)]

          const sport = splits[0]
          const path = splits[1]
          let name = ''

          const teamData = getterref.findTeamByPath(sport, path)
          if (Object.keys(teamData).length > 0) {
            name = teamData.name
          }

          lst.push({
            title: `${sport} / ${path}`,
            route: `team-${sport}-${path}`,
            sport,
            path,
            name,
          })
        })
      }
    }
    return lst
  },

  getPostsFromCache: state => (sport, page) => {
    if (sport === undefined || sport === '' || page === undefined || page <= 0) {
      return {
        posts: [],
        count: 0,
        time: 0,
      }
    }

    const pageStr = page.toString()

    if (!(sport in state.cache)) {
      state.cache[sport] = {
        pageStr: {
          posts: [],
          count: 0,
          time: 0,
        },
      }
    }

    return state.cache[sport][pageStr]
  },

  getPostsForSearch: state => {
    if (!(state.search)) {
      state.search = []
    }
    return {
      posts: state.search,
      count: state.search.length,
      time: 0,
    }
  },

  getPostsForCurrentPage: (state, getterref) => {
    // if (!(state.postsForCurrentPage.posts)) {
    //   state.postsForCurrentPage = {
    //     ...state.postsForCurrentPage,
    //     posts: [],
    //     count: 0,
    //     time: 0,
    //   }
    // }
    // return state.postsForCurrentPage
    const { sport, page } = state.currentSportPage
    return getterref.getPostsFromCache(sport, page)
  },

  // getPostsBySport: state => (sport, page) => {
  //   // TODO: console.log('\n\n\n THIS IS NOT WORKING - THERE IS NO state.posts !!!!!!!!!!!!!! But this is used by Sport.vue which is a used component\n\n\n')
  //   if (!(sport in state.posts)) {
  //     // state.posts[sport] = { posts: [], count: 0, time: 0 }
  //     state.posts = { ...state.posts, [sport]: { posts: [], count: 0, time: 0 } }
  //   }
  //   return {
  //     // posts: state.posts[sport].posts.slice((page - 1) * 5, (page - 1) * 5 + 5),
  //     posts: state.posts[sport].posts.slice((page - 1) * 5, (page - 1) * 5 + 5),
  //     count: state.posts[sport].count,
  //     time: state.posts[sport].time
  //   }
  // },

  getCache: state => state.cache,
  getError: state => state.error,
  getFavorites: state => state.favorites,
  getKeyValue: state => value => state.cache[value].posts,
  getPeople: state => state.people,
  getWordCloud: state => state.wordcloud,
  getGames: state => sport => state.games[sport],
  getStandings: state => sport => state.standings[sport],

  // TBD - Team is not even used except in the console.log - so why is it here?
  getRandomPostsForSportTeam: state => sport => {
    if (sport !== undefined && state.cache !== undefined && sport in state.cache) {
      if (state.cache[sport].length > 1 && state.cache[sport]['1'].count > 6) {
        const results = getRandom(state.cache[sport]['1'].posts, 6)
        return results
      }
    }

    return []
  },

  getSportData: state => sport => {
    const { sports } = state
    if (!(sport.value in sports)) {
      return {}
    }

    const results = sports[sport.value]
    // Return only the main elements of the sport's data - exclude the 'divisions'
    // since that is extra data we don't need. In order to do this we have to make a
    // new dictionary, because we cannot delete the 'divisions' from the original
    // dictionary because it will be deleted for all.
    return {
      title: results.title,
      category: results.category,
      path: results.path,
      route: results.route,
      keywords: results.keywords,
      images: results.images,
    }
  },

  getImageForSport: state => sport => {
    const { sports } = state
    if (!(sport.value in sports)) {
      return ''
    }
    // Return the first and only image since getRandom() returns an array/list.
    return getRandom(sports[sport.value].images, 1)[0]
  },

  getTeamsForSport: state => sport => {
    const { sports } = state
    if (!(sport.value in sports)) {
      return []
    }
    const first = Object.keys(sports[sport.value].divisions)[0]
    return sports[sport.value].divisions[first].teams
  },

  findTeam: state => (sport, team) => {
    // Get all of the sports from the VUEX store.
    const { sports } = state

    // Check if the sport provided actually exists in the dictionary.
    if (sport === undefined || !(sport in sports)) {
      return {}
    }

    // Loop over the divisions in the sport and the teams in the division
    // looking for the team name that matches the one passed in. If a team
    // is found, short circuit out of for-loop and return it. Do not use the
    // forEach() loop as that requires an exception to be raised to force a
    // break from the loop.
    const { divisions } = sports[sport]
    const keys = Object.keys(divisions)
    for (let i = 0; i < keys.length; i += 1) {
      const { teams } = divisions[keys[i]]
      // Loop over the teams to find matching name
      for (let j = 0; j < teams.length; j += 1) {
        if (team.toLowerCase() === teams[j].name.toLowerCase()) {
          return teams[j]
        }
      }
    }

    // Nothing found, so return empty dictionary
    return {}
  },

  findTeamByPath: state => (sport, path) => {
    // Get all of the sports from the VUEX store.
    const { sports } = state

    // Check if the sport provided actually exists in the dictionary.
    if (sport === undefined || !(sport in sports)) {
      return {}
    }

    // Loop over the divisions in the sport and the teams in the division
    // looking for the team name that matches the one passed in. If a team
    // is found, short circuit out of for-loop and return it. Do not use the
    // forEach() loop as that requires an exception to be raised to force a
    // break from the loop.
    const { divisions } = sports[sport]
    const keys = Object.keys(divisions)
    for (let i = 0; i < keys.length; i += 1) {
      const { teams } = divisions[keys[i]]
      // Loop over the teams to find matching name
      for (let j = 0; j < teams.length; j += 1) {
        if (path.toLowerCase() === teams[j].path.toLowerCase()) {
          return teams[j]
        }
      }
    }

    // Nothing found, so return empty dictionary
    return {}
  },

  getTeamFromSports: (state, getterref) => (sport, team) => {
    // if (team === undefined || team === '') {
    //   return {}
    // }
    const { sports } = state

    let teamData
    const BreakException = {}
    try {
      if (sport in sports) {
        // Object.keys(sports).forEach(sport => {
        const { divisions } = sports[sport]
        Object.keys(divisions).forEach(division => {
          const { teams } = divisions[division]
          for (let i = 0; i < teams.length; i += 1) {
            // TBD: Shouldn't this be checking for the .lower() version of team names?
            if (team === teams[i].name) {
              teamData = teams[i]
              break
            }
          }

          if (teamData !== undefined) {
            throw BreakException
          }
        })

        if (teamData !== undefined) {
          throw BreakException
        }
        // })
      }
    } catch (e) {
      // Add the sport to the team data because it doesn't come with it by default.
      teamData.sport = sport

      // If teamdata has 'rivals', then the rival names already exist, so for each name find the
      // corresponding rival logo path and ad it to the 'rivalsdata' array
      const { rivals } = teamData

      if (rivals !== undefined) {
        teamData.rivalsdata = []

        rivals.forEach(rival => {
          const rivalteam = getterref.findTeam(sport, rival)
          if (Object.keys(rivalteam).length > 0) {
          // if (rivalteam !== undefined) {
            teamData.rivalsdata.push({
              name: rivalteam.name,
              path: `/${sport}/${rivalteam.path}`,
              logo: rivalteam.logo,
            })
          }
          // Object.keys(divisions).forEach(division => {
          //   const { teams } = divisions[division]
          //   for (let i = 0; i < teams.length; i += 1) {
          //     // TBD: Shouldn't this be checking for the .lower() version of team names?
          //     if (rival === teams[i].name) {
          //       teamData.rivalsdata.push(teams[i].logo)
          //       break
          //     }
          //   }
          // })
        })
      }
    }

    // for (const sport of Object.keys(sports)) {
    //   const { divisions } = sports[sport]
    //   for (const divname of Object.keys(divisions)) {
    //     const { teams } = divisions[divname]
    //     for (let i = 0; i < teams.length; i += 1) {
    //       console.log(teams[i].name)
    //       if (team === teams[i].name) {
    //         teamData = teams[i]
    //         break
    //       }
    //     }
    //   }
    // }

    return teamData
  },

}

// ============================================================================
// Mutations
// ============================================================================
const mutations = {
  SET_FAVORITE_TEAMS(state, val) {
    state.favoriteTeams = val
    Cookies.set('favoriteTeams', val, { expires: 365 })
  },

  SET_PEOPLE: (state, people) => {
    // capitalization is good-practice for vuex-mutations
    state.people = people
  },

  RESET_STATE(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },

  setPostsForCurrentPage(state, payload) {
    state.currentSportPage.sport = payload.category
    state.currentSportPage.page = payload.page

    if (state.cache[payload.category]) {
      state.cache[payload.category][payload.page] = payload.posts
    } else {
      state.cache[payload.category] = {
        [payload.page]: payload.posts,
      }
    }
  },

  setPostsForCategoryAndPage(state, payload) {
    if (state.cache[payload.category]) {
      state.cache[payload.category][payload.page] = payload.posts
    } else {
      state.cache[payload.category] = {
        [payload.page]: payload.posts,
      }
    }
  },

  setPostsForCategoryPage(state, { payload, category, page }) {
    if (state.cache[category]) {
      state.cache[category][page] = payload
    } else {
      state.cache[category] = {
        [page]: payload,
      }
    }
  },

  setWordCloud(state, { wordcloud }) {
    state.wordcloud = wordcloud
  },

  setGames(state, { games, sport }) {
    state.games[sport] = games
  },
  setStandings(state, { standings, sport }) {
    state.standings[sport] = standings
  },

  setPostsForKeyValue(state, payload) {
    state.cache[payload.value] = payload.posts
  },

  setPostsForSearch(state, payload) {
    state.cache.search = payload.posts
  },

  setPeople(state, payload) {
    state.people = payload
  },

  setFavorites(state, payload) {
    state.favorites = payload
  },

  setBackendError(state, payload) {
    if (payload.error) {
      state.error = payload.error
    }
  },
}

// ============================================================================
// Actions
// ============================================================================
const actions = {
  setFavoriteTeams({ commit }, val) {
    commit('SET_FAVORITE_TEAMS', val)
  },

  resetState({ commit }) {
    commit('RESET_STATE')
  },

  updatePosts({ commit }, posts) {
    commit('UPDATE_POSTS', posts)
  },

  clearPosts({ commit }) {
    commit('CLEAR_POSTS')
  },

  // This method and next are the same ... just different naming
  async fetchPostsForSearch(context, { text }) {
    let response = null
    try {
      response = await PostService.getAllPostsWithSearch(text)
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    const data = {
      posts: response.data.tmp.posts.map(item => an('Post', item)),
      count: response.data.tmp.count,
      time: response.data.tmp.time,
    }

    context.commit('setPostsForSearch', { posts: data })
  },

  // This method and next are the same ... just different naming
  async fetchPostsFromDatabaseBySport(context, { sport }) {
    if (context.state.cache[sport]['1'].count > 0) {
      return
    }

    let response = null
    try {
      response = await PostService.getAllPostsFromCategoryAndPage(sport, '1')
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    const data = {
      posts: response.data.tmp.posts.map(item => an('Post', item)),
      count: response.data.tmp.count,
      time: response.data.tmp.time,
    }

    context.commit('setPostsForCategoryAndPage', { posts: data, category: sport, page: '1' })
  },

  async fetchTop(context, { page }) {
    const pageStr = page.toString()
    if (pageStr in context.state.cache.top && context.state.cache.top[pageStr].count > 0) {
      return
    }

    const tmpData = {
      posts: [],
      count: 0,
      page: 0,
      time: 0,
    }
    context.commit('setPostsForCategoryAndPage', { posts: tmpData, category: 'top', page: pageStr })

    let response = null
    try {
      response = await PostService.getTestTop(page)
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    const data = {
      posts: response.data.posts.map(item => an('Post', item)),
      count: response.data.total,
      page: response.data.page,
      time: response.data.time,
    }

    context.commit('setPostsForCategoryAndPage', { posts: data, category: 'top', page: data.page.toString() })
  },

  async fetchWordCloud(context) {
    try {
      const response = await DataService.getWordCloud()
      const wordcloud = response.data.data.data
      context.commit('setWordCloud', { wordcloud })
    } catch (error) {
      context.commit('setBackendError', { error })
    }
  },

  async fetchGames(context, { sport }) {
    try {
      const response = await DataService.getGames(sport)
      const { games, standings } = response.data.data
      context.commit('setGames', { games, sport })
      context.commit('setStandings', { standings, sport })
    } catch (error) {
      context.commit('setBackendError', { error })
    }
  },

  async fetchPosts(context, { sport, page, status }) {
    const pageStr = page.toString()
    if (pageStr in context.state.cache[sport] && context.state.cache[sport][pageStr].count > 0) {
      return
    }

    let payload = {
      posts: [],
      count: 0,
      page: 0,
      time: 0,
    }
    context.commit('setPostsForCategoryPage', { payload, category: sport, page: pageStr })
    let response = null
    try {
      // sport = '', text = '', limit = 0, page = 0, sort = '', type = '', status = ''
      response = await PostService.getPosts('', '', '', page, '', '', status)

      payload = {
        posts: response.data.posts.map(item => an('Post', item)),
        total: response.data.total,
        count: response.data.count,
        page: response.data.page,
        pageCount: response.data.pageCount,
        limit: response.data.limit,
        time: response.data.time,
      }

      context.commit('setPostsForCategoryPage', { payload, category: sport, page: payload.page.toString() })
    } catch (error) {
      context.commit('setBackendError', { error })
    }
  },

  async fetchPostsForCategoryPage(context, { category, page }) {
    const pageStr = page.toString()
    if (pageStr in context.state.cache[category] && context.state.cache[category][pageStr].count > 0) {
      return
    }

    let data = {
      posts: [],
      count: 0,
      page: 0,
      time: 0,
    }
    context.commit('setPostsForCategoryPage', { data, category, page: pageStr })
    let response = null
    try {
      response = await PostService.getPostsForCategoryPage(category, page)
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    // const data = {
    //   posts: response.data.posts.map(item => an('Post', item)),
    //   count: response.data.total,
    //   page: response.data.page,
    //   time: response.data.time,
    // }
    data = {
      posts: response.data.tmp.posts.map(item => an('Post', item)),
      count: response.data.tmp.count,
      page: 1,
      time: response.data.tmp.time,
    }
    context.commit('setPostsForCategoryPage', { data, category, page: data.page.toString() })
  },

  // async fetchTop(context) {
  //   if (context.state.cache.top['1'].count > 0) {
  //     return
  //   }

  //   let response = null
  //   try {
  //     response = await PostService.getTop()
  //   } catch (error) {
  //     context.commit('setBackendError', { error })
  //     return
  //   }

  //   const data = {
  //     posts: response.data.tmp.posts.map(item => an('Post', item)),
  //     count: response.data.tmp.count,
  //     time: response.data.tmp.time,
  //   }

  //   context.commit('setPostsForCategoryAndPage', { posts: data, category: 'top', page: '1' })
  // },

  // Method above is the same - fix naming from 'category' to 'sport' because confusing
  async fetchPostsFromDatabaseForCategory(context, { category, page }) {
    context.dispatch('loader/show', null, { root: true })

    if (context.state.cache[category] && context.state.cache[category][page.toString()]
      && context.state.cache[category][page.toString()].posts.length > 0) {
      context.commit('setPostsForCurrentPage', {
        posts: context.state.cache[category][page.toString()],
        category,
        page,
      })

      context.dispatch('loader/hide', null, { root: true })
      return
    }

    await PostService.getAllPostsFromCategoryAndPage(category, page).then(response => {
      // Have to Map the data using the CASL ability 'an' function to 'Post' items.
      // const tmpposts = {
      //   posts: response.data.tmp.posts.map(item => an('Post', item)),
      //   count: response.data.tmp.count,
      //   time: response.data.tmp.time,
      // }
      const tmpposts = {
        posts: response.data.posts.map(item => an('Post', item)),
        count: response.data.count,
        total: response.data.total,
        time: response.data.time,
        limit: response.data.limit,
      }
      context.commit('setPostsForCurrentPage', {
        posts: tmpposts,
        category,
        page,
      })

      context.dispatch('loader/hide', null, { root: true })
    })
  },

  async fetchPeople(context) {
    if (context.state.people.length > 0) {
      return
    }

    let response = null
    try {
      response = await PostService.getAllPeople()
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    const data = response.data.data.data.map(item => an('Person', item))
    context.commit('setPeople', data)
  },

  async fetchFavoritePosts(context) {
    const favoriteTeamsCookie = Cookies.get('favoriteTeams') || ''
    // const favoriteTeamsVuex = context.getters['appData/getFavoriteTeams']
    const favoriteTeamsVuex = context.state.favoriteTeams

    if (context.state.favorites.length > 0 && favoriteTeamsCookie === favoriteTeamsVuex) {
      return
    }

    // context.dispatch('appData/setFavoriteTeams', favoriteTeamsCookie)
    context.commit('SET_FAVORITE_TEAMS', favoriteTeamsCookie)

    if (favoriteTeamsCookie === '') {
      context.commit('setFavorites', [])
      return
    }

    try {
      const favorites = favoriteTeamsCookie.split(';')
      const favoritePosts = []
      if (favoriteTeamsCookie.length > 0) {
        if (favorites.length > 0) {
          // Reading in Parallel
          // https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop
          await Promise.all(favorites.map(async f => {
            const i = f.indexOf(':')
            const splits = [f.slice(0, i), f.slice(i + 1)]

            if (splits[0] === 'sports') {
              const response = await PostService.getPosts(splits[1], '', 25)
              if (response) {
                const tmpPosts = response.data.posts.map(item => an('Post', item))
                favoritePosts.push(...tmpPosts)
              }
            } else {
              const response = await PostService.getAllPostsFromCategoryWithSearch(splits[0], splits[1], 25)
              if (response) {
                const tmpPosts = response.data.posts.map(item => an('Post', item))
                favoritePosts.push(...tmpPosts)
              }
            }

            // const response = await PostService.getAllPostsFromCategoryWithSearch(splits[0], splits[1], 25)
            // if (response) {
            //   const tmpPosts = response.data.posts.map(item => an('Post', item))
            //   favoritePosts.push(...tmpPosts)
            // }
          }))
        }
      }
      if (favoritePosts.length > 0) {
        // Suffle the posts at the end so they are not in order of
        // favorite team listing
        // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
        const shuffled = favoritePosts
          .map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
        context.commit('setFavorites', shuffled)
      }
    } catch (error) {
      context.commit('setBackendError', { error })
    }
  },

  async fetchTopPosts(context) {
    if (context.state.cache.top['1'].count > 0) {
      return
    }

    let response = null
    try {
      response = await PostService.getTopPosts()
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    const data = {
      posts: response.data.data.data.map(item => an('Post', item)),
      count: response.data.data.data.count,
      time: response.data.data.data.time,
    }

    context.commit('setPostsForCategoryAndPage', { posts: data, category: 'top', page: '1' })
  },

  async fetchPostsForKeyValue(context, { key, value }) {
    if (context.state.cache[value].count > 0) {
      return
    }
    if (key === undefined || value === undefined || key === '' || value === '') {
      return
    }

    let response = null
    try {
      response = await PostService.getPostsForKeyValue(key, value)
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    const data = {
      posts: response.data.data.data.map(item => an('Post', item)),
      count: response.data.data.data.count,
      time: response.data.data.data.time,
    }

    context.commit('setPostsForKeyValue', { value, posts: data })
  },

  async fetchPostsFromDatabase(context) {
    if (context.state.cache.top['1'].count > 0) {
      return
    }

    let response = null

    // try {
    //   const favoriteTeams = Cookies.get('favoriteTeams') || ''
    //   const favorites = favoriteTeams.split(';')
    //   if (favorites.length > 0) {
    //     // Todo - this is only getting the first team because the backend does not have a way to submit multiple
    //     // teams and their categories
    //     const s = favorites[0]
    //     const i = s.indexOf(':')
    //     const splits = [s.slice(0, i), s.slice(i + 1)]
    //     response = await PostService.getAllPostsFromCategoryWithSearch(splits[0], splits[1])
    //   }
    // } catch (error) {
    //   context.commit('setBackendError', { error })
    //   return
    // }
    // if (response) {
    //   const tmp = response.data.data.data.map(item => an('Post', item))
    //   context.commit('setTmp', tmp)
    // }

    try {
      const favoriteTeams = Cookies.get('favoriteTeams') || ''
      const favorites = favoriteTeams.split(';')
      const favoritePosts = []
      if (favoriteTeams.length > 0) {
        if (favorites.length > 0) {
          // Reading in Parallel
          // https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop
          await Promise.all(favorites.map(async f => {
            const i = f.indexOf(':')
            const splits = [f.slice(0, i), f.slice(i + 1)]
            response = await PostService.getAllPostsFromCategoryWithSearch(splits[0], splits[1], 5)

            if (response) {
              const tmpPosts = response.data.data.data.map(item => an('Post', item))
              favoritePosts.push(...tmpPosts)
            }
          }))

          // COde when trying to get multiple awaits that wasn't working
          // // favorites.forEach(f => {
          // for (const f of favorites) {
          //   const i = f.indexOf(':')
          //   const splits = [f.slice(0, i), f.slice(i + 1)]
          //   response = await PostService.getAllPostsFromCategoryWithSearch(splits[0], splits[1], 5)

          //   if (response) {
          //     const tmpPosts = response.data.data.data.map(item => an('Post', item))
          //     favoritePosts.push(...tmpPosts)
          //   }
          // // })
          // }
        }
      }
      if (favoritePosts.length > 0) {
        // Suffle the posts at the end so they are not in order of
        // favorite team listing
        // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
        const shuffled = favoritePosts
          .map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
        context.commit('setFavorites', shuffled)
      }
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    try {
      response = await PostService.getFrontPagePosts()
    } catch (error) {
      context.commit('setBackendError', { error })
      return
    }

    const top = {
      posts: response.data.top.posts.map(item => an('Post', item)),
      count: response.data.top.count,
      time: response.data.top.time,
    }
    const nhl = {
      posts: response.data.nhl.posts.map(item => an('Post', item)),
      count: response.data.nhl.count,
      time: response.data.nhl.time,
    }
    const nfl = {
      posts: response.data.nfl.posts.map(item => an('Post', item)),
      count: response.data.nfl.count,
      time: response.data.nfl.time,
    }
    const nba = {
      posts: response.data.nba.posts.map(item => an('Post', item)),
      count: response.data.nba.count,
      time: response.data.nba.time,
    }
    const mlb = {
      posts: response.data.mlb.posts.map(item => an('Post', item)),
      count: response.data.mlb.count,
      time: response.data.mlb.time,
    }
    const tweets = {
      posts: response.data.tweets.posts.map(item => an('Post', item)),
      count: response.data.tweets.count,
      time: response.data.tweets.time,
    }

    context.commit('setPostsForCategoryAndPage', { posts: top, category: 'top', page: '1' })
    context.commit('setPostsForCategoryAndPage', { posts: nhl, category: 'nhl', page: '1' })
    context.commit('setPostsForCategoryAndPage', { posts: nfl, category: 'nfl', page: '1' })
    context.commit('setPostsForCategoryAndPage', { posts: nba, category: 'nba', page: '1' })
    context.commit('setPostsForCategoryAndPage', { posts: mlb, category: 'mlb', page: '1' })
    context.commit('setPostsForCategoryAndPage', { posts: tweets, category: 'tweets', page: '1' })
  },

}

export default {
  namespaced: true,

  state: initstate,
  getters,
  actions,
  mutations,
}

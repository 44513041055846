import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}

  // Can set IP address/hostname here for backend API reference
  // baseURL: 'https://192.168.0.181:3000/api/v1',
  // baseURL: 'https://deksports.com:3000/api/v1',
  // baseURL: 'https://unfin.com:3000/api/v1',
  baseURL: `${window.location.protocol}//${window.location.hostname}:3000/api/v1`,
})

// Alter defaults after instance has been created
axiosIns.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`

// Temporary definition of 'baseFrontendUrl' for global access via:
//    Vue 2:            this.$baseFrontendUrl
//    Composition API:  context.root.$baseFrontendUrl
// Vue.prototype.$baseFrontendUrl = 'https://98.199.249.33:8080'
// Vue.prototype.$baseFrontendUrl = `${window.location.protocol}//${window.location.hostname}:8080`
// Vue.prototype.$baseFrontendUrl = `${window.location.protocol}//${window.location.hostname}:8080`

// Vue.prototype.$baseFrontendUrl = 'https://home-5005019842.app-ionos.space'

Vue.prototype.$baseFrontendUrl = `${window.location.protocol}//${window.location.hostname}`
Vue.prototype.$baseFrontendUrlWithPort = `${window.location.protocol}//${window.location.hostname}:3000`

Vue.prototype.$http = axiosIns

export default axiosIns

import american from './ncaa/american'
import acc from './ncaa/acc'

/* eslint-disable array-bracket-spacing */
/* eslint-disable comma-dangle */
export default {
  title: 'NCAA',
  category: 'ncaa',
  path: '/ncaa',
  route: 'sports-ncaa',
  size: '30px',
  style: 'padding: 5px 5px;',
  keywords: [],
  iconSource: 'image',
  images: [
    'http://d279m997dpfwgl.cloudfront.net/wp/2016/11/AP_16308115675097-1000x667.jpg',
    'https://cdn.shopify.com/s/files/1/0749/2955/products/afraidgoats_grande.jpg?v=1476125341',
    'https://i.pinimg.com/originals/cf/dd/25/cfdd2580283f0734fb1c9b5d4cac1836.jpg',
    'https://i.pinimg.com/originals/76/02/07/7602079cc28ccfa76826d908262c9b9e.jpg'
  ],
  logo: 'https://content.sportslogos.net/logos/4/490/thumbs/49042272019.gif',
  createTeamRoutes: true,
  createTeamNavRoutes: false,
  divisions: {
    american,
    acc,
  }
}

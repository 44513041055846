// import processSportJsonToObj from '../utils'
//
// const nfl = processSportJsonToObj(
//   require('./nfl.json'),
//   require('@/assets/images/icons/sports/football.svg'),
// )
//
// const mlb = processSportJsonToObj(
//   require('./mlb.json'),
//   require('@/assets/images/icons/sports/baseball.svg'),
// )
//
// const nhl = processSportJsonToObj(
//   require('./nhl.json'),
//   require('@/assets/images/icons/sports/hockey.svg'),
// )
//
// const nba = processSportJsonToObj(
//   require('./nba.json'),
//   require('@/assets/images/icons/sports/basketball.svg'),
// )
//
// const soccer = processSportJsonToObj(
//   require('./soccer.json'),
//   require('@/assets/images/icons/sports/soccer.svg'),
// )

import nfl from './nfl'
import mlb from './mlb'
import nhl from './nhl'
import nba from './nba'
import soccer from './soccer'
import ncaa from './ncaa'
import tennis from '../other/tennis'
import golf from '../other/golf'

nfl.icon = require('@/assets/images/icons/sports/football.svg')
mlb.icon = require('@/assets/images/icons/sports/baseball.svg')
nhl.icon = require('@/assets/images/icons/sports/hockey.svg')
nba.icon = require('@/assets/images/icons/sports/basketball.svg')
soccer.icon = require('@/assets/images/icons/sports/soccer.png')
ncaa.icon = require('@/assets/images/icons/sports/college.png')
tennis.icon = require('@/assets/images/icons/sports/tennis.png')
golf.icon = require('@/assets/images/icons/sports/golf.png')

// Dictionary of teams - the order will determine the order in the navigation menu.
const prosports = {
  nfl,
  mlb,
  nhl,
  nba,
  soccer,
  ncaa,
  tennis,
  golf,
}

export default prosports

import axiosIns from '@/libs/axios'

export function getAllPosts() {
  return axiosIns.get('/posts?sort=-pubDate')
}

export function getAllPostsWithSearch(text, page) {
  return axiosIns.get(`/posts?sort=-pubDate&search=${text}&page=${page}`)
}

export function getPostsWithSearch(text, limit = 0) {
  let limitStr = ''
  if (limit > 0) {
    limitStr = `&limit=${limit}`
  }
  return axiosIns.get(`/posts?sort=-pubDate&search=${text}${limitStr}`)
}

export function getAllPostsFromCategory(category, limit = 0) {
  let limitStr = ''
  if (limit > 0) {
    limitStr = `&limit=${limit}`
  }
  return axiosIns.get(`/posts?sort=-rating&categories=${category}${limitStr}`)
}

export function getAllPostsFromCategoryAndPage(category, page) {
  if (category === 'top') {
    // return axiosIns.get('/posts/topwithcount')
    return axiosIns.get('/posts?sort=-pubDate&categories=top&page=1')
  }
  return axiosIns.get(`/posts?sort=-pubDate&categories=${category}&page=${page}`)
}

export function getTestTop(page = 0) {
  let pageStr = ''
  if (page > 0) {
    pageStr = `?page=${page}`
  }
  return axiosIns.get(`/posts/testtop${pageStr}`)
}

export function getPostsForCategoryPage(category, page = 0) {
  let pageStr = ''
  if (page > 0) {
    pageStr = `&page=${page}`
  }
  return axiosIns.get(`/posts?sort=-pubDate&categories=${category}${pageStr}`)
}

export function getTop() {
  return axiosIns.get('/posts?sort=-pubDate')
}

export function getPostsForKeyValue(key, value) {
  return axiosIns.get(`/posts?sort=-pubDate&${key}=${value}`)
}

// TBD: Rename - this is not returning ALL posts if the limit is non-zero, so better to not name
// it this way. Better to call it:   getPostsFromCategoryWithSearch()  or something way better
export function getAllPostsFromCategoryWithSearch(category, text, limit = 0, page = 0) {
  let limitStr = ''
  if (limit > 0) {
    limitStr = `&limit=${limit}`
  }
  let pageStr = ''
  if (page > 0) {
    pageStr = `&page=${page}`
  }
  if (category === 'sports') {
    return axiosIns.get(`/posts?sort=-pubDate&categories=${category}&search=${text}${limitStr}${pageStr}`)
  }
  return axiosIns.get(`/posts?sort=-pubDate&categories=${category}&search=${text}${limitStr}${pageStr}`)
}

export function getPostsByTeams(sport, away, home) {
  return axiosIns.get(`/posts/teams/${sport}/${away}/${home}`)
}

/**
 * Return Posts from a sport and limited by a optional text search, or count limit, or
 * page number. A sort option is also included to provide the results sorted - with the
 * default sort being the published date in reverse (newest first).
 *
 * Currently the backend forces pagination and will return page '1' of the results limited
 * to 50 posts, unless a manual limit value is entered. The backend has a maximum query limit
 * defined as well - currently that is 250 results per page returned.
 *
 * @param {*} sport
 * @param {*} text
 * @param {*} limit
 * @param {*} page
 * @param {*} sort
 * @returns
 */
export function getPosts(sport = '', text = '', limit = 0, page = 0, sort = '', type = '', status = '') {
  let sep = '?'

  let sportStr = ''
  if (sport !== '') {
    sportStr = `${sep}sport=${sport}`
    sep = '&'
  }

  let textStr = ''
  if (text !== '') {
    textStr = `${sep}search=${text}`
    sep = '&'
  }

  let limitStr = ''
  if (limit > 0) {
    limitStr = `${sep}limit=${limit}`
    sep = '&'
  }

  let pageStr = ''
  if (page > 0) {
    pageStr = `${sep}page=${page}`
    sep = '&'
  }

  let sortStr = `${sep}sort=-pubDate`
  if (sort !== '') {
    sortStr = `${sep}sort=${sort}`
    sep = '&'
  }

  let typeStr = ''
  if (type !== '') {
    typeStr = `${sep}postType=${type}`
    sep = '&'
  }

  let statusStr = ''
  if (status !== '') {
    statusStr = `${sep}postStatus=${status}`
    sep = '&'
  }

  return axiosIns.get(`/posts${sportStr}${textStr}${limitStr}${pageStr}${sortStr}${typeStr}${statusStr}`)
}

export function getTopPosts() {
  return axiosIns.get('/posts/top')
}

export function getFrontPagePosts() {
  return axiosIns.get('/posts/frontpage')
}

export function getPostBySlug(slug) {
  return axiosIns.get(`/posts/slug/${slug}`)
}

export function getPostById(id) {
  return axiosIns.get(`/posts/${id}`)
}

export function go(sport, team, slug) {
  let link = '/go'
  if (sport !== undefined && sport !== '') {
    link += `/${sport}`
  }
  if (team !== undefined && team !== '') {
    link += `/${team}`
  }
  link += `/${slug}`
  return axiosIns.post(link)
}

export function getPostByIdWithLock(id) {
  return axiosIns.patch(`/posts/${id}/edit`)
}

export function updatePost(post) {
  return axiosIns.patch(`/posts/${post.id}`, post)
}

export function deletePost(id) {
  return axiosIns.delete(`/posts/${id}`)
}

export function getAllPeople() {
  return axiosIns.get('/people?sort=sport')
}

export function likePost(id) {
  return axiosIns.patch(`/posts/${id}/like`)
}

export function unlikePost(id) {
  return axiosIns.patch(`/posts/${id}/unlike`)
}

import Vue from 'vue'
import VueMeta from 'vue-meta'

// import Ads from 'vue-google-adsense'
// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ImageFallBack from '@/libs/directive'
import {
  Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay,
} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// Meta
Vue.use(VueMeta)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Swiper
SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))

Vue.config.productionTip = false

// Directive for Image Fallback
// Usage: Inside the IMG component simply add this call:
//    v-image-fall-back
// Example:
//    <b-card-img
//      v-image-fall-back
//      :src="imageUrl"
//    />
Vue.directive('image-fall-back', ImageFallBack)

// Ads - Google Adsense Plugin
require('@/plugins/vue-google-adsense')

// Vue.use(require('vue-script2'))

// Vue.use(Ads.Adsense)
// Vue.use(Ads.InArticleAdsense)
// Vue.use(Ads.InFeedAdsense)
// Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-8596108207777733', isNewAdsCode: true })

// Global Filters
//   TBD: Replace in VUE 3 with this:
//   https://ipocs.co/article/how-to-register-and-use-global-properties-in-vuejs-3
Vue.prototype.$filters = {
  limitTo: (value, size) => {
    if (!value || !size) return ''
    if (value.length < size) {
      return value
    }
    return `${value.substring(0, size)} ...`
  },
}

//
// Vue.js with Webpack
//
// If you use vue cli with the Webpack template (default config), you can create and
// add your environment variables to a .env file.
//
// The variables will automatically be accessible under process.env.variableName in
// your project. Loaded variables are also available to all vue-cli-service commands,
// plugins and dependencies.
//
// Fetch the VUE_APP_CONFIG defined in the .env file in the root folder.
//   .env                # loaded in all cases
//   .env.local          # loaded in all cases, ignored by git
//   .env.[mode]         # only loaded in specified mode
//   .env.[mode].local   # only loaded in specified mode, ignored by git
//
//   VUE_APP_MY_ENV_VARIABLE=value
//   VUE_APP_ANOTHER_VARIABLE=value
//
//  If you are using Vue cli 3, only variables that start with VUE_APP_ will be loaded.
//
// Source: https://cli.vuejs.org/guide/mode-and-env.html
//
fetch(`${process.env.VUE_APP_CONFIG}`)
  .then(response => response.json())
  .then(config => {
    Vue.prototype.$config = config

    new Vue({
      router,
      store,

      // Initialize the VUEX store with a request for 'people' data at creation.
      created() {
        this.$store.dispatch('appData/fetchPeople')
      },
      render: h => h(App),
    }).$mount('#app')
  })

// new Vue({
//   router,
//   store,
//   // Initialize the VUEX store with a request for 'people' data at creation.
//   created() {
//     this.$store.dispatch('appData/fetchPeople')
//   },
//   render: h => h(App),
// }).$mount('#app')

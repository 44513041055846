/* eslint-disable array-bracket-spacing */
/* eslint-disable comma-dangle */
export default {
  title: 'TENNIS',
  category: 'tennis',
  path: '/tennis',
  route: 'sports-tennis',
  size: '30px',
  style: 'padding: 5px 5px;',
  keywords: [ 'singles', 'doubles'],
  iconSource: 'image',
  images: [
    'https://www.gobet.com.au/wp-content/uploads/2020/12/ncaaf-college-football-picks.jpg',
    'https://cdn.mybookie.ag/wp-content/uploads/NCAAF-Top-Week-5-Matches-to-Must-Watch-and-Bet-On.jpg'
  ],
  logo: 'https://content.sportslogos.net/logos/7/1007/thumbs/dwuw5lojnwsj12vfe0hfa6z47.gif',
  createTeamRoutes: false,
  createTeamNavRoutes: false,
  divisions: {
    // xyxy: {
    //   title: 'XYXY',
    //   icon: 'https://content.sportslogos.net/logos/60/2079/thumbs/f3he8tlrxtuo4klgchxhtuihr.gif',
    //   iconSource: 'image',
    //   teams: [
    //     {
    //       name: 'XXX',
    //       path: 'xxx',
    //       location: 'xxx',
    //       logo: 'https://content.sportslogos.net/logos/130/4016/thumbs/hy5fvvdkee83gg3r5ym22zr5o.gif',
    //       youtube: [],
    //       nicknames: [ 'xxx', 'yyy' ],
    //       keywords: [],
    //       colors: [ '#8C2633', '#E2D6B5' ],
    //       images: [
    //         'https://content.sportslogos.net/logos/130/4016/thumbs/hy5fvvdkee83gg3r5ym22zr5o.gif'
    //       ]
    //     }
    //   ]
    // }
  }
}

/* eslint no-param-reassign: ["error", { "props": false }] */

/* eslint-disable global-require */
const fallBackImageArray = [
  require('@/assets/images/logo/dek_sports_01.png'),
  require('@/assets/images/logo/logo.png'),
  require('@/assets/images/logo/slide-background.png'),
  require('@/assets/images/logo/vuexy-logo.png'),
]

export default {
  bind(el, binding) {
    try {
      const { value } = binding
      const loader = require('@/assets/images/logo/loading-soccer.gif')

      // const fallBackImage = require('@/assets/images/logo/dek_sports_01.png')
      const randomId = Math.floor(Math.random() * fallBackImageArray.length)
      const fallBackImage = fallBackImageArray[randomId]

      const img = new Image()
      let loading = loader
      let error = fallBackImage
      const original = el.src

      if (typeof value === 'string') {
        loading = value
        error = value
      }
      if (value instanceof Object) {
        loading = value.imageLoader || loader
        error = value.fallBackImage || fallBackImage
      }
      img.src = original
      el.src = loading
      img.onload = () => {
        el.src = original
      }
      img.onerror = () => {
        el.src = error
      }
    } catch (e) {
      // TODO - put this random image code to offset removing the console.log(), so not sure if this is what
      // I need to do or something else.
      // console.log(e)
      const randomId = Math.floor(Math.random() * fallBackImageArray.length)
      const fallBackImage = fallBackImageArray[randomId]
      el.src = fallBackImage
    }
  },
}

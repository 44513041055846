/* eslint-disable array-bracket-spacing */
/* eslint-disable comma-dangle */
export default {
  title: 'NHL',
  category: 'nhl',
  path: '/nhl',
  route: 'sports-nhl',
  size: '30px',
  style: 'padding: 5px 10px;',
  iconSource: 'image',
  images: [
    'https://www.si.com/.image/t_share/MTY4MTkxMzU5Mjc5NDQxODIx/chicago-blackhawks-ice-crew-girls-164040515_729_blues_at_blackhawksjpg.jpg',
    'https://i.pinimg.com/236x/48/6a/1e/486a1ee172f23bc3b4a35d8cfeb23eff--ice-girls-cheerleader.jpg',
    'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MTkxMzU5NTQ2MzA0NDEz/chicago-blackhawks-ice-crew-girls-164021315_603_devils_at_blackhawksjpg.jpg',
    'https://i.pinimg.com/originals/ec/22/46/ec2246d18297faecdaa38b1302a964f2.jpg'
  ],
  logo: 'https://content.sportslogos.net/leagues/thumbs/1.gif',
  createTeamRoutes: true,
  createTeamNavRoutes: true,
  divisions: {
    eastern: {
      title: 'Eastern',
      icon: 'https://upload.wikimedia.org/wikipedia/en/thumb/1/16/NHL_Eastern_Conference.svg/300px-NHL_Eastern_Conference.svg.png',
      iconSource: 'image',
      teams: [
        {
          name: 'Coyotes',
          code: 'ARI',
          path: 'coyotes',
          location: 'arizona',
          logo: 'https://content.sportslogos.net/logos/1/5263/thumbs/526378072015.gif',
          youtube: [ 'https://www.youtube.com/c/ArizonaCoyotes' ],
          nicknames: [ 'coyotes', 'desert dogs', 'dogs', 'yotes', 'bizs wagon' ],
          keywords: [],
          colors: [ '#8C2633', '#E2D6B5' ],
          rivals: [ 'Blackhawks', 'Avalanche', 'Stars' ],
          images: [
            'https://content.sportslogos.net/logos/1/5263/thumbs/526378072015.gif',
            'https://cms.nhl.bamgrid.com/images/photos/307389486/3936x1837/cut.jpg',
            'https://i.pinimg.com/736x/ba/0c/f4/ba0cf41cd1d1c72208fdf2195af66ba4.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/300447744/1024x576/cut.jpg',
            'https://www.si.com/.image/t_share/MTY4MTk2MTkyNDU3NTk4ODc3/phoenix-coyotes-paw-patrol-ice-girljpg.jpg',
            'https://i.pinimg.com/originals/ad/9f/02/ad9f0223599080487f398774ed8fb119.jpg',
            'https://i.pinimg.com/736x/0a/ec/4e/0aec4e2ece6aa2803c6ab04f3f96c5f8--ice-girls-sports-women.jpg',
            'https://cdn.prohockeyrumors.com/files/2019/08/arizona-coyotes-1024x683.jpg',
            'https://www.reviewjournal.com/wp-content/uploads/2019/12/13133034_web1_Coyotes-Hockey.jpg',
            'https://live.staticflickr.com/1940/45347746101_d3900b44d2_b.jpg',
            'https://live.staticflickr.com/1940/45347746101_d3900b44d2_b.jpg',
            'https://arizonadailyindependent.com/wp-content/uploads/2021/01/hockey-678x381.jpg',
            'https://cdn.vox-cdn.com/thumbor/Ny9-gWBxhLe_2AFW4C0JIx7Y-k0=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22881708/1341162585.jpg',
            'https://www.si.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY4MDA4MTc1OTcwMTY2MTQ0/coyotes-nick-schmaltz-contractjpg.jpg',
            'https://pcdn.columbian.com/wp-content/uploads/2022/02/AP22041178038907-scaled-1226x0-c-default.jpg',
            'https://arizonasports.com/wp-content/uploads/2017/12/Wedgewood-620x370.jpg',
            'https://seatgeek.com/images/performers-landscape/arizona-coyotes-ac2427/2146/1100x1900.jpg',
            'https://sportsnaut.com/wp-content/uploads/2022/03/17519502-748x420.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335175348/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335175254/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/308875920/1024x576/cut.jpg',
            'https://cdn.shopify.com/s/files/1/0279/0234/5304/products/1900-00493-002_NHL_Clayton_Keller_RESIZED_prod-all.jpg?v=1602391702&width=1946',
            'https://arizonasports.com/wp-content/uploads/2021/02/hayden-1.jpg'
          ]
        },
        {
          name: 'Blackhawks',
          code: 'CHI',
          path: 'blackhawks',
          location: 'chicago',
          logo: 'https://content.sportslogos.net/logos/1/7/thumbs/56.gif',
          youtube: [ 'https://www.youtube.com/c/blackhawks' ],
          nicknames: [ 'blackhawks', 'b hawks', 'blackbirds', 'hawks' ],
          keywords: [],
          colors: [ '#CF0A2C', '#000000' ],
          rivals: [ 'Coyotes', 'Avalanche', 'Stars' ],
          images: [
            'https://content.sportslogos.net/logos/1/7/thumbs/56.gif',
            'https://i.pinimg.com/originals/74/49/cf/7449cf91469b94ad57dc9516db9bc31e.png',
            'https://i.pinimg.com/originals/36/56/6a/36566a948fb30c9e7de7c73b9d5f3027.jpg',
            'https://www.si.com/.image/t_share/MTY4MTkxMzU5Mjc5NDQxODIx/chicago-blackhawks-ice-crew-girls-164040515_729_blues_at_blackhawksjpg.jpg',
            'https://i.pinimg.com/236x/48/6a/1e/486a1ee172f23bc3b4a35d8cfeb23eff--ice-girls-cheerleader.jpg',
            'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MTkxMzU5NTQ2MzA0NDEz/chicago-blackhawks-ice-crew-girls-164021315_603_devils_at_blackhawksjpg.jpg',
            'https://i.pinimg.com/originals/ec/22/46/ec2246d18297faecdaa38b1302a964f2.jpg',
            'https://www.si.com/.image/t_share/MTY4MTcyMTEzNTMwMDA0NzUz/chicago-blackhawks-ice-crew-girls-164120815_586_preds_at_blhksjpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MTcyMTEzNTI5NTQ2MDAx/chicago-blackhawks-ice-crew-girls-164022516_226_preds_at_blackhawksjpg.jpg',
            'https://pophockeyculture.files.wordpress.com/2012/03/blackhawks-ice-crew-2012109.jpg',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBzPz2z.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1275&y=422',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AAnM6Zk.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1228&y=541',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AAmiCrB.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1260&y=546',
            'https://cdn.vox-cdn.com/thumbor/E_MAHtIrNzqL2Xr5jQTPY9W3zMU=/14x0:585x381/1200x800/filters:focal(14x0:585x381)/cdn.vox-cdn.com/uploads/chorus_image/image/9121087/gyi0063939227.0.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284326438/1024x576/cut.jpg',
            'https://imgix.ranker.com/user_node_img/89/1763125/original/patrick-kane-photo-u13?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://cdn.shopify.com/s/files/1/0664/2387/files/blackhawks-zamboni.jpg?682655422851175412'
          ]
        },
        {
          name: 'Avalanche',
          code: 'COL',
          path: 'avalanche',
          location: 'colorado',
          logo: 'https://content.sportslogos.net/logos/1/8/thumbs/64.gif',
          youtube: [ 'https://www.youtube.com/c/ColoradoAvalanche' ],
          nicknames: [ 'avalanche', 'avs', 'lanches', 'snowy a' ],
          keywords: [],
          colors: [ '#6F263D', '#236192' ],
          rivals: [ 'Coyotes', 'Blackhawks', 'Stars' ],
          images: [
            'https://content.sportslogos.net/logos/1/8/thumbs/64.gif',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBztBdB.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1354&y=438',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AAomUYP.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1522&y=241',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AAo8izI.img?h=1080&w=1920&m=6&q=60&o=f&l=f',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AAlRqJW.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=826&y=453',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480069.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326162366/2000x900/cut.png',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477492.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478420.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476455.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334795414/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334745704/568x320/cut.jpg'
          ]
        },
        {
          name: 'Stars',
          code: 'DAL',
          path: 'stars',
          location: 'dallas',
          logo: 'https://content.sportslogos.net/logos/1/10/thumbs/1079172014.gif',
          youtube: [ 'https://www.youtube.com/c/dallasstars' ],
          nicknames: [ 'stars', 'sheriffs', 'southern stars', 'yellow stars' ],
          keywords: [],
          colors: [ '#006847', '#8F8F8C' ],
          rivals: [ 'Coyotes', 'Blackhawks', 'Avalanche' ],
          images: [
            'https://content.sportslogos.net/logos/1/10/thumbs/1079172014.gif',
            'https://www.si.com/.image/t_share/MTY4MTkzOTg0MzA5NzY2MDE3/dallas-stars-ice-girls-cey141118105_hurricanes_at_starsjpg.jpg',
            'https://64.media.tumblr.com/b47ef5473ce660ad53a7ec9c9a178cda/tumblr_n2jjlbcjtC1r0wff2o1_640.jpg',
            'https://pbs.twimg.com/media/DxTrx6IU0AAkEHm?format=jpg&name=large',
            'https://s6458.pcdn.co/wp-content/uploads/2014/06/Dallas-Stars-Ice-Girl-Jade.jpg',
            'https://s6458.pcdn.co/wp-content/uploads/2014/06/Jade-of-the-Dallas-Stars-Ice-Girls.jpg',
            'https://prodancecheer.com/wp-content/uploads/2018/10/Happy-Birthday-Samantha-12-17-18-2.jpg',
            'https://prodancecheer.com/wp-content/uploads/2018/12/DSIG-Stars-vs-Oilers-12-3-18-3.jpg',
            'https://s6458.pcdn.co/wp-content/uploads/2014/06/Dallas-Stars-Ice-Girl-Ashley.jpg',
            'https://i.pinimg.com/originals/0e/f2/5c/0ef25cebd77dcd1ae7e0b47f030535c4.jpg',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBzPrAS.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1009&y=515',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBzyGyk.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=852&y=630',
            'https://www.si.com/.image/t_share/MTY4MTU2MzYzNjE1ODM5NjE2/dallas-stars-ice-girls-cey1604091175_predators_at_starsjpg.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284860154/1024x576/cut.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1390210170_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1390210171_slide.jpg'
          ]
        },
        {
          name: 'Wild',
          code: 'MIN',
          path: 'wild',
          location: 'minnesota',
          logo: 'https://content.sportslogos.net/logos/1/15/thumbs/1588102014.gif',
          youtube: [ 'https://www.youtube.com/c/mnwild' ],
          nicknames: [ 'wild', 'brisket bonanza', 'minny mild', 'manbearpigs' ],
          keywords: [],
          colors: [ '#154734', '#DDCBA4' ],
          rivals: [ 'Predators', 'Blues', 'Jets' ],
          images: [
            'https://content.sportslogos.net/logos/1/15/thumbs/1588102014.gif',
            'https://i.pinimg.com/originals/bc/2a/54/bc2a5416758871880b913efcd156f990.jpg',
            'https://foxsportsnorth1.files.wordpress.com/2014/10/dscn3162.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335194564/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478864.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODYwMDg3MDU3/minnesota-wild-fans-470973564jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODYwNjc2ODgx/minnesota-wild-fans-472264518jpg.webp',
            'https://cms.nhl.bamgrid.com/images/photos/326766330/1024x768/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326880770/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/319437476/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334984028/960x540/cut.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/03/1238738644_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/03/1372817081_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/03/1372817096_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8470594.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475692.jpg',
            'https://cms.nhl.bamgrid.com/images/arena/default/30.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477451.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478493.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474716.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476856.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fgonepuckwild.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1064635916.jpeg',
            'https://nhl.nbcsports.com/wp-content/uploads/sites/13/2012/07/wild-fans.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/290745488/1024x576/cut.jpg'
          ]
        },
        {
          name: 'Predators',
          code: 'NSH',
          path: 'predators',
          location: 'nashville',
          logo: 'https://content.sportslogos.net/logos/1/17/thumbs/lvchw3qfsun2e7oc02kh2zxb6.gif',
          youtube: [ 'https://www.youtube.com/c/NashvillePredatorsNHL' ],
          nicknames: [
            'predators',
            'mustard cats',
            'preds',
            'smashville',
            'dorktown central'
          ],
          keywords: [],
          colors: [ '#FFB81C', '#041E42' ],
          rivals: [ 'Wild', 'Blues', 'Jets' ],
          images: [
            'https://content.sportslogos.net/logos/1/17/thumbs/lvchw3qfsun2e7oc02kh2zxb6.gif',
            'https://hockeyfanland.com/wp-content/uploads/2015/04/preds-ice-girl2.jpg',
            'https://predlines.com/files/2012/12/6224300.jpg',
            'https://www.si.com/.image/t_share/MTY4MjYzMDU5NDY1NTEyMTAx/nashville-predators.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474600.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334689792/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334567474/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334496144/568x320/cut.jpg',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/641/hi-res-6f8a22f7208317c0e1eacad022111e96_crop_exact.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477424.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334689792/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334640544/568x320/cut.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODYyMzgwODE3/nashville-predators-fans-470135278jpg.webp',
            'https://townsquare.media/site/204/files/2017/06/Nicole-Keith.jpg?w=980&q=75',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fpredlines.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1097137700.jpeg',
            'https://www.gannett-cdn.com/presto/2018/09/26/PNAS/06ec33d3-3b45-4b99-8997-b253ae8631ff-Preds-Car-092518-018.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335027232/813x542/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335028992/811x542/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335028244/362x542/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335028928/359x542/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476887.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475168.jpg',
            'https://cms.nhl.bamgrid.com/images/arena/default/18.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475793.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8481577.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333521620/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326626020/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/329195204/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326625530/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/329195216/binary-file/raw.jpg'
          ]
        },
        {
          name: 'Blues',
          code: 'STL',
          path: 'blues',
          location: 'st. louis',
          logo: 'https://content.sportslogos.net/logos/1/25/thumbs/187.gif',
          youtube: [ 'https://www.youtube.com/c/StLouisBlues' ],
          nicknames: [ 'blues', 'bluenotes', 'notes' ],
          keywords: [],
          colors: [ '#002F87', '#FCB514' ],
          rivals: [ 'Wild', 'Predators', 'Jets' ],
          images: [
            'https://content.sportslogos.net/logos/1/25/thumbs/187.gif',
            'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MTk2MTkyMTkwMDE1Mzg5/st-louis-blues-blue-crew-ice-girl2jpg.jpg',
            'https://external-preview.redd.it/2u4ZgQTSxBYEBYWHZTBd11Wiyy0IENIqnBLIyqNGAZ4.jpg?width=640&crop=smart&auto=webp&s=973c0d6c472de3e5f06b6a8b3ecf86f5c2fd397d',
            'https://i.pinimg.com/564x/16/0f/7d/160f7db150f286e1e745d1415855caed.jpg',
            'http://www.elliottharris.com/wp-content/uploads/2014/04/blue_crew.jpg',
            'https://www.ballysports.com/images/image/private/t_16-9_1920/prod/mu7a1rz3mpm0trlt9tzx.jpg',
            'https://64.media.tumblr.com/fa9bc5f5b20e3edea3147b630ec963ba/tumblr_mvi5o1kSqe1r0wff2o1_1280.jpg',
            'https://cdn.vox-cdn.com/thumbor/1deg0-bWUuqPWEK1grBbCAByObk=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/9951531/Sharpe.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326874192/1280x1024/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/322609100/1280x1024/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/322354080/2668x2134/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/307941684/raw.jpg',
            'https://pbs.twimg.com/media/EFFqKGOX4AIaCdb.jpg',
            'http://www.elliottharris.com/wp-content/uploads/2014/04/blue_crew.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475765.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477402.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476792.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480023.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479385.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475170.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475158.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476412.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475753.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477964.jpg',
            'https://fox2now.com/wp-content/uploads/sites/14/2019/06/stanley-cup-parade_272.jpg?w=900',
            'https://blog.thelineup.com/hs-fs/hubfs/St%20Louis%20Blue%20Crew%20with%20flag.jpg?width=960&name=St%20Louis%20Blue%20Crew%20with%20flag.jpg'
          ]
        },
        {
          name: 'Jets',
          code: 'WPG',
          path: 'jets',
          location: 'winnipeg',
          logo: 'https://content.sportslogos.net/logos/1/3050/thumbs/z9qyy9xqoxfjn0njxgzoy2rwk.gif',
          youtube: [ 'https://www.youtube.com/user/NHLJets' ],
          nicknames: [ 'jets', 'airforce', 'white out crowd' ],
          keywords: [],
          colors: [ '#041E42', '#AC162C' ],
          rivals: [ 'Wild', 'Predators', 'Blues' ],
          images: [
            'https://content.sportslogos.net/logos/1/3050/thumbs/z9qyy9xqoxfjn0njxgzoy2rwk.gif',
            'https://cms.nhl.bamgrid.com/images/photos/335205148/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478398.jpg',
            'https://imgix.ranker.com/user_node_img/4135/82695195/original/brendan-lemieux-photo-u1?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://imgix.ranker.com/user_node_img/4368/87349210/original/87349210-photo-u1574411923?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'http://www.edumckaytion.com/images/gawking.jpg',
            'https://globalnews.ca/wp-content/uploads/2018/04/gettyimages-951396638.jpg?w=2048',
            'https://www.sportsnet.ca/wp-content/uploads/2018/04/1704050871_5767910151001_5767888909001-vs.jpg',
            'http://images.dailyhive.com/20180413124310/winnipeg-jets-whiteout-fans.png',
            'https://globalnews.ca/wp-content/uploads/2018/04/whiteout-1.jpg?quality=85&strip=all&w=1200',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODYwNzQyNDE3/tampa-bay-lightning-fans-473086716jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODY1MTMzMzI5/winnipeg-jets-fans-470480258jpg.webp',
            'https://cms.nhl.bamgrid.com/images/photos/300395376/1280x800/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/317053302/1080x1920/cut.jpeg',
            'https://cms.nhl.bamgrid.com/images/photos/317052642/1080x1920/cut.jpeg',
            'https://cms.nhl.bamgrid.com/images/photos/317052646/1080x1920/cut.jpeg',
            'https://cms.nhl.bamgrid.com/images/photos/317052464/1080x1920/cut.jpeg',
            'https://cms.nhl.bamgrid.com/images/photos/290855258/640x360/cut.jpeg',
            'https://cms.nhl.bamgrid.com/images/photos/288640818/640x360/cut.jpg',
            'https://cdn.nhle.com/jets/images/upload/gallery/2022/04/1240288439_slide.jpg',
            'https://cdn.nhle.com/jets/images/upload/gallery/2022/04/1240289349_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476460.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477940.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476945.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8471218.jpg',
            'https://cms.nhl.bamgrid.com/images/arena/default/52.jpg'
          ]
        },
        {
          name: 'Ducks',
          code: 'ANA',
          path: 'ducks',
          location: 'anaheim',
          logo: 'https://content.sportslogos.net/logos/1/1736/thumbs/173616512014.gif',
          youtube: [ 'https://www.youtube.com/c/AnaheimDucksOfficial' ],
          nicknames: [
            'ducks',
            'mallards',
            'mighty ducks',
            'webbed toes',
            'quackers',
            'fowl',
            'dirty birds'
          ],
          keywords: [],
          colors: [ '#B9975B', '#000000' ],
          rivals: [ 'Flames', 'Oilers', 'Kings' ],
          images: [
            'https://content.sportslogos.net/logos/1/1736/thumbs/173616512014.gif',
            'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MjU4ODM1MDk3MDY5NDQx/anaheim-ducks.jpg',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBAFnGa.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1443&y=631',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBztqA6.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1521&y=644',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BByiRA0.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1170&y=663',
            'https://www.si.com/.image/t_share/MTY4MjYzMDYwMDAxNTMxMDQ1/anaheim-ducks.jpg',
            'https://hockeyfanland.com/wp-content/uploads/2015/04/Ice-girl-Ducks-1.jpg',
            'https://mediacdn.cincopa.com/v2/264337/1802!2ODBAYCPiDQRlD/1/PPU2017-9830.jpg',
            'https://www.si.com/.image/t_share/MTY4MTg1NTQ1NTEyNzg5MjY1/ducks-power-player-ice-girl-5061012196017jpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MTg1NTQ1NTA3MjE4NzA1/ducks-power-player-ice-girl-5061012196007jpg.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDU5NDY0MTM1ODQ1/anaheim-ducks.webp',
            'https://imgix.ranker.com/node_img/39/774892/original/corey-perry-hockey-players-photo-1?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://cms.nhl.bamgrid.com/images/actionshots/8481533.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478873.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476434.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/313253148/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/313251552/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/313252184/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/313252196/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/313252716/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/283836352/372x210/cut.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/01/1238010297_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/01/1238010550_slide.jpg'
          ]
        },
        {
          name: 'Flames',
          code: 'CGY',
          path: 'flames',
          location: 'calgary',
          logo: 'https://content.sportslogos.net/logos/1/5/thumbs/548202021.gif',
          youtube: [
            'https://www.youtube.com/c/OfficialCalgaryFlamesHockeyClub'
          ],
          nicknames: [
            'flames',
            'fire smoke',
            'flaming cs',
            'flamers',
            'flaming snot donkeys',
            'c of red'
          ],
          keywords: [],
          colors: [ '#111111', '#F1BE48' ],
          rivals: [ 'Ducks', 'Oilers', 'Kings' ],
          images: [
            'https://content.sportslogos.net/logos/1/5/thumbs/548202021.gif',
            'https://cdn.bleacherreport.net/images_root/slides/photos/000/800/295/109347123_original.jpg?1300678322',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDU5NzM1NDU0ODg1/calgary-flames.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDU5OTk5MzAyODIx/calgary-flames.webp',
            'https://live.staticflickr.com/8246/8661752484_3021c231d1_b.jpg',
            'https://www.si.com/.image/t_share/MTY4MjU5MTQyNzI0MDM2NDgx/calgary-flames.jpg',
            'https://www.imago-images.com/bild/sp/0039189093/w.jpg',
            'https://mark11photography.files.wordpress.com/2011/10/d3x9374.jpg',
            'https://i.pinimg.com/736x/26/19/a1/2619a11d0e3ed6e2ee22b714b31f17ea--ice-girls-hockey-girls.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335188646/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326151970/2400x1876/cut.jpg',
            'https://imgix.ranker.com/user_node_img/3174/63473755/original/63473755-photo-u-170119032?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/643/cfdad028bda31bf9b02abc6ad0adceb3_crop_exact.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODYxOTIyMDY1/calgary-flames-fans-470395818jpg.webp',
            'https://thumb.spokesman.com/8zERMNb86vb_9J9WSIHbWjHB3RM=/1200x0/media.spokesman.com/photos/2010/01/14/ADDITION_Penguins_Flames_Hockey.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334302006/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316732594/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477496.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474593.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477346.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475726.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478397.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478396.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478233.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335174402/960x540/cut.jpg',
            'http://thereflector.ca/wp-content/uploads/2015/05/17301753972_ddd0e6e9b1_o.jpg',
            'https://thehockeywriters.com/wp-content/uploads/2015/09/Flames-Fans-2019.jpg'
          ]
        },
        {
          name: 'Oilers',
          code: 'EDM',
          path: 'oilers',
          location: 'edmonton',
          logo: 'https://content.sportslogos.net/logos/1/12/thumbs/1227282018.gif',
          youtube: [ 'https://www.youtube.com/Oilers' ],
          nicknames: [ 'oilers', 'oily boys', 'coilers', 'oil' ],
          keywords: [],
          colors: [ '#041E42', '#FF4C00' ],
          rivals: [ 'Ducks', 'Flames', 'Kings' ],
          images: [
            'https://content.sportslogos.net/logos/1/12/thumbs/1227282018.gif',
            'https://www.msgnetworks.com/wp-content/uploads/2017/04/GettyImages-667855728.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334333514/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335176068/1024x576/cut.jpg',
            'https://www.langleyadvancetimes.com/wp-content/uploads/2021/10/26777885_web1_20211009231012-61625a184f53ea0b15409910jpeg.jpg',
            'http://res.cloudinary.com/ybmedia/image/upload/c_crop,h_686,w_1222,x_265,y_126/c_fill,f_auto,h_900,q_auto,w_1600/v1/m/1/e/1ea40c5e564f9105faa0bf893e5f87d76145aaea/oilers-evander-kane-permission-talk-other-teams.jpg',
            'https://images.dailyhive.com/20220803170323/yamamoto.jpeg',
            'https://www.terracestandard.com/wp-content/uploads/2022/05/29260363_web1_220526-CPW-Oilers-Flames-mcdavid_1.jpg',
            'https://cdn.vox-cdn.com/thumbor/To5tueerFNlyaOoKK7dWhgcDE4I=/0x0:1583x1055/1200x800/filters:focal(781x201:1033x453)/cdn.vox-cdn.com/uploads/chorus_image/image/70915223/usa_today_18346898.0.jpg',
            'https://oilersnation.com/wp-content/uploads/2022/02/USATSI_17439099-scaled.jpg',
            'https://s.abcnews.com/images/Sports/WireAP_66c69d997ff947a1825c0bd3f7e38c1b_16x9_992.jpg',
            'https://saltwire.imgix.net/2022/3/13/nhl_0017_91179184-20220313jpg?cs=srgb&fit=crop&h=568&w=847&dpr=1&auto=enhance%2Cformat%2Ccompress',
            'https://wtop.com/wp-content/uploads/2022/03/Capitals_Oilers_Hockey_71103-1880x1254.jpg',
            'https://www.twincities.com/wp-content/uploads/2022/05/APTOPIX_Kings_Oilers_Hockey_80097-1.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Foilonwhyte.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2018%2F08%2F18480316-850x560.jpeg',
            'http://thenationnetwork.s3.amazonaws.com/uploads/images/14/e6/article_14e6fef3-e415-4ce3-b7ac-027050539ae1.jpg',
            'https://i.pinimg.com/736x/ac/4f/f2/ac4ff205770f8364c3de1386728ab58e--ice-girls-hockey-girls.jpg',
            'https://img.huffingtonpost.com/asset/5cd675b121000059007575df.jpeg?ops=scalefit_720_noupscale',
            'https://nypost.com/wp-content/uploads/sites/2/2022/05/oilers-flames-1.jpg',
            'https://images.squarespace-cdn.com/content/v1/59ee4da97131a5b48cf2c51b/a227f0e5-2e8f-4bd2-9eba-6a344b2d99f1/header_woodcroft.jpg?format=1000w',
            'https://cms.nhl.bamgrid.com/images/photos/294522142/1024x576/cut.jpg',
            'https://www.si.com/.image/t_share/MTY4MjU4OTgzMjcwNzU0MjA1/-new-york-islanders.jpg',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBAFqcp.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1285&y=433',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDU5OTk5NDk5NDI5/edmonton-oilers.webp',
            'https://cms.nhl.bamgrid.com/images/photos/284860280/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478402.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477934.jpg',
            'https://i.giphy.com/media/t3sZxY5zS5B0z5zMIz/giphy.webp',
            'https://i.giphy.com/media/2sZBUYqJhe6aeXfmeq/giphy.webp',
            'https://i.giphy.com/media/EshbXe7ZRw50Mk83Ee/giphy.webp',
            'https://media1.giphy.com/media/qCVVHK2zWYLwXXsBEd/giphy.gif?cid=ecf05e477x6z1qp5548aqxou1mickhy1dljcrhiop9siien1&rid=giphy.gif&ct=g',
            'https://media4.giphy.com/media/H3z6N7H5ScazBr7R0w/giphy.gif?cid=ecf05e471tnb7qam4qwfop94zy5rex6ev13e12ecp6vjs136&rid=giphy.gif&ct=g',
            'https://media1.giphy.com/media/4PSESi5DLQDMDlq5Mn/giphy.gif?cid=ecf05e472fc5hi6wihx31h35zfso2wt95naquoxofl5jjyse&rid=giphy.gif&ct=g',
            'https://media1.giphy.com/media/YWz3tBUQbRVAyvrmHw/giphy.gif?cid=ecf05e47e9vo5822qdljhw7ptxu064xrghbl5kcszwhs90fw&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Kings',
          code: 'LAK',
          path: 'kings',
          location: 'los angeles',
          logo: 'https://content.sportslogos.net/logos/1/14/thumbs/1422202020.gif',
          youtube: [ 'https://www.youtube.com/c/lakings' ],
          nicknames: [ 'kings', 'crown', 'monarchs', 'rink royalty', 'royalty' ],
          keywords: [],
          colors: [ '#C4CED4', '#000000' ],
          rivals: [ 'Ducks', 'Flames', 'Oilers' ],
          images: [
            'https://content.sportslogos.net/logos/1/14/thumbs/1422202020.gif',
            'https://cardiaccane.com/files/2011/12/kings-Photo-Rob-CurtisIcon-SMI.jpg',
            'https://pbs.twimg.com/media/AugIydcCEAAkyRC.jpg',
            'https://i.pinimg.com/736x/8b/a0/61/8ba061bcc35abb44173fd9890577d3d2--hockey-girls-ice-girls-nhl.jpg',
            'https://i.pinimg.com/originals/ae/03/3a/ae033a365588721c065978fa8e5ae738.jpg',
            'https://www.letsgokings.com/gallery/files/1/img_1790.jpg',
            'https://www.letsgokings.com/gallery/files/1/_mgl2908.jpg',
            'https://www.si.com/.image/t_share/MTY4MTk2MTkyNDU5MjM3Mjc3/los-angeles-kings-ice-crew-girls-269140320_006_capitals_at_kingsjpg.jpg',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BByiIyo.img?h=1080&w=1920&m=6&q=60&o=f&l=f',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AAo8e3V.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1583&y=330',
            'https://lovelace-media.imgix.net/getty/186402971.jpg',
            'https://imgix.ranker.com/user_node_img/45/889228/original/889228-photo-u2086675399?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/642/hi-res-8ae0ed560992c1ed234ff1d404b6716a_crop_exact.jpg',
            'https://hockey1on1.com/wp-content/uploads/2021/08/Wayne-Gretzky-Kings.jpg',
            'https://i0.wp.com/laxsportsnation.com/wp-content/uploads/2020/06/MartyMcSorley.jpg?fit=644%2C427&ssl=1',
            'https://cms.nhl.bamgrid.com/images/photos/320324750/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316772660/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474563.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8471685.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8471734.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478042.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8481560.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333505014/568x320/cut.jpg'
          ]
        },
        {
          name: 'Sharks',
          code: 'SJS',
          path: 'sharks',
          location: 'san jose',
          logo: 'https://content.sportslogos.net/logos/1/26/thumbs/dmo1xf3z4pph27vmg3gf.gif',
          youtube: [ 'https://www.youtube.com/c/sanjosesharks' ],
          nicknames: [
            'sharks',
            'fins',
            'fish',
            'teal team',
            'teal tuna',
            'men of teal'
          ],
          keywords: [],
          colors: [ '#006D75', '#EA7200' ],
          rivals: [ 'Kraken', 'Canucks', 'Golden Knights' ],
          images: [
            'https://content.sportslogos.net/logos/1/26/thumbs/dmo1xf3z4pph27vmg3gf.gif',
            'https://www.mercurynews.com/wp-content/uploads/2016/08/20140713__sharks07132-1.jpg?w=620',
            'https://cdn.abcotvs.com/dip/images/183710_071414-kgo-sharks-ice-girls-social-img.jpg',
            'https://i.pinimg.com/originals/6b/3e/bb/6b3ebb09c825ba87ce6056a4cf24ad84.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284326318/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478414.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333401676/568x320/cut.jpg',
            'https://imgix.ranker.com/node_img/105/2096117/original/steven-stamkos-hockey-players-photo-1?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/640/3aeac46e6a84a5bcc095fe68f10d39e1_crop_exact.jpg',
            'http://images.thepostgame.com/assets/public/Getty-Zamboni-72220129-BP.jpg',
            'https://bladesofteal.com/files/2016/06/nhl-stanley-cup-playoffs-st.-louis-blues-san-jose-sharks.jpg',
            'https://cdn.vox-cdn.com/thumbor/LT0E0Ellz58W0SHr4DGx1Edw1FU=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23268582/1236357480.jpg',
            'https://i.pinimg.com/236x/02/ab/68/02ab686557a0c11cf355d24203ae4bf6--hockey-sport-hockey-teams.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/319536636/600x600/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/319537096/568x568/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/319537336/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/319537308/568x568/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/319537216/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/319538326/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474578.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476881.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474053.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316730064/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316577228/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/315711622/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/307328088/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/306598140/960x540/cut.jpg'
          ]
        },
        {
          name: 'Kraken',
          code: 'SEA',
          path: 'kraken',
          location: 'seattle',
          logo: 'https://content.sportslogos.net/logos/1/6740/thumbs/674079522022.gif',
          youtube: [ 'https://www.youtube.com/c/SeattleKraken' ],
          nicknames: [ 'kraken' ],
          keywords: [],
          colors: [ '#001628', '#99D9D9' ],
          rivals: [ 'Sharks', 'Canucks', 'Golden Knights' ],
          images: [
            'https://content.sportslogos.net/logos/1/6740/thumbs/674079522022.gif',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/634/11eae6bedb5ec03c3d743ee3a09e8171_crop_exact.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/nhl_default_contemporary.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478407.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477955.jpg',
            'https://pbs.twimg.com/media/EdoBnLbU4AU6zUi.jpg',
            'https://cdn.vox-cdn.com/thumbor/FqF0EjUglrYHBN6sjX9YrOuw-xY=/0x0:4000x2667/1200x800/filters:focal(1514x597:2154x1237)/cdn.vox-cdn.com/uploads/chorus_image/image/70721795/1365740241.0.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/327058816/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/327162344/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/317586570/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/317610990/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/317585088/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/317585078/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326068778/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331649774/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331649570/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331649568/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334175156/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335101754/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331390560/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334928600/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333998708/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333993706/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333656934/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333616690/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333476052/960x540/cut.png'
          ]
        },
        {
          name: 'Canucks',
          code: 'VAN',
          path: 'canucks',
          location: 'vancouver',
          logo: 'https://content.sportslogos.net/logos/1/29/thumbs/2920842020.gif',
          youtube: [ 'https://www.youtube.com/c/Canucks1970' ],
          nicknames: [ 'canucks', 'canuckleheads', 'nuckers', 'nucks' ],
          keywords: [],
          colors: [ '#00205B', '#041C2C' ],
          rivals: [ 'Sharks', 'Kraken', 'Golden Knights' ],
          images: [
            'https://content.sportslogos.net/logos/1/29/thumbs/2920842020.gif',
            'https://gunaxin.com/wp-content/uploads/gallery/canucks/sexy_canucks_girls-10.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284326442/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476468.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334978384/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335008876/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334632428/568x320/cut.jpg',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/715/hi-res-37245acba30869ba4e4664616d87302c_crop_exact.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODYyNTExODg5/vancouver-canucks-fans-469853580jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODYyOTA1MTA1/vancouver-canucks-fans-470878174jpg.webp',
            'https://thecanuckway.com/files/2016/12/8520791-nhl-stanley-cup-playoffs-calgary-flames-vancouver-canucks-812x560.jpg',
            'https://www.theglobeandmail.com/resizer/hz6XRsvpCfW__b5A4MrY8sqxjwc=/0x0/filters:quality(80)/cloudfront-us-east-1.images.arcpublishing.com/tgam/PKRFOC6BEBHCDDRR3CAPB4QQ7Y',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/03/1128974745_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/03/1128984654_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/03/1128984714_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/03/1128986057_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/03/1128986107_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/03/1128986151_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/03/1129000327_slide.jpg',
            'https://newsfeed.time.com/wp-content/uploads/sites/9/2011/06/vanfans.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/03/1129000358_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480012.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477967.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480800.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477500.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478444.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8481617.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334570098/568x320/cut.jpg'
          ]
        },
        {
          name: 'Golden Knights',
          code: 'VGK',
          path: 'goldenknights',
          location: 'vegas',
          logo: 'https://content.sportslogos.net/logos/1/6114/thumbs/611426842018.gif',
          youtube: [
            'https://www.youtube.com/channel/UCyUzGmp1kAmlkNUzAqW9xJw'
          ],
          nicknames: [ 'golden knights', 'golden misfits', 'knights' ],
          keywords: [],
          colors: [ '#B4975A', '#000000' ],
          rivals: [ 'Sharks', 'Kraken', 'Canucks' ],
          images: [
            'https://content.sportslogos.net/logos/1/6114/thumbs/611426842018.gif',
            'https://i.imgur.com/iVfZ0Kk.jpg',
            'https://e00-marca.uecdn.es/assets/multimedia/imagenes/2018/03/19/15214403840628_640x0.jpg',
            'https://www.reviewjournal.com/wp-content/uploads/2017/11/9618093_web1_hkn-knights-nov20-17_11192017_rb_025.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/290533010/1024x576/cut.jpg',
            'https://pbs.twimg.com/media/Ejk9AoZWAAAqcdy.jpg',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/716/hi-res-ec0ea715c7f260b1ab74a907156bcadc_crop_exact.jpg',
            'https://media.lasvegassun.com/media/img/photos/2019/04/16/0416_sun_VGKSharks13_t653.JPG?214bc4f9d9bd7c08c7d0f6599bb3328710e01e7b',
            'https://media.okmagazine.com/brand-img/J-3fwrS3I/0x0/2018/06/863489284.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1180769912_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1186372516_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1180769917_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1180770706_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1180770779_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1180771160_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1180789101_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1186372525_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314012386/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/313724876/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314011824/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314011830/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314011974/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314012236/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314012240/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314012128/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314011862/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/314012250/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335197256/960x540/cut.JPG',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478403.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475913.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477447.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474565.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476539.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480313.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475215.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476905.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331712578/568x320/cut.jpg'
          ]
        }
      ]
    },
    western: {
      title: 'Western',
      icon: 'https://upload.wikimedia.org/wikipedia/en/thumb/6/65/NHL_Western_Conference.svg/300px-NHL_Western_Conference.svg.png',
      iconSource: 'image',
      teams: [
        {
          name: 'Bruins',
          code: 'BOS',
          path: 'bruins',
          location: 'boston',
          logo: 'https://content.sportslogos.net/logos/1/3/thumbs/venf9fmhgnsawnxxvehf.gif',
          youtube: [ 'https://www.youtube.com/c/BostonBruinsNHL' ],
          nicknames: [
            'bruins',
            'bs',
            'big bad bruins',
            'black and gold bears',
            'spoked b',
            'spokes',
            'beasts of the east',
            'beaners'
          ],
          keywords: [],
          colors: [ '#000000', '#FFB81C' ],
          rivals: [ 'Sabres', 'Red Wings', 'Panthers' ],
          images: [
            'https://content.sportslogos.net/logos/1/3/thumbs/venf9fmhgnsawnxxvehf.gif',
            'https://www.si.com/.image/t_share/MTY4MTk2MTkyNDU4NTgxOTE3/boston-bruins-ice-girls-147131114072_blue_jackets_at_bruinsjpg.jpg',
            'https://i.imgur.com/wHGG5Vc.jpg',
            'https://i.pinimg.com/originals/06/89/76/068976ed4fa21d7792cc89081d4a37c3.jpg',
            'https://i.pinimg.com/originals/ef/2b/0c/ef2b0cecadfc878cbd8a89013f74efb0.jpg',
            'https://i.redd.it/lh7a8fpidbx81.jpg',
            'https://i.pinimg.com/originals/68/5d/e9/685de9620d8fe80d36e721c0d7647b80.jpg',
            'https://pbs.twimg.com/media/BrZ-mAkCcAA270Z.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDYwMDAyNTc5NjIx/boston-bruins.webp',
            'https://cms.nhl.bamgrid.com/images/photos/284326324/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284860272/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477956.jpg',
            'https://imgix.ranker.com/node_img/89/1762029/original/patrice-bergeron-hockey-players-photo-1?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://imgix.ranker.com/user_node_img/31/619242/original/brad-marchand-photo-u18?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&w=650',
            'https://imgix.ranker.com/user_node_img/80/1587916/original/milan-lucic-photo-u38?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&w=650',
            'https://cms.nhl.bamgrid.com/images/photos/335074452/960x540/cut.png',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1182665728_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1187918478_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1187918729_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1187928536_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1182726623_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/11/1182737503_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8473419.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480280.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479325.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475791.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476854.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476999.jpg',
            'https://i.giphy.com/media/MLXQicVNaeoSY/giphy.webp'
          ]
        },
        {
          name: 'Sabres',
          code: 'BUF',
          path: 'sabres',
          location: 'buffalo',
          logo: 'https://content.sportslogos.net/logos/1/4/thumbs/454932021.gif',
          youtube: [ 'https://www.youtube.com/c/BuffaloSabres' ],
          nicknames: [
            'sabres',
            'katanas',
            'sabes',
            'sabs',
            'slugs',
            'swords',
            'true wagon'
          ],
          keywords: [],
          colors: [ '#002654', '#FCB514' ],
          rivals: [ 'Bruins', 'Red Wings', 'Panthers' ],
          images: [
            'https://content.sportslogos.net/logos/1/4/thumbs/454932021.gif',
            'https://sabrenoise.com/files/2014/04/nashville-predators-ice-girls-nhl-stanley-cup-playoffs-detroit-red-wings-nashville-predators.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fsabrenoise.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F17827871.jpeg',
            'https://cms.nhl.bamgrid.com/images/photos/335114892/2560x1440/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335044664/2560x1440/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334831678/2560x1440/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334614526/2560x1440/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333980718/2560x1440/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/332706380/2560x1440/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/332184932/2560x1440/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/315274784/1080x1921/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335015378/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335011042/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335006880/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335002916/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334954444/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334940446/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334909794/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334839720/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334779698/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334772404/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/319466366/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333918816/960x540/cut.png',
            'https://cdn.vox-cdn.com/thumbor/c1j4Yy3JEtF8p281PVtORGZqMjA=/0x0:3000x2043/1200x800/filters:focal(1172x733:1652x1213)/cdn.vox-cdn.com/uploads/chorus_image/image/54932597/112376588.0.jpg',
            'https://assets.rebelmouse.io/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbWFnZSI6Imh0dHBzOi8vbWVkaWEucmJsLm1zL2ltYWdlP3U9JTJGZmlsZXMlMkYyMDE2JTJGMDMlMkYwOSUyRjYzNTkzMTMyNjIxODYzNjg5OTIwMTUwOTU4NzBfc2FicmVzcy5qcGcmaG89aHR0cHMlM0ElMkYlMkZhejYxNjU3OC52by5tc2VjbmQubmV0JnM9NDU3Jmg9NzIxNDViMzIyZjg0MmNiYzZhZjhlMjMzYTQyODQyZjQ1Y2VmYzQzZGM4Yzk4MGQzNWY0Mjc5ZjM4MzVhOTQ5YyZzaXplPTk4MHgmYz0xODQxMTE4NDI1IiwiZXhwaXJlc19hdCI6MTYzNTUwNjg0MH0.LaeLLgQQ4qQNhK_87XEigu-OSB2iXLyr4krd0SPHzW8/img.jpg',
            'https://townsquare.media/site/10/files/2020/05/GettyImages-575397.jpg?w=980&q=75',
            'https://thehockeywriters.com/wp-content/uploads/2010/04/gperreault1977-340x480.jpg?ezimgfmt=ng:webp/ngcb58',
            'https://popularbio.com/wp-content/uploads/2019/12/Danny-Gare.jpg',
            'https://www.buffalosportshallfame.com/wp-content/uploads/2016/09/Mogilny-2.jpg',
            'https://sabrenoise.com/wp-content/uploads/getty-images/2017/07/97978356.jpeg',
            'https://static.wikia.nocookie.net/icehockey/images/6/6d/Frenchconnectionlogo.png/revision/latest?cb=20100115171012',
            'https://sabrenoise.com/files/2013/08/71787881.jpg',
            'https://cdn.bleacherreport.net/images_root/slides/photos/000/393/242/ray_original.jpg?1284422311',
            'https://cdn.bleacherreport.net/images_root/slides/photos/000/393/245/foligno_original.jpg?1284659210',
            'https://media1.giphy.com/media/l4FGuX7XwLffBYQGQ/giphy.gif?cid=ecf05e47pqgzz7izxwwwajuiwm70pf8lpwfm1nk1dcaxq5ib&rid=giphy.gif&ct=g'
          ]
        },
        {
          name: 'Red Wings',
          code: 'DET',
          path: 'redwings',
          location: 'detroit',
          logo: 'https://content.sportslogos.net/logos/1/11/thumbs/yo3wysbjtagzmwj37tb11u0fh.gif',
          youtube: [ 'https://www.youtube.com/c/OfficialRedWings' ],
          nicknames: [
            'red wings',
            'dead wings',
            'empire',
            'red and white winged wheel',
            'wings'
          ],
          keywords: [],
          colors: [ '#CE1126', '#FFFFFF' ],
          rivals: [ 'Bruins', 'Sabres', 'Panthers' ],
          images: [
            'https://content.sportslogos.net/logos/1/11/thumbs/yo3wysbjtagzmwj37tb11u0fh.gif',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBztBd8.img?h=1080&w=1920&m=6&q=60&o=f&l=f',
            'https://hockeyfanland.com/wp-content/uploads/2015/04/Ice-girl-Red-Wings.jpg',
            'https://hockeyfanland.com/wp-content/uploads/2015/04/Detroit-Red-Wings-Girl.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284322132/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/285951906/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326061330/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/291476630/1024x576/cut.jpg',
            'https://www.gannett-cdn.com/presto/2022/07/14/PDTF/cdb06534-bff4-429c-88d5-14155388185c-07142022_redwingrookiec7_1.jpg?width=660&height=441&fit=crop&format=pjpg&auto=webp',
            'https://s.yimg.com/ny/api/res/1.2/OR.6nvM57_rnlN38lzFqrg--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTM1OQ--/https://s.yimg.com/uu/api/res/1.2/gvs_sjEbqPEXCZ2CvtaSmw--~B/aD0xMTMyO3c9MjAxNjthcHBpZD15dGFjaHlvbg--/https://media.zenfs.com/en/rotoworld.com/bf1a4aaa674eba5b3c7d6dbd2bf5ff87',
            'https://i.imgur.com/iVfZ0Kk.jpg',
            'https://www.gannett-cdn.com/presto/2020/04/26/PDTN/85293d29-b1e5-4ed5-a455-cf2e8d1711ad-wingscover.jpg?crop=6015,3384,x0,y0&width=660&height=372&format=pjpg&auto=webp',
            'https://www.mlive.com/resizer/rZ5RS11am1vMek9u_EIAGiFnurM=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/FY5CZR52IVCDTA4KV7AJTNOEKU.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/301705850/1304x326/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331629254/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331628900/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331628728/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331627808/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/329906684/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/329949080/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/329705618/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/329705598/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/328042270/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316975166/1920x1080/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316975286/1920x1080/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/322343470/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326016050/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326055792/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/326055596/1080x1920/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/327456028/1080x1920/cut.jpg',
            'https://i2.wp.com/thenationnetwork.s3.amazonaws.com/uploads/images/71/69/article_716963b6-ad07-4a16-8d96-ad623b654032.jpg',
            'https://external-preview.redd.it/z5gBE2DmnpEHoOt1kXrJQW2UNGEgmaSxCEI5UF8fmP4.jpg?auto=webp&s=fd6472fbdabbf3ecf2e8f90524e96a8df97e3599',
            'https://meetthematts.com/wp-content/uploads/2016/04/not-this-year-red-wings-cup-best-customized-fan-jerseys.jpg',
            'https://www.si.com/.image/t_share/MTY4MjU3MDcyNTQ3OTY0ODAx/red-wings-fansjpg.jpg'
          ]
        },
        {
          name: 'Panthers',
          code: 'FLA',
          path: 'panthers',
          location: 'florida',
          logo: 'https://content.sportslogos.net/logos/1/13/thumbs/1380782017.gif',
          youtube: [ 'https://www.youtube.com/c/FloridapanthersNHL' ],
          nicknames: [ 'panthers', 'cats', 'paws', 'rink rats' ],
          keywords: [],
          colors: [ '#041E42', '#B9975B' ],
          rivals: [ 'Bruins', 'Sabres', 'Red Wings' ],
          images: [
            'https://content.sportslogos.net/logos/1/13/thumbs/1380782017.gif',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDYwMjY3MjEzOTg5/florida-panthers.webp',
            'https://www.si.com/.image/t_share/MTY4MTk3MDg4MjMwMDU3ODU3/florida-panthers-lady-panthers-ice-girls-161642836jpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MTk3MDg4MjI5OTkyMzIx/florida-panthers-lady-panthers-ice-girl-166110956jpg.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284860148/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477493.jpg',
            'https://imgix.ranker.com/user_node_img/3113/62250818/original/62250818-photo-u1924109981?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://cms.nhl.bamgrid.com/images/photos/325255146/1284x722/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/325255382/1284x722/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/325255140/1284x722/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/315880954/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316502138/640x360/cut.jpg',
            'https://res.cloudinary.com/graham-media-group/image/upload/f_auto/q_auto/c_scale,w_400/v1/media/gmg/TAYGRAOFLVB5LN2FC5P4TI7YZ4.jpg?_a=ATAK9AA0',
            'https://cdn.vox-cdn.com/thumbor/mnErZMNDu-mS0vDNbzrMhEi66zw=/0x0:3900x2610/1200x800/filters:focal(1638x993:2262x1617)/cdn.vox-cdn.com/uploads/chorus_image/image/70818583/1240290641.0.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477932.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333700638/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/332051708/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477933.jpg',
            'https://www.mercurynews.com/wp-content/uploads/2022/01/BNG-L-THORNTON-0128-4.jpg?w=978',
            'https://www.sun-sentinel.com/resizer/Zgqv4MpvOlX4z7vTrfVSuuUIimE=/1200x630/filters:format(jpg):quality(70)/www.trbimg.com/img-53d0143b/turbine/sfl-florida-panthers-drop-cheerleaders-20140723',
            'https://preview.redd.it/2csm9du5fm071.jpg?width=640&crop=smart&auto=webp&s=34a7b8a84e6fd8edd327a440c56ecbe62efe4bbe',
            'https://cms.nhl.bamgrid.com/images/photos/316180058/1024x576/cut.jpg',
            'https://cdn.nhle.com/jets/images/upload/gallery/2022/04/1240009750_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477932.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477933.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475683.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8481519.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477935.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8482113.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477409.jpg',
            'https://i.giphy.com/media/xTiN0PFx6zhGqiuLq8/giphy.webp'
          ]
        },
        {
          name: 'Canadiens',
          code: 'MTL',
          path: 'canadiens',
          location: 'montreal',
          logo: 'https://content.sportslogos.net/logos/1/16/thumbs/124.gif',
          youtube: [ 'https://www.youtube.com/c/canadiensmtl' ],
          nicknames: [
            'canadiens',
            'le bleu blanc rouge',
            'flying frenchmen',
            'les glorieux'
          ],
          keywords: [],
          colors: [ '#AF1E2D', '#192168' ],
          rivals: [ 'Senators', 'Lightning', 'Maple Leafs' ],
          images: [
            'https://content.sportslogos.net/logos/1/16/thumbs/124.gif',
            'https://fansided.com/files/2015/07/nhl-stanley-cup-playoffs-new-york-rangers-montreal-canadiens.jpg',
            'https://www.onesixthwarriors.com/d3/xfmg/thumbnail/434/434201-06f37e646c0606c52d154f6959c9a171.jpg?1635501578',
            'https://cms.nhl.bamgrid.com/images/photos/284865394/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480018.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8481540.jpg',
            'https://cms.nhl.bamgrid.com/images/arena/default/8.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475848.jpg',
            'https://cdn.vox-cdn.com/thumbor/IMD9buB2hWhi-vUDReBN5KrT25Y=/0x0:3000x2000/1200x800/filters:focal(1324x559:1804x1039)/cdn.vox-cdn.com/uploads/chorus_image/image/70962429/1396128530.0.jpg',
            'https://cdn.vox-cdn.com/thumbor/2klYscuAqgHBGf7dU66cb_br9qE=/0x0:5000x3334/1200x800/filters:focal(1362x617:2162x1417)/cdn.vox-cdn.com/uploads/chorus_image/image/71207051/1241847299.0.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316626972/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316627622/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316626774/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/312401650/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/282861066/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/282861202/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335192772/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335169784/960x540/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/335162622/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335129770/960x540/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/335073846/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335051652/960x540/cut.jpg',
            'https://images.dailyhive.com/20210628124835/montreal-canadiens-fans.jpeg',
            'https://www.theglobeandmail.com/resizer/NC-Bvgp40r85DnS3xOn_Iq_xNKY=/1200x826/filters:quality(80)/cloudfront-us-east-1.images.arcpublishing.com/tgam/TKDGL7VXO5MLTENOZTEWVLPA6Y.jpg',
            'http://allhabs.net/wp-content/uploads/2018/01/9890368Habs-fan.jpg',
            'https://images.squarespace-cdn.com/content/v1/5772abc4cd0f6802d9d5e148/1535382885083-6P672CGYFU13Z6MHHEOU/5420ff919f31311bef44cbd072bef0cde81fe269.png?format=2500w',
            'https://www.si.com/.image/t_share/MTY4MTg2MTI1ODU5NzU5Mzc3/montreal-canadiens-fans-471873146jpg.jpg',
            'https://hockeygods.com/system/gallery_images/9870/original.png?1356678767',
            'https://sndp.mediadelivery.fi/img/468/200749651.jpg',
            'https://wallpapers.com/images/high/montreal-canadiens-misa-campo-szl1lfy3eop1w6dl.jpg',
            'https://content.invisioncic.com/Mnhlcanad/gallery_0_3_97.jpg',
            'https://i.guim.co.uk/img/media/19b005c25c9af60df3b1b47f1a6f96b7ea2d66e5/0_7_5269_3162/master/5269.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=ac9488fbe8778608035000348c7ec08c'
          ]
        },
        {
          name: 'Senators',
          code: 'OTT',
          path: 'senators',
          location: 'ottawa',
          logo: 'https://content.sportslogos.net/logos/1/21/thumbs/2111292021.gif',
          youtube: [ 'https://www.youtube.com/c/OttawaSenatorsNHL' ],
          nicknames: [ 'senators', 'sens', 'pesky sens' ],
          keywords: [],
          colors: [ '#C2912C', '#C52032' ],
          rivals: [ 'Canadiens', 'Lightning', 'Maple Leafs' ],
          images: [
            'https://content.sportslogos.net/logos/1/21/thumbs/2111292021.gif',
            'https://cdn.vox-cdn.com/thumbor/0W5YMi_8msPK8pRqHSqGyxIb3iY=/430x223:2699x1685/1200x800/filters:focal(1256x473:1736x953)/cdn.vox-cdn.com/uploads/chorus_image/image/67762725/875871216.0.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480801.jpg',
            'https://cdn.vox-cdn.com/thumbor/2qNGncIyTGR-V3-CO4vtxHlpF-g=/0x0:5760x3840/1200x800/filters:focal(2197x250:3117x1170)/cdn.vox-cdn.com/uploads/chorus_image/image/71007380/1235603884.0.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fsenshot.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F160896923.jpeg',
            'https://thehockeywriters.com/wp-content/uploads/2022/04/Mathieu-Joseph-Senators-1200x798.jpg?ezimgfmt=ng:webp/ngcb58',
            'https://images.thestar.com/eBnFvL4rHOn9SBlx7c6jGIdWwdI=/1280x1024/smart/filters:cb(1659391858481)/https://www.thestar.com/content/dam/thestar/sports/hockey/2022/07/28/senators-mathieu-joseph-agree-to-terms-on-four-year-contract-extension/20220728190736-62e31da8e989c92922b1f235jpeg.jpg',
            'https://www.sportsnet.ca/wp-content/uploads/2022/07/Joseph-1040x572.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2240,w_3200/https%3A%2F%2Fsenshot.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1390666446.jpeg',
            'https://cms.nhl.bamgrid.com/images/photos/320237180/1024x576/cut.jpg',
            'https://thehockeynews.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_xy_center%2Cq_auto:good%2Cw_620%2Cx_950%2Cy_273/MTkwMjQ5MzAxMTg2NzE2ODI1/usatsi_18158676.jpg',
            'https://thehockeynews.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_xy_center%2Cq_auto:good%2Cw_1200%2Cx_1514%2Cy_277/MTg4MzIyNjc1MjI2MDYwMzA3/usatsi_17501221.jpg',
            'https://thehockeynews.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_xy_center%2Cq_auto:good%2Cw_620%2Cx_2221%2Cy_919/MTkwMjY1ODgwMDI4OTE1MDMw/usatsi_18112269.jpg',
            'https://i.cbc.ca/1.4119430.1498250333!/fileImage/httpImage/senators-fans-army-1180.jpg',
            'https://pbs.twimg.com/media/CC_pblHUsAANvZs.jpg',
            'https://static01.nyt.com/images/2014/11/09/sports/senators1/senators1-articleLarge.jpg?quality=75&auto=webp&disable=upscale',
            'https://www.sportsnet.ca/wp-content/uploads/2015/04/sensfans1280.jpg',
            'https://s.yimg.com/os/en/blogs/sptusnhlexperts/630fdsdfsf.jpg',
            'https://cdn.vox-cdn.com/thumbor/OgcGzMmsqxepDMVP0Mf39mm6Iu0=/0x0:4384x2923/1200x800/filters:focal(1735x793:2435x1493)/cdn.vox-cdn.com/uploads/chorus_image/image/63250999/usa_today_12364145.0.jpg',
            'https://facesmag.ca/wp-content/uploads/2020/01/GettyImages-131934061-scaled.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479337.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1NTkzMDI3ODU3/ottawa-senators-fans-470745832jpg.webp',
            'https://cms.nhl.bamgrid.com/images/actionshots/8473512.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480208.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8482116.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478469.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476341.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/329717246/568x320/cut.jpeg',
            'https://cms.nhl.bamgrid.com/images/photos/327299532/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/321727610/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/330637222/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/323645906/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/323606736/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/322046552/568x320/cut.jpg'
          ]
        },
        {
          name: 'Lightning',
          code: 'TBL',
          path: 'lightning',
          location: 'tampa bay',
          logo: 'https://content.sportslogos.net/logos/1/27/thumbs/97hhvk8e5if0riesnex30etgz.gif',
          youtube: [ 'https://www.youtube.com/c/TBLightningNHL' ],
          nicknames: [ 'lightning', 'bolts' ],
          keywords: [],
          colors: [ '#002868', '#FFFFFF' ],
          rivals: [ 'Canadiens', 'Senators', 'Maple Leafs' ],
          images: [
            'https://content.sportslogos.net/logos/1/27/thumbs/97hhvk8e5if0riesnex30etgz.gif',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBxKc4p.img?h=1080&w=1920&m=6&q=60&o=f&l=f',
            'https://www.si.com/.image/t_share/MTY4MTk2MTkyNzI1OTY4Nzk3/tampa-bay-lightning-girljpg.jpg',
            'https://i.pinimg.com/originals/ef/f7/6e/eff76e2e7132c0306e63357379f3d1b8.jpg',
            'https://preview.redd.it/2aba6o7514t51.jpg?auto=webp&s=426fb8f71703d31e68272416850f565d87b5c422',
            'https://i.pinimg.com/474x/bc/43/38/bc4338ba1e250112f637cceacec7c647.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_474/MTY4MjYzMDU5NzI5OTQ5ODYx/tampa-bay-lightning.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_544/MTY4MjYzMDU5NzM1MzIzODEz/tampa-bay-lightning.webp',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476883.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476453.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475167.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474564.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334742740/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335149548/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333901246/960x640/cut.jpg',
            'https://cdn.vox-cdn.com/thumbor/vYheM5OuwZ7QpjLVFIoC1knOVXY=/0x0:2904x1937/1200x800/filters:focal(1149x450:1613x914)/cdn.vox-cdn.com/uploads/chorus_image/image/69555718/1327563283.0.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478010.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479410.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479525.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8473986.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/298706104/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335085994/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334981024/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334824136/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478010.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479410.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479525.jpg'
          ]
        },
        {
          name: 'Maple Leafs',
          code: 'TOR',
          path: 'mapleleafs',
          location: 'toronto',
          logo: 'https://content.sportslogos.net/logos/1/28/thumbs/2887612017.gif',
          youtube: [ 'https://www.youtube.com/c/TorontoMapleLeafs' ],
          nicknames: [
            'maple leafs',
            'bay street bullies',
            'blue bombers',
            'leafs'
          ],
          keywords: [],
          colors: [ '#00205B', '#FFFFFF' ],
          rivals: [ 'Canadiens', 'Senators', 'Lightning' ],
          images: [
            'https://content.sportslogos.net/logos/1/28/thumbs/2887612017.gif',
            'https://1.bp.blogspot.com/-09GosR8iEkU/X_iMsmAQkNI/AAAAAAAABPA/8iMaywNqFoMhEZ2u_1vUhDSHQZGlFP_UwCLcBGAsYHQ/s1000/DLP191212051_CGY_vs_TOR.jpg',
            'https://i.cbc.ca/1.5095060.1561921629!/fileImage/httpImage/image.jpg_gen/derivatives/16x9_620/775097751bw00238-divisional.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Feditorinleaf.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2018%2F08%2F1319627138.jpeg',
            'https://www.si.com/.image/t_share/MTY4MTk2MTkyNDYyMTIwODYx/toronto-maple-leafs-ice-girlsjpg.jpg',
            'https://i.pinimg.com/originals/59/a7/cc/59a7cc12467fbabd04043ec45505a382.jpg',
            'https://i.pinimg.com/736x/98/dc/65/98dc658e46ae3a5b0447aa4bf627326d.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284326428/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479318.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478483.jpg',
            'https://imgix.ranker.com/user_node_img/4269/85377516/original/auston-matthews-u1?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/637/b2e2548c4d67571594c4f82a6bf755a6_crop_exact.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2017/11/876025004_slide.jpg',
            'https://i.ytimg.com/vi/iyFiGuMh3lc/maxresdefault.jpg',
            'https://3.bp.blogspot.com/-M9bbDlpxKBE/TlBv5UwFTKI/AAAAAAAAJYs/4HFqRjJu-aM/s1600/sportsdayDSC_9498.jpg',
            'https://www.capperspicks.com/wp-content/uploads/2016/04/Toronto-Maple-Leafs-NHL-Betting-Predictions.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2017/11/876025038_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2017/11/876043408_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1240262610_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1240262680_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1240262762_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1240336525_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476853.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475166.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477939.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478047.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1239823220_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/01/1238006117_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/01/1238010103_slide.jpg'
          ]
        },
        {
          name: 'Hurricanes',
          code: 'CAR',
          path: 'hurricanes',
          location: 'carolina',
          logo: 'https://content.sportslogos.net/logos/1/6/thumbs/fotih31tn5r345nufo5xxayh3.gif',
          youtube: [ 'https://www.youtube.com/CaniacNation' ],
          nicknames: [ 'hurricanes', 'canes', 'bunch of jerks', 'whale' ],
          keywords: [],
          colors: [ '#CC0000', '#000000' ],
          rivals: [ 'Blue Jackets', 'Devils', 'Islanders' ],
          images: [
            'https://content.sportslogos.net/logos/1/6/thumbs/fotih31tn5r345nufo5xxayh3.gif',
            'https://i.pinimg.com/originals/ac/1f/3b/ac1f3b7b77580a4595ea3216d1cffd31.jpg',
            'https://www.si.com/.image/t_share/MTY4MjU4ODM0ODI1Njg0ODY1/carolina-hurricanes.jpg',
            'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MjYxMzk4MTE0ODcwNTQ5/hurricanes-storm-squad-4035jpg.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_523/MTY4MjYzMDU5NDY1MDUzMzQ5/carolina-hurricanes.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_530/MTY4MjYzMDYwMDAwNjc5MDc3/carolina-hurricanes.webp',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475883.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/317125894/1920x1080/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/332501524/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478427.jpg',
            'https://imgix.ranker.com/user_node_img/4031/80610484/original/80610484-photo-u979411711?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://imgix.ranker.com/user_node_img/4368/87349989/original/87349989-photo-u-538192298?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480830.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8474157.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476882.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8482093.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477293.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476958.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316602584/568x320/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/286652474/568x320/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/310246390/568x320/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/316580576/raw.png',
            'https://cms.nhl.bamgrid.com/images/photos/316579868/raw.png',
            'https://cms.nhl.bamgrid.com/images/photos/327333386/1242x2688/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/327333390/1242x2688/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/327333388/1242x2688/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/327333174/1242x2688/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/316580070/raw.png'
          ]
        },
        {
          name: 'Blue Jackets',
          code: 'CBJ',
          path: 'bluejackets',
          location: 'columbus',
          logo: 'https://content.sportslogos.net/logos/1/9/thumbs/jhepegs329pc7ugyypebl28wg.gif',
          youtube: [ 'https://www.youtube.com/c/BlueJacketsNHL' ],
          nicknames: [
            'blue jackets',
            'cbj',
            'cannons',
            'jackets',
            'bavy blazers'
          ],
          keywords: [],
          colors: [ '#002654', '#CE1126' ],
          rivals: [ 'Hurricanes', 'Devils', 'Islanders' ],
          images: [
            'https://content.sportslogos.net/logos/1/9/thumbs/jhepegs329pc7ugyypebl28wg.gif',
            'https://i.pinimg.com/originals/0d/12/da/0d12dad322c5cf3031f4de25a6d976ba.jpg',
            'https://s6458.pcdn.co/wp-content/uploads/2014/06/Columbus-Blue-Jackets-Ice-Girl-Cyara.jpg',
            'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MjU5OTIzMDYxNzc4MzMz/-columbus-blue-jackets.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_548/MTY4MjYzMDU5OTk4NzEyOTk3/columbus-blue-jackets.webp',
            'https://www.si.com/.image/t_share/MTY4MTkyOTg5MjIyNzQxOTE3/columbus-blue-jackets-ice-crew-girls-ceh141227088_bruins_at_cbjjpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MTkyOTg5MjE4NDE2NTQx/columbus-blue-jackets-ice-crew-girls-ceh150213072_flyers_at_cbjjpg.jpg',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/639/bcba4efefe68ae6f53dafa010bb351e4_crop_exact.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335168882/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479339.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476432.jpg',
            'https://cms.nhl.bamgrid.com/images/arena/default/29.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335146546/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/331741178/960x540/cut.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1177482742_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1177482743_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1177483113_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1177489032_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1177489249_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1177489253_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1177498918_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1182707779_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1182725347_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1182725343_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1182725401_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/10/1182725482_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/306587564/1024x576/cut.jpg',
            'https://cdn.theathletic.com/app/uploads/2020/03/10193146/GettyImages-1188240789-1024x683.jpg',
            'https://media3.giphy.com/media/sc9yYkGDBIN0dWMSSm/giphy.gif?cid=ecf05e47s1zujvvzinx7v9ni2dgu46j6j8f2h5ddmxtof5im&rid=giphy.gif&ct=g',
            'https://i.giphy.com/media/xT0Gqg9ukZ8T0FEGCk/giphy.webp'
          ]
        },
        {
          name: 'Devils',
          code: 'NJD',
          path: 'devils',
          location: 'new jersey',
          logo: 'https://content.sportslogos.net/logos/1/18/thumbs/32tfs723a3bes0p0hb4hgcy1u.gif',
          youtube: [ 'https://www.youtube.com/c/newjerseydevils' ],
          nicknames: [ 'devils', 'devs', 'jerseys team' ],
          keywords: [],
          colors: [ '#CE1126', '#000000' ],
          rivals: [ 'Hurricanes', 'Blue Jackets', 'Islanders' ],
          images: [
            'https://content.sportslogos.net/logos/1/18/thumbs/32tfs723a3bes0p0hb4hgcy1u.gif',
            'https://www.si.com/.image/t_share/MTY4MTk2MTkyNDU3ODYxMDIx/new-jersey-devils-dancerjpg.jpg',
            'https://i.pinimg.com/originals/17/e2/fb/17e2fbb8577b76ee86c26387f8da2722.jpg',
            'https://townsquare.media/site/385/files/2021/10/attachment-GettyImages-888626912.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_736,h_485,c_fill,g_auto,f_auto/https%3A%2F%2Fpucksandpitchforks.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1056894038-850x560.jpeg',
            'https://assets1.cbsnewsstatic.com/hub/i/r/2012/05/31/92e90b72-01ed-478e-8a2b-22ca7b0296ee/thumbnail/1200x630/bd516144bfda89de3439f7d2d43f4abc/rex-ryan1.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_1198,w_1600/http%3A%2F%2Fstarsandsticks.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1130813158.jpeg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/12/1196276395_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/12/1196276421_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/12/1190615066_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/12/1196277002_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/12/1190615182_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2019/12/1196281042_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8481559.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479407.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476462.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480002.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476923.jpg',
            'https://www.totalprosports.com/wp-content/uploads/2017/09/New-Jersey-Devils-Fan-Free-Season-Tickets-for-Retweets.jpg',
            'https://img.bleacherreport.net/img/images/photos/001/619/598/140802166_crop_north.jpg?1332202519&w=3072&h=2048',
            'https://www.nj.com/resizer/0p3soPixVnSIVNbvxzzqQ38T28I=/1280x0/smart/advancelocal-adapter-image-uploads.s3.amazonaws.com/image.nj.com/home/njo-media/width2048/img/devils_main/photo/devils-rangersjpg-37a79ba57f2aeb5b.jpg',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/003/831/194/61ebc74f9bb9493a4d99269debfa2186_crop_exact.jpg'
          ]
        },
        {
          name: 'Islanders',
          code: 'NYI',
          path: 'islanders',
          location: 'new york',
          logo: 'https://content.sportslogos.net/logos/1/19/thumbs/1939112018.gif',
          youtube: [ 'https://www.youtube.com/c/islanderstv' ],
          nicknames: [ 'islanders', 'fishsticks', 'isles' ],
          keywords: [],
          colors: [ '#00539B', '#F47D30' ],
          rivals: [ 'Hurricanes', 'Blue Jackets', 'Devils' ],
          images: [
            'https://content.sportslogos.net/logos/1/19/thumbs/1939112018.gif',
            'https://preview.redd.it/dsujw3gixd241.jpg?auto=webp&s=caeccb283d187750df48edadfa5b4ccfe800c56e',
            'https://meetthematts.com/wp-content/uploads/2013/04/130304175210-new-york-islanders-ice-girls-163055730-10-single-image-cut.jpg',
            'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MjU5NjYzNDg1MjgyMjA1/new-york-islanders.jpg',
            'https://nypost.com/wp-content/uploads/sites/2/2013/10/ice5.jpg?quality=75&strip=all&w=1024',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY4MjYyNzcyNzgwMzc3MjUz/islanders-ice-girl-12904041jpg.jpg',
            'https://www.si.com/.image/c_fit%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_620/MTY4MTg2NDc0ODI3Njg3Mjk2/new-york-islanders-ice-girls-459905494_10jpg.jpg',
            'https://i.pinimg.com/originals/de/bf/bd/debfbd9b3bb6859cd98df128bd246764.jpg',
            'https://www.si.com/.image/t_share/MTY4MjYyNzcyMjQzNjM3NDEz/islanders-ice-girl-13574596jpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MjYyNzcyNzc2OTY5Mzgx/islanders-ice-girl-13426361jpg.jpg',
            'https://nypost.com/wp-content/uploads/sites/2/2013/10/ice10.jpg?quality=75&strip=all&w=1024',
            'https://www.si.com/.image/t_share/MTY4MjU4OTgzNTM5MjU1MTk3/-new-york-islanders.jpg',
            'https://imgix.ranker.com/user_node_img/4269/85377586/original/mathew-barzal-u1?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478009.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333050962/568x320/cut.jpg',
            'https://images.hellomagazine.com/imagenes/celebrities/20220503138182/nhl-fever-celebrity-hockey-fans-cheering-on-their-favourite-teams/0-672-138/gallery_3_5-a.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2020/01/1191821440_slide.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/313695418/568x320/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/314408358/2568x1444/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/319533910/2560x1440/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/312764350/2568x1444/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/312764118/2568x1444/cut.png',
            'http://cms.nhl.bamgrid.com/images/photos/313075276/2568x1444/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/316286090/1980x1140/cut.png',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480865.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478445.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475754.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8473575.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8480789.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475314.jpg'
          ]
        },
        {
          name: 'Rangers',
          code: 'NYR',
          path: 'rangers',
          location: 'new york',
          logo: 'https://content.sportslogos.net/logos/1/20/thumbs/144.gif',
          youtube: [ 'https://www.youtube.com/c/nyrangers' ],
          nicknames: [
            'rangers',
            'blueshirts',
            'broadway blueshirts',
            'broadway bullies',
            'rags'
          ],
          keywords: [],
          colors: [ '#0038A8', '#CE1126' ],
          rivals: [ 'Flyers', 'Penguins', 'Capitals' ],
          images: [
            'https://content.sportslogos.net/logos/1/20/thumbs/144.gif',
            'http://meetthematts.com/wp-content/uploads/2021/02/NY-Rangers-Ice-Girl-Meet-The-Matts-Different-Matt-Brian-Tyler-NHL.jpg',
            'https://pbs.twimg.com/media/EGxrR2YXUAE8vCC.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/284326660/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478048.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8479323.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333997406/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476459.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334247160/568x320/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/334428976/568x320/cut.png',
            'https://cms.nhl.bamgrid.com/images/photos/334463096/568x320/cut.png',
            'https://cms.nhl.bamgrid.com/images/actionshots/8478550.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334112584/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333648050/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475184.jpg',
            'https://imgix.ranker.com/user_node_img/4368/87349206/original/87349206-photo-u375435554?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/636/391f9d01a1e65fc4f6385bf7e2e16938_crop_exact.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/332964058/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334019682/binary-file/raw.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334019562/1080x1920/cut.jpg',
            'https://www.athletespeakers.com/storage/celebrities/1532532981_mark-messier.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476885.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8482109.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335119944/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335064232/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334934500/960x540/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/334686616/960x540/cut.jpg',
            'https://i.giphy.com/media/WskFubuu5vfXFwaPmn/giphy.webp'
          ]
        },
        {
          name: 'Flyers',
          code: 'PHI',
          path: 'flyers',
          location: 'philadelphia',
          logo: 'https://content.sportslogos.net/logos/1/22/thumbs/161.gif',
          youtube: [ 'https://www.youtube.com/c/PhiladelphiaFlyers' ],
          nicknames: [
            'flyers',
            'broad street bullies',
            'fly guys',
            'orange and black'
          ],
          keywords: [],
          colors: [ '#F74902', '#000000' ],
          rivals: [ 'Rangers', 'Penguins', 'Capitals' ],
          images: [
            'https://content.sportslogos.net/logos/1/22/thumbs/161.gif',
            'https://www.si.com/.image/t_share/MTY4MTkwMTM5MjQwODg0MDk3/philadelphia-flyers-ice-girls-458710842_10jpg.jpg',
            'https://insidehockey.com/wp-content/uploads/2014/10/2014-10-28_Kings-vs-Flyers_Img-003.jpg',
            'https://hockeygods.com/system/gallery_images/8749/original.jpg?1345601492',
            'https://www.si.com/.image/t_share/MTY4MTU0ODU0NzQwMjA2OTc2/philadelphia-flyers-ice-girls-gettyimages-507040056_masterjpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MTU0ODU0NzM5OTQ0ODMy/philadelphia-flyers-ice-girls-gettyimages-506568000_masterjpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MjYxOTM2MDYxNzUyNDg1/flyers-ice-girl280329jpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MjYxOTM2ODY2MDc1ODEz/flyers-ice-girl280129jpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MjYxOTM2ODY1NDg1OTg5/flyers-ice-girl281129jpg.jpg',
            'https://i.pinimg.com/originals/85/f0/54/85f054b2abcff62789b199c6b4df173a.jpg',
            'https://i.pinimg.com/originals/f3/87/00/f3870011fa5719f6ae7f9d4f517de225.jpg',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBzPBxt.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1435&y=199',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBztqFF.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1366&y=359',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BByiFZL.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1023&y=474',
            'https://i.pinimg.com/originals/0e/cb/14/0ecb144a46a4a4e606c23bac0a257f0a.jpg',
            'https://www.si.com/.image/t_share/MTY4MTk2MTkyNzI2MDM0MzMz/philadelphia-flyers-ice-girl-184765651_10jpg.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_627/MTY4MjYzMDU5NzM0MTQ0MTY1/philadelphia-flyers.webp',
            'https://imgix.ranker.com/user_node_img/3107/62127188/original/zac-rinaldo-photo-u2?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&w=650',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/633/e1e75fcef483ab300074e4b762cad20b_crop_exact.jpg',
            'https://media.bleacherreport.com/w_800,h_533,c_fill/br-img-slides/004/486/638/hi-res-c16200f8d4f277b9ddbc6fb5d211c9a0_crop_exact.jpg',
            'https://cdn.nhle.com/jets/images/upload/gallery/2022/04/1240289116_slide.jpg',
            'https://cdn.nhle.com/jets/images/upload/gallery/2022/04/1240289080_slide.jpg'
          ]
        },
        {
          name: 'Penguins',
          code: 'PIT',
          path: 'penguins',
          location: 'pittsburgh',
          logo: 'https://content.sportslogos.net/logos/1/24/thumbs/2463622017.gif',
          youtube: [ 'https://www.youtube.com/c/penguins' ],
          nicknames: [ 'penguins', 'pens', 'guins' ],
          keywords: [],
          colors: [ '#000000', '#FCB514' ],
          rivals: [ 'Rangers', 'Flyers', 'Capitals' ],
          images: [
            'https://content.sportslogos.net/logos/1/24/thumbs/2463622017.gif',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BByiIyv.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=1188&y=513',
            'https://cms.nhl.bamgrid.com/images/photos/312650376/1024x576/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/288800164/1024x576/cut.jpg',
            'https://pbs.twimg.com/media/FIH9lkFXMAI_nDA.jpg:large',
            'https://cms.nhl.bamgrid.com/images/photos/333035670/1024x576/cut.jpg',
            'https://cdn.vox-cdn.com/thumbor/P7-vMNp6XXm7r-8G7pPDZicvSPs=/0x0:2000x3000/1400x933/filters:focal(465x591:785x911):no_upscale()/cdn.vox-cdn.com/uploads/chorus_image/image/70214872/1236901681.0.jpg',
            'https://www.si.com/.image/t_share/MTY4MjU5OTIyNzk1NjM2NjM3/-pittsburgh-penguins.jpg',
            'https://www.capperspicks.com/wp-content/uploads/2016/03/Pittsburgh-Penguins-NHL-Betting-Predictions.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDYwMDAxMjAzMzY1/pittsburgh-penguins.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDYwMDAyMjUxOTQx/pittsburgh-penguins.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYzMDU5NDYyMzY2Mzcz/pittsburgh-penguins.webp',
            'https://cms.nhl.bamgrid.com/images/actionshots/8471675.jpg',
            'https://imgix.ranker.com/user_node_img/49/965624/original/965624-photo-u1543176129?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477404.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/333952824/568x320/cut.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8471215.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8471724.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477465.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475810.jpg',
            'https://www.si.com/.image/t_share/MTY4MTk2MTkyNzMwNjIxODUz/pittsburgh-penguins-ice-crew-girljpg.jpg',
            'https://i.pinimg.com/originals/e2/84/e9/e284e9b782007333b34bf2b8000fb0ad.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Ffansided.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F05%2F682764334-ottawa-senators-v-pittsburgh-penguins-game-one.jpg.jpg'
          ]
        },
        {
          name: 'Capitals',
          code: 'WSH',
          path: 'capitals',
          location: 'washington',
          logo: 'https://content.sportslogos.net/logos/1/30/thumbs/llrs2zxi127vkqgcsvfb.gif',
          youtube: [ 'https://www.youtube.com/c/Capitals' ],
          nicknames: [ 'capitals', 'caps', 'red army' ],
          keywords: [],
          colors: [ '#041E42', '#C8102E' ],
          rivals: [ 'Rangers', 'Flyers', 'Penguins' ],
          images: [
            'https://content.sportslogos.net/logos/1/30/thumbs/llrs2zxi127vkqgcsvfb.gif',
            'https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BBzttbg.img?h=1080&w=1920&m=6&q=60&o=f&l=f&x=995&y=753',
            'https://i0.wp.com/russianmachineneverbreaks.com/wp-content/uploads/2019/07/red-rockers.jpg?fit=1200%2C650&ssl=1',
            'https://cms.nhl.bamgrid.com/images/actionshots/8471214.jpg',
            'https://imgix.ranker.com/user_node_img/3402/68031323/original/tom-wilson-photo-u169?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&w=650',
            'https://imgix.ranker.com/user_node_img/22/427061/original/427061-photo-u-1243763014?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&crop=faces&bg=fff&h=333&w=333',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1393646098_slide.jpg',
            'https://cdn.nhle.com/mapleleafs/images/upload/gallery/2022/04/1393646110_slide.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MTI1ODYxMjAxMTY5/washington-capitals-fans-f75f4ea9917e4fc88553b9b563c38d62-0jpg.webp',
            'https://media.wusa9.com/assets/WUSA/images/558783326/558783326_750x422.jpg',
            'https://cms.nhl.bamgrid.com/images/photos/335043588/960x540/cut.png',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475744.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8475311.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8476880.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8471698.jpg',
            'https://cms.nhl.bamgrid.com/images/actionshots/8477511.jpg',
            'https://thehockeynews.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_xy_center%2Cq_auto:good%2Cw_620%2Cx_2202%2Cy_1037/MTkxMzM3Mjc5NzUzNTYxNjk4/usatsi_15871692.jpg',
            'https://imageio.forbes.com/specials-images/dam/imageserve/2830d51baf6a4a26ae6f6dd1bb65f942/960x0.jpg?format=jpg&width=960',
            'https://wtop.com/wp-content/uploads/2020/01/Capitals_Lightning_Hockey_13880.jpg',
            'https://media0.giphy.com/media/1gVl3SMmOAFhWiBrlk/giphy.gif?cid=ecf05e478lwrahyzfsugloldvfh6iuk58lec5va9qkhk6pr1&rid=giphy.gif&ct=g',
            'https://thehockeywriters.com/wp-content/uploads/2015/09/T.J.-Oshie-Capitals-1-1-575x384.jpg?ezimgfmt=ng:webp/ngcb58',
            'https://media1.giphy.com/media/m5tbsa5idSXAY/giphy.gif?cid=ecf05e47q5k5tq5g2sv0ysl1o6att38a39ya08rozo6bcnd1&rid=giphy.gif&ct=g'
          ]
        }
      ]
    }
  }
}

function processSportRoutes(sports) {
  const routes = []

  function createRoutes(category, dict) {
    dict.forEach(team => {
      const route = {
        // path: `/${team.path}`,
        path: `/${category}/${team.name.toLowerCase().replace(/\s/g, '')}`,
        name: `team-${category}-${team.name.toLowerCase().replace(/\s/g, '')}`,
        component: () => import(/* webpackChunkName: "Team" */ '@/views/sports/Team.vue'),
        meta: {
          pageTitle: team.name,
          keywords: team.keywords,
          nicknames: team.nicknames,
          category,
          breadcrumb: [
            {
              text: team.name,
              active: true,
            },
          ],
          resource: 'Public',
          action: 'read',
        },
      }

      routes.push(route)
    })
  }

  Object.keys(sports).forEach(key => {
    const sport = sports[key]
    // Add the primary header for the current sport
    // console.log(`---------------------> ROUTE for sport ${sport.path} ${sport.route}`)
    routes.push(
      {
        path: `${sport.path}`,
        name: `${sport.route}`,
        component: () => import(/* webpackChunkName: "Sport" */ '@/views/sports/Sport.vue'),
        meta: {
          pageTitle: `${sport.title}`,
          breadcrumb: [
            {
              text: `${sport.title}`,
              active: true,
            },
          ],
          resource: 'Public',
          action: 'read',
        },
      },
    )

    /* eslint-disable no-console */
    console.log(`---> Router Route: ${sport.path}, ${sport.route}`)
    /* eslint-enable no-console */

    // Add the divisions from each sport
    if (sport.createTeamRoutes && Object.keys(sport).includes('divisions')) {
      Object.keys(sport.divisions).forEach(subkey => {
        const division = sport.divisions[subkey]
        createRoutes(sport.category, division.teams)
      })
    }
  })

  return routes
}

export default processSportRoutes

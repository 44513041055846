/* eslint-disable array-bracket-spacing */
/* eslint-disable comma-dangle */
export default {
  title: 'Soccer',
  category: 'soccer',
  path: '/soccer',
  route: 'sports-soccer',
  size: '30px',
  style: 'padding: 5px 10px;',
  keywords: [ 'goalie', 'forward' ],
  iconSource: 'image',
  images: [
    'https://content.sportslogos.net/logos/133/4207/thumbs/nfefzmf4ia47uoqxvdemhe1o4.gif',
    'https://static.clubs..com/image/private/t_photo_album/w_800,h_450/nfl100/nltcarj93vl7ybzi7mmu',
    'https://content.sportslogos.net/logos/130/4017/thumbs/yfhezt5oyr0jbq29u4hp50w63.gif',
    'https://content.sportslogos.net/logos/130/4016/thumbs/hy5fvvdkee83gg3r5ym22zr5o.gif'
  ],
  logo: 'https://cdn2.iconfinder.com/data/icons/iconslandsport/PNG/256x256/Soccer_Ball.png',
  createTeamRoutes: true,
  createTeamNavRoutes: false,
  divisions: {
    eufa: {
      title: 'UEFA',
      icon: 'https://content.sportslogos.net/logos/60/2079/thumbs/f3he8tlrxtuo4klgchxhtuihr.gif',
      iconSource: 'image',
      teams: [
        {
          name: 'Barcelona',
          code: 'BAR',
          path: 'barcelona',
          location: 'barcelona',
          logo: 'https://content.sportslogos.net/logos/130/4016/thumbs/hy5fvvdkee83gg3r5ym22zr5o.gif',
          youtube: [],
          nicknames: [ 'barcelona', 'barca' ],
          keywords: [],
          colors: [ '#8C2633', '#E2D6B5' ],
          images: [
            'https://content.sportslogos.net/logos/130/4016/thumbs/hy5fvvdkee83gg3r5ym22zr5o.gif'
          ]
        },
        {
          name: 'Real Madrid',
          code: 'RMD',
          path: 'realmadrid',
          location: 'madrid',
          logo: 'https://content.sportslogos.net/logos/130/4017/thumbs/yfhezt5oyr0jbq29u4hp50w63.gif',
          youtube: [],
          nicknames: [ 'real madrid', 'real' ],
          keywords: [],
          colors: [ '#6F263D', '#236192' ],
          images: [
            'https://content.sportslogos.net/logos/130/4017/thumbs/yfhezt5oyr0jbq29u4hp50w63.gif'
          ]
        },
        {
          name: 'Manchester United',
          code: 'MNU',
          path: 'manchesterunited',
          location: 'greater manchester',
          logo: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/7a/Manchester_United_FC_crest.svg/190px-Manchester_United_FC_crest.svg.png',
          youtube: [],
          nicknames: [ 'machester' ],
          keywords: [],
          colors: [ '#6F263D', '#236192' ],
          images: [
            'https://upload.wikimedia.org/wikipedia/en/thumb/7/7a/Manchester_United_FC_crest.svg/190px-Manchester_United_FC_crest.svg.png'
          ]
        },
        {
          name: 'Bayern Munich',
          code: 'MUN',
          path: 'bayernmunich',
          location: 'munich',
          logo: 'https://content.sportslogos.net/logos/132/4069/thumbs/rr72mhpas38h85jdw85neas5f.gif',
          youtube: [],
          nicknames: [ 'machester' ],
          keywords: [],
          colors: [ '#6F263D', '#236192' ],
          images: [
            'https://content.sportslogos.net/logos/132/4069/thumbs/rr72mhpas38h85jdw85neas5f.gif'
          ]
        },
        {
          name: 'Athletic Bilbao',
          code: 'ABB',
          path: 'atleticobilbao',
          location: 'bilbao'
        },
        {
          name: 'Atletico Madrid',
          code: 'AMD',
          path: 'atleticomadrid',
          location: 'madrid'
        },
        {
          name: 'Celta Vigo',
          code: 'CLV',
          path: 'celtavigo',
          location: 'spain'
        },
        {
          name: 'Deportivo La Coruna',
          code: 'DEP',
          path: 'deportivolacoruna',
          location: 'spain'
        }
      ]
    },
    concacaf: {
      title: 'CONCACAF',
      icon: 'https://content.sportslogos.net/leagues/thumbs/57.gif',
      iconSource: 'image',
      teams: [
        {
          name: 'Sao Paolo',
          code: 'SP',
          path: 'saopaolo',
          location: 'sao paolo',
          logo: 'https://content.sportslogos.net/logos/133/4207/thumbs/nfefzmf4ia47uoqxvdemhe1o4.gif',
          youtube: [],
          nicknames: [ 'sao paolo' ],
          keywords: [],
          colors: [ '#8C2633', '#E2D6B5' ],
          images: [
            'https://content.sportslogos.net/logos/133/4207/thumbs/nfefzmf4ia47uoqxvdemhe1o4.gif'
          ]
        },
        {
          name: 'US',
          code: 'US',
          path: 'us',
          location: 'usa',
          logo: 'https://content.sportslogos.net/logos/57/1565/thumbs/156566972016.gif',
          youtube: [],
          nicknames: [ 'united states', 'team usa' ],
          keywords: [],
          colors: [ '#6F263D', '#236192' ],
          images: [
            'https://content.sportslogos.net/logos/57/1565/thumbs/156566972016.gif'
          ]
        },
        {
          name: 'Mexico',
          code: 'MEX',
          path: 'mexico',
          location: 'mexico',
          logo: 'https://upload.wikimedia.org/wikipedia/en/thumb/6/69/Mexican_football_federation_fmx_logo.png/150px-Mexican_football_federation_fmx_logo.png',
          youtube: [],
          nicknames: [ 'mexico' ],
          keywords: [],
          colors: [ '#6F263D', '#236192' ],
          images: [
            'https://upload.wikimedia.org/wikipedia/en/thumb/6/69/Mexican_football_federation_fmx_logo.png/150px-Mexican_football_federation_fmx_logo.png'
          ]
        },
        {
          name: 'Canada',
          code: 'CAN',
          path: 'canada',
          location: 'canada',
          logo: 'https://upload.wikimedia.org/wikipedia/en/thumb/6/69/Canadian_Soccer_Association_logo.svg/200px-Canadian_Soccer_Association_logo.svg.png',
          youtube: [],
          nicknames: [ 'canada' ],
          keywords: [],
          colors: [ '#6F263D', '#236192' ],
          images: [
            'https://upload.wikimedia.org/wikipedia/en/thumb/6/69/Canadian_Soccer_Association_logo.svg/200px-Canadian_Soccer_Association_logo.svg.png'
          ]
        },
        {
          name: 'Bahamas',
          code: 'BAH',
          path: 'bahamas',
          location: 'bahamas'
        },
        {
          name: 'Jamaica',
          code: 'JAM',
          path: 'jamaica',
          location: 'jamaica'
        }
      ]
    },
    mls: {
      title: 'MLS',
      icon: 'https://content.sportslogos.net/leagues/thumbs/9.gif',
      iconSource: 'image',
      teams: [
        {
          name: 'Atlanta United FC',
          code: 'ATL',
          path: 'atlantaunited',
          location: 'atlanta',
          logo: 'https://content.sportslogos.net/logos/9/5475/thumbs/547573932017.gif',
          youtube: [ 'https://www.youtube.com/c/AtlantaUnited' ],
          nicknames: [ 'atlanta' ],
          keywords: [],
          colors: [ '#FF0000', '#FFFFFF' ],
          images: [
            'https://content.sportslogos.net/logos/9/5475/thumbs/547573932017.gif'
          ]
        },
        {
          name: 'Charlotte FC',
          code: 'CHA',
          path: 'charlotte',
          location: 'charlotte',
          logo: 'https://content.sportslogos.net/logos/9/6739/thumbs/673957692022.gif',
          youtube: [ 'https://www.youtube.com/c/MLSCharlotte' ],
          nicknames: [ 'charlotte' ],
          keywords: [],
          colors: [ '#FF0000', '#FFFFFF' ],
          images: [
            'https://content.sportslogos.net/logos/9/6739/thumbs/673957692022.gif'
          ]
        },
        {
          name: 'Chicago Fire FC',
          code: 'CHI',
          path: 'chicagofire',
          location: 'chicago',
          logo: 'https://content.sportslogos.net/logos/9/323/thumbs/32390352022.gif',
          youtube: [ 'https://www.youtube.com/c/chicagofire' ],
          nicknames: [ 'chicago fire' ],
          keywords: [],
          colors: [ '#FF0000', '#FFFFFF' ],
          images: [
            'https://content.sportslogos.net/logos/9/323/thumbs/32390352022.gif'
          ]
        },
        {
          name: 'Austin FC',
          code: 'AUS',
          path: 'austin',
          location: 'austin',
          logo: 'https://content.sportslogos.net/logos/9/6663/thumbs/666340662021.gif',
          youtube: [ 'https://www.youtube.com/c/AustinFC' ],
          nicknames: [ 'austin' ],
          keywords: [],
          colors: [ '#FF0000', '#FFFFFF' ],
          images: [
            'https://content.sportslogos.net/logos/9/6663/thumbs/666340662021.gif'
          ]
        }
      ]
    }
  }
}

/* eslint-disable array-bracket-spacing */
/* eslint-disable comma-dangle */
export default {
  title: 'MLB',
  category: 'mlb',
  path: '/mlb',
  route: 'sports-mlb',
  size: '30px',
  style: 'padding: 5px 5px;',
  keywords: [ 'pitcher', 'catcher' ],
  iconSource: 'image',
  images: [
    'http://d279m997dpfwgl.cloudfront.net/wp/2016/11/AP_16308115675097-1000x667.jpg',
    'https://cdn.shopify.com/s/files/1/0749/2955/products/afraidgoats_grande.jpg?v=1476125341',
    'https://i.pinimg.com/originals/cf/dd/25/cfdd2580283f0734fb1c9b5d4cac1836.jpg',
    'https://i.pinimg.com/originals/76/02/07/7602079cc28ccfa76826d908262c9b9e.jpg'
  ],
  logo: 'https://content.sportslogos.net/logos/4/490/thumbs/49042272019.gif',
  createTeamRoutes: true,
  createTeamNavRoutes: true,
  divisions: {
    national: {
      title: 'National',
      icon: 'https://content.sportslogos.net/leagues/thumbs/54.gif',
      iconSource: 'image',
      teams: [
        {
          name: 'Cubs',
          code: 'CHI',
          path: 'cubs',
          location: 'chicago',
          logo: 'https://content.sportslogos.net/logos/54/54/thumbs/q9gvs07u72gc9xr3395u6jh68.gif',
          youtube: [ 'https://www.youtube.com/c/cubs' ],
          nicknames: [ 'cubs', 'cubbies', 'baby bears', 'lovable losers' ],
          keywords: [],
          colors: [ '#0E3386', '#CC3433' ],
          rivals: [ 'Reds', 'Brewers', 'Pirates', 'Cardinals' ],
          images: [
            'https://content.sportslogos.net/logos/54/54/thumbs/q9gvs07u72gc9xr3395u6jh68.gif',
            'https://www.bleachernation.com/wp-content/uploads/2022/07/Screen-Shot-2022-07-06-at-5.16.50-PM.jpg',
            'https://a.espncdn.com/photo/2022/0706/r1033217_1296x518_5-2.jpg',
            'https://cubbiescrib.com/wp-content/uploads/getty-images/2018/08/1176680066.jpeg',
            'https://storage.googleapis.com/afs-prod/media/media:655049c8e76b46e69e5b9e9de1f3e208/2716.jpeg',
            'http://d279m997dpfwgl.cloudfront.net/wp/2016/11/AP_16308115675097-1000x667.jpg',
            'https://cdn.shopify.com/s/files/1/0749/2955/products/afraidgoats_grande.jpg?v=1476125341',
            'https://i.pinimg.com/originals/cf/dd/25/cfdd2580283f0734fb1c9b5d4cac1836.jpg',
            'https://i.pinimg.com/originals/76/02/07/7602079cc28ccfa76826d908262c9b9e.jpg',
            'https://pbs.twimg.com/media/BFe3xeVCcAMCs4g.jpg',
            'https://media.gq.com/photos/55d62ed795040f5e14f4defc/master/w_1600%2Cc_limit/MM.jpg',
            'https://media1.popsugar-assets.com/files/thumbor/d0v6GK5UwFIfdaPf_9wC8WNHzHg/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/05/02/834/n/1922398/658d5694fa2755f2_116959282/i/Vince-Vaughn-took-mound-Chicago-Cubs-June-2011.jpg'
          ]
        },
        {
          name: 'Reds',
          code: 'CIN',
          path: 'cincinnati/reds',
          location: 'cincinnati',
          logo: 'https://content.sportslogos.net/logos/54/56/thumbs/z9e0rqit393ojiizsemd0t1hx.gif',
          youtube: [ 'https://www.youtube.com/c/reds' ],
          nicknames: [ 'reds', 'big red machine' ],
          keywords: [],
          colors: [ '#C6011F', '#000000' ],
          rivals: [ 'Cubs', 'Brewers', 'Pirates', 'Cardinals' ],
          images: [
            'https://content.sportslogos.net/logos/54/56/thumbs/z9e0rqit393ojiizsemd0t1hx.gif',
            'https://securea.mlb.com/assets/images/9/4/8/116584948/cuts/RedsFan_gh32iezk_dxa659wq.jpg',
            'https://s.yimg.com/ny/api/res/1.2/852K6YloaPT3NlfADYJLHQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTU5NjtjZj13ZWJw/https://s.yimg.com/os/en/blogs/sptusmlbexperts/GABPpicBLS45.jpg',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Ffansided.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2022%2F05%2F1393073132.jpeg',
            'https://thumbor.bigedition.com/nick-lachey/eTv2UCzYKRLC-KQGv26FL8O7hY4=/800x600/filters:format(webp):quality(80)/granite-web-prod/82/ab/82abbd58c29d4c23903e6ae0d9d900ba.jpeg',
            'https://phantom-marca.unidadeditorial.es/11d526ebd5ffc3fb72276e302021b06b/resize/660/f/webp/assets/multimedia/imagenes/2022/04/12/16497982869109.jpg',
            'https://sportsnaut.com/wp-content/uploads/2022/04/18164077-748x420.jpg',
            'https://kubrick.htvapps.com/htv-prod-media.s3.amazonaws.com/images/ap-20267837429210.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/v1576607766/mlb/vafcibtiplbww6fatrpg.jpg',
            'https://phantom-marca.unidadeditorial.es/9c37e9df67ec71bc5c6afb2e569469ff/resize/660/f/webp/assets/multimedia/imagenes/2022/06/20/16556936369616.jpg',
            'https://pbs.twimg.com/media/Bu3trizIEAAPNiA.jpg',
            'https://i.pinimg.com/originals/5c/4f/c3/5c4fc32896b17842ca240d9bae6d8df7.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTkwNjg3NTI2NjMyMDM5NTQ5/brandon-drury-reds.webp'
          ]
        },
        {
          name: 'Brewers',
          code: 'MIL',
          path: 'brewers',
          location: 'milwaukee',
          logo: 'https://content.sportslogos.net/logos/54/64/thumbs/6464742020.gif',
          youtube: [ 'https://www.youtube.com/c/brewers' ],
          nicknames: [ 'brewers', 'brew crew', 'crew' ],
          keywords: [],
          colors: [ '#FFC52F', '#12284B' ],
          rivals: [ 'Cubs', 'Reds', 'Pirates', 'Cardinals' ],
          images: [
            'https://content.sportslogos.net/logos/54/64/thumbs/6464742020.gif',
            'https://cdn.vox-cdn.com/thumbor/66sCkSgMFFUuocpa488-faXUmwA=/0x0:4931x3282/920x613/filters:focal(1895x750:2683x1538):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/67497361/1052010504.jpg.0.jpg',
            'https://bloximages.newyork1.vip.townnews.com/ivpressonline.com/content/tncms/assets/v3/editorial/6/25/62518004-7be7-5453-a335-dc14c05c7d40/62bfb5fe159e1.image.jpg?resize=750%2C500',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTkwNjg3MzgwMzM0NzE2Nzk2/brandon-woodruff-brewers.webp',
            'https://pbs.twimg.com/media/Cs1tHzZWcAEqdn5.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_2x1/t_w1536/mlb/bxqomlr8xw1er0krawrp.jpg'
          ]
        },
        {
          name: 'Pirates',
          code: 'PIT',
          path: 'pirates',
          location: 'pittsburgh',
          logo: 'https://content.sportslogos.net/logos/54/71/thumbs/7112502014.gif',
          youtube: [ 'https://www.youtube.com/c/Pirates' ],
          nicknames: [ 'pirates', 'bucs' ],
          keywords: [],
          colors: [ '#27251F', '#FDB827' ],
          rivals: [ 'Cubs', 'Reds', 'Brewers', 'Cardinals' ],
          images: [
            'https://content.sportslogos.net/logos/54/71/thumbs/7112502014.gif',
            'https://thumbor.bigedition.com/michael-keaton/M34JJTJZtiRRJWLxeFUAv6WyMDs=/800x600/filters:format(webp):quality(80)/granite-web-prod/25/8a/258acb66f85045368b4564ea79d8b9c0.jpeg',
            'https://ftw.usatoday.com/wp-content/uploads/sites/90/2017/10/usp_mlb__world_series-houston_astros_at_los_angele_94995675.jpg?resize=1024,811',
            'https://media3.giphy.com/media/xT0GqhT8SulsK6DHmo/giphy.gif?cid=ecf05e47qfndut94g3w0n8l3dz2me3o9p9f63pcjddm9kbdl&rid=giphy.gif&ct=g',
            'https://i.giphy.com/media/Y1MGj9GGPniHm/giphy.webp'
          ]
        },
        {
          name: 'Cardinals',
          code: 'STL',
          path: 'cardinals',
          location: 'st louis',
          logo: 'https://content.sportslogos.net/logos/54/72/thumbs/3zhma0aeq17tktge1huh7yok5.gif',
          youtube: [ 'https://www.youtube.com/c/Cardinal' ],
          nicknames: [ 'st.louis', 'cardinals', 'cards', 'red birds' ],
          keywords: [],
          colors: [ '#C41E3A', '#0C2340' ],
          rivals: [ 'Cubs', 'Reds', 'Brewers', 'Pirates' ],
          images: [
            'https://content.sportslogos.net/logos/54/72/thumbs/3zhma0aeq17tktge1huh7yok5.gif',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MjIxOTYzMjU3MTA1/st-louis-cardinals-team-fredbird-cgd15050226_pirates_at_cardinalsjpg.webp',
            'https://i.pinimg.com/474x/1e/1f/fe/1e1ffe1624ceccd03bb4a084069f4e94--sports-women-st-louis-cardinals.jpg',
            'https://images.squarespace-cdn.com/content/v1/54cfafa1e4b059148956c403/1456158185860-EXBIMKU9CT019E6MMQGL/image-asset.jpeg?format=1500w',
            'https://i.pinimg.com/originals/c1/ca/31/c1ca31330cd639c31fcde9c962012107.png',
            'https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Fredbirdrants.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1166629942.jpeg',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN4ZKjNdPiGlMkNhq7DKiCgz5JQyNPiMzVWoyCZxidyvN-zrR9DI88fs0al_kq94WzFRA&usqp=CAU',
            'https://img.mlbstatic.com/mlb-images/image/private/ar_16:9,g_auto,q_auto:good,w_372,c_fill,f_jpg/mlb/hkmf3frt2zvt0p3kydj8',
            'https://thumbor.bigedition.com/jon-hamm/rZDsh6N5gnDOKpU5eJpIDX-FehI=/800x600/filters:format(webp):quality(80)/granite-web-prod/ef/e2/efe2925dc74744bc9bb63b712cf3a737.jpeg'
          ]
        },
        {
          name: 'Braves',
          code: 'ATL',
          path: 'braves',
          location: 'atlanta',
          logo: 'https://content.sportslogos.net/logos/54/51/thumbs/5171502018.gif',
          youtube: [ 'https://www.youtube.com/c/braves' ],
          nicknames: [ 'braves' ],
          keywords: [],
          colors: [ '#CE1141', '#13274F' ],
          rivals: [ 'Marlins', 'Mets', 'Phillies', 'Nationals' ],
          images: [
            'https://content.sportslogos.net/logos/54/51/thumbs/5171502018.gif',
            'https://thumbor.bigedition.com/ludacris/U3QRU6PpH7M4-KICMzxPNhDyavI=/800x600/filters:format(webp):quality(80)/granite-web-prod/5b/d1/5bd175e499734fd2b52aa903f9c4722b.jpeg',
            'https://a.espncdn.com/photo/2021/1013/r922229_1296x729_16-9.jpg',
            'https://www.gannett-cdn.com/presto/2018/09/14/USAT/3988f827-c4c2-42ad-9bb4-6c4beff97a37-AP_BRAVES_ROTATION.JPG?width=660&height=588&fit=crop&format=pjpg&auto=webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_594/MTY4MTI2MTE4NzIzODU1NjMz/atlanta-braves-tomahawk-team-gettyimages-541729482_masterjpg.webp',
            'https://i.pinimg.com/originals/b2/30/d4/b230d4a1601bd041ba5612a0dc3e4e98.jpg',
            'https://i.pinimg.com/originals/9d/dc/a3/9ddca3c59ebf1ebe816d5d1f8c88cefb.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w2208/mlb/kddkqnlwhimvz0ym2jec.jpg'
          ]
        },
        {
          name: 'Marlins',
          code: 'MIA',
          path: 'marlins',
          location: 'miami',
          logo: 'https://content.sportslogos.net/logos/54/3637/thumbs/363775442019.gif',
          youtube: [ 'https://www.youtube.com/c/marlins' ],
          nicknames: [ 'marlins', 'fish', 'fins' ],
          keywords: [],
          colors: [ '#00A3E0', '#EF3340' ],
          rivals: [ 'Braves', 'Mets', 'Phillies', 'Nationals' ],
          images: [
            'https://content.sportslogos.net/logos/54/3637/thumbs/363775442019.gif',
            'https://thumbor.bigedition.com/scott-stapp/L7L_9XgcajsmgZVX9JxifO7i75M=/800x600/filters:format(webp):quality(80)/granite-web-prod/99/36/9936d00430fa4f8ab4b9ea75dbacc4dd.jpeg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2011/02/jenmarlinmermaid.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_580/MTY4MTI2MTE4NDU3Mzg2MjU3/miami-marlins-energy-team-1c05c945eddf4e05b4317b71681cc7b2-0jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NDU3MTI0MTEz/miami-marlins-energy-team-375e3c0b2c554237849f6d6775bcdd8c-0jpg.webp',
            'https://www.si.com/.image/t_share/MTY4MjYxMzk1NDMxMzAyNDIx/marilins-mermaids-20110421_mje_su8_003jpg.jpg',
            'https://www.si.com/.image/t_share/MTY4MjYxMzk1NDMzMjAyOTY1/marilins-mermaids-20110411_kkt_su8_173jpg.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_500/MTY4MjYxMzk1MTYzNzg0NDY5/marilins-mermaids-20110405_ter_su8_371jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_513/MTY4MjYxMzk1OTY4NDM1NDc3/marilins-mermaids-20110424_pjc_su8_074jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_581/MTY4MjYxMzk1MTYyOTk4MDM3/marilins-mermaids-20110519_kkt_su8_654jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_641/MTY4MjYxMzk1MTYxNzUyODUz/marilins-mermaids-20110612_pjc_su8_073jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MjIxOTYxMDI4ODgx/miami-marlins-energy-team-a7b51e9abeff49dcac2f8937a1d6fa48-0jpg.webp'
          ]
        },
        {
          name: 'Mets',
          code: 'NYM',
          path: 'mets',
          location: 'new york',
          logo: 'https://content.sportslogos.net/logos/54/67/thumbs/m01gfgeorgvbfw15fy04alujm.gif',
          youtube: [ 'https://www.youtube.com/c/mets' ],
          nicknames: [ 'mets', 'metropolitans', 'metros' ],
          keywords: [],
          colors: [ '#002D72', '#FF5910' ],
          rivals: [ 'Braves', 'Marlins', 'Phillies', 'Nationals' ],
          images: [
            'https://content.sportslogos.net/logos/54/67/thumbs/m01gfgeorgvbfw15fy04alujm.gif',
            'https://thumbor.bigedition.com/jerry-seinfeld/R_xkFyYTjq55ilnkcvkT7Ag0xHU=/800x600/filters:format(webp):quality(80)/granite-web-prod/13/01/130101b425374169a9f8fdbf080e2193.jpeg',
            'https://media1.popsugar-assets.com/files/thumbor/mowlB8SbDMnkPwAzKFbkUMGZbMY/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/05/02/731/n/1922398/b99fffa9ffa078fa_150634237/i/Olympic-gold-medalist-Gabby-Douglas-threw-first-pitch.jpg',
            'https://apptrigger.com/wp-content/uploads/getty-images/2017/07/1233857892.jpeg',
            'https://i.pinimg.com/736x/16/8a/55/168a55d687bf37f35b41394621305d86--mets-game-tonight-my-mets.jpg',
            'https://cdn.abcotvs.com/dip/images/1044592_10202015-wabc-mets-fans-game3-1280-363-img.jpg',
            'https://cdn.cnn.com/cnnnext/dam/assets/151030235709-11-ws-game-three-1030-super-169.jpg',
            'https://pbs.twimg.com/media/Cg_ftEJU4AExb52?format=jpg&name=small',
            'https://pagesix.com/wp-content/uploads/sites/3/2015/10/people_billy_joel.jpg?quality=80&strip=all&w=1024',
            'https://i.pinimg.com/736x/04/94/b2/0494b2b0551950ff1574bd9ba5f95b50--mets-game-mets-baseball.jpg'
          ]
        },
        {
          name: 'Phillies',
          code: 'PHI',
          path: 'phillies',
          location: 'philadelphia',
          logo: 'https://content.sportslogos.net/logos/54/70/thumbs/7075792019.gif',
          youtube: [ 'https://www.youtube.com/c/phillies' ],
          nicknames: [ 'phillies', 'phils' ],
          keywords: [],
          colors: [ '#E81828', '#002D72' ],
          rivals: [ 'Braves', 'Marlins', 'Mets', 'Nationals' ],
          images: [
            'https://content.sportslogos.net/logos/54/70/thumbs/7075792019.gif',
            'https://thumbor.bigedition.com/tina-fey/c0JUhElbIgT7t3CauDCkmhuV3aM=/800x600/filters:format(webp):quality(80)/granite-web-prod/5a/bb/5abb7d8e18014e7597d214b470e24371.jpeg',
            'https://i.pinimg.com/474x/8b/97/2e/8b972e28617cd2addb728c019c5d1fc2--baseball-girls-baseball-jerseys.jpg',
            'https://pbs.twimg.com/media/D6T1BcIW4AEEt9W.jpg',
            'https://pbs.twimg.com/media/C9ekZdgXsAImWTL.jpg',
            'https://billypenn.com/wp-content/uploads/2021/05/philliesfan-springtraining-costume-crop-1024x576.jpg',
            'https://www.etonline.com/sites/default/files/styles/video_1920x1080/public/images/2019-05/eto_aol_bruce_willis_051619.jpg?h=59450369&itok=XoUX_1AI',
            'https://i.pinimg.com/originals/bb/7e/3c/bb7e3c90424159e8c17df7801dcb9f8f.jpg',
            'https://townsquare.media/site/399/files/2022/07/attachment-Untitled-design62.jpg',
            'https://i.giphy.com/media/4WWEOyzQSh0CXizWET/giphy.webp',
            'https://cdn.vox-cdn.com/thumbor/tPMNDZvQBIqNBhCt2DU143M9r6g=/0x0:3813x2541/1200x800/filters:focal(1602x966:2212x1576)/cdn.vox-cdn.com/uploads/chorus_image/image/69487083/usa_today_16286261.0.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NDU3MjU1MTg1/philadelphia-phillies-ballgirls-gettyimages-537995170jpg.webp'
          ]
        },
        {
          name: 'Nationals',
          code: 'WSH',
          path: 'nationals',
          location: 'washington',
          logo: 'https://content.sportslogos.net/logos/54/578/thumbs/rcehah9k0kekjkgzm077fflws.gif',
          youtube: [ 'https://www.youtube.com/c/washingtonnationals' ],
          nicknames: [ 'nationals', 'nats' ],
          keywords: [],
          colors: [ '#AB0003', '#14225A' ],
          rivals: [ 'Braves', 'Marlins', 'Mets', 'Phillies' ],
          images: [
            'https://content.sportslogos.net/logos/54/578/thumbs/rcehah9k0kekjkgzm077fflws.gif',
            'https://i.ytimg.com/vi/NDUjKDJZYvs/maxresdefault.jpg',
            'https://cdn.vox-cdn.com/thumbor/vK-PxHIujuIAsKrJLlzuq0_49f8=/0x0:3990x2660/1400x1400/filters:focal(0x0:3990x2660):format(jpeg)/cdn.vox-cdn.com/uploads/chorus_image/image/36919770/20140808_ajw_ad1_283.JPG.0.jpg',
            'https://jmuddd.files.wordpress.com/2013/07/haley_natpack.jpg',
            'https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/3K73RHWHYMI6VKBFQ4RAATSBKA.jpg',
            'https://wtop.com/wp-content/uploads/2017/03/032817_pitch14.jpg'
          ]
        },
        {
          name: 'Diamondbacks',
          code: 'ARI',
          path: 'diamondbacks',
          location: 'arizona',
          logo: 'https://content.sportslogos.net/logos/54/50/thumbs/gnnnrbxcmjhdgeu6mavqk3945.gif',
          youtube: [ 'https://www.youtube.com/c/Dbacks' ],
          nicknames: [ 'diamondbacks', 'd backs', 'snakes' ],
          keywords: [],
          colors: [ '#A71930', '#000000' ],
          rivals: [ 'Rockies', 'Dodgers', 'Padres', 'Giants' ],
          images: [
            'https://content.sportslogos.net/logos/54/50/thumbs/gnnnrbxcmjhdgeu6mavqk3945.gif',
            'https://thewiffhotstove.files.wordpress.com/2014/06/diamondbacks-sexy.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTgwNTc4MDY1NjI4Mjc1ODAw/madison-bumgarner.webp',
            'https://cdn.vox-cdn.com/thumbor/RzvdrqCmJ-Q5_sDYPLgkfbpX3vY=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/23395600/1240032769.jpg',
            'https://pbs.twimg.com/media/Dep0s5EVAAAY0Zr.jpg',
            'https://cdn.vox-cdn.com/thumbor/hxw1_-zgu_AsqNFBGH_y6Jc4lEI=/0x66:4000x2733/1400x1050/filters:focal(0x66:4000x2733):format(jpeg)/cdn.vox-cdn.com/uploads/chorus_image/image/11683453/166642095.0.jpg',
            'https://static.wikia.nocookie.net/sportsmascots/images/e/e5/Baxter.jpg/revision/latest/scale-to-width-down/340?cb=20160214013932',
            'https://3.bp.blogspot.com/-Yuemw5q7jPQ/TZ2pMnQ-rCI/AAAAAAAAAtE/5fXUFVWLrVU/s1600/diamondbacks+2.jpg'
          ]
        },
        {
          name: 'Rockies',
          code: 'COL',
          path: 'rockies',
          location: 'colorado',
          logo: 'https://content.sportslogos.net/logos/54/58/thumbs/5868712017.gif',
          youtube: [ 'https://www.youtube.com/c/Rockies' ],
          nicknames: [ 'rockies', 'rocks' ],
          keywords: [],
          colors: [ '#33006F', '#C4CED4' ],
          rivals: [ 'Diamondbacks', 'Dodgers', 'Padres', 'Giants' ],
          images: [
            'https://content.sportslogos.net/logos/54/58/thumbs/5868712017.gif',
            'https://travel.home.sndimg.com/content/dam/images/travel/fullset/2011/09/30/9f/idea_baseball-stadiums_003_596x334.rend.hgtvcom.966.644.suffix/1491586507224.jpeg',
            'https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MTc0NTA2MTQxMjQ0NDY3MTkw/the-greatest-players-in-colorado-rockies-history.webp',
            'https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MTc1NDU3OTc1NzkwNjA5OTg4/the-greatest-players-in-colorado-rockies-history.webp',
            'https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MTc1NDU3OTc1NzkwNjc1NTI0/the-greatest-players-in-colorado-rockies-history.webp',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w372/mlb/cfebsy9lsgoj3ndnasiv.jpg'
          ]
        },
        {
          name: 'Dodgers',
          code: 'LAD',
          path: 'dodgers',
          location: 'los angeles',
          logo: 'https://content.sportslogos.net/logos/54/63/thumbs/efvfv5b5g1zgpsf56gb04lthx.gif',
          youtube: [ 'https://www.youtube.com/c/Dodgers' ],
          nicknames: [ 'dodgers', 'blue crew', 'dodger blue' ],
          keywords: [],
          colors: [ '#005A9C', '#A5ACAF' ],
          rivals: [ 'Diamondbacks', 'Rockies', 'Padres', 'Giants' ],
          images: [
            'https://content.sportslogos.net/logos/54/63/thumbs/efvfv5b5g1zgpsf56gb04lthx.gif',
            'https://pagesix.com/wp-content/uploads/sites/3/2016/10/gettyimages-175119507.jpg?quality=90&strip=all&w=513',
            'https://pagesix.com/wp-content/uploads/sites/3/2016/10/gettyimages-453829586.jpg?quality=90&strip=all&w=814',
            'https://images.viacbs.tech/uri/mgid:arc:imageassetref:bet.com:5a12cbc0-4873-11e7-a442-0e40cf2fc285?quality=0.7',
            'https://pagesix.com/wp-content/uploads/sites/3/2016/10/gettyimages-471389344.jpg?quality=90&strip=all&w=722',
            'https://pagesix.com/wp-content/uploads/sites/3/2016/10/gettyimages-615944608.jpg?quality=90&strip=all&w=361',
            'https://ftw.usatoday.com/wp-content/uploads/sites/90/2017/10/gty_868767542_94996027.jpg?w=1000&h=600&crop=1',
            'https://ftw.usatoday.com/wp-content/uploads/sites/90/2017/10/usp_mlb__world_series-houston_astros_at_los_angele_94995679.jpg?resize=1030,1536',
            'https://data.nssmag.com/images/galleries/19642/856103948-0.jpg'
          ]
        },
        {
          name: 'Padres',
          code: 'SD',
          path: 'padres',
          location: 'san diego',
          logo: 'https://content.sportslogos.net/logos/54/73/thumbs/7375172020.gif',
          youtube: [ 'https://www.youtube.com/c/Padres' ],
          nicknames: [ 'padres', 'pads', 'friars' ],
          keywords: [],
          colors: [ '#2F241D', '#FFC425' ],
          rivals: [ 'Diamondbacks', 'Rockies', 'Dodgers', 'Giants' ],
          images: [
            'https://content.sportslogos.net/logos/54/73/thumbs/7375172020.gif',
            'https://data.nssmag.com/images/galleries/19642/openingnight6.jpg',
            'https://data.nssmag.com/images/galleries/19642/dsasd-01.jpg',
            'https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto,f_auto,h_2133,w_3200/https%3A%2F%2Ffriarsonbase.com%2Fwp-content%2Fuploads%2Fimagn-images%2F2017%2F07%2F14883508.jpeg',
            'https://static01.nyt.com/images/2021/08/24/sports/24mlb-padres-1/24mlb-padres-1-videoSixteenByNine3000.jpg',
            'https://mlbblogger.files.wordpress.com/2012/08/padres.jpg'
          ]
        },
        {
          name: 'Giants',
          code: 'SF',
          path: 'giants',
          location: 'san francisco',
          logo: 'https://content.sportslogos.net/logos/54/74/thumbs/cpqj6up5bvgpoedg5fwsk20ve.gif',
          youtube: [ 'https://www.youtube.com/c/Giants' ],
          nicknames: [ 'giants', 'gints', 'g men' ],
          keywords: [],
          colors: [ '#27251F', '#FD5A1E' ],
          rivals: [ 'Diamondbacks', 'Rockies', 'Dodgers', 'Padres' ],
          images: [
            'https://content.sportslogos.net/logos/54/74/thumbs/cpqj6up5bvgpoedg5fwsk20ve.gif',
            'https://fivethirtyeight.com/wp-content/uploads/2022/04/GettyImages-1391303069-4x3-1.jpg?w=916',
            'http://ww2.kqed.org/bayareabites/wp-content/uploads/sites/24/2012/11/mvp-fans1000a.jpg',
            'https://i.pinimg.com/originals/67/10/fd/6710fd26f1d611eca1ab558cc665ef79.jpg',
            'https://www.hollywoodreporter.com/wp-content/uploads/2012/10/rob_schneider_giants.jpg?w=800',
            'https://cdn.vox-cdn.com/thumbor/tuKIKBAAY0jAYUzQY-vd3bGBaA0=/0x0:3931x2621/920x613/filters:focal(1652x997:2280x1625):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/70959206/1401853555.0.jpg',
            'https://travel.home.sndimg.com/content/dam/images/travel/fullset/2011/09/30/95/idea_baseball-stadiums_004_596x334.rend.hgtvcom.966.644.suffix/1491586605176.jpeg'
          ]
        }
      ]
    },
    american: {
      title: 'American',
      icon: 'https://content.sportslogos.net/leagues/thumbs/53.gif',
      iconSource: 'image',
      teams: [
        {
          name: 'White Sox',
          code: 'CHW',
          path: 'whitesox',
          location: 'chicago',
          logo: 'https://content.sportslogos.net/logos/53/55/thumbs/oxvkprv7v4inf5dgqdebp0yse.gif',
          youtube: [ 'https://www.youtube.com/c/whitesox' ],
          nicknames: [ 'white sox', 'chisox' ],
          keywords: [],
          colors: [ '#27251F', '#C4CED4' ],
          rivals: [ 'Guardians', 'Tigers', 'Royals', 'Twins' ],
          images: [
            'https://content.sportslogos.net/logos/53/55/thumbs/oxvkprv7v4inf5dgqdebp0yse.gif',
            'https://miro.medium.com/max/1200/1*2Q7CrgIY0p0AosPckDm-6g.jpeg',
            'https://wgntv.com/wp-content/uploads/sites/5/2022/06/gettyimages-1401631395-594x594-1.jpg?w=594',
            'https://www.thesportsbank.net/wp-content/uploads/2013/06/Sexy-White-Sox.jpg',
            'https://assets.vogue.com/photos/5891529e85b3959618473343/master/pass/00-taylor-hill-baseball.jpg',
            'https://i.pinimg.com/originals/9b/6b/33/9b6b332c09d9e80f6db8abbb6b39c367.jpg',
            'https://media1.popsugar-assets.com/files/thumbor/hvz4UveMD2OyIxmCDtIHi0IwY60/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/05/02/834/n/1922398/94a1057dbdc86f26_115173834/i/Minnie-Driver-pitched-Chicago-White-Sox-June-2011.jpg'
          ]
        },
        {
          name: 'Guardians',
          code: 'CLE',
          path: 'guardians',
          location: 'cleveland',
          logo: 'https://content.sportslogos.net/logos/53/57/thumbs/5753472014.gif',
          youtube: [ 'https://www.youtube.com/c/clevelandguardians' ],
          nicknames: [ 'guardians', 'indians', 'wahoos' ],
          keywords: [],
          colors: [ '#0C2340', '#E31937' ],
          rivals: [ 'White Sox', 'Tigers', 'Royals', 'Twins' ],
          images: [
            'https://content.sportslogos.net/logos/53/57/thumbs/5753472014.gif',
            'https://thumbor.bigedition.com/drew-carey-and-bobblehead-doll/VQaFXoGkc0KD_jezX86CffHts1I=/800x600/filters:format(webp):quality(80)/granite-web-prod/5a/82/5a82d87c1cc24ab0a65b5c7434989674.jpeg',
            'https://i0.wp.com/www.fitnessgurls.com/wp-content/uploads/2017/05/mlb-sarah-e-evans-fitness-gurls-01.jpg?w=1000&ssl=1',
            'https://data.nssmag.com/images/galleries/19642/usa-today-9970221-0.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NzI3MjYzNTA1/cleveland-indians-strikers-dct160602009_kansas_city_at_clevelandjpg.webp'
          ]
        },
        {
          name: 'Tigers',
          code: 'DET',
          path: 'tigers',
          location: 'detroit',
          logo: 'https://content.sportslogos.net/logos/53/59/thumbs/5989642016.gif',
          youtube: [ 'https://www.youtube.com/c/Tigers' ],
          nicknames: [ 'tigers', 'tiggers', 'cats' ],
          keywords: [],
          colors: [ '#0C2340', '#FA4616' ],
          rivals: [ 'White Sox', 'Guardians', 'Royals', 'Twins' ],
          images: [
            'https://content.sportslogos.net/logos/53/59/thumbs/5989642016.gif',
            'https://thumbor.bigedition.com/eminem/f0JuIqRrLQdjIiDDrgYOWgKOaVo=/800x600/filters:format(webp):quality(80)/granite-web-prod/7a/9c/7a9cb764a7c04687b769440f6b65dea7.jpeg',
            'https://thewiffhotstove.files.wordpress.com/2014/06/tigers-teresa2.jpg',
            'https://www.usmagazine.com/wp-content/uploads/1351041491_15-tyra-banks-640.jpg?w=600&quality=86&strip=all',
            'https://img.mlbstatic.com/mlb-images/image/private/t_4x5/t_w2208/mlb/gxrarwmezklusu54ywmc.jpg',
            'https://editorial01.shutterstock.com/preview/5825265be/8646ef62/Shutterstock_5825265be.jpg',
            'http://detroit.cbslocal.com/wp-content/uploads/sites/15909782/2014/09/454848402.jpg',
            'https://i.pinimg.com/originals/78/1b/2b/781b2bda72d7b9f7bb1489146590acc1.jpg',
            'https://www.si.com/.image/t_share/MTgwMTk5OTUyMDA4MzU2OTg2/akil-baddoo-tigers.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NDU3OTc2MDgx/detroit-tigers-energy-squad-gettyimages-545865222jpg.webp'
          ]
        },
        {
          name: 'Royals',
          code: 'KC',
          path: 'royals',
          location: 'kansas city',
          logo: 'https://content.sportslogos.net/logos/53/62/thumbs/6283222019.gif',
          youtube: [ 'https://www.youtube.com/c/royals' ],
          nicknames: [ 'royals', 'blue crew' ],
          keywords: [],
          colors: [ '#004687', '#BD9B60' ],
          rivals: [ 'White Sox', 'Guardians', 'Tigers', 'Twins' ],
          images: [
            'https://content.sportslogos.net/logos/53/62/thumbs/6283222019.gif',
            'https://pbs.twimg.com/media/FPwvQygXoAYlCPA.jpg',
            'https://www.mvnews.org/wp-content/uploads/2015/04/20141015_0023-900x600.gif',
            'https://akns-images.eonline.com/eol_images/Entire_Site/2015520/rs_634x1024-150620090811-634.selena-gomez.cm.62015.jpg?fit=around%7C634:1024&output-quality=90&crop=634:1024;center,top',
            'https://cdn.vox-cdn.com/thumbor/qRddkHNOFEvWG-ZR1r99173_3pM=/0x0:8640x5760/1200x675/filters:focal(3036x1925:4418x3307)/cdn.vox-cdn.com/uploads/chorus_image/image/70721141/1239581613.0.jpg',
            'https://www.si.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTc5NzYyNjEyMTMxNjAzNjk0/salvador-perez-royals.jpg',
            'https://thumbor.bigedition.com/paul-rudd/MjwBHs_y1oRj-rvvvaDO1AUo6lM=/800x600/filters:format(webp):quality(80)/granite-web-prod/be/61/be61e72a294c45bf9074d9778678580d.jpeg',
            'https://data.nssmag.com/images/galleries/19642/usatsi-10141319-153192880-lowres.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NDU2NDY4NzUz/kansas-city-royals-kcrew-dgm160707309_mariners_at_royalsjpg.webp'
          ]
        },
        {
          name: 'Twins',
          code: 'MIN',
          path: 'twins',
          location: 'minnesota',
          logo: 'https://content.sportslogos.net/logos/53/65/thumbs/peii986yf4l42v3aa3hy0ovlf.gif',
          youtube: [ 'https://www.youtube.com/c/Twins' ],
          nicknames: [ 'twins', 'twinkies' ],
          keywords: [],
          colors: [ '#002B5C', '#D31145' ],
          rivals: [ 'White Sox', 'Guardians', 'Tigers', 'Royals' ],
          images: [
            'https://content.sportslogos.net/logos/53/65/thumbs/peii986yf4l42v3aa3hy0ovlf.gif',
            'https://dubsism.files.wordpress.com/2011/07/twins-hot-chick.jpg',
            'https://cdn.vox-cdn.com/thumbor/Abne75QYv5-donSu4st1ZBpq9Tg=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22869701/usa_today_16751873.jpg',
            'https://cdn.vox-cdn.com/thumbor/DM-id2wMKFwHOeBuTjUcTdRnh2I=/0x0:3685x2460/1200x800/filters:focal(1533x119:2121x707)/cdn.vox-cdn.com/uploads/chorus_image/image/68768218/1272476823.0.jpg',
            'https://thumbor.bigedition.com/jesse-ventura/xt-mRtJSxCqkMB-KVpjeow6js68=/800x600/filters:format(webp):quality(80)/granite-web-prod/4f/77/4f77c795004e4281b3cc5da6dec5e50d.jpeg',
            'https://s3.amazonaws.com/cdn.chatsports.com/wp-content/uploads/2014/06/BB11.jpg',
            'https://s.yimg.com/ny/api/res/1.2/s5QKGCYYnRGvFRnjOH0G_A--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTQyNw--/https://s.yimg.com/os/creatr-uploaded-images/2022-04/43f57fc0-bc54-11ec-bb7f-84b529f51499',
            'https://i.pinimg.com/236x/ff/04/37/ff043780734951171e406515fd6b2cbb--twin-girls-secret-love.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/iru7rt9rwa4hpzwoqebp.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/nr5tosi3mdu042uiumx2.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/jrkhxi382tpnfzy82ejw.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/mc522joghnopb11r95bh.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/eruddfjjtgiar0ysdzzn.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/byoablwpe9pxvjxqpx3d.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/mzogotx2lnqeiue27bev.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/eptkjvtboaw0surwj6wb.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/focxjervjrqsg6kubcsv.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/cit3q2lbiretdhgmfwhw.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/qlf7ywp0ctih5dp8qo2n.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/xnqfhiggtxy4oadghrxc.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/cksvut4qd4ooz1yn4fvv.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/aofmekhgh8y75h4djda3.jpg'
          ]
        },
        {
          name: 'Orioles',
          code: 'BAL',
          path: 'orioles',
          location: 'baltimore',
          logo: 'https://content.sportslogos.net/logos/53/52/thumbs/5258002019.gif',
          youtube: [ 'https://www.youtube.com/c/masnOriole' ],
          nicknames: [ 'orioles', 'birds', 'orange birds', 'oreos' ],
          keywords: [],
          colors: [ '#DF4601', '#000000' ],
          rivals: [ 'Red Sox', 'Yankees', 'Rays', 'Blue Jays' ],
          images: [
            'https://content.sportslogos.net/logos/53/52/thumbs/5258002019.gif',
            'https://thumbor.bigedition.com/michael-phelps/1T04ITS1SAs8rO5Dume6DH02IUo=/800x600/filters:format(webp):quality(80)/granite-web-prod/8b/b7/8bb7799db7c34caa968c93009bcf7534.jpeg',
            'https://www.thecoldwire.com/wp-content/uploads/2021/07/Trey-Mancini-Orioles-1536x1022.jpg',
            'https://pressboxonline.com/wp-content/uploads/2019/11/orioles19-529-camden-yards-800x445.jpg',
            'https://static.wikia.nocookie.net/sportsmascots/images/d/d8/Oriole.jpg/revision/latest?cb=20160215023915',
            'https://i.pinimg.com/736x/aa/a0/c1/aaa0c1a85f3a450af9e0c5268df43a94--orioles-baseball-baltimore-orioles.jpg',
            'https://live.staticflickr.com/66/206042888_da864c4a69_b.jpg',
            'https://eutawstreetreport.com/wp-content/uploads/2016/06/Screen-Shot-2016-06-23-at-10.00.08-AM.png',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/cmnrsyy7zqhjqjhmvfj8.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/rwxgrnexqhd6ssvyl5nr.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/gf1ndfmdxlfmumvaieog.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/yv62jtisrcnunrjmdutb.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/kijnmqu6exhr29zenzxl.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/tgpz8b0bat8dritanjog.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/k71cdxakemspqkphsofh.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/a6jybvli0yo88hdgppsr.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/pkuvqnu3yeg4xesr8ugt.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/pkuvqnu3yeg4xesr8ugt.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zmyys0sgzwequeggwprf.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/risvawiamzyjqmlfd908.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/risvawiamzyjqmlfd908.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/szpvownaorgpacww5it2.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/jclnvlxd3rmkbyapncny.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/sbygitlpfxgv3gcd771b.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/hi6cxzu6fkran2v7hncz.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/lfik0njmquq3rwvznwdc.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/lfik0njmquq3rwvznwdc.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/w5dsp20zhr7cn9lpl8ww.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/cons3peyy1gkekux36at.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/xua0sp2i27wikbgcozvn.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/p4x0yu12bq9520hg7cj3.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/cc5d2tsbbkisvykikwly.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/xzjbdyk13leljnqouteo.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/n1mi0veefynu2qt6npkg.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/x1jptrfunqxdkglawscq.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/d2brkl4q73d2k5ps8med.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/d2brkl4q73d2k5ps8med.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/qbvmz7zklpyr0fqaptrc.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/liokggtvgoyjgaov4tmg.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/rl5efe7pjnazftka4rxn.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/yfxohod8nfwbyez5m7ae.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/lik45mwb7vsyorazshfo.jpg'
          ]
        },
        {
          name: 'Red Sox',
          code: 'BOS',
          path: 'redsox',
          location: 'boston',
          logo: 'https://content.sportslogos.net/logos/53/53/thumbs/c0whfsa9j0vbs079opk2s05lx.gif',
          youtube: [ 'https://www.youtube.com/c/redsox' ],
          nicknames: [ 'red sox', 'cheaters', 'bo sox' ],
          keywords: [],
          colors: [ '#BD3039', '#0C2340' ],
          rivals: [ 'Orioles', 'Yankees', 'Rays', 'Blue Jays' ],
          images: [
            'https://content.sportslogos.net/logos/53/53/thumbs/c0whfsa9j0vbs079opk2s05lx.gif',
            'https://thumbor.bigedition.com/stephen-king/FpLfYIEfAnr7uG7_PZUMMNvzvls=/800x600/filters:format(webp):quality(80)/granite-web-prod/40/74/40746de5595a4409a29dc2b60e3c08a1.jpeg',
            'https://s3.amazonaws.com/cdn.chatsports.com/wp-content/uploads/2014/06/BB8.jpg',
            'https://media1.popsugar-assets.com/files/thumbor/gdQaYzN4UvlTX5V1UQrRaBX6t6Q/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2012/04/16/4/192/1922398/7781f3d62958b384_80950333_10/i/Gisele-B%C3%BCndchen-showed-her-strength-after-she-threw-out-first.jpg',
            'https://media1.popsugar-assets.com/files/thumbor/GPQ2c4vpLrWapAZJIVXNtLLJ8p4/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2012/04/16/4/192/1922398/85a17cc4888e2670_111692296_10/i/Ferrell-full-Boston-Red-Sox-gear-throw-pitch-July.jpg',
            'https://media1.popsugar-assets.com/files/thumbor/YnhLxnKCk-XABx3zQMQZJrKqDGA/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2012/04/16/4/192/1922398/cac99f2fb5a76d2e_88842893_10/i/July-2009-Mark-Wahlberg-wore-his-Boston-Red-Sox-gear-throw-out.jpg',
            'https://a.espncdn.com/photo/2021/1012/r921758_1296x729_16-9.jpg?w=300&r=16%3A9',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTkwNjg3NTA2NDk5MzgwMzQ5/michael-wacha-redsox.webp',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/bhjwqqfcxe2sg1dboua6.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/kbhit421vunlv340o9wn.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/yzgbl0nt7o8p431isfn3.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/efspzkyxioatekenz42y.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/sxulp0zpuvro5zpjnlxo.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/v2gtmbrdm8s8fmx3eh3y.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/qubv5vmrpwkbdaqjxsg8.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/toxnkwpsdtapepxtebpo.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/nisdmp6xrvchd7cl0izw.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/jqclfjjv0nhjqwvqxbgp.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/htxnqsgfbelwearuaowr.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/ci3v9aokknomljqlbwl4.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/loqjavajedpqa4njjpgs.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/bcjbaj84wgy6rusdxure.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/jwsobcijvvrzrp1ddnxk.jpg'
          ]
        },
        {
          name: 'Yankees',
          code: 'NYY',
          path: 'yankees',
          location: 'new york',
          logo: 'https://content.sportslogos.net/logos/53/68/thumbs/1256.gif',
          youtube: [ 'https://www.youtube.com/c/yankees' ],
          nicknames: [ 'yankees', 'yanks', 'pinstripes', 'bronx bombers' ],
          keywords: [],
          colors: [ '#E4002C', '#0C2340' ],
          rivals: [ 'Orioles', 'Red Sox', 'Rays', 'Blue Jays' ],
          images: [
            'https://content.sportslogos.net/logos/53/68/thumbs/1256.gif',
            'https://thumbor.bigedition.com/billy-crystal/-o-bX2Kl5zfUgBuUZ0XX7ZnMLSQ=/800x514/filters:format(webp):quality(80)/granite-web-prod/64/d6/64d6d1aa390d4db48945731c749edf71.jpeg',
            'https://www.usmagazine.com/wp-content/uploads/1351041340_8-george-clooney-chuck-knoblauch-640.jpg?w=600&quality=86&strip=all',
            'https://media1.popsugar-assets.com/files/thumbor/b5lQnKF7VHGgwWpDan6K_82y9ng/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/05/02/834/n/1922398/532ad34e0ee13024_52993884/i/Adam-Sandler-strapped-his-Yankees-gear-first-pitch.jpg',
            'https://data.nssmag.com/images/galleries/19642/ezgif-com-webp-to-jpg-4.jpg',
            'https://data.nssmag.com/images/galleries/19642/crop-exact-GettyImages-1049120918.jpg',
            'https://data.nssmag.com/images/galleries/19642/judge-5795833d-46fd-41a2-b79f-860d79427559.jpg',
            'https://data.nssmag.com/images/galleries/19642/mlb-socks-sneakers-2.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/rzni4hpual0m5hzexbgd.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/srostekgll7ehpskomjd.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/qooaacsdzszstgwz6t07.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/edutoruaelpclpuqdgkl.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ezfb6s5d2ylnvtg6csyp.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/egkbd5w9ac3rycbpfqsl.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/fnziiolx9eooykbxgape.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ujyepfhj21d7nljbyea8.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zv1rngl9lgcjh2rhvzex.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/q63wothvdci9q1eqo7xi.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/jo5kdklosldk0qa9hp3e.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/mqrlj54y7i6e5rjf4buz.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/jsgkda6n0qh9iokimztp.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/oipsmf3jh61q7i6jubqq.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zgrcpkw17weegxbaoonr.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/xadwg5bmvtyqw6vuzire.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/f0em5uztmmbusrzorb5o.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/azykdjhmaoswt4grdsx3.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/py9jt3korptiuqiei3i0.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ijbymoffyfpg52owywr3.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/mt8ckxawk9ufwydcxefi.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/msfbkek0zs9hff6walg0.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/clcpli8k72ravkwviwfb.jpg'
          ]
        },
        {
          name: 'Rays',
          code: 'TB',
          path: 'rays',
          location: 'tampa bay',
          logo: 'https://content.sportslogos.net/logos/53/2535/thumbs/253594482019.gif',
          youtube: [ 'https://www.youtube.com/c/Rays' ],
          nicknames: [ 'rays', 'mantas' ],
          keywords: [],
          colors: [ '#092C5C', '#8FBCE6' ],
          rivals: [ 'Orioles', 'Red Sox', 'Yankees', 'Blue Jays' ],
          images: [
            'https://content.sportslogos.net/logos/53/2535/thumbs/253594482019.gif',
            'https://thumbor.bigedition.com/dick-vitale/HoI5k0WMxzL7zYhz5EAHuP8tqNU=/800x600/filters:format(webp):quality(80)/granite-web-prod/05/5c/055c66f2d25640df9674668c3b9d126c.jpeg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NDU2MzM3Njgx/tampa-bay-rays-ray-teamjpg.webp',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w2208/mlb/t1l2hfukpvsumfochp0n.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2015/05/7.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2015/04/a1-5-029.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2015/04/a3-5-138.jpg',
            'https://ultimatecheerleaders.com/wp-content/uploads/2013/04/ray4.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/otivm85qpjh0s9x9sbji.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/fmwwtv7rqmb5fydszohk.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/pw26adv0gtivvgzl75h2.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/i8sdm5ry8rkfx0f7rc6s.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/wzbpna104es8jruz2gcy.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/whyfayc6s7gk7pcpzubm.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/kquyx2ffprbreasuhmfl.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/cz25fauabqnf4tkovo4r.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/f56vmgauxm9w6rimlqdn.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/iosdhoonol4h35tqgg57.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/mnsblsqbcp0y5bkvwtxx.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/cjkgmvmrt1ywp33xrbbr.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/yaauxbnfhux6d5xz5bt4.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/duapkkvltrdquf4wvrnm.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/hpcnwrqvxfslaroylphb.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/kvwojf9daufyjbilnaby.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/cjlczb9kbdwmq9umbdqu.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/eklg4unygezf9tltd8lt.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/p2yobzlj8dnupbxsisl8.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/ftsfcnjyyjbovrplklfg.jpg',
            'https://media2.giphy.com/media/d3Wlfyssa7WThwRuxq/giphy.webp'
          ]
        },
        {
          name: 'Blue Jays',
          code: 'TOR',
          path: 'bluejays',
          location: 'toronto',
          logo: 'https://content.sportslogos.net/logos/53/78/thumbs/7889872020.gif',
          youtube: [ 'https://www.youtube.com/c/bluejays' ],
          nicknames: [ 'blue jays', 'jays', 'blue birds' ],
          keywords: [],
          colors: [ '#134A8E', '#E8291C' ],
          rivals: [ 'Orioles', 'Red Sox', 'Yankees', 'Rays' ],
          images: [
            'https://content.sportslogos.net/logos/53/78/thumbs/7889872020.gif',
            'https://i.pinimg.com/736x/d7/ad/e2/d7ade2a972529c925e9a5949f74787d2--root-root-toronto-blue-jays.jpg',
            'https://i.pinimg.com/originals/09/10/a3/0910a39533162c127387942ba8a5f52a.jpg',
            'https://images.dailyhive.com/20210727140314/blue-jays-fans.jpeg',
            'https://thestar.blogs.com/.a/6a00d8341bf8f353ef019affab16c6970d-pi',
            'http://images.dailyhive.com/20170607185831/Jays-Fans-Safeco.jpg',
            'https://pbs.twimg.com/media/CL99ZPBWEAAsnC3.jpg',
            'https://images.squarespace-cdn.com/content/v1/54f9f8eee4b0cfcc918a27eb/1439674852813-A8NZDQHSK056OV6LXKZM/image-asset.jpeg',
            'https://i.dailymail.co.uk/i/pix/2015/08/07/05/2B29A74D00000578-3187627-image-a-9_1438922261704.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_613/MTY4MTg2MjIxOTU5MzI0OTQ1/toronto-blue-jays-cheerleaders-dca15042338_orioles_vs_blue_jaysjpg.webp',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/nmftdypbzjvg2234k9w7.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ghtxthouanffvly5ekec.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/bwgmibitv7ggbceaccn8.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/kz95roxumtyghrkaqlfz.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/v8oiswi43qdpwjah3u4s.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/u6sdguo1eo9weqhaxzrt.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zk3qzn8xryqwfwlqnstd.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/tgfckpad46btctkrqgmm.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/azldsqn95keqf3iw0vjx.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/lav4rhzgo8oqpzxf3ded.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/lh2adqwhwoinuns3hg0s.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/mo0eovneoosfhpsds7qw.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/ktimry7umoqc0fqxtc6k.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/wxldzfxfigiupb16j6v2.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/v4skbr6dqzyrulwo1phv.jpg'
          ]
        },
        {
          name: 'Astros',
          code: 'HOU',
          path: 'astros',
          location: 'houston',
          logo: 'https://content.sportslogos.net/logos/53/4929/thumbs/492995032000.gif',
          youtube: [ 'https://www.youtube.com/c/astros' ],
          nicknames: [ 'astros', 'asterisks', 'cheaters', 'cheatstros', 'stros' ],
          keywords: [],
          colors: [ '#002D62', '#EB6E1F' ],
          rivals: [ 'Angels', 'Athletics', 'Mariners', 'Rangers' ],
          images: [
            'https://content.sportslogos.net/logos/53/4929/thumbs/492995032000.gif',
            'https://i0.wp.com/www.fitnessgurls.com/wp-content/uploads/2017/05/fitness-gurls-astros.jpg?w=640&ssl=1',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NzI5MDMyOTc3/houston-astros-shooting-stars-si340_tk1_00762jpg.webp',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NzMyMjQ0MjQx/houston-astros-shooting-stars-dba160504_twins_vs_astros15jpg.webp',
            'https://static.wikia.nocookie.net/baseball/images/1/1e/4769828.jpg/revision/latest?cb=20170719202945',
            'https://www.papercitymag.com/wp-content/uploads/2021/05/Astros-_Dodgers_CK_0260_web-1024x768.jpg',
            'https://s.yimg.com/uu/api/res/1.2/71jLZrS_MEDr_T48SE0Ggg--~B/aD02NzU7dz0xMDI4O2FwcGlkPXl0YWNoeW9u/https://o.aolcdn.com/images/dims3/GLOB/crop/1063x698+0+0/resize/1028x675!/format/jpg/quality/85/http://o.aolcdn.com/hss/storage/midas/a2923d6c0f98e5ae8dfd5be02ff9b8f0/204169458/AstrosGirl%2B%25281%2529.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1024/mlb/ts8guuoysrfnw1p3flcq',
            'https://res.cloudinary.com/graham-media-group/image/upload/f_auto/q_auto/c_scale,w_800/v1/media/gmg/AG75EQX63BEFLNMJ3EKV7AYAR4.jpg?_a=AJAEtWI0',
            'https://a.espncdn.com/media/motion/2022/0705/dm_220705_Astros_tattoo_Greinke_in_5th_for_two_homers/dm_220705_Astros_tattoo_Greinke_in_5th_for_two_homers.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/axarailsayw6uixhi6eo.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/jwutpnqaecrtvz25dg3k.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/stxj7slwatzxcpzzsbmf.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/sd0vtrjisxx5haz2cr4j.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/jknov9monjkobtdvdogp.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ufgc1tuqgrybzflayeba.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/dave2xdngbeyaujqqcjt.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/lws9l6g9swvajtwbgcfa.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/nwt5litwvqhrcbzgmy0e.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/zvn4pq1fmjhfmz1g30i2.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/y3tcnbxdhbmsvo9ytuty.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/qja06atvmxm6hjvvzg1a.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/fzo8ysw89dse1c7g8uy4.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/f2dvd8ulhf28imtbkwqi.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/wm5xe3x5fksvjwbmraqe.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/gsmcawfh5ojbzpkcbbaw.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/bzmtnygm3ana7rs3pusj.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/erjjd2ohu92uheasx15n.jpg',
            ''
          ]
        },
        {
          name: 'Angels',
          code: 'LAA',
          path: 'angels',
          location: 'los angeles',
          logo: 'https://content.sportslogos.net/logos/53/6521/thumbs/652143892016.gif',
          youtube: [ 'https://www.youtube.com/c/Angels' ],
          nicknames: [ 'angels', 'halos' ],
          keywords: [],
          colors: [ '#003263', '#BA0021' ],
          rivals: [ 'Astros', 'Athletics', 'Mariners', 'Rangers' ],
          images: [
            'https://content.sportslogos.net/logos/53/6521/thumbs/652143892016.gif',
            'https://media.gq.com/photos/55d62edd95040f5e14f4df24/master/w_1600%2Cc_limit/EL.jpg',
            'https://data.nssmag.com/images/galleries/19642/1001512054-jpg-0.jpg',
            'https://data.nssmag.com/images/galleries/19642/960619764-jpg-0.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTI2MTE4NzMxMTMwMTI5/los-angeles-angels-strike-force-gettyimages-537430418jpg.webp',
            'https://www1.pictures.stylebistro.com/gi/Nina+Agdal+Celebrates+Opening+New+Era+Cap+PQ07Z8zA_KHl.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/uzbmqotqs0ktsqsjx6jh.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ahr2mdhkdtqnipl4nuhj.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/po97gcwbsi1ecraddguc.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ek9lgrfsetyntuo03q1o.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/beo1yra5ynpx5elbeh78.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/mgow2wafdvm71j5sn1ou.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/gzhaxad9yehfeda83qmh.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/orkywz8vrmgejfa2op1f.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/yrvw7vk7utjtzfyqtph7.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/m6jorrsa5x5yvvxtfsnz.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/kkci3ribu4fs1ykvncel.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/kqjpkqii2y9ybie8gl7f.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/mimrtkt7icjdx6opmxvl.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/p1kjbotjgetlbcmk0flz.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ztui20yluatzlhlzhlpj.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/sfzkpqucavq3c1ahbfix.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/wdvqiewi19hpydyb3cz3.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/gliojtqfeo08lbhg58kl.jpg'
          ]
        },
        {
          name: 'Athletics',
          code: 'OAK',
          path: 'athletics',
          location: 'oakland',
          logo: 'https://content.sportslogos.net/logos/53/69/thumbs/6xk2lpc36146pbg2kydf13e50.gif',
          youtube: [ 'https://www.youtube.com/c/Athletics' ],
          nicknames: [ 'athletics', 'as' ],
          keywords: [],
          colors: [ '#003831', '#EFB21E' ],
          rivals: [ 'Astros', 'Angels', 'Mariners', 'Rangers' ],
          images: [
            'https://content.sportslogos.net/logos/53/69/thumbs/6xk2lpc36146pbg2kydf13e50.gif',
            'https://thumbor.bigedition.com/tom-hanks/ebOfk6t_rlp0Wq--zU3iWy1lb4I=/800x800/filters:format(webp):quality(80)/granite-web-prod/aa/04/aa04d27c401f4eb8bc07c94598e5d256.jpeg',
            'https://data.nssmag.com/images/galleries/19642/34373855986-bd7b4b667d-b.jpg',
            'https://www.mercurynews.com/wp-content/uploads/2021/07/BNG-L-AS-0721-8.jpg?w=512',
            'https://i.pinimg.com/236x/bb/45/ed/bb45ed62bb558475d1641d9e41f15563--blonde-hair-makeup-baseball-fashion.jpg',
            'https://cdn.abcotvs.com/dip/images/5950276_021920-kgo-6p-spring-training-as-img_Image_00-00-01,08.jpg',
            'https://www.si.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MTY4MTg2MjIxOTYyMDc3NDU3/oakland-athletics-budweiser-girls-gettyimages-470393834jpg.webp',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/nou8kn7whmhztgbcw1ra.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/qnxg1gr2msry63uzqt8d.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ettgza2jdbxxydwjap6h.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ka8ramzxke9cdxn0mnzo.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/tylgvzg0hmvqhyj2xddg.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/kgid8tbjzvpdl52wton5.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/pc2rx9bo41squ8ppk7qr.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/h3s9hhzdwwiurit0hayg.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/jrpb8hmzhfya9dintr7e.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/d5bffbiyw326isvj6j9j.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/fvwdo3ush13jcmurgx8s.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/pycqr4xu16eudqgm2tzj.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/qnz5ubwnmltt4rq7odmu.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/kxnqzjooypaohy8dzaam.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/cazjdz7s8no0pajsre2g.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/zozsrovozazffotkvtyu.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/chn6skkbmz8m7yvq4nqd.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/oqib8a9ayo90bxkybcd8.jpg'
          ]
        },
        {
          name: 'Mariners',
          code: 'SEA',
          path: 'mariners',
          location: 'seattle',
          logo: 'https://content.sportslogos.net/logos/53/75/thumbs/1305.gif',
          youtube: [ 'https://www.youtube.com/c/mariners' ],
          nicknames: [ 'mariners', 'ms' ],
          keywords: [],
          colors: [ '#0C2C56', '#C4CED4' ],
          rivals: [ 'Astros', 'Angels', 'Athletics', 'Rangers' ],
          images: [
            'https://content.sportslogos.net/logos/53/75/thumbs/1305.gif',
            'https://thewiffhotstove.files.wordpress.com/2014/06/mariners-jersey.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/v0hgi1g963zdpqtxlioo.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/v0hgi1g963zdpqtxlioo.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/eux4z8ftkcnz5t6pzcwb.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/u77ijobhqpd0r56kaldh.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zjxfodlmbhjkupavqbf9.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/kqbvodrd9m6tmgg3y6bk.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/lrnw0ngsehb8cstb0xoc.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zhujzkkaxt9tmqda4nsi.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ols8x4slrphactixq3h9.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/wfhbu7qpqifkurxmmt9q.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/igk5sk1dpidwl7huanyk.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/bkt98fatzcr78bcxbvyn.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zs6jzl73j8jth3m6a59h.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/q4qstpcf9h5mzhwsijjh.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/cbl4hxib5fpwjoiwwrau.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/im8upiirovogq7jk6r36.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/jzxuun4zjtd0yxyxfcyf.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/wah0wzjiqrb3l1ueipjn.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/hje9wjseycwc5tv1c5i7.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zsgkxbwhe40oki5xluk4.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/qwgdsaaki5abqf35cjgx.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/ehorygxzwnkenfb0mfqy.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/m5ut0suwr0n4xfompn7t.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/clvf469ckvon7ge21y4w.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/yeymmxfjbmfh7uf4qxj4.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/obnl908wruhnpqj9gtfu.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/uez5fj3cyn8niuaftv2m.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/uez5fj3cyn8niuaftv2m.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/cc731ozen18ebxzw3ywh.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/xlukkxvpkezp7ysjheai.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/tttejzbkqdx7tyflcval.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/ueln02yt9igzcme7ssnz.jpg'
          ]
        },
        {
          name: 'Rangers',
          code: 'TEX',
          path: 'rangers',
          location: 'texas',
          logo: 'https://content.sportslogos.net/logos/53/77/thumbs/ajfeh4oqeealq37er15r3673h.gif',
          youtube: [ 'https://www.youtube.com/c/Rangers' ],
          nicknames: [ 'rangers', 'power rangers' ],
          keywords: [],
          colors: [ '#003278', '#C0111F' ],
          rivals: [ 'Astros', 'Angels', 'Athletics', 'Mariners' ],
          images: [
            'https://content.sportslogos.net/logos/53/77/thumbs/ajfeh4oqeealq37er15r3673h.gif',
            'https://media1.popsugar-assets.com/files/thumbor/sYuR14AqXi8QQXPWG3ESDLl0edY/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/05/02/834/n/1922398/0f73e6332aecc11c_53254257/i/Owen-Wilson-packed-heat-his-pitch-Texas-Rangers.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ugitf788ww60fy8eiqgo.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/rmupiwh354d26k18gp1k.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/hpyc7rtypzorv7yattns.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/e51bewtytxfdhxudokra.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/u9xjncxaevprd957tahu.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ld6ua99oq2uwt4gwopfv.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/lehsbbx9hsglgk1fjd6z.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/bm2iyjy5izwbxub04lv5.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/bm2iyjy5izwbxub04lv5.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/bm2iyjy5izwbxub04lv5.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/ro8z04r39y9gp6thd6mx.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/luk8xk3jxqoox2tnjlbp.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/oknra5iaw68uvgma4d4j.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/g1mmojlaidj6guv8eqsf.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/hcierlzslewsrmmnnaff.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/hcierlzslewsrmmnnaff.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/r9hjv3ojhepojnvoc7je.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/z6kpnqkcwk9kpfygwk7q.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/j0pxq0lmzo5pemsml6xq.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/uaxnw7tmnf8icdlk89cf.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/zn99xywynzcxdhl3nog8.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/sl4eocm7rnycs8imxcbm.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/erm7ebwpittjl2ozbnbj.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/sidqqowhdg09bcitrg4d.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/wjj0okvujncm7m4yjw0e.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/osgkox9oorj8lophq47l.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/f4uerh9qavrlvcvnxh0j.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/mpvpnwjoczdbwxcubibx.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/vypfbwmxz2kcwh0g2vfh.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/tldsrc3wwopofjczsutq.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/eolfaoxj9rlp9kujqbpw.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_w1536/mlb/j2djowc5gdo3k3ilw0jg.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/xvt4u7cbrkiyz6pfdmnm.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/ummweemoejtsmsrgxale.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/nrk9jm8atymkdq6wrx1s.jpg',
            'https://img.mlbstatic.com/mlb-images/image/private/t_16x9/t_w1536/mlb/vrs1irfnrt6zijl0sr8m.jpg'
          ]
        }
      ]
    }
  }
}
